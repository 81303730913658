/* eslint-disable ember/no-classic-components, quotes, space-before-function-paren, one-var, indent, prefer-template, no-implicit-coercion, no-multi-spaces, ember/no-classic-classes, ember/require-tagless-components, ember/no-component-lifecycle-hooks */
import Component from "@ember/component";
import Cleave from 'cleave.js';
import { isPresent } from '@ember/utils';

const { TimeFormatter } = Cleave;

const getValidatedTime = function (value) {
  let owner = this,
      result = '';
  value = value.replace(/[^0-9apmAPM]/g, '');
  let timeFormatOptions = owner.getTimeFormatOptions();
  owner.blocks.forEach(function (length, index) {
    if (value.length > 0) {
      let sub = value.slice(0, length),
          sub0 = sub.slice(0, 1),
          rest = value.slice(length);

      switch (owner.timePattern[index]) {
        case 'h':
          if (parseInt(sub0, 10) > timeFormatOptions.maxHourFirstDigit) {
            sub = '0' + sub0;
          } else if (parseInt(sub, 10) > timeFormatOptions.maxHours) {
            sub = sub0 + '';
          }

          break;

        case 'm':
        case 's':
          if (parseInt(sub0, 10) > timeFormatOptions.maxMinutesFirstDigit) {
            sub = '0' + sub0;
          } else if (parseInt(sub, 10) > timeFormatOptions.maxMinutes) {
            sub = sub0 + '';
          }

          break;

        case 'a':
          if (!sub.match(/^[apAP][mM]?/)) {
            sub = '';
          }

          break;
      }

      result += sub; // update remaining string

      value = rest;
    }
  });
  return this.getFixedTimeString(result);
};

const getFixedTimeString = function (value) {
  let owner = this,
      timePattern = owner.timePattern,
      blocks = owner.blocks,
      time = [],
      expectedLength = 0,
      ampmStartIndex = 6,
      secondStartIndex = 4,
      minuteStartIndex = 2,
      hourStartIndex = 0,
      second,
      minute,
      hour,
      ampm;

  expectedLength = blocks.reduce((sum, length) => sum + length, 0);

  hour = parseInt(value.slice(hourStartIndex, hourStartIndex + 2), 10);
  minute = parseInt(value.slice(minuteStartIndex, minuteStartIndex + 2), 10);

  if (timePattern.indexOf('s') > 0) {
    second = parseInt(value.slice(secondStartIndex, secondStartIndex + 2), 10);
  } else {
    second = 0;
  }

  if (timePattern.indexOf('s') > 0) {
    if (timePattern.indexOf('a') > 0) {
      ampm = value.slice(ampmStartIndex, ampmStartIndex + 2);
    } else {
      ampm = '';
    }
  } else {
    if (timePattern.indexOf('a') > 0) {
      ampm = value.slice(ampmStartIndex - 2, ampmStartIndex);
    } else {
      ampm = '';
    }
  }

  if (value.length === expectedLength) {
    time = this.getFixedTime(hour, minute, second, ampm);
  }

  owner.time = time;
  return time.length === 0 ? value : timePattern.reduce(function (previous, current) {
    switch (current) {
      case 'a':
        return previous + time[3];

      case 's':
        return previous + owner.addLeadingZero(time[2]);

      case 'm':
        return previous + owner.addLeadingZero(time[1]);

      case 'h':
        return previous + owner.addLeadingZero(time[0]);
    }
  }, '');
};

const getFixedTime = function (hour, minute, second, ampm) {
  second = Math.min(parseInt(second || 0, 10), 60);
  minute = Math.min(minute, 60);
  hour = Math.min(hour, 60);
  return [hour, minute, second, ampm];
};

TimeFormatter.prototype.getValidatedTime   = getValidatedTime;
TimeFormatter.prototype.getFixedTimeString = getFixedTimeString;
TimeFormatter.prototype.getFixedTime       = getFixedTime;

export default Component.extend({
  tagName: "input",
  attributeBindings: ["placeholder", "type", "disabled"],

  type: "text",

  input(event) {
    if (this.onChange) {
      this.onChange(this.element.value);
    }

    this.cleave.onChange(event);
  },

  change(event) {
    if (this.onChange) {
      this.onChange(this.element.value);
    }

    this.cleave.onChange(event);
  },

  keyDown(event) {
    if (this.onKeyDown) {
      this.onKeyDown(event);
    }

    this.cleave.onKeyDown(event);
  },

  didInsertElement() {
    this._super(...arguments);
    this.set("cleave", new Cleave(`input#${this.elementId}`, this.options));

    this._setDefaultValue();
  },

  didReceiveAttrs() {
    this._super(...arguments);

    if (!this.options) {
      throw new Error("Missing options object");
    }

    this._setDefaultValue();
  },

  _setDefaultValue() {
    if (this.cleave && isPresent(this.value)) {
      this.cleave.onInput(this.value.toString());
    }
  }
});
