/* eslint-disable ember/no-new-mixins, ember/no-get, object-shorthand, semi */
import { computed, get } from '@ember/object';
import { notEmpty } from '@ember/object/computed';
import { A } from '@ember/array';
import Mixin from '@ember/object/mixin';

export default Mixin.create({
  reports: null,
  hasReports: notEmpty('reports'),

  groupedReports: computed('reports', 'reports.@each.displayName', function() {
    let groupedReports = A();

    if (!this.reports) {
      return;
    }

    this.reports.forEach((report) => {
      let displayName = get(report, 'displayName');
      let hasDisplayName = groupedReports.findBy('displayName', displayName);
      if (!hasDisplayName) {
        groupedReports.pushObject({
          displayName: displayName,
          associatedReportConfigurationId: get(report, 'associatedReportConfigurationId'),
          reports: A(),
          canDeliverReports: get(report, 'canDeliverReports')
        });
      }
      groupedReports.findBy('displayName', displayName).reports.pushObject(report);
    });

    return groupedReports;
  }),
})
