import ApplicationSerializer from 'shared/serializers/application';

export default ApplicationSerializer.extend({
  attrs: {
    requisitionTemplateAssociations: { serialize: 'records' },
    requisitionTemplateGlobalCustomAttributes: { serialize: 'records', deserialize: 'ids' },
    requisitionTemplateSamplesGroups: { serialize: 'records', deserialize: 'ids' },
    printTemplate: { serialize: 'ids', deserialize: 'records' }
  }
});
