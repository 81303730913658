/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components, max-len, ember/require-return-from-computed, no-multi-spaces, ember/no-get, no-prototype-builtins */
import Component from '@ember/component';
import { notEmpty } from '@ember/object/computed';
import { getProperties, get, computed } from '@ember/object';
import { isNone } from '@ember/utils';
import layout from './template';

export default Component.extend({
  layout,
  tagName: 'div',
  classNames: ['cell'],
  classNameBindings: ['cellCssClass'],

  isLabel: notEmpty('values.firstObject.label'),

  _isInLine(line, columnIndex, rowIndex) {
    return line && ((line.line === 'column' && line.index === columnIndex) || (line.line === 'row' && line.index === rowIndex));
  },

  cellCssClass: computed('columnIndex', 'columnSelected', 'currentLine', 'isLabel', 'rowIndex', 'rowSelected', 'selectedLine', 'values', function() {
    let  { values, rowIndex, columnIndex, rowSelected, columnSelected, currentLine, selectedLine, isLabel } = getProperties(this, ['values', 'rowIndex', 'columnIndex', 'rowSelected', 'columnSelected', 'currentLine', 'selectedLine', 'isLabel']);
    let firstValue = values[0];

    if (this._isInLine(currentLine, columnIndex, rowIndex) || this._isInLine(selectedLine, columnIndex, rowIndex)) {
      return `${isLabel ? 'cell-label' : ''} line-selected`;
    }

    if (rowSelected === rowIndex && columnSelected === columnIndex) {
      return 'cell-selected';
    } else if (firstValue && firstValue.hasOwnProperty('id')) {
      return firstValue.hasOwnProperty('adapter_container_well') ? 'cell-active-with-adapter' : 'cell-active';
    } else if (isLabel) {
      return 'cell-label';
    }
  }),

  click() {
    let  { rowIndex, columnIndex, disableSelection, currentLine, isLabel } = getProperties(this, ['rowIndex', 'columnIndex', 'disableSelection', 'currentLine', 'isLabel']);

    if (disableSelection || (isLabel && isNone(currentLine))) {
      return false;
    }

    get(this, 'onSelect')(rowIndex, columnIndex);
  },

  mouseEnter() {
    let  { isLabel, rowIndex, columnIndex, allowLineSelection } = getProperties(this, ['isLabel', 'rowIndex', 'columnIndex', 'allowLineSelection']);

    if (isLabel && allowLineSelection) {
      get(this, 'onLabelHover')(rowIndex, columnIndex);
    }
  },

  mouseLeave() {
    let  { isLabel, allowLineSelection } = getProperties(this, ['isLabel', 'allowLineSelection']);

    if (isLabel && allowLineSelection) {
      get(this, 'onLabelBlur')();
    }
  }
});
