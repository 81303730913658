/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components, ember/no-component-lifecycle-hooks, ember/require-super-in-lifecycle-hooks, ember/no-get, ember/no-actions-hash, no-multi-spaces */
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import ArrayProxy from '@ember/array/proxy';
import { A } from '@ember/array';
import { isNone } from '@ember/utils';
import layout from './template';

export default Component.extend({
  layout,
  coriell: service(),

  selected: null,
  collection: null,
  showTitle: true,

  init() {
    this._collection = ArrayProxy.create({ content: A() });
    this._super(...arguments);
  },

  didReceiveAttrs() {
    let collection = this.get('collection');
    this.set('_collection.content', collection);
  },

  actions: {
    search(term) {
      return this.coriell.search.perform(term, this.organization);
    },

    select(entry) {
      this.set('selected', entry);
    },

    add() {
      let collection = this.get('_collection');
      let selected   = this.get('selected');

      if (isNone(collection.findBy('id', selected.id))) {
        collection.pushObject({ id: selected.id, name: selected.name });
      }

      this.set('selected', null);
    },

    remove(entry) {
      let collection = this.get('_collection');

      collection.removeObject(entry);
    }
  }

});
