/* eslint-disable ember/no-classic-components, quotes, ember/no-classic-classes, ember/no-get, max-len, semi, ember/require-computed-property-dependencies, ember/require-return-from-computed, ember/use-brace-expansion, space-before-blocks, space-before-function-paren, ember/no-actions-hash, indent, no-multi-spaces */
import { inject as service } from '@ember/service';
import { alias, notEmpty, filterBy, or, not } from '@ember/object/computed';
import Component from '@ember/component';
import { isEmpty, isPresent } from '@ember/utils';
import { set, computed } from '@ember/object';
import layout from './template';
import moment from 'moment';
import { attestationText } from 'shared/helpers/attestation-text';

export default Component.extend({
  layout,
  tagName: '',

  // Required Attributes
  components: null,
  requisition: null,

  onSave: () => {},
  onSignAndSave: () => {},
  onSaveDraft: () => {},
  onCancel: () => {},
  onSign: () => {},

  hideButtons: true,
  hideSaveDraftButton: true,

  showErrors: false,
  isSaving: false,
  showCancelConfirm: false,
  portalOrder: false,

  requisitionTemplate: alias('requisition.requisitionTemplate'),
  autoVerification: alias('requisitionTemplate.autoVerification'),
  currentSession: service('current-session'),
  currentUser: alias('currentSession.currentUser'),
  userName: alias('currentUser.name'),
  currentOrganization: service(),
  organization: alias('currentOrganization.organization'),

  reqFormText: computed('portalOrder', function() {
    return this.portalOrder ? 'order' : 'req form';
  }),

  requisitionText: computed('portalOrder', function() {
    return this.portalOrder ? 'order' : 'requisition';
  }),

  canComplete: computed('missingRequirements.[]', 'requisition.complete', function() {
    let missingRequirements = this.missingRequirements;
    return !this.get('requisition.complete') && missingRequirements.length === 0;
  }),

  fullyCompleted: computed.reads('requisition.fullyCompleted'),

  canPortalSign: computed('requisition.canPortalSign', 'hasMissingRequirements', function() {
    return this.get('requisition.canPortalSign') && !this.hasMissingRequirements;
  }),

  canSign: computed('canPortalSign', 'hasMissingRequirements', 'missingRequirements.[]', 'portalOrder', 'requisition.{canPortalSign,complete,portalSignedAt}', function() {
    return this.portalOrder ? this.canPortalSign : (this.requisition.complete && !this.hasMissingRequirements);
  }),

  hasMissingRequirements: computed('missingRequirements.[]', function() {
    return this.missingRequirements.length !== 0;
  }),

  signatureRequired: computed('portalOrder', 'requisition.{portalSignatureRequired,signatureRequired}', function() {
    return this.portalOrder ? this.get('requisition.portalSignatureRequired') : this.get('requisition.signatureRequired');
  }),

  portalSignatureRequired: computed('{portalOrder,portalSigned}', 'requisition.portalSignatureRequired', function() {
    return this.portalOrder && this.requisition.portalSignatureRequired && !this.portalSigned;
  }),

  statusChangeConfirmationNeeded: computed('requisition.status', 'requisition.processingStatus', 'requisition.hasMissingReports', function() {
    return (['held', 'canceled'].includes(this.requisition.status)) &&
      this.requisition.processingStatus === 'complete' &&
      this.requisition.reportingStatus === 'signed' &&
      !this.requisition.hasMissingReports;
  }),

  signatureText: computed('canSign', 'formattedAttestationDate', 'portalOrder', 'requisition.physician.name', 'statusChangeConfirmationNeeded', 'requisition.status', function() {
    let text = null;
    if (this.portalOrder) {
      let providerName = this.get('requisition.physician.name');
      text = attestationText([providerName, this.formattedAttestationDate]);
    } else if (this.statusChangeConfirmationNeeded) {
      text = `Verification will cause this requisition\'s ${this.requisition.status} status to be reverted. Do you wish to proceed?`;
    }
    return text;
  }),

  formattedAttestationDate: computed('organization', 'requisition.submittedAt', function() {
    let organization = this.organization;

    let date = moment();
    if (isPresent(this.requisition.submittedAt)) {
      date = moment.unix(this.requisition.submittedAt);
    }

    return date.format(organization.dateOutputFormat);
  }),

  isDraft: computed('requisition.status', function() {
    let status = this.get('requisition.status');
    return status === 'draft';
  }),

  isSubmitted: computed('requisition.status', function() {
    let status = this.get('requisition.status');
    return status && status !== 'draft' && status !== 'canceled';
  }),

  isCanceled: computed('requisition.status', function() {
    let status = this.get('requisition.status');
    return status === 'canceled';
  }),

  isNew: alias('requisition.isNew'),
  dateUpdated: alias('requisition.dateUpdated'),
  dateSubmitted: alias('requisition.dateSubmitted'),

  signed: alias('requisition.signed'),
  signedBy: alias('requisition.signedBy'),
  signedAt: alias('requisition.signedAt'),
  signatureSource: alias('requisition.signatureSource'),
  signatureMessage: computed('requisition.{sftpCredentialName,signatureSource}', 'signatureSource', 'signedBy', function() {
    let signedBy = this.signedBy;
    // let reqFormText = this.get('reqFormText');
    let reqFormText = 'Req Form';
    let sftpCredentialName = this.requisition.sftpCredentialName;

    if (signedBy) {
      if (this.signatureSource === 'csv_import') {
        return `${reqFormText} verified via csv import`;
      } else if (this.signatureSource === 'box_integration') {
        return `${reqFormText} verified via box integration`;
      } else if (this.signatureSource === 'hl7_import') {
        return `${reqFormText} verified by HL7 Import from SFTP (${sftpCredentialName})`;
      } else {
        return `${reqFormText} verified by ${signedBy}`;
      }
    }
  }),
  submitMessage: computed('canSign', 'portalOrder', 'signatureRequired', function() {
    let message = 'Save to complete';

    if (this.portalOrder) {
      if (this.signatureRequired) {
        message = 'Save to complete';

        if (this.canSign) {
          message = 'Sign and Submit to complete';
        }
      } else {
        message = 'Submit to complete';
      }
    }

    return message;
  }),

  portalSigned: alias('requisition.portalSigned'),
  portalSignedBy: alias('requisition.portalSignedBy'),
  portalSignatureRole: alias('requisition.portalSignatureRole'),
  portalSignedAt: alias('requisition.portalSignedAt'),

  missingMinRequirements: computed('components.@each.numMissingMinRequirements', function() {
    return this._aggregateComponentsBy('missingMinRequirements');
  }),

  missingRequirements: computed('components.@each.numMissingRequirements', function() {
    return this._aggregateComponentsBy('missingRequirements');
  }),

  missingRequisitionIdentifier: not('requisition.identifier'),

  errors: computed('components.@each.numErrors', function() {
    return this._aggregateComponentsBy('errors');
  }),

  _aggregateComponentsBy(key) {
    let components = this.components;
    let collection = [];

    components.forEach((component) => {
      collection.pushObjects(component.get(key) || []);
    });

    return Array.from(new Set(collection)); // remove duplicated
  },

  minRequirementsIncomplete: notEmpty('missingMinRequirements'),
  requirementsIncomplete: notEmpty('missingRequirements'),
  hasErrors: notEmpty('errors'),

  uploading: filterBy('components', 'isUploading', true),
  isUploading: notEmpty('uploading'),

  isSavingOrUploading: or('isSaving', 'isUploading'),
  cancelDisabled: alias('isSavingOrUploading'),
  saveDisabled: computed('isSavingOrUploading', 'minRequirementsIncomplete', 'portalOrder', 'requirementsIncomplete', function() {
    return this.isSavingOrUploading ||
      (this.portalOrder ? this.requirementsIncomplete : this.minRequirementsIncomplete);
  }),

  saveDraftDisabled: or('isSaving', 'isUploading', 'minRequirementsIncomplete'),

  triggerText: computed('hasErrors', 'showErrors', function() {
    if (this.showErrors) {
      return 'Hide errors';
    } else {
      if (this.hasErrors) {
        return 'Show errors';
      } else {
        return 'What\'s missing?';
      }
    }
  }),

  isOnHeld: computed('requisition.onHold', 'requisition.processingStatus', function() {
    let onHold = this.get('requisition.onHold');
    let processingStatus = this.get('requisition.processingStatus');
    return onHold || processingStatus === 'held';
  }),

  saveConfirmationMessage: computed('hasReports', 'isContainerIdentifierChanged', 'isIdentifierChanged', 'isSampleAutogenerateFromRequisition', 'isSampleDateReceivedChanged', 'isSampleIdentifierChanged', 'signed', function() {
    let changes = [];
    let reportMessage = 'A report has been generated for this requisition. You may need to regenerate the report to include the updated identifiers.';
    let autogenerateMessage = 'Please note that we will change the sample identifiers automatically because the current requisition template is configured to use the requisition identifier to autogenerate the samples identifiers.';
    // signAgainMessage is hardcoded in requisition-save component, but we won't show it if the saveConfirmationMessage is present
    // Adding the message here since we still want to notify the user if they need to sign again
    let signAgainMessage = 'Saving this requisition will require that it be signed again. Do you want to continue?';
    let extraMessage = [];

    if (this.isIdentifierChanged) {
      changes.push('Requisition Identifier');
    }
    if (this.isSampleIdentifierChanged) {
      changes.push('Sample Identifier');
    }
    if (this.isSampleDateReceivedChanged) {
      changes.push('Date Received');
    }
    if (this.isContainerIdentifierChanged) {
      changes.push('Container ID');
    }
    if (this.isSampleAutogenerateFromRequisition) {
      extraMessage.push(autogenerateMessage);
    }
    if (this.hasReports) {
      extraMessage.push(reportMessage);
    }
    if (this.signed) {
      extraMessage.push(signAgainMessage);
    }
    return `${changes.join(' and ')} changed! Do you want to proceed? ${extraMessage.join(' ')}`;
  }),

  isSampleAutogenerateFromRequisition: computed('requisition.requisitionTemplate.requisitionTemplateSamplesGroups.@each.sampleIdentifierInterpolation', function() {
    if (this.get('requisition.requisitionTemplate.requisitionTemplateSamplesGroups')) {
      let samplesGroups = this.get('requisition.requisitionTemplate.requisitionTemplateSamplesGroups');
      let interpolations = samplesGroups.map(sampleGroup => sampleGroup.sampleIdentifierInterpolation);
      if (interpolations === null || interpolations.length === 0) {
        return false;
      }
      for (let i = 0; i < interpolations.length; i++) {
        if (interpolations[i] !== null && interpolations[i].includes('requisition.identifier')) {
          return true;
        }
      }
    }
    return false;
  }),

  showSaveConfirmation: computed('requisition.isNew', 'isIdentifierChanged', 'isSampleIdentifierChanged', 'isSampleDateReceivedChanged', 'isContainerIdentifierChanged', function() {
    let isNew = this.get('requisition.isNew');
    if (isNew) {
      return false;
    }
    let identifierChanged = this.isIdentifierChanged;
    let samplesIdentifierChanged = this.isSampleIdentifierChanged;
    let samplesDateReceivedChanged = this.isSampleDateReceivedChanged;
    let containerIdentifierChanged = this.isContainerIdentifierChanged;
    return (identifierChanged || samplesIdentifierChanged || samplesDateReceivedChanged || containerIdentifierChanged);
  }),

  isIdentifierChanged: computed('isSaving', 'requisition.identifier', 'requisition.changedAttributes.identifier', function() {
    let requisition = this.requisition;
    let identifierChange = requisition.changedAttributes().identifier;
    if (!identifierChange) {
      return false;
    }
    return (identifierChange[0] !== identifierChange[1]);
  }),

  isSampleIdentifierChanged: computed('requisition.samples.@each.{identifier,isNew}', function() {
    let samples = this.get('requisition.samples');
    let s1Changed = null;
    let s2Changed = null;

    let s1 = samples.objectAt(0);
    if (s1 && !s1.isNew) {
      s1Changed = s1.changedAttributes().identifier;
    }

    let s2 = samples.objectAt(1);
    if (s2 && !s2.isNew) {
      s2Changed = s2.changedAttributes().identifier;
    }
    return (s1Changed || s2Changed);
  }),

  isSampleDateReceivedChanged: computed('requisition.samples.@each.dateReceived', function() {
    let samples = this.get('requisition.samples');
    let s1Changed = null;
    let s2Changed = null;
    if (samples.objectAt(0)) {
      let s1 = samples.objectAt(0);
      let dateChanged = s1.changedAttributes().dateReceived;

      if (dateChanged && dateChanged[0] && dateChanged[1]) { // don't compare emtpy dates
        s1Changed = dateChanged[0].getTime() !== dateChanged[1].getTime();
      }
    }
    if (samples.objectAt(1)) {
      let s2 = samples.objectAt(1);
      let dateChanged = s2.changedAttributes().dateReceived;
      if (dateChanged && dateChanged[0] && dateChanged[1]) { // don't compare emtpy dates
        s2Changed = dateChanged[0].getTime() !== dateChanged[1].getTime();
      }
    }
    return (s1Changed || s2Changed);
  }),

  firstSampleStates: computed('requisition.samples.@each.sampleStates', function() {
    let samples = this.get('requisition.samples');
    let firstSampleStates = [];
    samples.forEach((sample) => {
      let sampleState = sample.get('sampleStates').firstObject;
      firstSampleStates.push(sampleState);
    });
    return firstSampleStates;
  }),

  containers: computed('firstSampleStates.@each.container', 'requisition.isNew', function() {
    let isNew = this.get('requisition.isNew');
    if (isNew) {
      return null;
    }
    let containers = [];
    let sampleStates = this.firstSampleStates;
    sampleStates.forEach((sampleState) => {
      let container = sampleState.get('container');
      containers.push(container);
    });
    return containers;
  }),

  isContainerIdentifierChanged: computed('containers.@each.barcode', function() {
    let containers = this.containers;
    if (!containers) {
      return false;
    }
    let container1Changed = null;
    let container2Changed = null;
    if (containers.objectAt(0)) {
      let container1 = containers.objectAt(0);
      let changedContainerId1 = container1.changedAttributes().barcode;
      if (changedContainerId1 && changedContainerId1[0] && changedContainerId1[1]) {
        container1Changed = changedContainerId1[0] !== changedContainerId1[1];
      }
    }
    if (containers.objectAt(1)) {
      let container2 = containers.objectAt(1);
      let changedContainerId2 = container2.changedAttributes().barcode;
      if (changedContainerId2 && changedContainerId2[0] && changedContainerId2[1]) {
        container2Changed = changedContainerId2[0] !== changedContainerId2[1];
      }
    }
    return (container1Changed || container2Changed);
  }),

  hasReports: computed('requisition.reports', function() {
    let reports = this.get('requisition.reports');
    return (typeof reports !== 'undefined' && reports !== null && reports.length !== null && reports.length > 0);
  }),

  // checks to see if at least one sample for requistion has been received
  isSampleReceived: computed('requisition.samples.@each.received', function() {
    let samples = this.get('requisition.samples');

    samples.forEach((s1) => {
      let sampleReceived = s1.received;
      if (sampleReceived) {
        return true;
      }
    });

    return false;
  }),

  actions: {
    toggleErrors() {
      this.toggleProperty('showErrors');
    },

    save(redirect, sign) {
      this.set('isSaving', true);
      let complete = !this.hasMissingRequirements;
      this.set('requisition.complete', complete);

      if (sign) {
        this.onSignAndSave(redirect)
          .finally(() => {
            if (!this.isDestroyed) {
              this.set('isSaving', false);
            }
          });
      } else {
        this.onSave(redirect)
          .finally(() => {
            if (!this.isDestroyed) {
              this.set('isSaving', false);
            }
          });
      }
    },

    saveDraft(redirect) {
      this.set('isSaving', true);

      let complete = !this.hasMissingRequirements;
      this.set('requisition.complete', complete);

      this.onSaveDraft(redirect)
        .finally(() => {
          if (!this.isDestroyed) {
            this.set('isSaving', false);
          }
        });
    },

    sign(requisition, signature) {
      this.set('isSaving', true);
      requisition.set('portalOrder', this.portalOrder);
      this.onSign(requisition, signature)
        .finally(() => {
          if (!this.isDestroyed) {
            this.set('isSaving', false);
          }
        });
    },

    closeCancelConfirm() {
      set(this, 'showCancelConfirm', false);
    },

    cancel(checkChanges) {
      let requisition = this.requisition;
      let attributesToIgnore = ['uuid', 'projectId', 'processed', 'onHold'];
      let changedAttributes = requisition.changedAttributes();

      // get the actual user changes
      let userChanges = Object.keys(changedAttributes).filter((attr) => {
        let before = changedAttributes[attr][0];
        let after = changedAttributes[attr][1];

        if (attributesToIgnore.includes(attr)) {
          return false;
        } else {
          return !(isEmpty(before) && (isEmpty(after) || isEmpty(Object.keys(after))));
        }
      });

      if (checkChanges && requisition.get('hasDirtyAttributes') && userChanges.length > 0) {
        set(this, 'showCancelConfirm', true);
      } else {
        this.onCancel();
      }
    }
  }

});
