/* eslint-disable ember/no-classic-classes, key-spacing */
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

export default Model.extend({
  // Associations
  organization:           belongsTo('organization', { async: true }),
  webhookEndpointEvents:  hasMany('webhook-endpoint', { async: true }),

  // Attributes
  event:                  attr('string'),
  targetUrl:              attr('string'),

});
