/* eslint-disable ember/no-new-mixins, ember/no-get */
import { get } from '@ember/object';
import { alias, filter, filterBy, readOnly } from '@ember/object/computed';
import Mixin from '@ember/object/mixin';
import { inject as service } from '@ember/service';
import { isBlank, isEmpty } from '@ember/utils';

export default Mixin.create({
  currentOrganization: service(),
  flashMessages: service(),

  containerType: null,

  organization: readOnly('currentOrganization.organization'),
  containerWellOrder: alias('organization.containerWellOrder'),

  filteredBarcodeTemplates: filter('barcodeTemplates.@each.{containerTypes,isBiobank}', function(barcodeTemplate) {
    let containerTypes = barcodeTemplate.containerTypes;
    if (barcodeTemplate.isBiobank) {
      return false;
    }

    if (isEmpty(containerTypes) || isBlank(this.containerType)) {
      return true;
    }

    return containerTypes.includes(this.containerType);
  }),

  tubeBarcodeTemplates: filter('barcodeTemplates.@each.containerTypes', function(barcodeTemplate) {
    const containerTypes = get(barcodeTemplate, 'containerTypes');
    return containerTypes.includes('Tube');
  }),

  dymoTubeBarcodeTemplates: filterBy('tubeBarcodeTemplates', 'format', 'DYMO'),
  zebraTubeBarcodeTemplates: filterBy('tubeBarcodeTemplates', 'format', 'Zebra'),

  obcBarcodeTemplates: filterBy('filteredBarcodeTemplates', 'format', 'obc'),
  dymoBarcodeTemplates: filterBy('filteredBarcodeTemplates', 'format', 'DYMO'),
  zebraBarcodeTemplates: filterBy('filteredBarcodeTemplates', 'format', 'Zebra'),

  printers: alias('dymo.printers'),
  dymoPrinters: alias('dymo.dymoPrinters'),
  zebraPrinters: alias('dymo.zebraPrinters'),

  actions: {
    printDYMO(label, template, printer, printerOptions) {
      printerOptions = printerOptions || this.get('model.printerOptions');
      let messages = this.get('flashMessages');

      if (this.get('dymo').print(label, template, printer, printerOptions)) {
        messages.success('Label sent to printer');

      } else {
        messages.error('Failed sending label to printer');
      }
    },

    bulkPrint(tubes, template, printer) {
      tubes
        .then((entries) => {
          const sortOrder = this.containerWellOrder == 'column' ? 'reversePosition' : 'position';

          entries
            .sortBy(sortOrder)
            .forEach((tube) => {
              this.dymo.print(tube.barcode, template, printer, tube.printerOptions);
            });

          let name = 'tubes';
          if (entries.length === 1) {
            name = 'tube';
          }

          this.flashMessages.success(`${entries.length} ${name} sent to printer`);
        });
    }
  }

});
