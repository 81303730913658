import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! template-lint-disable no-redundant-fn }}\n<BootstrapDatepicker\n  {{on \"focusout\" (fn this.datePickerFocusOutAction)}}\n  @value={{@value}}\n  @format={{this.format}}\n  @placeholder={{this.placeholder}}\n  @clearBtn={{this.clearBtn}}\n  @todayHighlight={{this.todayHighlight}}\n  @forceParse={{this.forceParse}}\n  @startDate={{@startDate}}\n  @endDate={{@endDate}}\n  @autoclose={{this.autoclose}}\n  @readonly={{this.readonly}}\n  @disabled={{@disabled}}\n  @class={{this.class}}\n  @onChange={{@onChange}}\n  @data-test-id={{this.dataTestId}}\n  ...attributes\n/>", {"contents":"{{! template-lint-disable no-redundant-fn }}\n<BootstrapDatepicker\n  {{on \"focusout\" (fn this.datePickerFocusOutAction)}}\n  @value={{@value}}\n  @format={{this.format}}\n  @placeholder={{this.placeholder}}\n  @clearBtn={{this.clearBtn}}\n  @todayHighlight={{this.todayHighlight}}\n  @forceParse={{this.forceParse}}\n  @startDate={{@startDate}}\n  @endDate={{@endDate}}\n  @autoclose={{this.autoclose}}\n  @readonly={{this.readonly}}\n  @disabled={{@disabled}}\n  @class={{this.class}}\n  @onChange={{@onChange}}\n  @data-test-id={{this.dataTestId}}\n  ...attributes\n/>","moduleName":"shared/components/bs-datepicker.hbs","parseOptions":{"srcName":"shared/components/bs-datepicker.hbs"}});
import Component from '@glimmer/component';
import moment from 'moment';
import { isNone } from '@ember/utils';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class BsDatepickerComponent extends Component {
  @tracked format = this.args.format || 'mm/dd/yyyy';
  @tracked placeholder = this.args.placeholder;
  @tracked clearBtn = this.args.clearBtn || true;
  @tracked todayHighlight = this.args.todayHighlight || true;
  @tracked forceParse = this.args.forceParse || false;
  @tracked autoclose = this.args.autoclose || true;
  @tracked readonly = this.args.readonly || false;
  @tracked class = this.args.class || 'uppercase';
  @tracked dataTestId = this.args.dataTestId || 'bs-datepicker';

  @action
  datePickerFocusOutAction(event) {
    let targetValue = event.target.value;
    let currentDateValue = this.args.value;
    let onChange = this.args.onChange;

    if (!targetValue || !onChange || (currentDateValue && !currentDateValue.getTime())) {
      return;
    }

    let targetValueDate = moment(targetValue).toDate();
    if (isNone(currentDateValue) || targetValueDate.getTime() != currentDateValue.getTime()) {
      onChange(targetValueDate);
    }
  }
}
