/* eslint-disable ember/no-classic-components, ember/no-mixins, ember/no-classic-classes, ember/no-component-lifecycle-hooks, ember/no-get, dot-notation, max-len, quotes, ember/no-actions-hash */
import _array from 'lodash/array';
import { alias, notEmpty, equal, not } from '@ember/object/computed';
import { getProperties, set, get, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { isPresent, isNone } from '@ember/utils';
import { task, timeout } from 'ember-concurrency';
import Component from '@ember/component';
import layout from './template';

import SamplesGroupedByPosition from 'shared/mixins/samples-grouped-by-position';

const { remove } = _array;

export default Component.extend(SamplesGroupedByPosition, {
  store: service(),

  layout,

  tagName: '',

  allowMultipleSamplesForWell: false,
  allowPoolAssignment: false,
  containerConfigurations: null,
  enableAutomaticPlating: true,
  enableMetadataFields: true,
  isSourceContainer: true,
  location: true,
  model: null,
  platingType: 'samples',
  required: false,
  samples: [],
  showSampleStatus: false,
  useWorkflowSamples: false,
  validateSameRequisitionOnWell: false,
  canEditIdentifier: false,

  _configuration: alias('activity.workflowActivity.configuration'),
  _sourceContainerCandidates: alias('activity.sourceContainerCandidates'),
  _workflow: alias('activity.workflow'),
  hasSourceMetadataKeys: alias('configuration.sourceMetadata'),
  isLoadingSamples: alias('loadSamples.isRunning'),
  manifestOptions: alias('configuration.manifestOptions'),
  notUnknown: not('unknown'),
  platingStrategy: alias('activity.platingStrategy'),
  source: alias('model'),
  sourcePresent: notEmpty('source'),
  unknown: equal('source.containerType', 'Unknown'),
  containerTypePresent: notEmpty('source.containerType'),
  containerTypeKnown: not('unknown'),

  didReceiveAttrs() {
    this._super(...arguments);
    this.loadSamples.perform();
  },

  loadSamples: task(function *() {
    yield timeout(250);

    let activity = get(this, 'activity');
    let workflow = get(this, 'activity.workflow');

    let containerIds = [];

    if (isPresent(activity) && isPresent(workflow)) {
      if (this.get('isSourceContainer') && isPresent(this.get('activity.source'))) {
        containerIds.push(this.get('activity.source.id'));
      } else if (this.source) {
        containerIds.push(this.source.id);
      }

      let workflowActivity = activity.get('workflowActivity');
      let sourceSampleStateLabels = null;
      if (isPresent(workflowActivity) && workflowActivity.get('configuration') &&
        'sourceSampleStateLabel' in workflowActivity.get('configuration')) {
        sourceSampleStateLabels = workflowActivity.get('configuration')['sourceSampleStateLabel'];
      }

      const query = {
        workflow_id: get(workflow, 'id'),
        include_sample_state_data: true,
        sample_labels: sourceSampleStateLabels
      };

      if (isPresent(containerIds) && !this.useWorkflowSamples) {
        query.for_container = true;
        query.container_ids = containerIds;
      }

      let samples = yield this.store.query('sample', query);

      this.set('samples', samples);
      this.set('activity.samplesForContainer', samples);
    }
  }).restartable(),

  configuration: computed('containerConfiguration', 'containerConfigurations', 'model.label', function() {
    let { containerConfiguration, containerConfigurations } = getProperties(this, ['containerConfiguration', 'containerConfigurations']);

    if (isPresent(containerConfiguration)) {
      return containerConfiguration;
    } else if (isPresent(containerConfigurations)) {
      return containerConfigurations.findBy('label', get(this, 'model.label'));
    } else {
      return null;
    }
  }),

  label: computed('model.label', function() {
    let label = get(this, 'model.label');

    if (isNone(label)) {
      return "Container ID";
    }

    return `Container ID (label: ${label})`;
  }),

  hasWellAssignmentConfig: computed('resourcesConfig', function() {
    let resourcesConfig = get(this, 'resourcesConfig');

    return isPresent(resourcesConfig) && isPresent(resourcesConfig.filter((r) => r.resourceLabel === 'well-assignment'));
  }),

  showReadOnlyPlate: computed('platingStrategy', 'allowSampleSelection', 'readOnlyPlate', function() {
    let { platingStrategy, allowSampleSelection, readOnlyPlate } = getProperties(this, ['platingStrategy', 'allowSampleSelection', 'readOnlyPlate']);

    return readOnlyPlate || (allowSampleSelection && platingStrategy === 'automatic');
  }),

  showManualPlating: computed('allowSampleSelection', 'platingStrategy', 'readOnlyPlate', function() {
    let { platingStrategy, allowSampleSelection, readOnlyPlate } = getProperties(this, ['platingStrategy', 'allowSampleSelection', 'readOnlyPlate']);

    return !readOnlyPlate && (!allowSampleSelection || platingStrategy === 'manual');
  }),

  showAutomaticPlatingMessage: computed('allowSampleSelection', 'platingStrategy', function() {
    let { platingStrategy, allowSampleSelection } = getProperties(this, ['platingStrategy', 'allowSampleSelection']);
    return platingStrategy === 'automatic' && allowSampleSelection;
  }),

  showManualPlatingMessage: computed('allowSampleSelection', 'enableManualPlating', 'platingStrategy', function() {
    let { platingStrategy, allowSampleSelection, enableManualPlating } = getProperties(this, ['platingStrategy', 'allowSampleSelection', 'enableManualPlating']);
    return platingStrategy === 'manual' && allowSampleSelection && enableManualPlating;
  }),

  enablePlating: computed('allowSampleSelection', 'allowAdapterPlating', function() {
    let { allowAdapterPlating, allowSampleSelection } = getProperties(this, ['allowSampleSelection', 'allowAdapterPlating']);

    return allowAdapterPlating || allowSampleSelection;
  }),

  hasMultipleStrategy: computed('enablePlating', 'enableManualPlating', 'enableAutomaticPlating', 'hasWellAssignmentConfig', function() {
    let { enablePlating, enableAutomaticPlating, enableManualPlating, hasWellAssignmentConfig } = getProperties(this, ['enablePlating', 'enableAutomaticPlating', 'enableManualPlating', 'hasWellAssignmentConfig']);

    return enablePlating && (enableAutomaticPlating + enableManualPlating + hasWellAssignmentConfig) > 1;
  }),

  setPoolIndexesForLocation(wellLocation) {
    let wellAssignments = this.get('source.wellAssignments');
    let poolIndex = 0;
    wellAssignments.forEach((wa) => {
      if (wa.locations.includes(wellLocation)) {
        wa.pool_index = poolIndex;
        poolIndex += 1;
      }
    });
    this.set('source.wellAssignments', wellAssignments);
  },

  actions: {
    clearSelection() {
      const source = get(this, 'source');
      if (source && source.rollbackAttributes) {
        source.rollbackAttributes();
      }
      get(this, 'clearSelection')();
    },

    onSelectSample(wellLocation, sample) {
      let wellAssignments = get(this, 'source.wellAssignments');
      let allowMultipleWellsForSample = get(this, 'allowMultipleWellsForSample');
      let allowMultipleSamplesForWell = get(this, 'allowMultipleSamplesForWell');
      let sampleId = get(sample, 'id');
      let sampleIdentifier = get(sample, 'identifier');
      let sampleMetadata = get(sample, 'customAttributes');
      let requisitionId = sample.requisitionId;
      let addToLocation = true;

      let samplesGrouped = get(this, 'samplesByContainerAndPosition');
      let samplesInPosition = samplesGrouped[get(sample, 'containerBarcodeAndPosition')]; // get samples in the same container and position

      if (allowMultipleWellsForSample) {
        samplesInPosition.forEach((s) => {
          sampleIdentifier = get(s, 'identifier');

          if (allowMultipleSamplesForWell) {
            wellAssignments = get(this, 'source.wellAssignments');
            let sampleLocations = wellAssignments.filter((wa) => wa.sample_identifier === sampleIdentifier)[0];

            set(this, 'source.wellAssignments', wellAssignments.filter((wa) => wa !== sampleLocations));

            if (sampleLocations) {
              if (isPresent(sampleLocations.locations.filter((l) => l === wellLocation)[0])) {
                remove(sampleLocations.locations, function(l) {
                  return l === wellLocation;
                });
              } else {
                // adding the location for the sample
                sampleLocations.locations.pushObject(wellLocation);
              }
            } else {
              sampleLocations = {
                locations: [wellLocation],
                sample_id: sampleId,
                sample_identifier: sampleIdentifier,
                requisition_id: requisitionId,
                pool_index: get(s, 'sampleStatePoolIndex'),
                metadata: sampleMetadata
              };
            }

            if (sampleLocations.locations.length) {
              get(this, 'source.wellAssignments').pushObject(sampleLocations);
              this.setPoolIndexesForLocation(wellLocation);
            }

          } else {

            let sampleLocations = wellAssignments.filter((wa) => wa.sample_identifier === sampleIdentifier)[0];
            let locationWithSample = wellAssignments.filter((wa) => wa.locations.includes(wellLocation))[0];

            set(this, 'source.wellAssignments', wellAssignments.filter((wa) => wa.sample_identifier !== sampleIdentifier));

            // remove the location from other sample identifier if exists
            if (isPresent(locationWithSample)) {
              set(this, 'source.wellAssignments', wellAssignments.filter((wa) => !wa.locations.includes(wellLocation)));
              let removedLocation = remove(locationWithSample.locations, function(l) {
                return l === wellLocation;
              })[0];

              addToLocation = removedLocation === wellLocation && sampleIdentifier !== locationWithSample.sample_identifier;

              if (locationWithSample.locations.length) {
                get(this, 'source.wellAssignments').pushObject(locationWithSample);
              }
            }

            if (isPresent(sampleLocations)) {
              // on uncheck sample remove the location
              if (isPresent(sampleLocations.locations.filter((l) => l === wellLocation)[0])) {
                remove(sampleLocations.locations, function(l) {
                  return l === wellLocation;
                });
              } else if (addToLocation) {
                // adding the location for the sample
                sampleLocations.locations.pushObject(wellLocation);
              }
            } else {
              sampleLocations = {
                locations: [wellLocation],
                sample_id: sampleId,
                sample_identifier: sampleIdentifier,
                requisition_id: requisitionId,
                metadata: sampleMetadata
              };
            }

            get(this, 'source.wellAssignments').pushObject(sampleLocations);
            this.setPoolIndexesForLocation(wellLocation);
          }
        });
      } else {

        let selectedLocationAndSample = null;

        if (!allowMultipleSamplesForWell) {
          selectedLocationAndSample = get(this, 'source.wellAssignments').filter((wa) => wa.locations.includes(wellLocation) && samplesInPosition.map((s) => get(s, 'identifier')).includes(wa.sample_identifier))[0];
          set(this, 'source.wellAssignments', wellAssignments.filter((wa) => !wa.locations.includes(wellLocation))); // remove the assignment for the position if exist when deselecting
        }

        samplesInPosition.forEach((s) => {
          wellAssignments = get(this, 'source.wellAssignments');

          if (allowMultipleSamplesForWell) {
            set(this, 'source.wellAssignments', wellAssignments.filter((wa) => wa !== selectedLocationAndSample));
            selectedLocationAndSample = wellAssignments.filter((wa) => wa.locations.includes(wellLocation) && wa.sample_identifier === get(s, 'identifier'))[0];
          }

          if (!selectedLocationAndSample) {
            get(this, 'source.wellAssignments').pushObject({
              locations: [wellLocation],
              sample_id: sampleId,
              sample_identifier: get(s, 'identifier'),
              requisition_id: get(s, 'requisitionId'),
              pool_index: get(s, 'sampleStatePoolIndex'),
              metadata: sampleMetadata
            });
            this.setPoolIndexesForLocation(wellLocation);
          } else {
            get(this, 'source.wellAssignments').removeObject(selectedLocationAndSample);
          }
        });
      }
    },

    updateWellAssignments(wellAssignments) {
      set(this, 'source.wellAssignments', wellAssignments);
    }
  }
});
