/* eslint-disable ember/no-mixins, ember/no-get */
import { inject as service } from '@ember/service';
import { not } from '@ember/object/computed';
import { isPresent } from '@ember/utils';
import { get, computed } from '@ember/object';
import BaseComponent from '../requisition-base/component';
import Progress from 'shared/mixins/progress';
import Resources from 'shared/mixins/resources';
import layout from './template';

export default BaseComponent.extend(Progress, Resources, {
  layout,
  classNames: ['row', 'requisition-file-upload'],

  store: service(),

  label: null,
  title: null,

  templateKey: 'requisition.fileUpload',
  resources: computed('model.resources', 'label', function() {
    let label = get(this, 'label');
    let resources = get(this, 'model.resources');
    if (label) {
      return resources.reject((resource) => {
        return resource.get('label') !== label;
      });
    }

    return resources;
  }),

  isVisible: computed('template', 'template.requisition.fileUpload', function() {
    return isPresent(this.get('template.requisition.fileUpload'));
  }),

  incomplete: not('model.complete')

});
