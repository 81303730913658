/* eslint-disable ember/no-classic-classes, key-spacing */
import Model, { belongsTo, attr } from '@ember-data/model';

export default Model.extend({
  // Associations
  requisition: belongsTo('requisition'),
  sample: belongsTo('sample'),
  patient: belongsTo('patient'),
  globalCustomAttribute: belongsTo('global-custom-attribute'),

  // Attributes
  value:              attr()
});
