/* eslint-disable ember/no-get, ember/no-actions-hash, object-shorthand, ember/no-incorrect-calls-with-inline-anonymous-functions, no-console */
import { computed } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { debounce } from '@ember/runloop';
import WBPlateLookupComponent from 'shared/components/wb-plate-lookup/component';
import layout from './template';

export default WBPlateLookupComponent.extend({
  layout,

  _defaultContainerType: computed('filteredContainers', function() {
    let filteredContainers = this.filteredContainers;

    return filteredContainers.get('firstObject.value');
  }),

  actions: {
    lookupContainer() {
      let identifier = this.identifier;

      if (isEmpty(identifier)) {
        return;
      }

      debounce(this, function() {
        this._lookupContainer(identifier).then(
          (collection) => {
            let container = collection.get('firstObject');
            let callback = this.callback;

            if (container && container.get('positions.length')) {
              this.set('showMessage', false);
              this.set('container', container);
            } else {
              this.set('container', null);
              this.set('showMessage', true);
            }

            if (typeof callback === 'function') {
              callback({ container });
            }
          },
          (xhr) => {
            console.log(xhr);
          }
        );
      }, 500);
    }
  }

});
