/* eslint-disable key-spacing, ember/no-classic-classes, no-multi-spaces, semi, ember/require-return-from-computed, space-before-function-paren */
import Model, { attr, hasMany, belongsTo } from '@ember-data/model';
import { alias, equal } from '@ember/object/computed';
import { computed } from '@ember/object';
import moment from 'moment';
import { validator, buildValidations } from 'ember-cp-validations';

const MAX_RANGE_DAYS = 396;
export const CDC_COLUMNS = Object.freeze([
  'Requisition ID',
  'Test Panel Name',
  'Test Result',
  'Test Result (LOINC)',
  'Test Ordered Date',
  'Reported Date',
  'Sample ID',
  'Sample Type',
  'Sample Collection Date',
  'Sample Received Date',
  'Patient First Name',
  'Patient Middle Name',
  'Patient Last Name',
  'Patient DOB',
  'Patient Age',
  'Patient Age Units',
  'Patient Ethnicity',
  'Patient Race',
  'Patient Sex',
  'Patient Phone Number',
  'Patient Email',
  'Patient Address 1',
  'Patient Address 2',
  'Patient City',
  'Patient State/Region/Province',
  'Patient Zip/Postal Code',
  'Patient Country',
  'Patient MRN',
  'First Test',
  'Employed in Healthcare',
  'Symptomatic as Defined by CDC?',
  'Date of Symptom Onset',
  'Hospitalized?',
  'ICU?',
  'Resident in a Congregate Care Setting',
  'Pregnant?',
  'Provider Account Number',
  'Provider Account Name',
  'Provider Name',
  'Provider Address',
  'Provider City',
  'Provider State/Province/Region',
  'Provider Zip/Postal Code',
  'Provider Country',
  'Provider Phone Number',
  'Provider NPI Number',
  'Revised Report',
  'Original Reported Date',
  'Correction Reason',
  'Signature Comment',
  'Signed Unified Consent'
]);

// eslint-disable-next-line
const manyEmails = /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/;

const Validations = buildValidations({
  reportSelected: validator('presence', true),
  fromDate:       validator('presence', true),
  toDate:         validator('presence', true),
  validDateRange: validator('presence', true),
  organization:   validator('presence', true)
});

export default Model.extend(Validations, {
  organization: belongsTo('organization', { async: false }),
  user:         belongsTo('user', { async: false }),
  resources:    hasMany('resource', { async: false }),

  cdc:          attr('boolean', { defaultValue: true }),
  fromDate:     attr('datetime'),
  toDate:       attr('datetime'),
  status:       attr('string'),
  createdAt:    attr('date'),
  workflowIds:  attr(),
  workflowNames: attr(),
  providerAccountNames: attr(),
  providerAccountIds: attr(),
  providerNames: attr(),
  providerNpis: attr(),
  customParams: attr(),
  recipients: attr('string'),
  name: attr('string'),

  isGenerated: equal('status', 'Generated'),

  providerNamesWithNPI: computed('providerNames.[]', 'providerNpis.[]', function() {
    const names = this.providerNames || [];
    const npis  = this.providerNpis  || [];

    let npi;

    return names.map((name, idx) => {
      npi = npis[idx];

      return npi ? `${name} (${npi})` : name;
    })
  }),

  reportSelected: alias('cdc'),

  validDateRange: computed('{fromDate,toDate}', function () {
    if (this.fromDate && this.toDate) {
      const diff = moment(this.toDate).diff(moment(this.fromDate), 'days', true);
      return this.fromDate <= this.toDate && diff <= MAX_RANGE_DAYS  ? true : null;
    }
  }),

  reports: computed('resources.@each.name', function() {
    return this.resources.map((resource) => {
      return {
        name: resource.name,
        url: resource.publicUrl,
      }
    });
  })
});
