/* eslint-disable quotes, ember/no-classic-classes, object-shorthand, comma-spacing, semi, ember/no-get */
import { computed } from '@ember/object';
import { isPresent } from '@ember/utils';
import BaseComponent from 'shared/components/requisitions/requisition-base/component';
import ObjectProxy from '@ember/object/proxy';
import layout from "./template";

const DiagnosisProxy = ObjectProxy.extend({
  setUnknownProperty: function(key, value) {
    if (!key.match(/^([d][0-9])/)) {
      return null;
    }

    const idx = parseInt(key.substr(1,10), 10);
    const diagnosis = this.get('content.diagnosis');

    if (value !== undefined) {
      diagnosis[idx - 1] = value;
      this.set('content.diagnosis', diagnosis);
    }

    return value;
  },

  unknownProperty: function(key) {
    if (!key.match(/^([d][0-9])/)) {
      return null;
    }

    const idx = parseInt(key.substr(1,10), 10);
    const diagnosis = this.get('content.diagnosis');

    return diagnosis[idx - 1];
  }
})

export default BaseComponent.extend({
  layout,
  classNames: ['row', 'requisition-diagnosis'],

  templateKey: 'diagnosis',
  propertyNameSuffix: 'Diagnosis',
  readOnly: false,
  multipleChoiceSelection: null,

  init() {
    this._super(...arguments);
    this.set('proxy', DiagnosisProxy.create({ content: this.model || {} }));
  },

  isVisible: computed('template', 'template.diagnosis', function() {
    return isPresent(this.get('template.diagnosis'));
  }),

  missingRequirements: computed('_dirtyAttributes', 'proxy', 'requirements', function() {
    return this._missingFrom('requirements', this.proxy);
  })

});
