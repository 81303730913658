/* eslint-disable semi, max-len, space-infix-ops */
import showdown from 'showdown';

import Helper from '@ember/component/helper';
import { assert } from '@ember/debug';
import { htmlSafe } from '@ember/template'

export function formatMarkdown(params, options={ omitExtraWLInCodeBlocks: true, simplifiedAutoLink: true, tasklists: true }) {
  assert('must pass a single argument to {{format-markdown}}', params.length >= 1);

  let [markdown] = params;
  if (!markdown) {
    return;
  }
  let converter = new showdown.Converter(options);
  converter.setFlavor('github');
  let html = converter.makeHtml(markdown);

  return htmlSafe(html);
}

export default Helper.helper(formatMarkdown);
