import { helper as buildHelper } from '@ember/component/helper';
import { get } from '@ember/object';

export function testGeneSelected(params/*, hash*/) {
  let testGene = params[0];
  let testPanel = params[1];
  let requestedTests = params[2];
  let testGeneAssociations = params[3];
  let testGeneKey = testGene.get('key');
  let value = get(requestedTests, testGeneKey);
  if (!value) {
    return false;
  }
  let associatedTestPanels = get(testGeneAssociations, testGeneKey);

  if (associatedTestPanels === undefined) {
    return false;
  }

  if (associatedTestPanels.length <= 1) {
    return true;
  } else {
    return get(requestedTests, testPanel.get('key'));
  }
}

export default buildHelper(testGeneSelected);
