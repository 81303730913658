/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components, max-len, ember/no-get, ember/no-observers, quotes, ember/no-actions-hash */
import Component from '@ember/component';
import { not } from '@ember/object/computed';
import { observer, get, computed } from '@ember/object';
import layout from './template';

export default Component.extend({
  layout,

  requisition: null,
  classNames: ['requisition-processing-status'],
  enabledForProcessing: not('requisition.onHold'),
  processingStatus: computed('requisition.processingStatus', 'template.requisition.defaultProcessingStatus', function() {
    let defaultProcessingStatus = get(this, 'template.requisition.defaultProcessingStatus');
    let processingStatus = get(this, 'requisition.processingStatus');
    if (processingStatus === undefined && defaultProcessingStatus) {
      return defaultProcessingStatus;
    }
    return processingStatus;
  }),

  samplesReceived: observer('requisition.samples.@each.received', function() {
    let samples = this.get('requisition.samples');
    let received = false;
    samples.forEach((s1) => {
      let sampleReceived = get(s1, 'received');
      if (sampleReceived) {
        received = true;
        return;
      }
    });
    if (received) {
      this.set('requisition.onHold', false);
    } else {
      this.set('requisition.onHold', true);
    }
  }),

  canHold: computed('processingStatus', function() {
    let processingStatus = this.get('processingStatus');
    return (!processingStatus || processingStatus === "unprocessed" || processingStatus === "held");
  }),

  actions: {
    toggleOnHold() {
      this.toggleProperty('requisition.onHold');
    }
  }

});
