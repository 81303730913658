/* eslint-disable ember/no-classic-components, ember/no-classic-classes */
import Component from '@ember/component';
import layout from './template';

export default Component.extend({
  layout,
  tagName: '',

  requiredFields: null,
  hiddenFields: null,
  fillData: null,
  fillDataLabel: 'Use Patient Data',
  model: null,

});
