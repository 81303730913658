import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! template-lint-disable link-href-attributes no-action no-curly-component-invocation no-implicit-this }}\n<div class=\"add-button-container\">\n  {{#if @model}}\n    <LinkTo @route={{@route}} @model={{@model}} class=\"button-green\" ...attributes>\n      <span>{{@text}}</span>\n    </LinkTo>\n\n  {{else if queryParams}}\n    <LinkTo @route={{@route}} @query={{@queryParams}} class=\"button-green\" ...attributes>\n      <span>{{@text}}</span>\n    </LinkTo>\n\n  {{else if @onClick}}\n    <a {{on \"click\" this.onClick}} class=\"button-green\" role=\"button\" ...attributes>\n      <span>{{@text}}</span>\n    </a>\n\n  {{else}}\n    <LinkTo @route={{@route}} class=\"button-green\" ...attributes>\n      <span>{{@text}}</span>\n    </LinkTo>\n  {{/if}}\n</div>", {"contents":"{{! template-lint-disable link-href-attributes no-action no-curly-component-invocation no-implicit-this }}\n<div class=\"add-button-container\">\n  {{#if @model}}\n    <LinkTo @route={{@route}} @model={{@model}} class=\"button-green\" ...attributes>\n      <span>{{@text}}</span>\n    </LinkTo>\n\n  {{else if queryParams}}\n    <LinkTo @route={{@route}} @query={{@queryParams}} class=\"button-green\" ...attributes>\n      <span>{{@text}}</span>\n    </LinkTo>\n\n  {{else if @onClick}}\n    <a {{on \"click\" this.onClick}} class=\"button-green\" role=\"button\" ...attributes>\n      <span>{{@text}}</span>\n    </a>\n\n  {{else}}\n    <LinkTo @route={{@route}} class=\"button-green\" ...attributes>\n      <span>{{@text}}</span>\n    </LinkTo>\n  {{/if}}\n</div>","moduleName":"shared/components/add-button.hbs","parseOptions":{"srcName":"shared/components/add-button.hbs"}});
/* eslint-disable ember/no-empty-glimmer-component-classes */
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class AddButton extends Component {
  @action
  onClick(e) {
    e.preventDefault();
    if (this.args?.onClick) {
      this.args.onClick();
    }
  }
}
