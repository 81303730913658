define("ember-preferences/storage/serializable", ["exports", "@ember/object", "ember-preferences/storage/decorator"], function (_exports, _object, _decorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _object.default.extend(_decorator.default, {
    setItem(key, value) {
      return this._super(key, JSON.stringify(value));
    },
    getItem(key) {
      let value = this._super(key);
      if (typeof value === 'undefined' || value === null || value === '') {
        return value;
      }
      return JSON.parse(value);
    }
  });
});