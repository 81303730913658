/* eslint-disable ember/no-classic-classes */
import Model, { attr, belongsTo } from '@ember-data/model';

export default Model.extend({
  container: belongsTo('container', { polymorphic: true, async: false }),

  globalId: attr('string'),
  materialType: attr('string'),
  storageCondition: attr('string'),
  volume: attr('number'),
  unit: attr('string'),
  createdAt: attr('date'),
  updatedAt: attr('date'),
  rejected: attr('boolean', { defaultValue: false }),
  exceptionReason: attr('string'),
  notes: attr('string'),
  received: attr('boolean'),
});
