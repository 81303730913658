/* eslint-disable ember/no-mixins, no-multiple-empty-lines, ember/no-classic-classes, comma-spacing, key-spacing, no-implicit-coercion */
import Model, { belongsTo, attr } from '@ember-data/model';
import Externalable from 'shared/mixins/externalable';
import Documents from 'shared/mixins/documents';
import { isBlank } from '@ember/utils';
import { computed } from '@ember/object';


export default Model.extend(Externalable,Documents, {
  // Associations
  salesAccount:  belongsTo('sales-account'),

  // Attributes
  name:                          attr('string'),
  streetAddress:                 attr('string'),
  streetAddressLine2:            attr('string'),
  city:                          attr('string'),
  state:                         attr('string'),
  zipCode:                       attr('string'),
  country:                       attr('string'),
  faxNumber:                     attr('string'),
  phoneNumber:                   attr('string'),
  email:                         attr('string'),
  notes:                         attr('string'),
  createdAt:                     attr('date'),
  updatedAt:                     attr('date'),
  archived:                      attr('boolean'),
  archivable:                    attr('boolean'),

  fullAddress: computed('streetAddress', 'streetAddressLine2', 'city', 'state', 'zipCode', function() {
    let parts = ['streetAddress', 'streetAddressLine2', 'city', 'state', 'zipCode'];
    let address = parts.map(p => this.get(p)).filter(p => !!p).join(', ');
    return `${address}`;
  }),

  contactInfo: computed('phoneNumber', 'faxNumber', 'email', 'fullAddress', function() {
    let parts = ['fullAddress', 'email', 'phoneNumber'];
    let address = parts.map(p => this.get(p)).filter(p => !!p).join(', ');
    if (this.faxNumber) {
      if (isBlank(address)) {
        return `Fax # ${this.faxNumber}`;
      } else {
        return `${address}, Fax # ${this.faxNumber}`;
      }
    }
    return `${address}`;
  })
});
