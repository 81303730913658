/* eslint-disable max-len, ember/no-component-lifecycle-hooks, ember/no-actions-hash */
import { assert } from '@ember/debug';
import BaseComponent from '../../requisition-base/component';
import { inject as service } from '@ember/service';
import layout from './template';
import { computed } from '@ember/object';
import { task } from 'ember-concurrency';
import { isPresent, isBlank } from '@ember/utils';
import { htmlSafe } from '@ember/template';
import { alias, or } from '@ember/object/computed';
import { capitalize } from '@ember/string';

const FIELDS = {
  hasMedicalConsent: { name: 'Medical Consent' },
  hasPatientConsent: { name: 'Secondary Use Notification' },
};

export default BaseComponent.extend({
  layout,
  classNames: ['row', 'requisition-patient-consent'],

  store: service(),
  settings: service(),

  isVisible: true,
  readOnly: false,

  consentType: null,
  model: null,
  organization: null,
  salesAccount: null,
  templateOptions: null,

  portalOrder: alias('settings.portalOrder'),

  consentVersionIdKey: computed('consentType', function() {
    return `${this.consentType}ConsentVersionId`;
  }),

  overrideConsentVersionKey: computed('consentType', function() {
    return `override${capitalize(this.consentType)}ConsentVersion`;
  }),

  consentOverrideKey: computed('consentType', function() {
    return `${this.consentType}ConsentOverride`;
  }),

  hasConsentKey: computed('consentType', function() {
    return `has${capitalize(this.consentType)}Consent`;
  }),

  requisitionConsentVersionId: computed('consentVersionIdKey', 'model.{patientConsentVersionId,medicalConsentVersionId}', function() {
    return this.get(`model.${this.consentVersionIdKey}`);
  }),

  providerAccountConsentVersionId: computed('consentVersionIdKey', 'salesAccount.{patientConsentVersionId,medicalConsentVersionId}', function() {
    return this.get(`salesAccount.${this.consentVersionIdKey}`);
  }),

  requisitionTemplate: alias('model.requisitionTemplate'),

  templateConsentVersionId: computed('consentVersionIdKey', 'requisitionTemplate.{patientConsentVersionId,medicalConsentVersionId}', function() {
    return this.get(`requisitionTemplate.${this.consentVersionIdKey}`);
  }),

  consentVersionId: or('requisitionConsentVersionId', 'providerAccountConsentVersionId', 'templateConsentVersionId'),

  /**
   * Load required consent version
   *
   * Then setup the entitlements selected based on those provided by Consent and
   * based on the `entitlementsBitFlag` on the {Model.Requisition}
   **/
  loadConsentVersion: task(function *() {
    if (!this.consentVersionId) {
      return;
    }

    let consentVersion;
    if (this.consentVersion && this.consentVersion.id === `${this.consentVersionId}`) {
      consentVersion = this.consentVersion;
    } else {
      consentVersion = yield this.store.peekRecord('consent-version', this.consentVersionId) ||
      this.store.queryRecord('consent-version', {
        id: this.consentVersionId,
        for_portal: this.portalOrder
      });
    }

    let selectedEntitlements = [];
    if (this.model.entitlementsBitFlag > 0 && this.model.get(this.hasConsentKey) === 2 && consentVersion && consentVersion.entitlements.length) {
      consentVersion.entitlements.forEach((entitlement) => {
        if ((this.model.entitlementsBitFlag & entitlement.bitFlag) > 0) {
          selectedEntitlements.push(entitlement);
        }
      });
    }

    this.setProperties({
      consentVersion,
      selectedEntitlements
    });
  }).restartable(),

  /**
   * @returns current consent text
   */
  consentText: computed('consentVersion.consentText', 'organization.name', function() {
    if (!this.consentVersion) {
      return;
    }

    let consentText = this.consentVersion
      .consentText
      .replace(/\n/g, '<br />')
      .replace(/\[LAB\]/g, this.organization.name);

    return htmlSafe(consentText);
  }),

  required: computed('portalOrder', 'templateOptions', function() {
    return Boolean(this._requiredFields().length);
  }),

  /**
   * @private
   * @returns consent selection from template
   */
  templateDefaultValue: alias('templateOptions.defaultValue'),

  /**
   * @private
   * @return consent selection from provider account if override is enabled
   */
  providerAccountDefaultValue: computed('{overrideConsentVersionKey,consentOverrideKey}', 'salesAccount.{overridePatientConsentVersion,overrideMedicalConsentVersion,patientConsentOverride,medicalConsentOverride}', function() {
    if (!this.salesAccount.get(this.overrideConsentVersionKey)) {
      return;
    }
    return this.salesAccount.get(this.consentOverrideKey);
  }),

  /**
   * @private
   * @returns consent selection either from provider account or template
   */
  defaultConsent: computed('{providerAccountDefaultValue,templateDefaultValue}', function() {
    let defaultConsent = this.templateDefaultValue;
    if (!isBlank(this.providerAccountDefaultValue)) {
      defaultConsent = this.providerAccountDefaultValue;
    }

    return defaultConsent === 3 ? null : defaultConsent;
  }),

  /**
   * Current consent selection [0,1,2] or defaultConsent;
   * @returns selected consent if selection has been made or defaultConsent
   */
  currentConsent: computed('{defaultConsent,hasConsentKey}', 'model.{hasPatientConsent,hasMedicalConsent}', function() {
    let hasConsent = this.model.get(this.hasConsentKey);
    return [0, 1, 2].includes(hasConsent) ? hasConsent : this.defaultConsent;
  }),

  /**
   * Include `currentConsent` in cache key
   * @override
   */
  requirements: computed('{template,templateKey,currentConsent}', function() {
    return this._requirements();
  }),

  /**
   * @override
   * @returns list of required fields
   */
  _requirements() {
    const fieldsMapping = { setting: this.hasConsentKey };

    const requirements = [];
    const requiredFields = this._requiredFields();

    requiredFields.forEach((fieldKey) => {
      let field = fieldsMapping[fieldKey];
      if (isPresent(field) && isBlank(this.model.get(field)) && isBlank(this.currentConsent)) {
        requirements.push({ key: field, name: FIELDS[field].name });
      }
    });

    return requirements.uniq();
  },

  /**
   * @private
   * @returns list of required fields from templateOptions
   */
  _requiredFields() {
    return this
      .templateOptions
      .fields
      .filterBy(this.portalOrder ? 'portal' : 'lab', 'required')
      .mapBy('key');
  },

  didReceiveAttrs() {
    this._super(...arguments);
    this.loadConsentVersion.perform();
  },

  init() {
    this._super(...arguments);
    assert('`consentType` is a required attribute', this.consentType);
    assert('`model` is a required attribute', this.model);
    assert('`organization` is a required attribute', this.organization);
    assert('`templateOptions` is a required attribute', this.templateOptions);

    this.set('selectedEntitlements', []);
  },

  /**
   * Calculate the entitlements bit flag for all selected entitlements patient
   * has agreed to and set this value on the {Model.Requisition} record
   */
  updateEntitlementsBitFlag() {
    if (this.consentType === 'patient') {
      let bitFlag = this.selectedEntitlements.reduce((memo, e) => {
        return memo + e.bitFlag;
      }, 0);
      this.model.set('entitlementsBitFlag', bitFlag);
    }
  },

  actions: {
    /**
     * Add or remove entitlements from the patient selected ones.
     */
    toggleSelectedEntitlements(entitlement) {
      if (this.consentType === 'patient') {
        if (this.selectedEntitlements.includes(entitlement)) {
          this.selectedEntitlements.removeObject(entitlement);
        } else {
          this.selectedEntitlements.addObject(entitlement);
        }
        this.updateEntitlementsBitFlag();
        this.change();
      }
    },

    /**
     * Has consent on {Model.Requisition} record can be 0, 1, or 2. If patient
     * selects "opt out" of any of the entitlements then has consent it is 2.
     */
    setHasConsent(value) {
      this.model.set(this.hasConsentKey, value);

      // Reset selected entitlements
      if (value === 2) {
        this.set('selectedEntitlements', this.consentVersion.entitlements.toArray());
      } else {
        this.set('selectedEntitlements', []);
      }

      // calculate entitlements bit flag
      this.updateEntitlementsBitFlag();
      this.change();
    }
  }
});
