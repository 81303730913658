/* eslint-disable ember/no-classic-classes, no-multi-spaces, ember/no-get, ember/no-observers */
import { alias } from '@ember/object/computed';
import Service, { inject as service } from '@ember/service';
import { set, get, observer, computed } from '@ember/object';
import moment from 'moment';

export default Service.extend({
  _currentOrganization: service('current-organization'),
  organizationId: alias('_currentOrganization.organization.id'),
  paramDateFormat: 'YYYY-MM-DD hh:mm:ss',

  queryParams: {},

  timeTypeOptions: {
    dayofweek: [
      { name: 'Last 7 Days',  range: 7,  unit: 'Days' },
      { name: 'Last 15 Days', range: 15, unit: 'Days' },
      { name: 'Last 30 Days', range: 30, unit: 'Days' },
      { name: 'Last 60 Days', range: 60, unit: 'Days' }
    ],
    week: [
      { name: 'Last Week',     range: 1,  unit: 'Weeks' },
      { name: 'Last 4 Weeks',  range: 4,  unit: 'Weeks' },
      { name: 'Last 12 Weeks', range: 12, unit: 'Weeks' },
      { name: 'Last 36 Weeks', range: 36, unit: 'Weeks' }
    ],
    month: [
      { name: 'Last 3 Months',  range: 3,  unit: 'Months' },
      { name: 'Last 6 Months',  range: 6,  unit: 'Months' },
      { name: 'Last 12 Months', range: 12, unit: 'Months' },
      { name: 'Last 24 Months', range: 24, unit: 'Months' }
    ]
  },

  dateRangesFiltered: computed('queryParams.time_type', function() {
    let timeType = get(this, 'queryParams.time_type');
    return get(this, `timeTypeOptions.${timeType}`);
  }),

  selectedDateRange: null,

  customRangeSelected: computed('selectedDateRange', function() {
    let selectedDateRange = get(this, 'selectedDateRange');
    return selectedDateRange === 'custom';
  }),

  startDate: computed('queryParams.date_start', {
    get() {
      return get(this, 'queryParams.date_start') ? moment(get(this, 'queryParams.date_start')).toDate() : '';
    },
    set(key, value) {
      set(this, 'queryParams.date_start', value ? moment(value).startOf('day').toDate() : '');
      return value;
    }
  }),

  endDate: computed('queryParams.date_end', {
    get() {
      return get(this, 'queryParams.date_end') ? moment(get(this, 'queryParams.date_end')).toDate() : '';
    },
    set(key, value) {
      set(this, 'queryParams.date_end', value ? moment(value).endOf('day').toDate() : '');
      return value;
    }
  }),

  onSelectedDateRangeChange: observer('selectedDateRange', function() {
    this._updateParamsDates();
  }),

  _setOrganization: observer('organizationId', function() {
    let newQuery = this.queryParams;
    newQuery.organization_id = this.organizationId;
    this.set('queryParams', newQuery);
  }),

  _updateParamsDates() {
    let selectedRange = get(this, 'selectedDateRange');

    if (selectedRange !== 'custom') {
      let startDate = moment().subtract(selectedRange.range, selectedRange.unit);

      set(this.queryParams, 'date_start', startDate.startOf('day').toDate());
      set(this, 'queryParams.date_end', moment().endOf('day').toDate());
    }
  },

  _setParams() {
    let defaultRange = get(this, 'timeTypeOptions.month')[0];
    set(this, 'selectedDateRange', defaultRange);
    set(this, 'queryParams.organization_id', this.organizationId);
    set(this, 'queryParams.time_type', 'month');

    this._updateParamsDates();
  },

  _changeTimeType(type) {
    set(this, 'queryParams.time_type', type);
    set(this, 'selectedDateRange', get(this, `timeTypeOptions.${type}`)[0]);
  },

  _updateDateRange(value) {
    let selectedRange = (value === 'custom') ? value : get(this, 'dateRangesFiltered')[value];
    set(this, 'selectedDateRange', selectedRange);
  },

  init() {
    this._super(...arguments);
    this._setParams();
  }
});
