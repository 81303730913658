/* eslint-disable ember/no-classic-components, ember/no-mixins, ember/no-classic-classes, ember/require-tagless-components, ember/no-get, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { computed, get, set } from '@ember/object';
import { alias } from '@ember/object/computed';
import { scheduleOnce } from '@ember/runloop';
import { isBlank, isNone } from '@ember/utils';
import CurrentOrganization from 'shared/mixins/current-organization';

const FIELDS_TO_IGNORE = ['sampleCollectionDate', 'sampleCollectionTime', 'sampleCollectedBy'];

export default Component.extend(CurrentOrganization, {
  classNameBindings: ['isVisible::hidden'],

  // Attributes
  template: null,
  templateKey: null,
  model: null,
  register() {},
  unregister() {},

  // Override in sub component
  minRequirements: computed('template', function() {
    return [];
  }),
  missingMinRequirements: computed('_dirtyAttributes', 'minRequirements', 'model', function() {
    let model = this.get('model');
    return this._missingFrom('minRequirements', model);
  }),
  numMissingMinRequirements: computed('missingMinRequirements.[]', function() {
    return this._numMissingBy('missingMinRequirements');
  }),

  // Override in sub component
  requirements: computed('template', 'templateKey', function() {
    return this._requirements();
  }),
  missingRequirements: computed('_dirtyAttributes', 'missingMinRequirements', 'model', 'requirements', function() {
    let model = this.get('model');
    let requirements = this._missingFrom('requirements', model);
    let minRequirements = this.missingMinRequirements;

    return requirements.concat(minRequirements).uniq();
  }),
  numMissingRequirements: computed('missingRequirements.[]', function() {
    return this._numMissingBy('missingRequirements');
  }),

  _requirements() {
    let templateKey = this.get('templateKey');
    let template = this.get(`template.${templateKey}`);
    let suffix = this.get('propertyNameSuffix');

    if (template && template.fields) {
      let fields = template.fields.filter((field) => !FIELDS_TO_IGNORE.includes(field.key)).flatten();
      if (suffix) {
        return fields.filterBy('required', true)
          .map((object) => {
            set(object, 'name', `${object.name} ${suffix}`);
            return object;
          });

      } else {
        return fields.flatten().filterBy('required', true);
      }
    } else {
      return [];
    }
  },

  _dirtyAttributes: null,

  _updateDirtyAttributes() {
    if (this.isDestroyed) {
      return;
    }

    this.set('_dirtyAttributes', (new Date()).getTime());
  },

  _missingFrom(key, context) {
    let requirements = this.get(key);

    let collection = [];
    if (context) {
      requirements.forEach((object) => {

        let value = get(context, object.key);

        if (value && value._type === 'custom-attributes') {
          value = value.toString();
        } else if (value && object.valueType === 'object') {
          value = Object.keys(value);
        }

        if (isBlank(value)) {
          collection.pushObject(object.name);
        }
      });
    }

    return collection;
  },

  _numMissingBy(key) {
    let missing = this.get(key);

    if (isNone(missing)) {
      return 0;
    }

    return missing.length;
  },

  // TODO
  errors: computed('model.errors.[]', function() {
    return [];
  }),

  numErrors: alias('errors.length'),

  didReceiveAttrs() {
    this._super(...arguments);
    if (this.isVisible) {
      this.change();
    }
  },

  didInsertElement() {
    this._super(...arguments);
    if (this.isVisible && typeof this.register === 'function') {
      this.register(this);
    }
  },

  // possibly causing issue with watchers of destroyed components
  didDestroyElement() {
    this._super(...arguments);
    this.unregister(this);
  },

  onChange() {
    if (this.isDestroyed) {
      return;
    }
    this._updateDirtyAttributes();
    this.get('numMissingMinRequirements');
    this.get('numMissingRequirements');
  },

  change() {
    scheduleOnce('afterRender', this, this.onChange);
  }
});
