/* eslint-disable ember/no-classic-classes, key-spacing */
import Model, { attr } from '@ember-data/model';
import { alias } from '@ember/object/computed';

export default Model.extend({
  // Attributes
  name:        attr('string'),
  description: attr('string'),
  manuallyEditable: attr('boolean'),

  canRemove: alias('manuallyEditable')
});
