/* eslint-disable ember/no-classic-classes, key-spacing, brace-style, ember/no-get, no-multi-spaces, quotes, no-prototype-builtins */
import Model, { hasMany, attr, belongsTo } from '@ember-data/model';
import { equal } from '@ember/object/computed';
import { computed } from '@ember/object';

export default Model.extend({
  // Associations

  requisition:                   belongsTo('requisition'),
  sample:                        belongsTo('sample'),

  reportRecipients:              hasMany('report-recipient', { async: false }),

  status:                        attr('string'),
  documentName:                  attr('string'),
  sampleIdentifier:              attr('string'),
  sampleId:                      attr('number'),
  sourceType:                    attr('string'),
  displayName:                   attr('string'),
  resourceUrl:                   attr('string'),
  resourceId:                    attr(),
  resourceRemoteUrl:             attr('string'),
  documentId:                    attr('number'),
  associatedReportConfigurationId: attr('number'),
  createdAt:                     attr('date'),
  signedAt:                      attr('date'),
  signatures:                    attr(),
  downloads:                     attr(),
  workflow:                      attr(),
  archived:                      attr('boolean', { defaultValue: false }),
  archivable:                    attr('boolean'),
  isCorrection:                  attr('boolean'),
  reportGenerationRequests:      attr(),
  faxResults:                    attr(),
  reportEvents:                  attr(),
  documentPermissions:           attr({ defaultValue: () => { return {}; } }),
  deliveryOptions:               attr({ defaultValue: () => { return {}; } }),
  canDeliverReports:             attr('boolean'),
  result:                        attr('string', { defaultValue: '' }),

  statusText: computed('status', function() {
    let status = this.get('status');

    return status === 'generated' ? 'Unsigned' : status;
  }),

  extendedStatusText: computed('isCorrection', 'status', 'statusText', function()  {
    let status = this.get("statusText");
    if (this.get('isCorrection')) {
      return `${status} (Correction)`;
    } else {
      return status;
    }
  }),

  canView: computed('documentPermissions', function() {
    let permissions = this.get('documentPermissions');

    return permissions.visible;
  }),

  readyToSign: computed('status', 'documentPermissions', function() {
    let status = this.get('status');
    let permissions = this.get('documentPermissions');

    return status === 'generated' && permissions.hasOwnProperty('editable') && permissions.editable;
  }),

  isSigned: computed('status', function() {
    let status = this.get('status');
    return ['signed', 'downloaded'].includes(status);
  }),

  signedBy: computed('signatures', function() {
    let signatures = this.signatures || [];
    if (signatures.length > 0) {
      return signatures[0].user_name;
    } else {
      return '';
    }
  }),

  downloaded: computed('status', function() {
    let status = this.get('status');

    return status === 'downloaded';
  }),

  downloadedBy: computed('downloads', function() {
    let downloads = this.downloads || [];
    return downloads.length > 0 ? downloads[0].user_name : '';
  }),

  downloadedAt: computed('downloads', function() {
    let downloads = this.downloads || [];
    return downloads.length > 0 ? downloads[0].downloaded_at : '';
  }),

  multipleSignatures: computed('signatures.[]', function() {
    let signatures = this.signatures || [];
    return signatures.length > 1;
  }),

  multipleDownloads: computed('downloads.[]', function() {
    let downloads = this.downloads || [];
    return downloads.length > 1;
  }),

  generationFailed: equal('status', 'generation-failed'),

  fromWorkflow: equal('sourceType', 'WorkflowActivity'),

  failedMessages: computed('reportGenerationRequests', function() {
    let requests = this.reportGenerationRequests || [];
    let messages = [];

    requests.forEach(function(request) {
      if (!request.success) {
        messages.push(`Report generation failed.`);
      }
    });

    return messages;
  })
});
