/* eslint-disable ember/no-classic-classes, key-spacing */
import Model, { attr, belongsTo } from '@ember-data/model';

export default Model.extend({
  // Associations
  salesAccount:  belongsTo('sales-account', { async: false }),
  physician:     belongsTo('physician', { async: false }),

  archived:     attr('boolean'),
  archivable:   attr('boolean'),
});
