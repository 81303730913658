import { helper as buildHelper } from '@ember/component/helper';

export function parseError(response, type) {
  let messages = [];

  if (typeof response.errors === 'string') { // Add 406 errors to display
    messages.push(`Failed to ${type}. ${response.errors}.`);
  } else if (typeof response.errors === 'object') {
    messages.push(`Failed to ${type}. Check errors below.`);
    let errors = response.errors.map(e => e.detail);
    messages.push(`${errors.join(', ')}`);
  } else {
    messages.push(`Failed to ${type}. Check errors below.`);
  }

  if (response?.errors?.base) {
    let errors = response.errors.base.join(', ');
    messages.push(`${errors}`);
  }
  return messages;
}

export default buildHelper(parseError);
