/* eslint-disable ember/no-new-mixins, key-spacing, ember/no-get */
import { hasMany } from '@ember-data/model';
import { computed } from '@ember/object';
import Mixin from '@ember/object/mixin';

export default Mixin.create({
  // Associations
  folders:      hasMany('folder', { async: true }),
  documents:    hasMany('document', { async: true }),

  // Helper methods
  approvedDocuments: computed('documents', function() {
    return this.get('documents').filterBy('approved');
  })
});
