import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! template-lint-disable prettier }}\n<div class=\"control-group {{if @required 'required'}} {{@class}}\" ...attributes>\n\n  {{#if this.hasLabel}}\n    <label class=\"control-label\" data-test-id=\"control-label\"> {{@label}} </label>\n  {{/if}}\n\n  <div class=\"controls\" data-test-id=\"control\">\n    {{yield (hash invalid=this.hasValidationErrors errors=this.validationErrors)}}\n\n    {{#if this.hasError}}\n      <span class=\"error\" data-test-id=\"model-error\"> {{this.errors}} </span>\n    {{/if}}\n\n    {{#if this.hasValidationErrors}}\n      {{#each this.validationErrors as |error|}}\n        <span class=\"error\" data-test-id=\"validation-error\"> {{error}} </span>\n      {{/each}}\n    {{/if}}\n  </div>\n</div>", {"contents":"{{! template-lint-disable prettier }}\n<div class=\"control-group {{if @required 'required'}} {{@class}}\" ...attributes>\n\n  {{#if this.hasLabel}}\n    <label class=\"control-label\" data-test-id=\"control-label\"> {{@label}} </label>\n  {{/if}}\n\n  <div class=\"controls\" data-test-id=\"control\">\n    {{yield (hash invalid=this.hasValidationErrors errors=this.validationErrors)}}\n\n    {{#if this.hasError}}\n      <span class=\"error\" data-test-id=\"model-error\"> {{this.errors}} </span>\n    {{/if}}\n\n    {{#if this.hasValidationErrors}}\n      {{#each this.validationErrors as |error|}}\n        <span class=\"error\" data-test-id=\"validation-error\"> {{error}} </span>\n      {{/each}}\n    {{/if}}\n  </div>\n</div>","moduleName":"shared/components/control-group.hbs","parseOptions":{"srcName":"shared/components/control-group.hbs"}});
/* eslint-disable ember/no-computed-properties-in-native-classes, ember/use-brace-expansion, ember/no-get */
import { computed } from '@ember/object';
import { gt, or } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { get } from '@ember/object';
import { A } from '@ember/array';

export default class ControlGroup extends Component {
  @or('args.validations', 'args.model.validations') _validations;
  @gt('validationErrors.length', 0) hasValidationErrors;

  @computed('args.label')
  get hasLabel() {
    return !isEmpty(this.args.label);
  }

  @computed('_validations.messages.[]', 'args.{for,model}')
  get validationErrors() {
    if (this.args.for && this.args.model && isEmpty(get(this, `args.model.${this.args.for}`))) {
      return [];
    }

    return get(this, `_validations.attrs.${this.args.for}.messages`) ||
      get(this, `_validations.attrs.model.${this.args.for}.messages`);
  }

  @computed('args.for', 'args.model.errors.[]')
  get hasError() {
    let errors = get(this, 'args.model.errors');

    if (errors && errors.has && this.args.for) {
      return errors.has(this.args.for);
    }
    return false;
  }

  @computed('args.for', 'args.model.errors.[]')
  get errors() {
    let errors = get(this, 'args.model.errors');

    if (errors && this.args.for) {
      return errors.errorsFor(this.args.for).mapBy('message').join(', ');
    }
    return A();
  }
}
