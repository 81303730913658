/* eslint-disable ember/no-classic-classes, key-spacing */
import Model, { belongsTo, hasMany, attr } from '@ember-data/model';
import { computed } from '@ember/object';

export default Model.extend({
  // Associations
  // organization: DS.belongsTo('organization', { async: true }),
  organization: belongsTo('organization'),
  users:        hasMany('user', { async: true }),
  permissions:  hasMany('permission', { async: true }),

  // Attributes
  name: attr('string'),

  // collection of all activities and labels
  // used to generate the rolePermissionList
  allPermissions: null,

  rolePermissionList: computed('allPermissions', 'permissions.[]', function() {
    let self = this;

    let allPermissions = self.get('allPermissions');

    return allPermissions;
  })

});
