/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components, ember/no-actions-hash, semi */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { equal, or } from '@ember/object/computed';
import { task } from 'ember-concurrency';
import layout from './template';
import { inject as service } from '@ember/service';

export default Component.extend({
  layout,

  flashMessages: service(),
  settings: service(),

  classNames: ['requisition-mark-delivered'],

  requisition: null,

  isDelivered: equal('requisition.status', 'delivered'),
  isDisabled: or('updateTask.isRunning', 'isDelivered'),

  name: computed('settings.portalOrder', function() {
    return this.settings.portalOrder ? 'order' : 'requisition';
  }),

  updateTask: task(function *() {
    if (this.requisition) {
      this.requisition.setProperties({
        status: 'delivered',
        confirmationOnly: true,
        portalOrder: this.settings.portalOrder
      });

      yield this
        .requisition
        .save()
        .then(() => {
          this.flashMessages.success(`${this.name} marked as delivered`);
          if (!this.isDestroyed) {
            this.set('showConfirmation', false);
          }
        })
        .catch(() => {
          this.flashMessages.error(`Failed to update ${this.name}`);
          this.requisition.rollbackAttributes();
        });
    }
  }),

  actions: {
    show() {
      this.set('showConfirmation', true);
    },

    hide() {
      this.set('showConfirmation', false);
    },

    submit() {
      this.updateTask.perform();
    }
  }

})
