/* eslint-disable ember/no-classic-classes, ember/no-get */
import Service from '@ember/service';
import { isEmpty, typeOf } from '@ember/utils';
import Error from '@ember/error';

/**
 * Extracted from https://github.com/softlayer/sl-ember-behavior which is no
 * longer maintained
 */
export default Service.extend({
  behaviors: null,

  isAble(activity, resource, possible) {
    if (isEmpty(activity) &&
       isEmpty(resource)) {
      throw new Error(
        'services/sl-behavior.isAble() expects at least two parameters to be provided'
      );
    }

    const behaviors = this.get('behaviors');

    if ('string' !== typeOf(activity)) {
      throw new Error(
        'services/sl-behavior.isAble() expects `activity` parameter to be a String'
      );
    }

    if ('string' !== typeOf(resource)) {
      throw new Error(
        'services/sl-behavior.isAble() expects `resource` parameter to be a String'
      );
    }

    if ('boolean' !== typeOf(possible) &&
       'undefined' !== typeOf(possible)) {
      throw new Error(
        'services/sl-behavior.isAble() expects `possible` parameter to be a Boolean or undefined'
      );
    }

    if ('undefined' === typeOf(possible)) {
      possible = true;
    }

    let isAble = false;

    if (
      behaviors &&
      behaviors[ resource ] &&
      behaviors[ resource ][ activity ]
    ) {
      isAble = possible;
    }

    return isAble;
  },

  isUnable(activity, resource, possible) {
    return !this.isAble(activity, resource, possible);
  },

  setBehaviors(behaviors) {
    /* jshint ignore:start */
    if (('instance' !== typeOf(behaviors) &&
         'object' !== typeOf(behaviors)) ||
       'symbol' === typeof behaviors) {
      throw new Error(
        'services/sl-behavior.setBehaviors() expects "behaviors" parameter to be an Object'
      );
    }
    /* jshint ignore:end */

    this.set('behaviors', behaviors);
  }
});
