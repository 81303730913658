define("ember-bootstrap-datepicker/components/bootstrap-datepicker", ["exports", "@ember/utils", "@ember/component", "ember-bootstrap-datepicker/components/datepicker-support", "jquery"], function (_exports, _utils, _component, _datepickerSupport, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(_datepickerSupport.default, {
    instrumentDisplay: '{{input type="text"}}',
    classNames: ['ember-text-field'],
    tagName: 'input',
    attributeBindings: ['accesskey', 'autocomplete', 'autofocus', 'contenteditable', 'contextmenu', 'dir', 'disabled', 'draggable', 'dropzone', 'form', 'hidden', 'id', 'lang', 'list', 'max', 'min', 'name', 'placeholder', 'readonly', 'required', 'spellcheck', 'step', 'tabindex', 'title', 'translate', 'type'],
    type: 'text',
    forceParse: true,
    _forceParse() {
      if (!this.get('forceParse')) {
        return;
      }
      let date = null,
        format = this.get('format');
      if (!(0, _utils.isEmpty)(this.element.value) && !(0, _utils.isEmpty)(format)) {
        let dpg = _jquery.default.fn.datepicker.DPGlobal;
        date = dpg.parseDate(this.element.value, dpg.parseFormat(format));
      } else {
        date = Date.parse(this.element.value);
      }
      if (!isNaN(date)) {
        this.set('value', new Date(date));
      }
    }
  });
});