define("ember-preferences/mixin", ["exports", "@ember/object/mixin", "@ember/service"], function (_exports, _mixin, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @public
   * @module ember-preferences
   */
  /**
   * @public
   *
   * Mixin to inject the preferences service on other injectable objects, this
   * DRYs up and gives consistency to the code.
   *
   * @class Mixin
   * @example
   *
   *   import Component from '@ember/component';
   *   import PreferencesMixin from 'ember-preferences/mixin';
   *   import { alias } from '@ember/object/computed';
   *
   *   export default Component.extend(PreferencesMixin, {
   *     foo: alias('preferences.foo')
   *   });
   */
  var _default = _exports.default = _mixin.default.create({
    /**
     * @property preferences
     * @type {Ember.Service}
     * @public
     */
    preferences: (0, _service.inject)()
  });
});