/* eslint-disable ember/no-new-mixins, ember/no-get, quotes */
import { notEmpty } from '@ember/object/computed';
import Mixin from '@ember/object/mixin';

export default Mixin.create({
  isUploading: false,
  hasResources: notEmpty('resources'),

  actions: {
    setUploading(boolean) {
      this.set('isUploading', boolean);
    },

    updateResource(resource) {
      let resources = this.get('resources');

      if (typeof resources === "undefined") {
        resources = this.get('model.resources');
      }

      resource.save().then(function() {
        resources.addObject(resource);
      });
    },

    updateResourceGroup(resourceGroup) {
      let resourceGroups = this.get('resourceGroups');

      if (typeof resourceGroups === "undefined") {
        resourceGroups = this.get('model.resourceGroups');
      }

      resourceGroups.addObject(resourceGroup);
    }
  }
});
