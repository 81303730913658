/* eslint-disable ember/no-new-mixins, ember/no-get */
import { inject as service } from '@ember/service';
import Mixin from '@ember/object/mixin';

export default Mixin.create({
  currentSession: service(),
  userPreference: null,

  async init() {
    this._super(...arguments);
    let user = await this.currentSession.currentUser;
    let userPreference = await user.userPreference;
    if (!this.isDestroyed) {
      this.set('userPreference', userPreference);
      this.userPreferenceLoaded(userPreference);
    }
  },

  updateLastAction(key, value) {
    let userPreference = this.get('userPreference');
    if (userPreference) {
      let lastAction = userPreference.lastAction;
      lastAction[key] = value;
      userPreference.set('lastAction', lastAction);
      userPreference.save();
    }
  }

});
