import EmberTooltip from 'ember-tooltips/components/ember-tooltip';

export default class UiTooltip extends EmberTooltip {
  event = 'hover';
  popperOptions = {
    modifiers: {
      flip: {
        enabled: false,
      },
    }
  };
}