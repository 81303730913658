/* eslint-disable ember/no-classic-classes, key-spacing */
import Model, { belongsTo, attr } from '@ember-data/model';

export default Model.extend({

  projectRequisitionTemplateAssociation:  belongsTo('project-requisition-template-association', { async: false }),

  configuration: attr(),
  matchingStrategy: attr('string')

});
