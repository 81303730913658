/* eslint-disable quotes */
import { not } from '@ember/object/computed';
import BaseComponent from 'shared/components/requisitions/requisition-base/component';
import layout from "./template";

export default BaseComponent.extend({
  layout,
  classNames: ['row', 'consent-information'],

  // Attributes
  template: null,
  model: null,
  readOnly: false,
  templateKey: 'consentInformation',
  isExisting: not('model.isNew'),

  isVisible: true

});
