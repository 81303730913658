/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components, space-before-function-paren, object-shorthand, semi, dot-notation, ember/no-get, ember/no-actions-hash, quotes */
import Component from '@ember/component';
import { computed, set } from '@ember/object';
import { alias, gt } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { task, timeout } from 'ember-concurrency';
import moment from 'moment';
import ENV from 'ember-get-config';
import layout from './template';

import { CDC_COLUMNS } from 'shared/models/covid-report';

const TIMEOUT = ENV.environment === 'test' ? 0 : 250;

const MAX_RANGE_DAYS = 396;

export default Component.extend({
  layout,

  store: service(),
  router: service(),
  covidReports: service(),
  flashMessages: service(),
  settings: service(),

  classNames: ['covid__new-report'],

  providersAccounts: null,
  selectedAccounts: [],

  returnRoute: 'organization.analytics.covid.history',

  providers: null,
  selectedProviders: null,
  disableProvider: gt('selectedAccounts.length', 1),

  lastAvailableDate: computed('organization.dateInputFormat', function () {
    const format = this.organization.dateInputFormat;
    return moment().format(format);
  }),

  // Selected Columns handler
  reportCDCColumns: CDC_COLUMNS,
  selectedCDC: alias('model.cdc'),
  selectedCDCColumns: [],

  lookupWorkflow: task(function *(search) {
    yield timeout(TIMEOUT);

    const query = {
      organization_id: this.organization.id,
      filter: { status: 'completed', search: search },
      fields: 'id,batch-name,organization.id',
      q: search,
      page: 1,
      per_page: 10
    };

    return yield this.store.query('workflow', query)
  }),

  createReport: task(function *() {

    let customParams = {};
    customParams['cdc_columns'] = this.selectedCDCColumns;
    this.model.set('customParams', customParams);

    if (this.workflows) {
      this.model.set('workflowIds', this.workflows.mapBy('id'));
      this.model.set('workflowNames', this.workflows.mapBy('batchName'));
    }

    if (this.selectedAccounts) {
      this.model.set('providerAccountIds', this.selectedAccounts.mapBy('id'));
    }

    if (this.selectedProviders) {
      this.model.set('providerNames', this.selectedProviders.mapBy('name'));
      this.model.set('providerNpis', this.selectedProviders.mapBy('npi'));
    }

    yield this.model.save()
      .then(() => {
        this.flashMessages.success('Report generated');
        this.covidReports.reload();
        this.router.transitionTo(this.returnRoute);
      }).catch(() => {
        this.flashMessages.error('An error occurred trying to generate report. Please try again.');
      });
  }).drop(),

  defaultUnselectedColumns: [
    'Signed Unified Consent',
    'Signature Comment',
  ],

  defaultSelectedColumns: computed('defaultUnselectedColumns', function() {
    return CDC_COLUMNS.reject(column => this.defaultUnselectedColumns.includes(column));
  }),

  init() {
    this._super(...arguments);
    let defaultCDCColumns = this.preference.get('selectedCDCColumns') || [...this.defaultSelectedColumns];
    this.set('selectedCDCColumns', defaultCDCColumns);
  },

  updatePreferences() {
    this.get('preference').setProperties({
      'selectedCDCColumns': this.selectedCDCColumns
    });
  },

  actions: {
    onValidate() {
      let message = null;
      const diff = moment(this.model.get('toDate')).diff(moment(this.model.get('fromDate')), 'days', true);
      if (diff > MAX_RANGE_DAYS) {
        message = "The maximum date range is 13 months.";
      }

      return message;
    },

    providerAccountChanged(selectedAccounts) {
      set(this, 'selectedAccounts', selectedAccounts);
      set(this, 'selectedProviders', null);
      set(this, 'providers', []);
    },

    providerChanged(selectedProviders) {
      set(this, 'selectedProviders', selectedProviders);
    },

    updateColumnSelection(selectedColumns) {
      this.set('selectedCDCColumns', selectedColumns);
      this.updatePreferences();
    },

    cancel() {
      this.router.transitionTo(this.returnRoute);
    },

    submit() {
      this.createReport.perform();
    }
  }
});
