/* eslint-disable ember/no-mixins */
import Model, { belongsTo, attr } from '@ember-data/model';
import Externalable from 'shared/mixins/externalable';
import Documents from 'shared/mixins/documents';
import { isBlank } from '@ember/utils';

export default class RequisitionContactInfo extends Model.extend(Externalable, Documents) {
  // Associations
  @belongsTo('requisition', { async: true }) requisition;

  // Attributes
  @attr('string') name;
  @attr('string') streetAddress;
  @attr('string') streetAddressLine2;
  @attr('string') city;
  @attr('string') state;
  @attr('string') zipCode;
  @attr('string') country;
  @attr('string') faxNumber;
  @attr('string') phoneNumber;
  @attr('string') email;
  @attr('string') notes;
  @attr('date') createdAt;
  @attr('date') updatedAt;
  @attr('boolean') archived;
  @attr('boolean') archivable;

  get isEmpty() {
    return (isBlank(this.name) && isBlank(this.streetAddress) && isBlank(this.streetAddressLine2) &&
      isBlank(this.city) && isBlank(this.state) && isBlank(this.zipCode) && isBlank(this.faxNumber) &&
      isBlank(this.phoneNumber) && isBlank(this.email) && isBlank(this.notes));
  }
}
