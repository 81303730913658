import Model, { attr, belongsTo } from '@ember-data/model';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  name: {
    description: 'Name',
    validators: [
      validator('presence', true)
    ]
  },

  sampleType: {
    description: 'SNOMED Name',
    validators: [
      validator('presence', true)
    ]
  }
});

export default class SampleTypeName extends Model.extend(Validations) {
  @belongsTo('organization', { async: false }) organization;
  @belongsTo('sample-type', { async: false }) sampleType;
  @belongsTo('user', { async: false }) user;

  @attr('string') name;
  @attr('date') createdAt;
  @attr('boolean') archived;
  @attr('boolean', { defaultValue: true }) archivable;
}