/* eslint-disable quotes */
const CHECKSUM_ALPHABET = "0123456789abcdefghijklmnopqrstuv";

function _checksum(partialGlobalId) {
  const sum = partialGlobalId
    .split("")
    .reduce((sum, char) => sum + char.charCodeAt(), 0);

  return CHECKSUM_ALPHABET[sum % CHECKSUM_ALPHABET.length];
}

export default function isValidGlobalId(globalId) {
  if (!globalId.match("^.{2}:.{2}:.{22}:.{1}$")) {
    return false;
  }

  const parts = (globalId || "").split(":");
  const partialGlobalId = [parts[0], parts[1], parts[2]].join(":");
  const checksum = parts[3];

  return checksum === _checksum(partialGlobalId);
}
