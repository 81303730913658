/* eslint-disable ember/no-classic-classes, key-spacing */
import Model, { attr, belongsTo } from '@ember-data/model';

export default Model.extend({
  // Associations
  requisition:  belongsTo('requisition'),

  // Attributes
  createdAt:              attr('date'),
  status:                 attr('string'),
  adjustedChargesDollars: attr('number'),
  balanceDollars:         attr('number'),
  chargesDollars:         attr('number'),
  receiptsDollars:        attr('number')
});
