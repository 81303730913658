import { helper as buildHelper } from '@ember/component/helper';
import { htmlSafe } from '@ember/template';
import { isNone } from '@ember/utils';
import { capitalize } from '@ember/string';

export function status(params/*, hash*/) {
  let status = params[0];

  if (!isNone(status)) {
    status = capitalize(status.replace(/-/g, ' '));
    return htmlSafe(status);
  }
}

export default buildHelper(status);
