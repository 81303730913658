/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components, ember/no-actions-hash */
import moment from 'moment';
import Component from '@ember/component';
import { task, timeout } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { SessionWatcher } from 'shared/const/session-watcher';
import layout from './template';

const formatDuration = function(timeRemaining) {
  let duration = moment.duration(timeRemaining, 'seconds');
  return `${duration.minutes()} min ${duration.seconds().toString().padStart(2, '0')} secs`;
};

export default Component.extend({
  layout,

  session: service(),

  authenticated: alias('session.isAuthenticated'),

  formattedTimeRemaining: null,

  startTimeout() {
    this._timer.perform();
  },

  _timeRemaining() {
    const expirationTime = this.session.get('data.authenticated.idTokenPayload.exp') - SessionWatcher.REFRESH_EARLY_S;
    const currentTime = Math.ceil(Date.now() / 1000);

    return Math.max(expirationTime - currentTime, 0);
  },

  // jscs:disable disallowSpacesInFunction
  _timer: task(function *() {
    let timeRemaining = this._timeRemaining();

    this.set('formattedTimeRemaining', formatDuration(timeRemaining));

    while (timeRemaining > 0) {
      yield timeout(1000);
      timeRemaining = this._timeRemaining();
      this.set('formattedTimeRemaining', formatDuration(timeRemaining));
    }

    yield timeout(1000);

    if (this.session.showSessionOverlay && this._timeRemaining() < SessionWatcher.CUTOFF_S) {
      this.logout();
    }
  }).drop(),
  // jscs:enable disallowSpacesInFunction

  init() {
    this._super(...arguments);
    this.startTimeout();
  },

  close() {
    this.session.disableSessionOverlay();
    localStorage.setItem('winnebago:isRefreshing', 'false');
  },

  logout() {
    this.close();

    if (this.authenticated) {
      this.session.invalidate();
    }
  },

  actions: {
    renew() {
      if (this._timeRemaining() < SessionWatcher.CUTOFF_S) {
        this.session.refreshSessionNow();
      }

      this.close();
    },

    logout() {
      this.logout();
    }
  }
});
