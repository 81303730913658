/* eslint-disable ember/no-classic-classes, key-spacing, dot-notation, ember/no-get, semi, max-len, ember/use-brace-expansion */
import Model, { hasMany, belongsTo, attr } from '@ember-data/model';
import { alias, filterBy, bool, or } from '@ember/object/computed';
import { isPresent, isNone } from '@ember/utils';
import { computed } from '@ember/object';
import toBoolean from 'shared/helpers/to-boolean';

export default Model.extend({
  // Associations
  workflowSample:     belongsTo('workflow-sample'),

  assays:             hasMany('assay', { async: false, inverse: 'workflowSampleResult' }),

  // Attributes
  resultType:           attr('string'),
  resultActivityLabel:  attr('string'),
  status:               attr('string'),
  computedStatus:       attr('string'),
  routing:              attr('string'),
  identifier:           attr('string'),
  result:               attr(),
  globalMessages:       attr('string'),
  requisition:          attr(),
  reviewed:             attr('boolean'),
  computedRouting:      attr('string'),

  containerBarcode: alias('workflowSample.sample.containerBarcode'),
  containerBarcodes: alias('workflowSample.sample.containerBarcodes'),
  containerCurrentLocation: alias('workflowSample.sample.containerCurrentLocation'),
  containerCurrentLocations: alias('workflowSample.sample.containerCurrentLocations'),
  hasMedicationResults: bool('result.medication_results'),
  medicationResults: alias('result.medication_results'),
  position: alias('workflowSample.sample.position'),
  positions: alias('workflowSample.sample.positions'),
  rejectedAssays: filterBy('assays', 'rejected', true),

  currentStatus: or('status', 'computedStatus'),

  currentStatusChanged: computed('computedStatus', 'status', function() {
    return this.computedStatus && this.status && this.status !== this.computedStatus;
  }),

  computedStatusLabel: computed('computedStatus', function() {
    let computedStatusLabels = {
      'accepted': 'Accept',
      'rejected': 'Reject',
      'repeat': 'Repeat',
      'ignore': 'Ignore',
      'reflex': 'Reflex',
    };

    return this.computedStatus && computedStatusLabels[this.computedStatus];
  }),

  recordsDetected: computed('result', function() {
    const records = [];

    for (const key in this.result) {
      if (key !== 'medication_results') {
        const assay = this.result[key];

        if (isNone(assay.records)) {
          continue;
        }

        const firstRecord = assay.records[0];
        const loadPercentage = typeof assay.computed_load_percentage === 'number' ? assay.computed_load_percentage : 0;

        if (isPresent(assay.computed_result)) {
          if (assay.computed_result === 'Detected') {
            records.push({
              name:             firstRecord.organism || firstRecord.name || assay.name,
              result:           assay.computed_result,
              load:             assay.computed_formatted_load,
              qualitative:      assay.computed_qualitative,
              load_percentage:  loadPercentage,
              gene_description: firstRecord.description,
              antibiotic_class: firstRecord.antibiotic_class || assay.antibiotic_class,
              description:       firstRecord.description || assay.description,
              in_requested_panel: assay.in_requested_panel || firstRecord.in_requested_panel,
              computed_skip_activities: assay.computed_skip_activities,
              sanford_id: assay.sanford_id,
              related_pathogens: firstRecord.related_pathogens,
              not_mapped: assay.not_mapped || firstRecord.not_mapped,
              is_control: toBoolean(assay.isControl) ||
                          toBoolean(assay.is_control) ||
                          toBoolean(firstRecord.isControl) ||
                          toBoolean(firstRecord.is_control)
            });
          }
        } else if (isPresent(firstRecord)) {
          if (firstRecord['result'] === 'Detected') {
            records.push({
              name:             firstRecord.organism || firstRecord.name || assay.name,
              result:           firstRecord.result,
              load:             firstRecord.computed_formatted_load,
              qualitative:      firstRecord.computed_qualitative,
              load_percentage:  loadPercentage,
              gene_description: firstRecord.description,
              antibiotic_class: firstRecord.antibiotic_class,
              description:       firstRecord.description,
              in_requested_panel: firstRecord.in_requested_panel,
              computed_skip_activities: firstRecord.computed_skip_activities,
              related_pathogens: firstRecord.related_pathogens,
              sanford_id: firstRecord.sanford_id,
              not_mapped: firstRecord.not_mapped,
              is_control: toBoolean(firstRecord.isControl) || toBoolean(firstRecord.is_control)
            });
          }
        }
      }
    }

    return records;
  }),

  /**
   * This is used for `infectious-disease` results
   */
  organismsDetected: computed('recordsDetected', function() {
    return this.recordsDetected.filter((record) => {
      return !isPresent(record['antibiotic_class']);
    });
  }),

  /**
   * Get/Set Computed Property for `organisms_detected`
   *
   * When a `medication-result` workflow sample result is created the
   * detected organisms from the previous workflow sample result
   * (infectioius-disease) gets copied into the `medication-result` results.
   * This makes it easier downstream for displaying all the data from only one
   * workflow sample result for this activity
   */
  organismsDetectedFromMedications: computed('result.medication_results.potential_therapies.detected_organisms', {
    get() {
      const organisms = this.get('result.medication_results.potential_therapies.detected_organisms');

      if (organisms) {
        return organisms.map((o) => {
          return {
            name: o.name,
            load: o.load,
            qualitative: o.qualitative,
            load_percentage: o.load_percentage,
            sanford_id: o.sanford_id,
            in_requested_panel: o.in_requested_panel,
            is_control: toBoolean(o.is_control) || toBoolean(o.isControl)
          }
        });
      } else {
        return []
      }
    },

    set(key, val) {
      !this.get('result.medication_results') && this.set('result.medication_results', {});
      !this.get('result.medication_results.potential_therapies') && this.set('result.medication_results.potential_therapies', {});

      this.set('result.medication_results.potential_therapies.detected_organisms', val);
      return val;
    }
  }),

  /**
   * This is used for `infectious-disease` results
   */
  resistanceGenesDetected: computed('recordsDetected', function() {
    return this.recordsDetected.filter((record) => {
      // Resistance genes are identified by checking the existance of `antibiotic_class` on the assay result
      return isPresent(record['antibiotic_class']);
    });
  }),

  /**
   * Get/Set Computed Property for `resistance_genes_detected`
   *
   * When a `medication-result` workflow sample result is created the
   * resistance genes from the previous workflow sample result
   * (infectioius-disease) gets copied into the `medication-result` results.
   * This makes it easier downstream for displaying all the data from only one
   * workflow sample result for this activity
   */
  resistanceGenesDetectedFromMedications: computed('result.medication_results.resistance_genes_detected', {
    get() {
      return this.get('result.medication_results.resistance_genes_detected') || [];
    },

    set(key, val) {
      !this.get('result.medication_results') && this.set('result.medication_results', {});

      this.set('result.medication_results.resistance_genes_detected', val);
      return val;
    }
  }),

  pharmacistNotes: computed('result.medication_results.pharmacist_notes', {
    get() {
      return this.get('result.medication_results.pharmacist_notes') || null;
    },

    set(key, val) {
      this.set('result.medication_results.pharmacist_notes', val);
      return val;
    }
  }),

  /**
   * Get/Set Computed Property for `therapies`
   *
   * This seems to have changed location over time so the logic has been moved
   * from the components where it previously lived into the model. There appears
   * to be 3 versions.
   * V1: Puts the "First Line" and "Alternative" therapy records directly under
   *     the result hash - sibling to `medication_results`
   * V2: Copies the treatments into every `detected_organism` record that was
   *     copied from `infectious-disease` result. This duplicates data and
   *     contextually is confusing so it has been updated to a more concise and
   *     clear format
   * V3: The format to use going forward. Therapies is an array`under the
   *     `medication_results` attribute in the results hash that lists out the
   *     therapy records which align with the `utils/therapy` object struct
   */
  therapies: computed('result.medication_results.potential_therapies.detected_organisms.firstObject.treatments', 'result.medication_results.{therapies,v3}', {
    get() {
      // V3
      if (this.get('result.medication_results.v3')) {
        return this.get('result.medication_results.therapies') || [];
      }

      // V2
      let v2 = this.get('result.medication_results.potential_therapies.detected_organisms.firstObject.treatments');
      if (v2) {
        return v2;
      }

      // V1 - transform to others
      if (this.result['First Line'] || this.result['Alternative']) {
        return [
          ...this.result['First Line']['records'],
          ...this.result['Alternative']['records']
        ];
      }

      return [];
    },

    set(key, val) {
      this.set('result.medication_results.v3', true);
      this.set('result.medication_results.therapies', val);
      return val;
    }
  }),

  getAssay(name) {
    return this.assays.findBy('name', name);
  }
});
