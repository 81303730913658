/* eslint-disable ember/no-classic-components, ember/no-classic-classes, no-dupe-keys, ember/require-computed-property-dependencies, space-before-function-paren, ember/require-return-from-computed, max-len */
import Component from '@ember/component';
import layout from './template';
import { htmlSafe } from '@ember/template';
import { computed } from '@ember/object';

export default Component.extend({
  tagName: '',
  layout,
  hl7Message: '',
  hl7MessageFormatted: '',
  defaultDelimiters: ['|', '^', '~'],
  delimiters: [],
  firstLevelDelimiter: '',
  defaultEscapeCharacter: '\\',
  escapeCharacter: '',

  hl7MessageFormatted: computed('hl7Message', function () {
    let hl7MessageFormatted = this.hl7Message;

    let mshConfig = '';
    // Remove delimiters in the MSH-1 and MSH-2, because it doesn't necessary to higtlight them
    if (this.delimiters.length && this.mshConfigMatch !== null) {
      mshConfig = this.mshConfigMatch[1];
      hl7MessageFormatted = hl7MessageFormatted.replace(mshConfig, '');
    }

    hl7MessageFormatted = this.replaceDelimiters(hl7MessageFormatted, this.delimiters);
    // Add configuration to the message
    hl7MessageFormatted = mshConfig + hl7MessageFormatted;
    return htmlSafe(hl7MessageFormatted);
  }),

  firstLevelDelimiter: computed('hl7Message', function () {
    let firstLevelDelimiterMatch = this.hl7Message.match(/^MSH(.{1})/);
    if (firstLevelDelimiterMatch !== null) {
      return firstLevelDelimiterMatch[1];
    }
  }),

  delimiters: computed('firstLevelDelimiter', 'mshConfigMatch', function () {
    if (this.firstLevelDelimiter && this.mshConfigMatch !== null) {
      let delimiters = this.mshConfigMatch[2].split('');
      delimiters.unshift(this.firstLevelDelimiter);
      return delimiters;
    }
    return this.defaultDelimiters;
  }),

  escapeCharacter: computed('delimiters', function () {
    // The escape character must be in the fourth position
    if (this.delimiters.length >= 4) {
      return this.delimiters[3];
    }
    return this.defaultEscapeCharacter;
  }),

  mshConfigMatch: computed('hl7Message', 'firstLevelDelimiter', function () {
    // Example: "MSH|^~\&|". Note that the MSH-1 field character ("|") must be present after the MSH-2 field ("^~\&")
    let re = new RegExp(`(^MSH\\${this.firstLevelDelimiter}([^\\${this.firstLevelDelimiter}]*))\\${this.firstLevelDelimiter}.*`);
    return this.hl7Message.match(re);
  }),

  replaceDelimiters(message, delimiters) {
    let resultMessage = '';
    for (let i = 0; i < message.length; i++) {
      let character = message.charAt(i);
      if (delimiters.includes(character)) {
        if (i > 0 && message.charAt(i - 1) == this.escapeCharacter || character == this.escapeCharacter) {
          resultMessage += character;
        } else {
          resultMessage += `<b>${character}</b>`;
        }
      } else {
        resultMessage += character;
      }
    }
    return resultMessage;
  }
});