/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';

export default Model.extend({
  sanfordId: attr('string'),
  name: attr('string'),
  url: attr('string'),
  comments: attr('string'),
  group: attr('string'),
  groupDisplayName: attr('string'),
});
