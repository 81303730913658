/* eslint-disable ember/no-classic-classes, key-spacing */
import Model, { belongsTo, attr } from '@ember-data/model';

export default Model.extend({

  organization:       belongsTo('organization'),
  user:               belongsTo('user'),

  // Attributes
  notification_type:  attr('string'),
  body:               attr('string'),
  read:               attr('boolean'),
  removed:            attr('boolean'),
  url:                attr('string'),
  created_at:         attr('date')

});
