import ApplicationSerializer from 'shared/serializers/application';

export default ApplicationSerializer.extend({
  attrs: {
    activity: { serialize: false },
    parent: { serialize: false },
    resources: { serialize: false },
    // resourceGroups: { serialize: false },

    activityName: { serialize: false },
    activityType: { serialize: false },
    sequence: { serialize: false },
    resultType: { serialize: false },
    repeatWorkflowType: { serialize: false },
    configuration: { serialize: false },
    canStart: { serialize: false },
    canEditOrRemove: { serialize: false },
    permissions: { serialize: false },

    childIds: { serialize: false },
    parentIds: { serialize: false }
  }
});
