define("awesome-autocomplete/components/awesome-autocomplete", ["exports", "@ember/component", "awesome-autocomplete/templates/components/awesome-autocomplete", "@ember/runloop"], function (_exports, _component, _awesomeAutocomplete, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _awesomeAutocomplete.default,
    fieldname: "name",
    results: [],
    placeholder: "Type to search",
    id: 'awesome',
    selected: null,
    text: null,
    is_open: false,
    current_index: 0,
    didInsertElement() {
      this._super(...arguments);
      document.addEventListener("click", e => {
        if (e.target.id !== `${this.id}-autocomplete`) {
          this.onBlur();
        }
      });
      document.getElementById(`${this.id}-autocomplete`).addEventListener("keydown", e => {
        if (e.keyCode !== 40 && e.keyCode !== 38 && e.keyCode !== 13) {
          document.getElementById(`${this.id}-autocomplete`).focus();
          try {
            this.set('is_open', true);
          } catch (e) {
            // ignore
          }
        }
        if (e.keyCode == 40) {
          /*If the arrow DOWN key is pressed,
          increase the currentFocus variable:*/
          if (this.current_index + 1 < this.results.length) {
            try {
              this.set('current_index', this.current_index + 1);
            } catch (e) {
              // ignore
            }
          }
        } else if (e.keyCode == 38) {
          //up
          /*If the arrow UP key is pressed,
          decrease the currentFocus variable:*/
          if (this.current_index > 0) {
            try {
              this.set('current_index', this.current_index - 1);
            } catch (e) {
              // ignore
            }
          }
        } else if (e.keyCode == 13) {
          /*If the ENTER key is pressed, prevent the form from being submitted,*/
          e.preventDefault();
          document.getElementById(`${this.id}-autocomplete`).blur();
          this.onEnterPress();
        }
      });
    },
    onBlur() {
      try {
        this.set('is_open', false);
      } catch (e) {
        // ignore
      }
    },
    onEnterPress() {
      try {
        this.send('select', this.results[this.current_index]);
      } catch (e) {
        // ignore
      }
    },
    onKeyup() {
      this.sendAction('search', this.text);
    },
    actions: {
      select(item) {
        try {
          this.set('current_index', 0);
          this.set('is_open', false);
          this.set('text', item[this.fieldname]);
          this.sendAction('onchange', item);
        } catch (e) {
          // ignore
        }
      },
      keyup() {
        (0, _runloop.debounce)(this, this.onKeyup, 300);
      }
    }
  });
});