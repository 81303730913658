/* eslint-disable ember/no-get, ember/no-actions-hash */
import { set, get } from '@ember/object';
import RequestedTestsComponent from '../form-v1/requested-tests/component';
import config from 'ember-get-config';
import layout from './template';

const { CONSTANTS } = config;

const {
  SCREEN
} = CONSTANTS;

export default RequestedTestsComponent.extend({
  layout,

  SCREEN,

  _updateTestGeneSelection(key, value) {
    let testPanel = this.get('testPanels').findBy('key', key);

    testPanel.testGenes.forEach((test) => {
      let testGeneKey = test.get('key');
      let compositeKey = `requestedTests.${testGeneKey}`;
      if (value) {
        this.set(compositeKey, {
          id: get(test, 'id'),
          key: testGeneKey,
          confirmation: true
        });
      } else {
        if (!this.anyPanelSelected(testGeneKey)) { // change value if no other related test panel is selected
          if (value) {
            this.set(compositeKey, {
              id: get(test, 'id'),
              key: testGeneKey,
              confirmation: true
            });
          } else {
            this.set(compositeKey, value);
          }
        }
      }
    });
  },

  _toggleProperty(object, property) {
    set(object, property, !get(object, property));
  },

  actions: {
    updateSelectedTestGene(test) {
      let testKey = get(test, 'key');
      let requestedTests = get(this, 'requestedTests');
      let currentSelection = get(requestedTests, testKey);

      if (currentSelection) {
        set(requestedTests, testKey, false);
      } else {
        set(requestedTests, testKey, {
          id: get(test, 'id'),
          key: testKey,
          confirmation: true
        });
      }
    },

    updateSelectedTestGeneConfirmation(test) {
      let testKey = get(test, 'key');
      let requestedTests = get(this, 'requestedTests');
      let currentSelection = get(requestedTests, testKey);

      if (currentSelection) {
        this._toggleProperty(currentSelection, 'confirmation');
      }
    }
  }

});
