import ApplicationSerializer from 'shared/serializers/application';

export default ApplicationSerializer.extend({
  attrs: {
    sampleStates: { deserialize: 'ids', serialize: 'records' },
    queueAssignments: { serialize: false },
    patient: { deserialize: 'records' },
    rejectedBy: { deserialize: 'records' },
    entityCustomAttributes: { embedded: 'always' }
  }
});
