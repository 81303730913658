/* eslint-disable ember/no-classic-classes, key-spacing, ember/no-get */
import Model, { belongsTo, attr } from '@ember-data/model';
import { computed } from '@ember/object';
const reservedTags = ['Complete Reports', 'Incomplete Reports'];

export default Model.extend({
  // Associations
  document:       belongsTo('document', { async: true }),
  control:        belongsTo('control', { async: true }),
  adapter:        belongsTo('adapter', { async: true }),
  tag:            belongsTo('tag', { async: true }),

  // Attributes
  name:           attr('string'),
  behaviors:      attr(),

  canBeRemoved:   computed('name', 'behaviors.destroy', function() {
    let canDestroy = this.get('behaviors.destroy');
    let isReservedTag = reservedTags.indexOf(this.get('name')) >= 0;

    return canDestroy && !isReservedTag;
  })

});
