/* eslint-disable ember/no-actions-hash, ember/no-component-lifecycle-hooks */
import { alias, mapBy } from '@ember/object/computed';
import { isNone, isEmpty } from '@ember/utils';
import { get, computed } from '@ember/object';
import BaseComponent from '../../requisition-base/component';
import { inject as service } from '@ember/service';
import layout from './template';

export default BaseComponent.extend({
  layout,
  classNames: ['requested-tests', 'entry-details-content', 'row'],

  store: service(),

  readOnly: false,
  portalOrder: false,
  isVisible: true,
  allowMultipleTests: true,
  associatedTestPanelKeys: null,
  requirements: Object.freeze([{ key: 'selectedPanelOrTests', name: 'Select 1 test' }]),

  requestedTests: alias('model.requestedTests'),
  requisitionTemplateAssociations: alias('requisitionTemplate.requisitionTemplateAssociations'),
  testPanels: mapBy('requisitionTemplateAssociations', 'testPanel'),

  filteredTestPanels: computed(
    'requisitionTemplate.{id,requisitionTemplateAssociations}',
    'requisitionTemplateAssociations',
    'testPanels.@each.fullName',
    function() {
      const unsetTestPanelPositions = this.requisitionTemplateAssociations.filterBy('positionAt', null);

      if (!isEmpty(unsetTestPanelPositions)) {
        return this.requisitionTemplateAssociations.mapBy('testPanel').sortBy('id');
      }

      return this.requisitionTemplateAssociations.sortBy('positionAt').mapBy('testPanel');
    }
  ),

  testGeneAssociations: computed('testPanels.[]', function() {
    let testGeneAssociations = {};
    this.testPanels.forEach((testPanel) => {
      let testGenes = testPanel.testGenes;
      testGenes?.forEach((testGene) => {
        if (isNone(testGeneAssociations[testGene.key])) {
          testGeneAssociations[testGene.key] = [];
        }
        testGeneAssociations[testGene.key].pushObject(testPanel.key);
      });
    });
    return testGeneAssociations;
  }
  ),

  _activePanel(requestedTests) {
    for (let key in requestedTests) {
      if (key.match(/^p/) && requestedTests[key]) {
        return key;
      }
    }
    this._updateDirtyAttributes();
    return null;
  },

  _updateTestGeneSelection(key, value) {
    let testPanel = this.testPanels.findBy('key', key);

    if (!testPanel) {
      return;
    }

    testPanel.testGenes.forEach((gene) => {
      let testGeneKey = gene.get('key');
      let compositeKey = `requestedTests.${testGeneKey}`;
      if (value) {
        this.set(compositeKey, value);
      } else {
        if (!this.anyPanelSelected(testGeneKey)) { // change value if no other related test panel is selected
          this.set(compositeKey, value);
        }
      }
    });
  },

  _toggleTest(value) {
    let compositeKey = `requestedTests.${value}`;
    this.toggleProperty(compositeKey);
  },

  anyPanelSelected(testGeneKey) {
    let testGeneAssociations = this.testGeneAssociations;
    let testPanelKeys = get(testGeneAssociations, testGeneKey);
    let self = this;
    let selected = false;
    testPanelKeys.forEach((testPanelKey) => {
      if (self.get(`requestedTests.${testPanelKey}`)) {
        selected = true;
        return selected;
      }
    });
    return selected;
  },

  updateSelectedTestPanel(value) {
    let allowMultiple = this.allowMultipleTests;
    let requestedTests = this.requestedTests;
    let activePanel = this._activePanel(requestedTests);

    // disable active panel
    if (activePanel && !allowMultiple) {
      this.set(`requestedTests.${activePanel}`, false);
      this._updateTestGeneSelection(activePanel, false);
    }

    this._toggleTest(value);
    let compositeKey = `requestedTests.${value}`;
    let checked = this.get(compositeKey);
    this._updateTestGeneSelection(value, checked);

    // If test panel is unchecked and is a dependent test panel, uncheck all associated test panels
    if (!checked && this.associatedTestPanelKeys.includes(value)) {
      this.associatedTestPanelKeys.removeObject(value);

      this.requisitionTemplateAssociations.forEach((requisitionTemplateAssociation) => {
        if (requisitionTemplateAssociation.testPanelAssociations.findBy('testPanel.key', value)) {
          let key = requisitionTemplateAssociation.testPanel.key;
          this.set(`requestedTests.${key}`, false);
          this._updateTestGeneSelection(key, false);
          this._updateAssociatedTestPanelsFor(key, false);
        }
      });
    }

    // Set Associated Test Panels
    this._updateAssociatedTestPanelsFor(value, checked);

    this._updateDirtyAttributes();
  },

  _updateAssociatedTestPanelsFor(value, checked) {
    let testPanel = this.testPanels.findBy('key', value);
    let requisitionTemplateAssociation = this.requisitionTemplateAssociations.findBy('testPanel', testPanel);
    requisitionTemplateAssociation?.testPanelAssociations?.forEach((testPanelAssociation) => {
      let associatedTestPanel = testPanelAssociation.testPanel;
      let key = associatedTestPanel.key;

      if (checked) {
        this.associatedTestPanelKeys.pushObject(key);
        this.set(`requestedTests.${key}`, true);
        this._updateTestGeneSelection(key, true);

      } else {
        // can't use `removeObject` because it will remove all instances of the key
        // the `includes` in the template doesn't recalculate when splice is used,
        // so unsetting and setting the value again
        let index = this.associatedTestPanelKeys.indexOf(key);
        this.associatedTestPanelKeys.splice(index, 1);
        let tempKeys = this.associatedTestPanelKeys.toArray();
        this.set('associatedTestPanelKeys', []);
        this.set('associatedTestPanelKeys', tempKeys);

        if (!this.associatedTestPanelKeys.includes(key)) {
          this.set(`requestedTests.${key}`, false);
          this._updateTestGeneSelection(key, false);
        }
      }
    });
  },

  _publish() {
    if (typeof this.publish === 'function') {
      this.publish('requestedTestsChanged');
    }
  },

  didReceiveAttrs() {
    this._super(...arguments);

    // Set Associated Test Panels
    this.associatedTestPanelKeys = [];
    for (let key in this.requestedTests) {
      if (key.match(/^p/) && this.requestedTests[key]) {
        let testPanel = this.testPanels.findBy('key', key);
        let requisitionTemplateAssociation = this.requisitionTemplateAssociations.findBy('testPanel', testPanel);
        requisitionTemplateAssociation?.testPanelAssociations?.forEach((testPanelAssociation) => {
          let associatedTestPanel = testPanelAssociation.testPanel;
          let key = associatedTestPanel.key;

          this.associatedTestPanelKeys.pushObject(key);
        });
      }
    }
  },

  actions: {
    updateSelectedTestPanel(value) {
      this.updateSelectedTestPanel(value);
      this._publish();
    },

    updateSelectedTestGene(value) {
      this._toggleTest(value);
      this._publish();
    },

    updateAssociatedTestPanelsFor(panelKey, checked) {
      this._updateAssociatedTestPanelsFor(panelKey, checked);
      this._publish();
    }
  }
});
