/* eslint-disable no-implicit-coercion */
import { helper as buildHelper } from '@ember/component/helper';
import { get } from '@ember/object';

// params[0] = Array to be searched, haystack
// params[1] = property to search
export function arraySome(params/*, hash*/) {
  if (Array.isArray(params[0])) {
    return params[0].some(o => !!get(o, params[1]));
  }

  return false;
}

export default buildHelper(arraySome);
