/* eslint-disable ember/no-classic-components, no-unused-vars, ember/no-classic-classes, ember/require-computed-property-dependencies, quotes, dot-notation, max-len */
import Component from '@ember/component';
import layout from './template';

import { task, timeout } from 'ember-concurrency';
import fetch from 'fetch';
import moment from 'moment';
import { computed } from '@ember/object';
import { htmlSafe } from '@ember/template';
import { dasherize } from '@ember/string';
import { equal, alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { warn } from '@ember/debug';
import ENV from 'ember-get-config';

const BASE_CLASS_NAME = 'system-event';

export default Component.extend({
  layout,

  tagName: '',

  displayMaintenance: null,

  // maintenance status
  isVerifying: equal('displayMaintenance.status', 'verifying'),
  isInProgress: equal('displayMaintenance.status', 'in-progress'),
  isScheduled: equal('displayMaintenance.status', 'scheduled'),
  isCompleted: equal('displayMaintenance.status', 'completed'),

  /**
   * @return whether an event can be dismissed
   */
  isDismissible: computed('displayMaintenance', function() {
    if (!this.displayMaintenance) {
      return true;
    } else if (this.isVerifying || this.isInProgress) {
      return false;
    } else {
      return true;
    }
  }),

  /**
   * based on status of event, notification renders with different color
   */
  cssClass: computed('isVerifying', 'isInProgress', function() {
    let cssSubClass = (this.isVerifying || this.isInProgress) ? "in-progress" : "pre-post";
    return `${BASE_CLASS_NAME} ${cssSubClass}`;
  }),

  /**
   * API call for StatusPage
   */
  async fetchEvents() {
    return fetch(ENV['statuspage'].maintenance.endpoint)
      .catch(() => {
        warn("Error getting scheduled maintenances from status page", false, {
          id: 'ember-debug.status-page.maintenances.getting-maintenances'
        });
      });
  },

  /**
   * @param {json} maintenance payload containing one maintenance given by statuspage
   * @return a json with releant info
   * Example:
   *   {
   *     "id": "12346",
   *     "scheduledUntil": "2021-08-12T22:00:00.000Z",
   *     "status": "in_progress",
   *     "notes": "There is an upcoming scheduled maintenance."
   *   }
   */
  serialize(maintenance) {
    return {
      id: maintenance.incident_updates[0].id, // we want the most recent update
      scheduledFor: maintenance.scheduled_for,
      scheduledUntil: maintenance.scheduled_until,
      status: dasherize(maintenance.status),
      notes: maintenance.incident_updates[0].body,
    };
  },

  /**
   * @return time with the qty added/subtracted to time
   */
  getTime(time, qty) {
    return moment(time, "YYYY-MM-DDTHH:mm:ss.SSSZ").add(qty, 'minutes');
  },

  /**
   * @param {json} events array of JSON maintenances
   * @return the most recent maintenance
   * if there are no current maintenances and no upcoming/previous maintenances that meet the time restraint, then returns null
   */
  getDisplayEvent(events) {
    let now = moment.utc();

    for (let i = 0; i < events.length; i++) {
      let end = this.getTime(events[i].scheduledUntil, ENV['statuspage'].maintenance.event_timeout_minutes);
      let start = this.getTime(events[i].scheduledFor, -ENV['statuspage'].maintenance.event_timeout_minutes);
      if (moment(now).isSameOrBefore(end) && moment(now).isSameOrAfter(start)) {
        return events[i];
      }
    }
    return null;
  },

  /**
   * gets all scheduled maitenances
   * and sets displayEvent if there is an event within appropriate time frame
   */
  setDisplayEvent: task(function *() {
    let response = yield this.fetchEvents();
    if (response) {
      let json = yield response.json();
      let events = json.scheduled_maintenances.map(this.serialize);
      let displayMaintenance = this.getDisplayEvent(events);

      this.set('displayMaintenance', displayMaintenance);
      return displayMaintenance;

    } else {
      warn("Error parsing JSON from status page maintenances API", false, {
        id: 'ember-debug.status-page.maintenances.parsing-JSON'
      });
    }
  }),

});
