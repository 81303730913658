/* eslint-disable ember/no-observers, ember/no-actions-hash */
import { computed, observer } from '@ember/object';
import { alias, empty } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import BaseComponent from 'shared/components/requisitions/requisition-base/component';
import layout from './template';

export default BaseComponent.extend({
  layout,
  classNames: ['row', 'requisition__secondary-service', 'flex'],

  store: service(),
  settings: service(),

  isVisible: true,
  templateKey: 'secondaryService',
  readOnly: false,

  portalOrder: alias('settings.portalOrder'),
  salesAccount: alias('model.salesAccount'),

  required: alias('templateOptions.required'),

  noSalesAccount: empty('model.secondarySalesAccount.id'),

  minRequirements: computed('template', 'templateOptions.required', function() {
    if (this.templateOptions.required) {
      return [
        { key: 'secondarySalesAccount.id', name: 'Secondary Provider - Provider Account' },
        { key: 'secondaryPhysician.id', name: 'Secondary Provider - Provider' }
      ];
    }

    return [];
  }),

  missingRequirements: computed('_dirtyAttributes', 'model', 'requirements', function() {
    return this._missingFrom('requirements', this.model);
  }),

  salesAccountChanged: observer('salesAccount', function() {
    this.model.set('secondarySalesAccount', null);
    this.model.set('secondaryPhysician', null);
    this.change();
  }),

  actions: {
    updatePhysician(physician) {
      this.model.set('secondaryPhysician', physician);
      this._updateDirtyAttributes();
    },

    updateSalesAccount(salesAccount) {
      this.model.set('secondarySalesAccount', salesAccount);
      this.model.set('secondaryPhysician', null);
      this.change();
    },

    updateSalesAccountAndPhysician(salesAccount, physician) {
      this.model.set('secondarySalesAccount', salesAccount);
      this.model.set('secondaryPhysician', physician);
      this._updateDirtyAttributes();
    }
  }

});
