/* eslint-disable quotes */
import { not } from '@ember/object/computed';
import BaseComponent from 'shared/components/requisitions/requisition-base/component';
import layout from "./template";

export default BaseComponent.extend({
  layout,
  classNames: ['row', 'clinical-details'],

  // Attributes
  template: null,
  model: null,
  readOnly: false,
  templateKey: 'clinicalInformation',
  isExisting: not('model.isNew'),

  isVisible: true

});
