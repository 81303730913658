import ApplicationSerializer from 'shared/serializers/application';

export default ApplicationSerializer.extend({
  attrs: {
    requisitionTemplate: { serialize: 'records' },
    requisitionSampleRoutings: { embedded: 'always' },
    associatedReportConfigurations: { embedded: 'always' },
    associatedBillingConfiguration: { embedded: 'always' }
  }
});
