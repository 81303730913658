/* eslint-disable ember/no-new-mixins */
import Mixin from '@ember/object/mixin';

export default Mixin.create({

  serialize(snapshot) {
    let json = this._super(...arguments);

    if (snapshot.id) {
      return json;
    }

    let resourceGroups = snapshot.hasMany('resourceGroups');

    if (resourceGroups) {
      json._resource_group_ids = resourceGroups.map((resource) => resource.id);
    }

    return json;
  }

});
