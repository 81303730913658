/* eslint-disable object-shorthand, no-prototype-builtins */
import ApplicationAdapter from 'shared/adapters/application';

export default class NotificationAdapter extends ApplicationAdapter {

  buildURL(modelName, id, snapshot, requestType, query) {
    let url = super.buildURL(modelName, id, snapshot, requestType, query);

    if (snapshot && snapshot.record.get('organization')) {
      url = url.replace('notifications', `organizations/${snapshot.record.get('organization.id')}/notifications`);
    } else if (query.hasOwnProperty('organization_id') && query.organization_id) {
      url = url.replace('notifications', `organizations/${query.organization_id}/notifications`);
    }

    return url;
  }

}
