/* eslint-disable ember/no-classic-classes */
import Model, { attr, hasMany } from '@ember-data/model';

export default Model.extend({
  entitlements: hasMany('entitlement', { async: false }),

  displayName: attr('string'),
  consentId: attr('number'),
  consentName: attr('string'),
  consentText: attr('string'),
  status: attr('string'),
  version: attr('number'),
  organizationId: attr('number'),
  consentType: attr('string'),

});
