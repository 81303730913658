import { helper as buildHelper } from '@ember/component/helper';

export function remainingColumns(params/*, hash*/) {
  let fields = params[0];
  let columns = params[1] || 12;

  return columns - fields.reduce((sum, field) => sum + field.columnWidth, 0);
}

export default buildHelper(remainingColumns);
