/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components, ember/no-component-lifecycle-hooks, ember/require-super-in-lifecycle-hooks, ember/no-jquery, no-trailing-spaces, max-len, ember/no-get, object-shorthand, brace-style */
import Ember from 'ember';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { match } from '@ember/object/computed';
import { action, set, get, computed } from '@ember/object';
import { isNone } from '@ember/utils';
import $ from 'jquery';
import { task, timeout } from 'ember-concurrency';
import layout from './template';

export default Component.extend({
  layout,
  classNames: ['file-preview'],
  classNameBindings: ['drawerToggle:show'],

  showCondition: true,
  showName: true,
  iFrameLoaded: false,

  session: service('current-session'),
  url: null,
  fileName: null,
  showCSV: match('extension', /csv$/),
  showText: match('extension', /txt$/),
  showHl7: match('extension', /hl7$/),
  showGoogleViewer: match('extension', /\.(doc|docx|dotx|potx|ppsx|ppt|pptx|xls|xlsb|xlsx|pdf)/),
  showImage: match('extension', /\.(png|jpg|jpeg|gif)/),

  init() {
    this._super(...arguments);
    set(this, 'currentPage', 1);

    if (this.showGoogleViewer && !Ember.testing) {
      this.retryIFrame.perform();
    }
  },

  retryIFrame: task(function *() {
    yield timeout(500);

    if (this.iFrameLoaded) {
      return;
    }

    const iframe = document.getElementById('frame-file-preview');

    if (iframe?.contentDocument?.readyState === 'complete') {
      iframe.src = `${this.googleViewerUrl}&embed=true&ignore=${Math.floor(Math.random() * 1000)}`;
    }

    this.retryIFrame.perform();
  }),

  unavailablePreview: computed('showCSV', 'showText', 'showHl7', 'showGoogleViewer', 'showImage', 'disablePreview', function() {
    return this.disablePreview || (!this.showCSV && !this.showText && !this.showHl7 && !this.showGoogleViewer && !this.showImage);
  }),

  text: computed('url', function() {
    let url = get(this, 'url');

    if (url === undefined) {
      return;
    }

    let text = $.ajax({
      url: url,
      async: false,
      success: function(data) { return data; }
    });

    return text.responseText;
  }),

  extension: computed('fileName', function() {
    let fileName = get(this, 'fileName');

    if (isNone(fileName)) {
      return;
    }

    fileName = fileName.toLowerCase();

    if (isNone(fileName.match(/\.([a-z]|[0-9]){3,4}$/))) {
      return;
    }

    return fileName.match(/\.([a-z]|[0-9]){3,4}$/)[0];
  }),

  googleViewerUrl: computed('url', function() {
    let url = get(this, 'url');
    let encodedUrl = window.encodeURIComponent(url);
    if (isNone(url)) {
      return;
    }

    return `https://docs.google.com/a/ovation.io/viewer?embedded=true&url=${encodedUrl}`; // ?AllowInteractivity=false&wdStartOn=1&wdEmbedCode=0&wdPrint=0`
  }),

  onLoad: action(function() {
    this.set('iFrameLoaded', true);
  })
});
