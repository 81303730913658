define("awesome-autocomplete/helpers/get-value", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.getValue = getValue;
  function getValue(params /*, hash*/) {
    if (params && params.length === 2) {
      var val = params[0];
      var key = params[1];
      return val[key];
    } else {
      return 'undefined';
    }
  }
  var _default = _exports.default = (0, _helper.helper)(getValue);
});