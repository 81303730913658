/* eslint-disable ember/no-classic-classes, key-spacing */
import Model, { belongsTo, attr } from '@ember-data/model';
import { alias } from '@ember/object/computed';

export default Model.extend({
  // Associations
  providerAccount:                       belongsTo('salesAccount', { async: true }),
  projectRequisitionTemplateAssociation: belongsTo('project-requisition-template-association', { async: true }),

  // Attributes
  displayName:    attr('string'),
  projectId:      attr('number'),

  name: alias('displayName')
});
