/* eslint-disable max-len, prefer-template */
import { helper as buildHelper } from '@ember/component/helper';

export function formatCurrency(params/*, hash*/) {
  try {
    let amount = params[0];

    let int = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(2)).toString();
    let intGroups = (int.length > 3) ? int.length % 3 : 0;

    return `$ ${(intGroups ? `${int.substr(0, intGroups)},` : '')}${int.substr(intGroups).replace(/(\d{3})(?=\d)/g, '$1,')}${('.' + Math.abs(amount - int).toFixed(2).slice(2))}`;
  } catch (e) {
    return '$ 0.00';
  }
}

export default buildHelper(formatCurrency);
