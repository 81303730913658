import ApplicationSerializer from 'shared/serializers/application';

export default ApplicationSerializer.extend({
  attrs: {
    integrationActions: { embedded: 'always' },
    projects: { embedded: 'always' },
    requisitionTemplates: { embedded: 'always' },
    salesAccounts: { embedded: 'always' },
    workflowDefinitions: { embedded: 'always' },
  }
});
