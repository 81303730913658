/* eslint-disable ember/no-classic-classes */
import Service from '@ember/service';
import Evented from '@ember/object/evented';

export default Service.extend(Evented, {

  update() {
    this.trigger('changed');
  }

});
