/* eslint-disable ember/no-new-mixins */
import Mixin from '@ember/object/mixin';
import { inject as service } from '@ember/service';

// TODO: Should this be "AuthenticatedRouteMixin"?
export default Mixin.create({
  currentSession: service(),
  session: service(),

  /**
   * If `isAuthenticated` is true, it onky means the authenticator has been
   * called. It does not necessarily mean that the accessToken returned from Auth0
   * has been processed. This will be checked and handled in `handleAuthentication`.
   **/
  async beforeModel(transition) {
    if (!this.session.isAuthenticated && transition.to.name === 'index' && window.location.hash.match('access_token')) {
      return await this.session.handleAuthentication(transition);
    }

    // If Auth0 data is already loaded and user has ended up on index, send
    // them to their org dashboard.
    if (this.session.isAuthenticated && transition.to.name === 'index') {
      return await this.session.afterAuthentication(transition);
    }

    if (this.session.isAuthenticated && transition.to.name !== 'index' && !this.currentSession.didFetchOrganizations) {
      this.session.setRaygunUser();
      return this.currentSession.fetchOrganizations();
    }

    if (!this.session.isAuthenticated) {
      return this.session.requireAuthentication(transition, 'login');
    }
  }
});
