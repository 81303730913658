/* eslint-disable ember/no-new-mixins */
import Mixin from '@ember/object/mixin';
import { set } from '@ember/object';

export default Mixin.create({
  selectedTab: null,

  actions: {
    setSelectedTab(tab) {
      set(this, 'selectedTab', tab);
    }
  }

});
