/* eslint-disable key-spacing, ember/no-classic-classes, no-multiple-empty-lines, no-multi-spaces, ember/require-return-from-computed, space-infix-ops */
import Model, { attr, hasMany, belongsTo } from '@ember-data/model';
import { equal } from '@ember/object/computed';
import { computed } from '@ember/object';
import moment from 'moment';
import { validator, buildValidations } from 'ember-cp-validations';

const MAX_RANGE_DAYS = 396;
const Validations = buildValidations({
  fromDate:       validator('presence', true),
  toDate:         validator('presence', true),
  validDateRange: validator('presence', true),
  organization:   validator('presence', true),
});

export default Model.extend(Validations, {
  organization: belongsTo('organization', { async: false }),
  user:         belongsTo('user', { async: false }),
  resources:    hasMany('resource', { async: false }),

  fromDate:     attr('datetime'),
  toDate:       attr('datetime'),
  status:       attr('string'),
  createdAt:    attr('date'),
  workflowIds:  attr(),
  workflowNames: attr(),
  providerAccountNames: attr(),
  providerAccountIds: attr(),
  providerNames: attr(),
  providerNpis: attr(),
  testPanelIds: attr(),
  testPanelNames: attr(),
  testGeneIds: attr(),
  testGeneNames: attr(),


  providerNamesWithNPI: computed('providerNames.[]', 'providerNpis.[]', function() {
    const names = this.providerNames || [];
    const npis  = this.providerNpis  || [];

    let npi;

    return names.map((name, idx) => {
      npi = npis[idx];

      return npi ? `${name} (${npi})` : name;
    });
  }),

  validDateRange: computed('{fromDate,toDate}', function() {
    if (this.fromDate && this.toDate) {
      const diff = moment(this.toDate).diff(moment(this.fromDate), 'days', true);
      return this.fromDate <= this.toDate && diff<= MAX_RANGE_DAYS ? true : null;
    }
  }),

  reports: computed('resources.@each.name', function() {
    return this.resources.map((resource) => {
      return {
        name: resource.name,
        url: resource.publicUrl,
      };
    });
  }),

  isGenerated: equal('status', 'Generated')
});
