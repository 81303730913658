/* eslint-disable ember/no-classic-classes, key-spacing */
import Model, { attr, hasMany, belongsTo } from '@ember-data/model';

export default Model.extend({
  // Associations
  organization:       belongsTo('organization', { async: true }),

  resources:          hasMany('resource', { async: false }),

  // Attributes
  uuid:               attr('string'),
  name:               attr('string'),
  plateType:          attr('string'),
  wellPositionColumn: attr('string'),
  sampleNameColumn:   attr('string'),
  setupSkipLines:     attr('number'),
  plateNames:         attr(),
  archived:           attr('boolean'),
  archivable:         attr('boolean')
});
