/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components */
import Component from '@ember/component';
import layout from './template';
import { computed } from '@ember/object';
import { isBlank } from '@ember/utils';

export default Component.extend({
  layout,
  tagName: 'span',
  classNames: ['resource-action'],

  downloadUrl: computed('url', function() {
    if (isBlank(this.url)) {
      return '#';
    }

    if (this.url.indexOf('?') > -1) {
      return `${this.url}&download=true`;
    }

    return `${this.url}?download=true`;
  }),

  click(e) {
    e.stopPropagation();
  }
});
