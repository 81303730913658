/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components, object-shorthand, ember/no-component-lifecycle-hooks, ember/require-super-in-lifecycle-hooks, ember/no-get, ember/no-actions-hash, no-multi-spaces */
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import ArrayProxy from '@ember/array/proxy';
import { A } from '@ember/array';
import { isNone } from '@ember/utils';
import { set, get } from '@ember/object';
import { task, timeout } from 'ember-concurrency';
import config from 'ember-get-config';
import layout from './template';

const { CONSTANTS, TOX_TEST_TYPES } = config;

const {
  CONFIRM_ALWAYS,
  CONFIRM_POSITIVE,
  CONFIRM_INC
} = CONSTANTS;

const CONFIRMATION_OPTIONS = [
  { name: 'None', value: null },
  { name: 'Always', value: CONFIRM_ALWAYS },
  { name: 'Positive', value: CONFIRM_POSITIVE },
  { name: 'Inconsistent', value: CONFIRM_INC }
];

export default Component.extend({
  layout,

  TOX_TEST_TYPES: TOX_TEST_TYPES,
  CONFIRMATION_OPTIONS,

  classNames: ['medication-search'],

  store: service(),

  selected: null,
  collection: null,

  onAdd: () => {},
  onRemove: () => {},
  onUpdate: () => {},

  init() {
    this._collection = ArrayProxy.create({ content: A() });
    this._super(...arguments);
  },

  didReceiveAttrs() {
    let collection = get(this, 'collection');
    set(this, '_collection.content', collection);
  },

  // jscs:disable disallowSpacesInFunction
  search: task(function *(search) {
    const store = get(this, 'store');
    const strategy = 'drugs,medications';

    yield timeout(250);

    const medications = yield store.query('medication', { search, strategy, per_page: 50 });
    const results = medications.toArray();

    return results.sortBy('name');
  }).restartable(),
  // jscs:enable disallowSpacesInFunction

  actions: {
    select(medication) {
      set(this, 'selected', medication);
    },

    toggleTest(medication, test, { newValue }) {
      set(medication, test, newValue);
      get(this, 'onUpdate')(medication);
    },

    updateConfirmation(medication, option) {
      set(medication, 'confirmation', get(option, 'value'));
      get(this, 'onUpdate')(medication);
    },

    add() {
      let collection = get(this, '_collection');
      let selected   = get(this, 'selected');
      let action = get(this, 'onAdd');
      let medication = {
        id: selected.id,
        name: get(selected, 'nameWithDose'),
        screen: true,
        confirmation: CONFIRM_ALWAYS
      };

      if (isNone(collection.findBy('id', selected.id))) {
        collection.pushObject(medication);
      }

      action(medication);

      set(this, 'selected', null);
    },

    remove(medication) {
      let collection = get(this, '_collection');
      let action = get(this, 'onRemove');

      collection.removeObject(medication);

      action(medication);
    }
  }

});
