/* eslint-disable ember/no-classic-classes, quotes */
import Service, { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

export default class ContainerTypesService extends Service {
  @service store;

  @tracked containerTypeMapping = {};
  @tracked containerTypes;

  @task({ restartable: true })
  *loadDataTask() {
    let query = {
      q: {
        s: 'group asc'
      }
    };

    query.fields = 'id,name,container_type,wells,size,allow_on_req_settings';

    let response = yield this.store.query('container-type', query);

    this._prepareStructure(response);
  }

  _prepareStructure(response) {
    let mapping = {};
    response.forEach((record) => {
      mapping[record.name] = {
        type: record.containerType,
        name: record.name,
        wells: record.wells,
        size: record.size,
        allowOnReqSettings: record.allowOnReqSettings
      };
    });
    this.containerTypes = response.toArray();
    this.containerTypeMapping = mapping;
  }
}
