/* eslint-disable ember/no-get */
import { computed } from '@ember/object';
import { isPresent } from '@ember/utils';
import BaseComponent from '../requisition-base/component';
import layout from './template';

export default BaseComponent.extend({
  layout,
  classNames: ['row', 'requisition-medications'],

  templateKey: 'coriellMedicationSearch',
  readOnly: false,
  showTitle: true,

  isVisible: computed('template', 'template.coriellMedicationSearch', function() {
    return isPresent(this.get('template.coriellMedicationSearch'));
  })

});
