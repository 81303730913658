/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components, ember/no-actions-hash */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import layout from './template';

export default Component.extend({
  layout,
  classNames: ['floating-row-timeline-container'],

  router: service(),

  actions: {
    close() {
      let isPhysicianContact = this.notesPermissions.isPhysicianContact || false;
      this.router.transitionTo(isPhysicianContact ? 'requisitions.index' : 'sales.index');
    }
  }
});
