/* eslint-disable ember/no-classic-classes, key-spacing, brace-style */
import Model, { belongsTo, attr } from '@ember-data/model';

export default Model.extend({

  // Associations
  organization: belongsTo('organization', { async: true }),

  // Attributes
  name:           attr('string'),
  default:        attr('boolean'),
  format:         attr('string'),
  template:       attr('string'),
  containerTypes: attr({ defaultValue: () => { return []; } }),
  isBiobank:      attr('boolean', { defaultValue: false })

});
