/* eslint-disable ember/no-classic-classes, key-spacing, brace-style, ember/no-get, quotes */
import Model, { attr, hasMany, belongsTo } from '@ember-data/model';
import { computed } from '@ember/object';
import config from 'ember-get-config';

export default Model.extend({
  // Associations
  analyte:      belongsTo('meta-analyte', { polymorphic: true, inverse: 'testGenes' }),
  organization: belongsTo('organization'),
  pathogen: belongsTo('pathogen', { async: false }),

  medications:  hasMany('medications'),
  testConfigurations: hasMany('test-configuration'),
  testPanels  : hasMany('test-panels', { async: false }),

  // Attributes
  name:         attr('string'),
  code:         attr('string'),
  key:          attr('string'),
  cptCode:      attr('string'),
  testType:     attr('string'),
  loinc:        attr('string'),
  archived:     attr('boolean'),
  archivable:   attr('boolean'),
  customAttributes: attr({ defaultValue: () => { return {}; } }),

  // Display Helper
  field: computed('fullName', 'key', 'name', function() {
    return {
      name:        this.get('fullName'),
      key:         this.get('key'),
      type:        "boolean",
      renderType:  "checkbox"
    };
  }),

  fullName: computed('name', 'code', function() {
    return `${this.get('name')} (${this.get('code')})`;
  }),

  isConfigurable: computed('testType', function() {
    return [
      config.CONSTANTS.ID,
      config.CONSTANTS.RG
    ].includes(this.testType);
  })

});
