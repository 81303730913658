/* eslint-disable ember/no-classic-classes */
import Model, { attr, hasMany, belongsTo } from '@ember-data/model';
import { alias, mapBy, reads } from '@ember/object/computed';
import { computed } from '@ember/object';
import { isNone, isEmpty } from '@ember/utils';

export default Model.extend({
  // Associations
  organization: belongsTo('organization', { async: true }),
  workflow: belongsTo('workflow', { async: false }),
  workflowActivity: belongsTo('workflow-activity', { async: true, inverse: 'activity' }),
  owner: belongsTo('user'),
  platingConfiguration: belongsTo('plating-configuration', { async: true }),

  source: belongsTo('source', { polymorphic: true, async: false }),
  destinations: hasMany('container', { polymorphic: true, async: false }),
  instrument: belongsTo('instrument'),
  resources: hasMany('resource'),
  resourceGroups: hasMany('resource-group'),
  samples: hasMany('sample'),
  sampleStates: hasMany('sample-state'),
  workflowSamples: hasMany('workflow-sample'),
  workflowSampleResults: hasMany('workflow-sample-result', { async: false }),
  adapterContainers: hasMany('adapter-container'),
  adapterContainerWells: hasMany('adapter-container-well', { async: false }),
  pooledWorkflowSampleResult: belongsTo('pooled-workflow-sample-result', { async: false }),

  // Attributes
  uuid: attr('string'),
  status: attr('string'),
  repeat: attr('boolean'),
  notes: attr('string'),
  assay: attr('string'),
  partial: attr('boolean'),
  created_at: attr('date'),
  workflowId: attr('number'),
  workflowActivityId: attr('number'),
  workflowBatchName: attr('string'),
  workflowActivityName: attr('string'),
  ownerName: attr('string'),
  ownerEmail: attr('string'),
  platingStrategy: attr('string', { defaultValue: 'automatic' }),

  destinationPosition: attr('string'),
  downloadUrl: attr('string'),
  customAttributes: attr({ defaultValue: () => {
    return {};
  } }),
  controls: attr({ defaultValue: () => {
    return {};
  } }),
  wellAssignments: attr({ defaultValue: () => {
    return [];
  } }),

  workflowSamplesCount: attr('number'),

  // Helper methods
  workflowIdFromBatchName: computed('workflowBatchName', function() {
    return (this.workflowBatchName || '').split('-').lastObject;
  }),

  destination: reads('destinations.firstObject'),

  parentActivity: alias('workflowActivity.parents.firstObject.activity'),
  parentActivities: mapBy('workflowActivity.parents', 'activity'),
  parentDestinations: computed('parentActivities.@each.destinations', function() {
    return this.parentActivities.reduce((collection, activity) => {
      return collection.concat(activity.get('destinations').toArray());
    }, []);
  }),
  parentContainers: computed('parentDestinations.@each.barcode', 'parentActivities.@each.parentContainers', function() {
    let containers = this.parentDestinations;

    if (isEmpty(containers)) {
      return this.parentActivities.reduce((collection, activity) => {
        return collection.concat(activity.get('parentContainers').toArray());
      }, []);
    }

    return containers;
  }),

  sourceContainerLabel: alias('workflowActivity.configuration.sourceContainers.filter.label'),
  sourceContainersCandidates: computed('sourceContainerLabel', 'parentContainers.@each.label', function() {
    let sourceContainerLabel = this.sourceContainerLabel;
    let parentContainers = this.parentContainers;

    if (isNone(sourceContainerLabel)) {
      return parentContainers;
    }

    if (isNone(parentContainers) || isNone(parentContainers.get('firstObject.label'))) {
      return parentContainers;
    }

    return parentContainers.filterBy('label', sourceContainerLabel);
  })

});
