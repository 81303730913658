import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

export default class IntegrationRouteModel extends Model {
  // Associations
  @belongsTo('credential', { async: false }) credential;
  @hasMany('project', { async: false }) projects;
  @hasMany('requisition-template', { async: false }) requisitionTemplates;
  @hasMany('integration-action', { async: false }) integrationActions;
  @hasMany('workflow-definition', { async: false }) workflowDefinitions;
  @hasMany('sales-account', { async: false }) salesAccounts;

  // Attributes
  @attr('string') name;
  @attr('string') routeType;
  @attr('string') subFolder;
  @attr('boolean') enabled;
  @attr('boolean') triggerOnce;
  @attr() triggers;
  @attr({
    defaultValue() {
      return {};
    }
  }) event_history;

  get lastEvent() {
    return this.event_history.last_event;
  }

  get health() {
    return this.event_history.health;
  }

  get status() {
    return this.enabled ? 'Enabled' : 'Disabled';
  }

}
