/* eslint-disable ember/no-classic-classes, key-spacing */
import Model, { belongsTo, attr } from '@ember-data/model';

export default Model.extend({
  // Associations
  insuranceInformation:  belongsTo('insurance-information'),

  // Attributes
  authorizationNumber:   attr('string'),
  numAllowedTests:       attr('string'),
  effectiveStartDate:    attr('date'),
  effectiveEndDate:      attr('date'),
  contactName:           attr('string'),
  contactPhoneNumber:    attr('string')

});
