/* eslint-disable ember/no-classic-classes, ember/no-get, ember/no-observers */
import { inject as service } from '@ember/service';
import Helper from '@ember/component/helper';
import { observer, get } from '@ember/object';

export default Helper.extend({

  currentSession: service('current-session'),
  currentOrganization: service(),

  compute([url]) {
    const organizationId = this.get('currentOrganization.organization.id');
    let separator = '?';

    if (url && url.match(/\?.*?=/)) {
      separator = '&';
    }

    return `${url}${separator}token=${get(this, 'currentSession.token')}&organization_id=${organizationId}`;
  },

  tokenDidChange: observer('currentSession.token', function() {
    this.recompute();
  })

});
