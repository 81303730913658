/* eslint-disable space-before-blocks, ember/no-actions-hash */
import BaseComponent from 'shared/components/requisitions/requisition-base/component';
import layout from './template';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';

export default BaseComponent.extend({
  layout,
  classNames: ['requisition-address-infos', 'm-bottom'],
  store: service(),
  settings: service(),

  requisition: alias('model'),
  isNew: alias('requisition.isNew'),

  isVisible: true,
  readOnly: false,
  portalOrder: alias('settings.portalOrder'),

  requisitionContactInfos: alias('requisition.requisitionContactInfos'),

  _addAddressInfo() {
    this.store.createRecord('requisition-contact-info', { requisition: this.requisition });
  },

  _cloneAddressInfo(addressInfo) {
    return this.store.createRecord('requisition-contact-info', {
      name: addressInfo.name,
      streetAddress: addressInfo.streetAddress,
      streetAddressLine2: addressInfo.streetAddressLine2,
      city: addressInfo.city,
      state: addressInfo.state,
      zipCode: addressInfo.zipCode,
      country: addressInfo.country,
      faxNumber: addressInfo.faxNumber,
      email: addressInfo.email,
      notes: addressInfo.notes,
      requisition: this.requisition
    });
  },

  _setRequisitionAddressInfo(reqAddress){
    this.requisitionContactInfos.pushObject(reqAddress);
  },

  actions: {
    addAddressInfo() {
      this._addAddressInfo();
    },

    removeAddressInfo(addressInfo) {
      if (addressInfo.isNew) {
        addressInfo.rollbackAttributes();
        this.requisitionContactInfos.removeObject(addressInfo);
      } else {
        addressInfo.archived = true;
      }
    },

    updateAddressInfo(addressInfo) {
      this._setRequisitionAddressInfo(this._cloneAddressInfo(addressInfo));
      this.change();
    },
  },
});
