/* eslint-disable ember/no-classic-classes, key-spacing */
import Model, { belongsTo, attr } from '@ember-data/model';

export default Model.extend({
  // Associations
  hl7Mapping:               belongsTo('hl7-mapping', { async: false }),

  // Attributes
  name:                     attr('string'),
  configuration:            attr(),
  configurationSchema:      attr()
});
