/* eslint-disable ember/no-new-mixins */
import Mixin from '@ember/object/mixin';
import { inject as service } from '@ember/service';
import Preference from 'shared/utils/preference';

export default Mixin.create({

  preferences: service(),
  currentSession: service(),

  setupController(controller) {
    this._super(...arguments);

    const preference = Preference.create({
      routeName: this.routeName,
      preferences: this.preferences,
      currentSession: this.currentSession,
    });

    this.setupPreferences(preference);

    controller.set('preference', preference);
  },

  // Override in Route
  setupPreferences(/* preference */) {
  },

});