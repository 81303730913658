/* eslint-disable ember/no-classic-classes, key-spacing */
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { computed } from '@ember/object';
import { mapBy } from '@ember/object/computed';

export default Model.extend({
  // Associations
  organization:         belongsTo('organization', { async: true }),
  testPanel:            belongsTo('test-panel', { async: false }),
  requisitionTemplate:  belongsTo('requisition-template', { async: false }),
  testPanelAssociations: hasMany('test-panel-association', { async: false }),

  // Attributes
  testPanelName: attr('string'),
  positionAt: attr('number'),

  testPanels: mapBy('testPanelAssociations', 'testPanel'),
  associatedTestPanelList: computed('testPanels.@each.name', function() {
    return this.testPanels
      .sortBy('name')
      .mapBy('name')
      .join(', ');
  })
});
