import ApplicationSerializer from 'shared/serializers/application';

export default ApplicationSerializer.extend({
  attrs: {
    team: { deserialize: 'records', serialize: 'ids' },
    sourceProject: { serialize: 'ids' },
    createdAt: { serialize: false },
    researchProjectUrl: { serialize: false },
    isAdmin: { serialize: false }
  }
});
