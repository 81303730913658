/* eslint-disable ember/no-classic-classes, key-spacing, indent, no-trailing-spaces, ember/no-get */
import Model, { belongsTo, attr } from '@ember-data/model';
import { computed, get } from '@ember/object';
import { or } from '@ember/object/computed';
import { isPresent } from '@ember/utils';

export default Model.extend({

  billingInformation:               belongsTo('billing-information', { async: true }),
  insuranceProvider:                belongsTo('insurance-provider', { async: false }),
  preAuthorization:                 belongsTo('pre-authorization'),

  insurance:                        attr('string'),
  idNumber:                         attr('string'),
  subscriberNumber:                 attr('string'),
  groupNumber:                      attr('string'),
  nameOfPersonInsured:              attr('string'),
  relationshipToInsured:            attr('string'),
  otherRelationshipToInsured:       attr('string'),
  dobOfInsured:                     attr('date'),
  insuranceType:                    attr('string'),
  insuredEmail:                     attr('string'),
  insuredStreetAddress:             attr('string'),
  insuredCity:                      attr('string'),
  insuredState:                     attr('string'),
  insuredZipCode:                   attr('string'),
  insuredSex:                       attr('string'),
  reimbursementEligibilityVerified: attr('boolean'),

  informationPresent: or('insurance', 'idNumber', 'groupNumber',
                                  'nameOfPersonInsured', 'dobOfInsured',
    'relationshipToInsured', 'insuranceType'),

  displaySex: computed('insuredSex', function() {
    const sex = get(this, 'insuredSex');
    let text = 'Undisclosed';

    if (isPresent(sex)) {
      text = sex.charAt(0).toUpperCase() + sex.slice(1);
    }

    return text;
  }),

});
