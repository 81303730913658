/* eslint-disable no-unused-vars, ember/classic-decorator-no-classic-methods, quotes */
/* global auth0 */
import Service, { inject as service } from '@ember/service';
import { assert } from '@ember/debug';
import { get } from '@ember/object';
import Ember from 'ember';
import { Promise } from 'rsvp';
import { getOwner } from '@ember/application';

/**
 * Primary interface between application and Auth0 service. A wrapper to the
 * vanilla JS Auth0 SDK which is located in `vendor/auth0.min.js`. The app uses
 * this from the Session service and the Auth0 Authenticator which is necessary
 * for Auth0 hooks to work as expected.
 *
 * TODO: Ideally we would be importing this differently.
 *
 * https://auth0.com/docs/libraries/auth0js#extract-the-authresult-and-get-user-info
 **/
export default class Auth0Service extends Service {
  @service session;
  _auth0;

  data = {
    authenticated: null,
    profile: null
  };

  constructor() {
    super(...arguments);
    this.initConfig();
    this.initLibrary();
  }

  /**
   * Initialize the configuration required for Auth0 library. Config is defined
   * in `environment.js` for the current environment running.
   **/
  initConfig() {
    const ENV = getOwner(this).resolveRegistration('config:environment');
    const emberSimpleAuthConfig = ENV['ember-simple-auth'];
    assert('ember-simple-auth config must be defined', emberSimpleAuthConfig);
    assert('ember-simple-auth.auth0 config must be defined', emberSimpleAuthConfig.auth0);
    this.config = emberSimpleAuthConfig.auth0;
  }

  /**
   * Initialize the Auth0 third party library used to authenticate.
   *
   * https://auth0.com/docs/libraries/auth0js#webauth-authorize-
   */
  initLibrary() {
    const {
      domain,
      clientID,
      redirectUri,
      audience,
      responseType,
      scope
    } = this.config;

    this._auth0 = new auth0.WebAuth({
      domain,
      clientID,
      redirectUri,
      audience,
      responseType,
      scope
    });
  }

  /**
   * Calling `authorize` will redirect the user to the custon Auth0 login
   * page which is hosted in a different repository and managed through Auth0.
   * Once the user logs in properly Auth0 will return to the application with
   * authorization data and the app will then be authorized
   *
   * https://auth0.com/docs/libraries/auth0js#webauth-authorize-
   **/
  async login(options) {
    return await this._auth0.authorize(options);
  }

  /**
   * End Auth0 session
   **/
  async logout() {
    const {
      domain,
      logoutReturnToURL,
      clientID
    } = this.config;

    if (!Ember.testing) {
      window.location.replace(`https://${domain}/v2/logout?returnTo=${logoutReturnToURL}&client_id=${clientID}`);
    }
  }

  /**
   * Check the session and see if the user is currently already authenticated
   * and if so just load the user.
   *
   * https://auth0.com/docs/libraries/auth0js#using-checksession-to-acquire-new-tokens
   **/
  async checkLogin() {
    return new Promise((resolve, reject) => {
      this._auth0.checkSession({}, (err, authResult) => {
        if (err) {
          return reject(false);
        }

        if (!authResult || !authResult.accessToken) {
          return reject(false);
        }

        this.setUser(authResult.accessToken);
        this.set('data.authenticated', authResult);
        this.clearUrlHash();

        this.updateSessionStore(authResult);

        return resolve(authResult);
      });
    });
  }

  /**
   * Load the auth0 user from access token in the url upon returning from Auth0
   * login screen.
   *
   * https://auth0.com/docs/libraries/auth0js#extract-the-authresult-and-get-user-info
   **/
  async loadAuth0User() {
    return new Promise((resolve, reject) => {
      this._auth0.parseHash({ __enableImpersonation: this.config.enableImpersonation }, (err, authResult) => {
        if (err) {
          return reject(false);
        }

        if (!authResult || !authResult.accessToken) {
          return reject(false);
        }

        this.setUser(authResult.accessToken);
        this.set('data.authenticated', authResult);
        this.clearUrlHash();

        this.updateSessionStore(authResult);

        return resolve(authResult);
      });
    });
  }

  /**
   * Get user info from Auth0 Client via the returned accessToken provided on
   * either the `checkLogin` or the `parseHash` methods.
   *
   * https://auth0.com/docs/libraries/auth0js#webauth-authorize-
   **/
  setUser(token) {
    this._auth0.client.userInfo(token, (err, profile) => {
      this.data.profile = profile;
    });
  }

  clearUrlHash() {
    if (!Ember.testing && window.history) {
      window.history.pushState('', document.title, window.location.pathname + window.location.search);
    }
  }

  updateSessionStore(authResult) {
    this.session.session._setup("authenticator:auth0", authResult);
  }
}
