/* eslint-disable ember/no-classic-classes */
import { computed } from '@ember/object';
import Model, { attr, belongsTo } from '@ember-data/model';

export default Model.extend({
  // Associations
  sample: belongsTo('sample', { async: false, inverse: 'sampleStates' }),
  source: belongsTo('sample-state', { async: false, inverse: null }),
  container: belongsTo('container', { async: false, polymorphic: true }),
  controlContainer: belongsTo('control-container', { async: false }),

  metadata: attr({ defaultValue: () => {} }),

  // Attributes
  identifier: attr('string'),
  position: attr('string'),
  internalPosition: attr('number'),
  barcode: attr('string'),
  lot: attr('string'),
  processed: attr('boolean'),
  createdAt: attr('date'),
  dateReceived: attr('date'),
  label: attr('string'),
  partial: attr('boolean'),
  containerBarcode: attr('string'),
  containerCurrentLocation: attr('string'),
  containerBarcodeLink: attr('string'),
  sourcePosition: attr('string'),
  sourceContainerCurrentLocation: attr('string'),
  sourceContainerBarcode: attr('string'),
  assay: attr('string'),
  requeue: attr('boolean'),
  containerID: attr(),
  activityId: attr('string'),
  workflowId: attr('string'),

  containerBarcodeAndPosition: computed('containerBarcode', 'position', function() {
    let { containerBarcode, position } = this;

    return `${containerBarcode}-${position}`;
  })
});
