/* eslint-disable ember/no-classic-classes, key-spacing, ember/no-get */
import Model, { attr, hasMany, belongsTo } from '@ember-data/model';
import { computed, get } from '@ember/object';
import { isEmpty } from '@ember/utils';

export default Model.extend({
  // Associations

  organization:   belongsTo('organization'),
  parent:         belongsTo('sales-group'),
  salesReps:      hasMany('sales-rep'),
  childrenGroups: hasMany('sales-group', { inverse: 'parent' }),
  salesAccounts: hasMany('sales-account'),

  // Attributes
  name:           attr('string'),
  pathToRoot:     attr('string'),
  archived:       attr('boolean'),
  archivable:     attr('boolean'),

  isDeletable: computed('salesReps.[]', 'childrenGroups.[]', function() {
    return isEmpty(get(this, 'salesReps')) && isEmpty(get(this, 'childrenGroups'));
  }),
});
