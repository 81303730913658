import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! template-lint-disable no-invalid-interactive prettier }}\n{{#if this.visible}}\n  <th\n    role={{if this.sortable \"button\"}}\n    class=\"{{@class}} {{@theme.theadCell}} {{if this.sortable @theme.sorting.columnSortable}} {{if this.isSorted @theme.sorting.columnSorted}} {{if this.isAscSorted @theme.sorting.columnSortedAsc}} {{if this.isDescSorted @theme.sorting.columnSortedDesc}}\"\n    {{on \"click\" (if this.sortable (fn @onClick this) this.noop)}}\n    {{did-insert this.updateName}}\n    ...attributes>\n    {{yield (hash\n      isSorted=this.isSorted\n      isAscSorted=this.isAscSorted\n      isDescSorted=this.isDescSorted\n    )}}\n  </th>\n{{/if}}", {"contents":"{{! template-lint-disable no-invalid-interactive prettier }}\n{{#if this.visible}}\n  <th\n    role={{if this.sortable \"button\"}}\n    class=\"{{@class}} {{@theme.theadCell}} {{if this.sortable @theme.sorting.columnSortable}} {{if this.isSorted @theme.sorting.columnSorted}} {{if this.isAscSorted @theme.sorting.columnSortedAsc}} {{if this.isDescSorted @theme.sorting.columnSortedDesc}}\"\n    {{on \"click\" (if this.sortable (fn @onClick this) this.noop)}}\n    {{did-insert this.updateName}}\n    ...attributes>\n    {{yield (hash\n      isSorted=this.isSorted\n      isAscSorted=this.isAscSorted\n      isDescSorted=this.isDescSorted\n    )}}\n  </th>\n{{/if}}","moduleName":"shared/components/ui/table/thead/row/column.hbs","parseOptions":{"srcName":"shared/components/ui/table/thead/row/column.hbs"}});
/* eslint-disable no-unused-vars, ember/no-get, ember/classic-decorator-no-classic-methods */
import YetiTableTheadRowColumn from 'ember-yeti-table/components/yeti-table/thead/row/column/component';
import { layout } from '@ember-decorators/component';
import { isEqual } from 'lodash';
import { next } from '@ember/runloop';

/**
 * Two properties have been added to columns in order to support the
 * {Ui::Table::SelectColumns} component. For the first header that has the
 * title of a column, add the `@display="Provider Account"`. Use the same
 * parameter for the `visible` attribute on each column so they both show
 * and hide together
 *
 * Example:
 * ````
 * <table.header as |header|>
 *   <header.column @display="Provider Account" @visible={{this.preference.visibleProviderAccount}}>
 *     Provider Account
 *   </header.column>
 * </table.header>
 *
 * <table.header as |header|>
 *   <header.column @visible={{this.preference.visibleProviderAccount}}>
 *     <DebounceInput ... />
 *   </header.column>
 * </table.header>
 * ```
 */
export default class UiTableTheadRowColumn extends YetiTableTheadRowColumn {
  display;

  /**
   * Whenever a user-entered filtering value is entered then reload the table
   * data with the page number back at 1. Otherwise, if user is on page 4 of `n`
   * then enters a filter in the header, they will stay on page 4 and see no
   * results which is confusing.
   **/
  didChangeAttrs(changes) {
    let filterValues = changes.filterUsing;
    let previous = filterValues && filterValues.previous;
    let current = filterValues && filterValues.current;

    if (filterValues && !isEqual(previous, current)) {
      next(() => {
        this.get('parent').set('pageNumber', 1);
      });
    }

    this.get('parent').runLoadData();
  }
}
