import { classify } from '@ember/string';
import ApplicationSerializer from 'shared/serializers/application';

export default class extends ApplicationSerializer {
  attrs = {
    location: { key: 'current_location' },
    numberOfWells: { serialize: false },
    positions: { deserialize: 'records', serialize: false },
    qsSetupLink: { serialize: false },
    sampleIdentifiers: { deserialize: 'records', serialize: false },
    setupLink: { serialize: false },
    viia7SetupLink: { serialize: false },
    ab7500Link: { serialize: false },
    qsCovidLink: { serialize: false },
    abiCovidLink: { serialize: false },
    tubes: { deserialize: 'records', serialize: false },
    containerInformation: { deserialize: 'records', serialize: 'records' },
    sampleStates: { deserialize: 'ids', serialize: 'ids' }
  };

  payloadKeyFromModelName(/* modelName */) {
    return 'container';
  }

  serializeIntoHash(hash, typeClass, snapshot, options) {
    hash.container = this.serialize(snapshot, options);
    hash.container.type = classify(typeClass.modelName);
  }
}
