/* eslint-disable quotes, ember/no-get, ember/no-volatile-computed-properties, no-multi-spaces, ember/no-actions-hash */
import { A } from '@ember/array';
import { computed } from '@ember/object';
import { isNone } from '@ember/utils';
import RequestedTestsComponent from '../requested-tests/component';
import layout from "./template";

export default RequestedTestsComponent.extend({
  layout,

  // selectedTestPanel: computed.readOnly('_selectedTestPanel', function() {
  selectedTestPanel: computed('_selectedTestPanel', 'model.requestedTests', function() {
    let selection = this.get('_selectedTestPanel');

    if (isNone(selection)) {
      let requestedTests = this.get('model.requestedTests');

      return this._activePanel(requestedTests);
    }

    return selection;
  }).volatile(),

  _activePanel(requestedTests) {
    for (let key in requestedTests) {
      if (key.match(/^p/) && requestedTests[key]) {
        return key;
      }
    }
    return null;
  },

  timestamp: null,

  testGenes: computed('filteredTestPanels', function() {
    let testPanels = this.get('filteredTestPanels');
    let testGenes  = A();

    testPanels.forEach((panel) => {
      panel.testGenes.forEach((gene) => {
        testGenes.addObject(gene);
      });
    });

    return testGenes.sortBy('name');
  }),

  groupedTestGenes: computed('testGenes', 'timestamp', function() {
    let groups = A();
    let testGenes = this.get('testGenes').sortBy('fullName');

    let number = 4;
    let division = window.parseInt(testGenes.length / number);
    let modulo = testGenes.length % number;
    let start = 0;

    for (let i = 0; i < number; i++) {
      let length = division + (modulo > 0 && modulo > i ? 1 : 0);
      groups.addObject(testGenes.slice(start, start + length));
      start = start + length;
    }

    return groups;
  }),

  _updateTestGeneSelection(key, value) {
    let testPanel = this.get('testPanels').findBy('key', key);

    testPanel.testGenes.forEach((gene) => {
      this.set(gene.get('key'), value);
    });
    this.set('timestamp', new Date().getTime());
  },

  actions: {
    updateSelectedTestPanel(value) {
      let requestedTests = this.get('model.requestedTests');
      let activePanel    = this._activePanel(requestedTests);

      // disable active panel
      if (activePanel) {
        requestedTests[activePanel] = false;
        this._updateTestGeneSelection(activePanel, false);
      }

      // set new panel
      activePanel = value;
      requestedTests[activePanel] = true;

      this._updateTestGeneSelection(activePanel, true);

      this.set('_selectedTestPanel', value);
    }
  }
});
