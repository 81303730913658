/* eslint-disable semi */
import { helper as buildHelper } from '@ember/component/helper';

const MAPPING = {
  delivered_modified: 'Delivered<sup>*</sup>'
}

export function requisitionStatusToHuman(params) {
  return MAPPING[params[0]] || params[0];
}

export default buildHelper(requisitionStatusToHuman);
