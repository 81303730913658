/* eslint-disable ember/no-classic-components, ember/no-mixins, quotes, ember/no-get, semi, ember/no-volatile-computed-properties, comma-spacing, ember/no-classic-classes, ember/require-tagless-components, ember/no-component-lifecycle-hooks, no-undef, ember/no-actions-hash */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { alias, not, or } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { US_STATES_LIST } from 'ember-countries';
import { buildValidations, validator } from 'ember-cp-validations';
import PhysicianSearch from 'shared/mixins/physician-search';
import layout from './template';

const COMMUNICATION_PREFERENCES = ["Cell", "Email", "Office", "Text"];

const Validations = buildValidations({
  'salesAccount.name': validator('presence', true),
  'salesAccount.streetAddressZipCode': validator('format', {
    allowBlank: true,
    regex: /^[0-9]{5}(?:-[0-9]{4})?$/,
    message: 'must be a valid zip code'
  }),
  'salesAccount.mailingAddressZipCode': validator('format', {
    allowBlank: true,
    regex: /^[0-9]{5}(?:-[0-9]{4})?$/,
    message: 'must be a valid zip code'
  }),
  'salesAccount.faxNumber': [
    validator('presence', {
      presence: true,
      disabled: not('salesAccount.faxSignedReports'),
      dependentKeys: ['salesAccount.faxSignedReports']
    }),
    validator('number', {
      allowString: true,
      disabled: not('salesAccount.faxSignedReports'),
      dependentKeys: ['salesAccount.faxSignedReports']
    }),
    validator('length', {
      min: 11,
      disabled: not('salesAccount.faxSignedReports'),
      dependentKeys: ['salesAccount.faxSignedReports']
    })
  ],
  'contact.firstName': validator('presence', true),
  'contact.lastName': validator('presence', true),
  'contact.officePhoneNumber': [
    validator('presence', {
      presence: true,
      disabled: computed('model.contact.communicationPreference', function() {
        return this.get('model.contact.communicationPreference') !== "Office"
      }).volatile(),
      dependentKeys: ['model.contact.communicationPreference'],
    }),
    validator('format', {
      type: 'phone',
      message: "Must be a valid phone",
      allowBlank: true
    })
  ],
  'contact.communicationPreference': [
    validator('presence', true),
    validator('format', { allowBlank: false })
  ],
  'contact.email': [
    validator('presence', {
      presence: true,
      disabled: computed('model.contact.communicationPreference', function() {
        return this.get('model.contact.communicationPreference') !== "Email"
      }).volatile(),
      dependentKeys: ['model.contact.communicationPreference'],
    }),
    validator('format',{
      type: 'email',
      message: "Must be a valid email",
      allowBlank: true
    })
  ],
  'contact.cellPhoneNumber': [
    validator('presence', {
      presence: true,
      disabled: computed('model.contact.communicationPreference', function() {
        let communicationPreference = this.get('model.contact.communicationPreference');
        return communicationPreference !== "Cell" && communicationPreference !== 'Text'
      }).volatile(),
      dependentKeys: ['model.contact.communicationPreference'],
    }),
    validator('format', {
      type: 'phone',
      message: "Must be a valid phone",
      allowBlank: true
    })
  ]
});

export default Component.extend(Validations, PhysicianSearch, {
  layout,

  communicationPreferences: COMMUNICATION_PREFERENCES,

  currentOrganization: service(),
  flashMessages: service(),
  store: service(),

  primarySalesAccount: null,
  salesRep: null,

  onSubmit: () => {},
  onCancel: () => {},

  salesAccount: null,
  physician: null,
  contact: null,

  model: alias('physician'),

  statesList: US_STATES_LIST.map((s) => s.name),

  isInvalid: not('validations.isValid'),
  isSaving: alias('salesAccount.isSaving'),
  saveDisabled: or('isSaving', 'isInvalid'),

  init() {
    this._super(...arguments);
    this.set('salesAccount', this.store.createRecord('sales-account', {
      secondaryServiceProvider: true,
      organization: this.currentOrganization.organization
    }));
    this.set('physician', this.store.createRecord('physician', {
      organization: this.currentOrganization.organization,
      licensingRegistry: 'NPI'
    }));
    this.set('contact', this.store.createRecord('sales-account-contact', {
      organization: this.currentOrganization.organization,
    }));
    this.set('secondaryService', this.store.createRecord('secondary-service', {
      salesAccount: this.primarySalesAccount
    }));
  },

  willDestroyElement() {
    this._super(...arguments);
    this.salesAccount.rollbackAttributes();
    this.physician.rollbackAttributes();
    this.contact.rollbackAttributes();
    this.secondaryService.rollbackAttributes();
  },

  async _setPhysicianInfo(attributes) {
    let physician = null;
    if (attributes.id) {
      physician = await this.store.findRecord('physician', attributes.id);
    }
    if (physician) {
      this.set('physician', physician);
    } else if (isEmpty(attributes.npi)) {
      this.physician.setProperties({ id: null, name: null, npi: null });
    } else {
      this.physician.setProperties(attributes);
    }
  },

  actions: {
    create() {
      if (this.salesRep) {
        this.set('salesAccount.salesRep', this.salesRep);
        this.set('salesAccount.salesGroup', this.salesRep.salesGroup);
      }
      this.set('salesAccount.contact', this.contact);

      if (this.physician.npi) {
        this.set('salesAccount.physician', this.physician);
      }

      this
        .salesAccount
        .save()
        .then((salesAccount) => {
          this.set('secondaryService.secondarySalesAccount', salesAccount);

          return this
            .secondaryService
            .save()
            .then(() => {
              this.flashMessages.success('Secondary Provider created');
              this.onSubmit(salesAccount, salesAccount.physician);
            })
            .catch((error) => {
              console.error(error);
              this.flashMessages.error('Failed to create Secondary Provider');
            });
        })
        .catch(() => {
          this.flashMessages.error('Failed to create Secondary Provider');
        });
    },

    cancel() {
      this.salesAccount.rollbackAttributes();
      this.physician.rollbackAttributes();
      this.secondaryService.rollbackAttributes();
      this.onCancel();
    },
  }
});
