import { alias, sort } from '@ember/object/computed';
import BaseComponent from '../../requisition-base/component';
import layout from './template';

export default BaseComponent.extend({
  layout,
  classNames: ['requisition-samples'],
  isVisible: true,
  canEditIdentifier: false,

  samplesGroups: alias('requisitionTemplate.requisitionTemplateSamplesGroups'),
  groupsSorting: Object.freeze(['groupOrder:asc']),
  sortedSamplesGroups: sort('samplesGroups', 'groupsSorting')
});
