/* eslint-disable ember/no-computed-properties-in-native-classes, no-unused-vars, getter-return, object-curly-spacing, ember/no-get, ember/classic-decorator-no-classic-methods */
import { alias } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';
import Service, { inject as service } from '@ember/service';
import { task, timeout } from 'ember-concurrency';
import Ember from 'ember';

export default class DownloadRequestsTracker extends Service {
  @service store;
  @service currentOrganization;

  @tracked downloadRequests = null;

  get organization() {
    this.currentOrganization.organization;
  }

  @task({ restartable: true})
  *loadDownloadRequests() {
    const _downloadRequests = yield this
      .store
      .query('download-request',
        { organization_id: this.get('organization.id'),
          include: 'resources' })
      .catch((e) => {
        return [];
      });
    this.set('downloadRequests', _downloadRequests);

    if (!Ember.testing) {
      yield timeout(60000);
    }
  }
}
