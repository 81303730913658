define("ember-preferences/storage/memory", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _object.default.extend({
    db: (0, _object.computed)(function () {
      return _object.default.create();
    }),
    setItem(key, value) {
      return this.get('db').set(key, value);
    },
    getItem(key) {
      return this.get('db').get(key);
    },
    clear() {
      this.set('db', _object.default.create());
    },
    removeItem(key) {
      this.get('db').set(key, undefined);
    }
  });
});