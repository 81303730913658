/* eslint-disable ember/no-classic-components, no-unused-vars, ember/no-classic-classes, ember/no-get, ember/no-component-lifecycle-hooks, semi */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { task, timeout } from 'ember-concurrency';
import ENV from 'ember-get-config';
import layout from './template';

const TIMEOUT = ENV.environment === 'test' ? 0 : 1000;

/**
 * Use this data wrapper component to access options for Provider Accounts and
 * Providers for a given Organization.
 *
 * Example:
 * ```
 *   <Data::ProviderOptions @organization={{@organization}} as |service|>
 *     <PowerSelect
 *       @placeholder="All Provider Accounts"
 *       @selected={{this.selectedProviderAccounts}}
 *       @options={{service.providerAccounts}}
 *       @search={{perform service.lookupProviderAccounts}}
 *       @searchEnabled={{true}}
 *       @onchange={{action (mut this.selectedProviderAccounts)}}
 *       @triggerClass="max-width-none light-gray"
 *       @dropdownClass="light-gray"
 *       @verticalPosition="below"
 *       as |providerAccount|>
 *
 *       {{providerAccount.name}}
 *
 *     </PowerSelect>
 *   </Data::ProviderOptions>
 * ```
 */
export default Component.extend({
  layout,
  tagName: '',

  store: service(),
  flashMessages: service(),

  organization: null,
  providerAccount: null,
  forOrdersPortal: false,
  forSalesPortal: false,
  secondaryServiceProvider: false,
  secondaryServiceProviderAccount: null,
  providerAccountGroupIds: [],
  projectId: null,
  requisitionTemplateId: null,
  excludePhysicianSalesAccounts: false,

  lookupProviderAccounts: task(function *(search) {
    yield timeout(TIMEOUT);

    if (!this.organization) {
      this.set('providerAccounts', []);
      return;
    }

    let query = {
      organization_id: this.organization.id,
      fields: 'id,name',
      filter: {
        secondary_service_provider: this.secondaryServiceProvider,
        search
      },
      page: 1,
      per_page: 10,
    };

    if (this.providerAccountGroupIds.length > 0) {
      query.provider_account_group_id = this.providerAccountGroupIds;
    }

    if (this.projectId) {
      query.filter.project_id = this.projectId;
    }

    if (this.requisitionTemplateId) {
      query.filter.req_template_id = this.requisitionTemplateId;
    }

    if (this.secondaryServiceProviderAccount) {
      query.filter.sales_account_id = this.get('secondaryServiceProviderAccount.id');
    }

    if (this.forSalesPortal) {
      query.for_sales_portal = true;
    }

    if (this.forOrdersPortal) {
      query.for_orders_portal = true;
    }

    const providerAccounts = yield this
      .store
      .query('sales-account', query)
      .catch(() => {
        this.flashMessages.error('Failed to lookup Provider Accounts');
        return [];
      });

    return providerAccounts;
  }).restartable(),

  lookupProviders: task(function *(search) {
    yield timeout(TIMEOUT);

    if (!this.organization) {
      this.set('providers', []);
      return;
    }

    const query = {
      organization_id: this.organization.id,
      fields: 'id,name,npi',
      filter: { search },
      page: 1,
      per_page: 10,
    };

    if (this.excludePhysicianSalesAccounts) {
      query.include = 'exclude-physician-sales-accounts';
    }

    if (this.providerAccount) {
      query.sales_account_id = this.get('providerAccount.id');
    }

    if (this.forSalesPortal) {
      query.for_sales_portal = true;
    }

    if (this.forOrdersPortal) {
      query.for_orders_portal = true;
    }

    const providers = yield this
      .store
      .query('physician', query)
      .catch(() => {
        this.flashMessages.error('Failed to lookup Providers');
        return [];
      });

    return providers;
  }).restartable(),

  didReceiveAttrs() {
    this._super(...arguments);
    this.set('providerAccounts', this.lookupProviderAccounts.perform());
    this.set('providers', this.lookupProviders.perform());
  },

});
