/* eslint-disable quotes, ember/no-mixins, ember/no-component-lifecycle-hooks, ember/no-get, max-len, no-multiple-empty-lines, object-shorthand, space-before-blocks, ember/no-actions-hash */
import { inject as service } from '@ember/service';
import { alias, not, or, bool } from '@ember/object/computed';
import { set, computed, getProperties } from '@ember/object';
import BaseComponent from 'shared/components/requisitions/requisition-base/component';
import { task } from 'ember-concurrency';
import SampleCollectionInputs from 'shared/mixins/sample-collection-inputs';
import layout from './template';

export default BaseComponent.extend(SampleCollectionInputs, {
  layout,
  classNames: ['requisition-sample'],

  sampleTypes: [],
  isNew: true,
  readOnly: false,

  requisitionTemplate: alias('model.requisition.requisitionTemplate'),
  templateName: alias('requisitionTemplate.name'),
  enforceSingleIdentifier: bool('requisitionTemplate.enforceSingleIdentifier'),
  requisition: alias('model.requisition'),
  projectId: alias('requisition.projectId'),

  isVisible: true,

  editable: not('readOnly'),
  received: alias('model.received'),
  dateReceivedValid: alias('model.dateReceivedValid'),
  dateReceivedAndSampleColectionDateValid: alias('model.dateReceivedAndSampleColectionDateValid'),
  notReceived: not('received'),
  canEditDateReceived: or('notReceived', 'processed'),
  plateConfiguration: { containerTypes: ['Tube', '96-Well', '384-Well'] },

  store: service(),

  didReceiveAttrs() {
    this._super(...arguments);
    this._loadDataTask.perform();
  },

  minRequirements: computed('dateReceivedAndSampleColectionDateValid', 'dateReceivedValid', 'received', 'requiredCollectedBy', 'requiredCollectionDate', 'requiredCollectionTime', 'template.required', function() {
    const template = this.template;
    const required = template.required;
    const { requiredCollectionDate, requiredCollectionTime, requiredCollectedBy } = getProperties(this, ['requiredCollectionDate', 'requiredCollectionTime', 'requiredCollectedBy']);

    let requiredFields = [];
    if (required) {
      requiredFields.push({
        key: 'identifier',
        name: `${template.name} identifier`
      });
      requiredFields.push({
        key: 'sampleStates.firstObject.container',
        name: `${template.name} container`
      });
      requiredFields.push({
        key: 'sampleStates.firstObject.label',
        name: `${template.name} label`
      });

      if (this.received) {
        requiredFields.push({
          key: 'dateReceived',
          name: `${template.name} Date Received`
        });
      }

      if (requiredCollectionDate) {
        requiredFields.push({
          key: 'sampleCollectionDate',
          name: `${template.name} Collection Date`
        });
      }

      if (requiredCollectionTime) {
        requiredFields.push({
          key: 'sampleCollectionTime',
          name: `${template.name} Collection Time`
        });
      }

      if (requiredCollectedBy) {
        requiredFields.push({
          key: 'sampleCollectedBy',
          name: `${template.name} Collected By`
        });
      }

      if (!this.dateReceivedValid) {
        requiredFields.push({
          key: 'dateReceivedValid',
          name: 'Sample Date Received cannot be in the future'
        });
      }

      if (!this.dateReceivedAndSampleColectionDateValid) {
        requiredFields.push({
          key: 'dateReceivedAndSampleColectionDateValid',
          name: 'Sample Date Received must be after Sample Collection Date'
        });
      }

    }

    return requiredFields;
  }),

  _loadDataTask: task(function *() {
    let project = this.project;

    if (project === undefined && this.projectId === undefined) {
      return;
    }

    if (project === undefined) {
      project = yield this.store.findRecord('project', this.projectId, {
        include: 'project-requisition-template-associations'
      });
      this.set('project', project);
    }

    const projectRequisitionTemplateAssociations = yield project.projectRequisitionTemplateAssociations;

    this._loadSampleTypes(projectRequisitionTemplateAssociations);
  }),

  _loadSampleTypes(projectRequisitionTemplateAssociations) {
    const templateName = this.templateName;
    const currentReqTemplateAssociation = projectRequisitionTemplateAssociations.findBy('requisitionTemplate.name', templateName);

    let sampleTypes = [];
    if (currentReqTemplateAssociation) {
      const rules = currentReqTemplateAssociation.get('requisitionSampleRouting.configuration');

      if (rules) {
        sampleTypes = rules
          .map(rule => rule.sampleType)
          .uniq()
          .sort();
      }
    }

    set(this, 'sampleTypes', sampleTypes);
  },

  actions: {
    setPlate(params) {
      let container = params.container;
      let sampleState = this.sampleState;

      if (container) {
        sampleState.set('container', container);
        sampleState.set('position', container.get('positions').shift());
      } else {
        sampleState.set('container', null);
      }

      this.change();
    }
  }

});
