/* eslint-disable ember/no-new-mixins */
import Mixin from '@ember/object/mixin';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';

export default Mixin.create({
  session: service(),
  currentSession: service(),

  currentUser: alias('currentSession.currentUser')
});
