/* eslint-disable ember/no-classic-classes, key-spacing, space-in-parens, ember/require-return-from-computed, ember/no-get */
import Model, { belongsTo, attr } from '@ember-data/model';
import { computed } from '@ember/object';
import moment from 'moment';

export default Model.extend({
  // Associations
  user:           belongsTo('user'),
  resource:       belongsTo('resource'),
  organization:   belongsTo('organization'),

  name:           attr('string'),
  requestDate:    attr('date'),
  reportType:     attr('string'),
  recipients:     attr('string'),
  status:         attr('string'),
  searchParams:   attr(),
  forPortal:      attr('boolean', { defaultValue: false }),

  associatedSalesAccounts:        attr(),
  associatedPhysicians:           attr(),
  associatedProject:             attr(),
  associatedRequisitionTemplate: attr(),
  associatedWorkflow:            attr(),

  generatedFromSchedule: attr('boolean', { defaultValue: false } ),

  startDate: computed('searchParams.created_at_gteq', function() {
    const date = this.get('searchParams.created_at_gteq');

    if (date) {
      return moment(date);
    }
  }),

  endDate: computed('searchParams.{created_at_lt,created_at_lteq}', function() {
    const date = this.get('searchParams.created_at_lt') || this.get('searchParams.created_at_lteq');

    if (date) {
      return moment(date);
    }
  }),

  recipientList: computed('recipients', function() {
    return (this.recipients || '').split(',');
  })

});
