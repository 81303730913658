/* eslint-disable ember/no-classic-classes, key-spacing, ember/no-get, quotes, ember/require-return-from-computed, object-shorthand */
import Model, { belongsTo, attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { alias, reads } from '@ember/object/computed';

export default Model.extend({

  // Associations
  role:         belongsTo('role'),
  tag:          belongsTo('tag'),

  // Attributes
  name:         attr('string'),
  view:         attr('boolean'),
  read:         attr('boolean'),
  write:        attr('boolean'),
  admin:        attr('boolean'),
  privilegeId:  attr('string'),
  activity:     attr('boolean'),

  // Helper methods
  canView:  alias('view'),
  canRead:  alias('read'),
  canWrite: alias('write'),
  canAdmin: alias('admin'),

  level: computed('view', 'read', 'write', 'admin', {
    get() {
      // getter
      if (this.get('admin')) {
        return "Admin";
      } else if (this.get('write')) {
        return "Write";
      } else if (this.get('read')) {
        return "Read";
      } else if (this.get('view')) {
        return "View";
      } else {
        return "None";
      }
    },
    set(_, value) {
      // setter
      if (arguments.length > 1) {
        this.setLevel(value);
      }
    }
  }),

  isAdmin: reads('admin'),

  isWriter: computed('admin', 'writer', 'wrtier', function() {
    return (!this.get('admin') && this.get('wrtier'));
  }),

  isReader: computed('admin', 'read', 'writer', 'wrtier', function() {
    return (!this.get('admin') && !this.get('wrtier') && this.get('read'));
  }),

  isViewer: computed('admin', 'read', 'view', 'writer', 'wrtier', function() {
    return (!this.get('admin') && !this.get('wrtier') && !this.get('read') && this.get('view'));
  }),

  setLevel: function(level) {
    if (level === "Admin") {
      this.setProperties({
        admin: true,
        write: true,
        read: true,
        view: true
      });
    } else if (level === "Write") {
      this.setProperties({
        admin: false,
        write: true,
        read: true,
        view: true
      });
    } else if (level === "Read") {
      this.setProperties({
        admin: false,
        write: false,
        read: true,
        view: true
      });
    } else if (level === "View") {
      this.setProperties({
        admin: false,
        write: false,
        read: false,
        view: true
      });
    } else if (level === "None") {
      this.setProperties({
        admin: false,
        write: false,
        read: false,
        view: false
      });
    }
  }

});
