/* eslint-disable brace-style, space-before-blocks, indent, quotes, no-multiple-empty-lines, semi */
import { assign } from '@ember/polyfills';
import { assert } from '@ember/debug';

const parseSortData = function(sortData) {
  sortData = sortData || [];

  let sort;
  if (sortData[0]) {
    sort = sortData[0].prop;
    if (sort && sortData[0].direction === 'desc') {
      sort = sort
        .split(',')
        .map((part) => `-${part}`)
        .join(',');
    }
  }

  return sort;
};

const parseSortDataWithDirectionText = function(sortData) {
  sortData = sortData || [];
  if (sortData[0]) {
    let property = sortData[0].prop;
    let direction = sortData[0].direction;

    assert(`Missing required 'prop' value on column: ${JSON.stringify(sortData)}`, property);

    if (property.includes('_and_')) {
      return property.split('_and_').map((prop) => { return `${prop} ${direction}`; });
    } else {
      return `${property} ${direction}`;
    }
  }
  return null;
};

const parseFilterData = function(filterData) {
  filterData = filterData || { columnFilters: [] };

  const search = filterData.filter;
  const filter = { search };

  if (filterData.columnFilters){
    filterData
    .columnFilters
    .forEach((option) => {
      if (option.filter !== undefined) {
        filter[option.filterUsing] = option.filter;
      }

      if (typeof(option.filterUsing) === "object") {
        assign(filter, option.filterUsing);
      }
    });
  }

  return filter;
};

const parsePaginationData = function(paginationData) {
  paginationData = paginationData || { pageNumber: 1, pageSize: 100 };

  const { pageNumber, pageSize } = paginationData;
  return { number: pageNumber, size: pageSize };
};

const parseQueryData = function({ paginationData, sortData, filterData }) {
  const sort = parseSortData(sortData);
  const order = parseSortDataWithDirectionText(sortData);
  const filter = parseFilterData(filterData);
  const page = parsePaginationData(paginationData);
  const q = { s: order, ...filter };

  let query = { sort, order, filter, _q: q, page: page.number, per_page: page.size };
  if (filter.search) {
    query.search = filter.search
  }

  return query;
};

export default parseQueryData;
