import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"ui-group\" data-test-id=\"ui-group\" ...attributes>\n  <div class=\"ui-group_label\" data-test-id=\"ui-group-label\"> {{yield to=\"label\"}} </div>\n  <div class=\"ui-group_content\" data-test-id=\"ui-group-content\"> {{yield to=\"content\"}} </div>\n</div>", {"contents":"<div class=\"ui-group\" data-test-id=\"ui-group\" ...attributes>\n  <div class=\"ui-group_label\" data-test-id=\"ui-group-label\"> {{yield to=\"label\"}} </div>\n  <div class=\"ui-group_content\" data-test-id=\"ui-group-content\"> {{yield to=\"content\"}} </div>\n</div>","moduleName":"shared/components/ui/group.hbs","parseOptions":{"srcName":"shared/components/ui/group.hbs"}});
/* eslint-disable ember/no-empty-glimmer-component-classes */
import Component from '@glimmer/component';

/**
 * This component is small and encapsulates the UI for a label: content pair
 * for consistent styling throughout the application.
 **/
export default class UiGroupComponent extends Component {

}
