/* eslint-disable ember/no-classic-classes */
import Model, { attr, belongsTo } from '@ember-data/model';

export default Model.extend({
  // Associations
  organization: belongsTo('organization'),
  toxNotificationRole: belongsTo('role'),

  archived: attr('boolean'),
  rootFolderId: attr('string')
});
