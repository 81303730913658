/* eslint-disable ember/no-classic-classes, object-shorthand */
import Transform from '@ember-data/serializer/transform';
import CustomAttributes from 'shared/models/custom-attributes';
import moment from 'moment';

export default Transform.extend({
  deserialize: function(serialized) {
    if (serialized) {
      return CustomAttributes.create(serialized);
    } else {
      return CustomAttributes.create({});
    }
  },
  serialize: function(deserialized) {
    Object
      .keys(deserialized)
      .forEach((key) => {
        if (deserialized[key] instanceof Date) {
          deserialized.set(key, moment(deserialized[key]).format('YYYY-MM-DD'));
        }
      });

    return deserialized;
  }
});
