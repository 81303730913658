/* eslint-disable max-len, ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components, ember/no-component-lifecycle-hooks */
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import config from 'ember-get-config';
import layout from './template';
import { task } from 'ember-concurrency';
import fetch from 'fetch';
import { action } from '@ember/object';

export default Component.extend({
  layout,
  currentSession: service(),
  session: service(),
  flashMessages: service(),
  mfaEnabled: false,
  showConfirmation: false,

  didReceiveAttrs() {
    this._super(...arguments);
    this.fetchMfa.perform();
  },

  fetchMfa: task(function *() {
    let token = this.currentSession.token;
    let response = yield fetch(
      `${config.api_endpoint}/api/v1/mfa`,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json'
        }
      }
    ).catch(() => {
      this.set('showConfirmation', false);
      this.flashMessages.error('Error encountered when fetching MFA enrollment URL.');
    });

    if (response.ok) {
      let data = yield response.json();
      this.set('mfaEnabled', data.mfa_enabled);
    }
  }),

  setMfa: task(function *() {
    let token = this.currentSession.token;
    let enabled = !this.mfaEnabled;
    let response = yield fetch(
      `${config.api_endpoint}/api/v1/mfa`,
      {
        method: 'POST',
        body: JSON.stringify({ 'mfa_enabled': enabled }),
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }
    ).catch(() => {
      this.set('showConfirmation', false);
      this.flashMessages.error('Error encountered when updating two-step verification settings.');
    });

    if (!response.ok) {
      this.flashMessages.error('Unable to save mfa');
    } else {
      yield response.json();
      this.session.invalidate();
    }
  }),

  toggleMfa: action(function() {
    this.setMfa.perform();
  })
});
