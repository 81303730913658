import { escape } from 'shared/utils/escape';

export function urlEncode(param, nesting = '') {
  if (typeof param === 'object') {
    return Object.entries(param)
      .map(([key, val]) => {
        // Handle a second base case where the value to encode is an array
        if (Array.isArray(val)) {
          return val
            .map(subVal => [nesting + key, subVal].map(escape).join('='))
            .join('&');
        } else if (typeof val === 'object') {
          return urlEncode(val, `${nesting}[${key}]`);
        } else {
          return [nesting + key, val].map(escape).join('=');
        }
      })
      .join('&');
  } else {
    return param;
  }
}
