/* eslint-disable key-spacing, brace-style, ember/no-get, curly, no-multi-spaces, space-before-blocks */
import { belongsTo, hasMany, attr } from '@ember-data/model';
import { isPresent } from '@ember/utils';
import { getProperties, get, computed } from '@ember/object';
import Source from 'shared/models/source';
import moment from 'moment';
import config from 'ember-get-config';

const {
  RACE_MAP,
  ETHNICITY_MAP,
} = config;

const MIN_DATE = new Date(1900, 0, 0);

export default Source.extend({
  // Associations
  project:              belongsTo('project'),
  physician:            belongsTo('physician'),
  billingInformation:   belongsTo('billing-information'),
  entityCustomAttributes: hasMany('entity-custom-attribute', { async: false }),

  // Attributes
  mrn:                  attr('string'),
  identifier:           attr('string'),
  lastName:             attr('string'),
  firstName:            attr('string'),
  middleName:           attr('string'),
  streetAddress:        attr('string'),
  streetAddressLine2:   attr('string'),
  city:                 attr('string'),
  state:                attr('string'),
  country:              attr('string'),
  zipCode:              attr('string'),
  dateOfBirth:          attr('date'),
  phoneNumber:          attr('string'),
  email:                attr('string'),
  sex:                  attr('string'),
  height:               attr('string'),
  weight:               attr('string'),
  ethnicity:            attr('string'),
  race:                 attr('string'),
  pin:                  attr('string'),
  originalOrderId:      attr('string'),
  patientId:            attr('string'),
  createdAt:            attr('date'),
  customAttributes:     attr({ defaultValue: () => { return {}; } }),

  fullName: computed('firstName', 'lastName', 'middleName', function() {
    const { firstName, lastName, middleName } = getProperties(this, ['firstName', 'lastName', 'middleName']);
    let names = [];

    if (firstName) names.push(firstName);
    if (middleName) names.push(middleName);
    if (lastName) names.push(lastName);

    if (names.length) {
      return names.join(' ');
    } else {
      return 'Unknown';
    }
  }),

  firstLastName: computed('firstName', 'lastName', function() {
    const { firstName, lastName } = getProperties(this, ['firstName', 'lastName']);
    let names = [];

    if (firstName) names.push(firstName);
    if (lastName) names.push(lastName);

    if (names.length) {
      return names.join(' ');
    } else {
      return 'Unknown';
    }
  }),

  age: computed('dateOfBirth', function() {
    const dateOfBirth = get(this, 'dateOfBirth');
    if (dateOfBirth) {
      return moment().diff(dateOfBirth, 'years');
    } else {
      return 'Unknown';
    }
  }),

  displaySex: computed('sex', function() {
    const sex = get(this, 'sex');
    let text = 'Undisclosed';

    if (isPresent(sex)) {
      text = sex.charAt(0).toUpperCase() + sex.slice(1);
    }

    return text;
  }),

  displayRace: computed('race', function() {
    return RACE_MAP[this.race];
  }),

  displayEthnicity: computed('ethnicity', function() {
    return ETHNICITY_MAP[this.ethnicity];
  }),

  address: computed('city', 'country', 'state', 'streetAddress', 'streetAddressLine2', 'zipCode', function() {
    const { streetAddress, streetAddressLine2, city, state, zipCode, country } =
      getProperties(this, ['streetAddress', 'streetAddressLine2', 'city', 'state', 'zipCode', 'country']);

    let addressParts = [];

    if (streetAddress) {
      addressParts.push(streetAddress);
    }

    if (streetAddressLine2) {
      addressParts.push(streetAddressLine2);
    }

    if (city) {
      addressParts.push(city);
    }

    if (state) {
      addressParts.push(state);
    }

    if (zipCode) {
      addressParts.push(zipCode);
    }

    if (country) {
      addressParts.push(country);
    }

    return addressParts.join(', ');
  }),

  dateOfBirthInvalid: computed('dateOfBirth', function() {
    if (this.dateOfBirth && (this.dateOfBirth >= new Date()  || this.dateOfBirth < MIN_DATE)){
      return null;
    }

    return true;
  })
});
