/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/no-get, ember/no-actions-hash, quotes */
import Component from '@ember/component';
import { get, computed } from '@ember/object';
import { isNone } from '@ember/utils';
import layout from './template';

export default Component.extend({
  layout,
  tagName: '',

  prefix: null,
  name: null,
  value: null,
  selection: null,
  isBoolean: false,

  compositeName: computed('prefix', 'name', function() {
    return `${this.get('prefix')}-${this.get('name')}`;
  }),

  _selection: computed('selection', 'isBoolean', function() {
    let selection = (isNone(get(this, 'selection')) ? '' : get(this, 'selection')) || '';
    let isBoolean = get(this, 'isBoolean');

    if (isBoolean) {
      return selection.toString();
    }

    return selection;
  }),

  actions: {
    update(value) {
      let onUpdate = get(this, 'on-update');
      let isBoolean = get(this, 'isBoolean');

      if (isBoolean) {
        value = value === "true";
      }

      if (onUpdate) {
        onUpdate(value);
      } else {
        this.set('selection', value);
      }
    }
  }

});
