/* eslint-disable ember/no-classic-components, quotes, ember/no-classic-classes, ember/require-tagless-components, ember/no-actions-hash */
import Component from "@ember/component";
import layout from "./template";

export default Component.extend({
  layout,
  title: null,

  // feature flags
  movable: false,
  useToggle: false,
  canMoveUp: false,
  canMoveDown: false,

  toggleValue: false,

  onMoveUp() {},
  onMoveDown() {},
  onToggle() {},

  actions: {
    moveUp() {
      this.onMoveUp();
    },

    moveDown() {
      this.onMoveDown();
    },

    onToggle(value) {
      this.onToggle(value);
    },
  },
});
