/* eslint-disable ember/require-computed-property-dependencies, ember/no-get */
/* globals Raygun */
import Ember from 'ember';
import SessionService from 'ember-simple-auth/services/session';
import { task, timeout } from 'ember-concurrency';
import { alias } from '@ember/object/computed';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Preference from 'shared/utils/preference';

const DELAY = 5 * 60 * 1000; // 5 Minutes
const REFRESH_EARLY = 30;

export default SessionService.extend({
  auth0: service(),
  currentOrganization: service(),
  currentSession: service(),
  logs: service(),
  preferences: service(),
  router: service(),
  segment: service(),
  store: service(),

  _showSessionOverlay: false,

  data: alias('auth0.data'),
  expirationTime: alias('data.authenticated.idTokenPayload.exp'),
  profile: alias('auth0.user'),
  sessionStore: alias('session.store'),

  /**
   * Check if session token is still valid
   */
  hasActiveToken: computed({
    get() {
      if (Ember.testing) {
        return true;
      }

      const expirationTime = this.get('data.authenticated.idTokenPayload.exp') || 0;
      const date = new Date();
      const currentTime = parseInt((date.getTime() / 1000).toFixed(0), 10);

      return expirationTime > currentTime;
    }
  }),

  /**
   * Disallow setting `attemptedTransition` to null so session can control
   * when it is set/unset. This keeps ember-simple-auth from removing it.
   **/
  attemptedTransition: computed('persistAttemptedTransition', {
    set(key, value) {
      if (value) {
        this.set('persistAttemptedTransition');
        localStorage.setItem(this.currentSession.attemptedTransitionKey, value.intent.url);
      }
      return value;
    },

    get() {
      return this.persistAttemptedTransition;
    }
  }),

  /**
   * Turn on timeout information overlay
   */
  enableSessionOverlay() {
    this.set('_showSessionOverlay', true);
    localStorage.setItem('winnebago:showSessionOverlay', 'true');
  },

  /**
   * Turn off timeout information overlay
   */
  disableSessionOverlay() {
    this.set('_showSessionOverlay', false);
    localStorage.setItem('winnebago:showSessionOverlay', 'false');
  },

  /**
   * Check if session timeout overlay should be displayed
   */
  showSessionOverlay: computed('_showSessionOverlay', function() {
    return this._showSessionOverlay || localStorage.getItem('winnebago:showSessionOverlay') === 'true';
  }),

  /**
   * This is called by the instance-initializer/session-watcher when user
   * creates some kind of activity
   **/
  async refreshSession() {
    if (Ember.testing) {
      return;
    }

    localStorage.setItem('winnebago:lastActivity', Math.ceil(Date.now() / 1000));
    return await this.refreshSessionTask.perform().catch(() => {});
  },

  /**
   * Refresh the session immediately. This is called when user selects to do so
   * from the session overlay.
   **/
  async refreshSessionNow() {
    if (Ember.testing) {
      return;
    }

    localStorage.setItem('winnebago:isRefreshing', 'false');
    this.refreshSessionTask.cancelAll();

    return await this.refreshAuthToken();
  },

  refreshSessionTask: task(function *() {
    this.logs.post('Task: _refreshSessionTask');

    let timeRemaining = this.timeRemainingSeconds() * 1000;
    let delay = Math.min(timeRemaining, DELAY);
    let expirationTime = this.expirationTime;

    localStorage.setItem('winnebago:isRefreshing', 'true');

    this.logs.post(`Task: _refreshSessionTask - delaying ${delay}`);
    yield timeout(delay);

    // check if the expiration time has changed (another tab might have updated the token)
    if (expirationTime === this.expirationTime) {
      this.logs.post('Task: _refreshSessionTask - refresh access token');
      localStorage.setItem('winnebago:isRefreshing', 'false');
      localStorage.setItem('winnebago:showSessionOverlay', 'false');

      yield this.refreshAuthToken();
      this.logs.post('Task: _refreshSessionTask - refresh access token done');
    } else {
      localStorage.setItem('winnebago:isRefreshing', 'false');
      this.logs.post('Task: _refreshSessionTask - abort refresh');
    }
  }).drop(),

  timeRemainingSeconds() {
    const expirationTime = this.expirationTime - REFRESH_EARLY;
    const currentTime = Math.ceil(Date.now() / 1000);

    return Math.max(expirationTime - currentTime, 0);
  },

  async refreshAuthToken() {
    return this.auth0.checkLogin();
  },

  invalidate() {
    this._super(...arguments).then(() => {
      this.auth0.logout();
    });
  },

  /**
   * This is called when the user has completed the login form from Auth0 and
   * is redirected back to the app. This will load the user data from the
   * access token in the url and the user will then be considered
   * fully authenticated
   **/
  async handleAuthentication(transition) {
    if (this.isAuthenticated) {
      return;
    }

    if (Ember.testing) {
      return this.afterAuthentication(transition);
    }

    return this.auth0.loadAuth0User().then(() => {
      this.afterAuthentication(transition);
    }).catch(() => {
      this.logs.post('Auth0 Authentication - loadAuth0User failed');
    });
  },

  /**
   * Things that need to happen when the user has been returned from the Auth0
   * login redirect should happen here.
   **/
  async afterAuthentication(transition) {
    this.clearSessionOverlay();
    this.clearSessionPreferences();
    this.trackLogin();
    this.setRaygunUser();

    if (transition.to.name === 'index') {
      this.routeUser();
    }
  },

  /**
   * If user had an attempted transition before login redirect then send them
   * to that transition. If not, send them to their default organization's
   * dashboard.
   **/
  async routeUser() {
    const transition = localStorage.getItem(this.currentSession.attemptedTransitionKey);

    if (transition && transition !== '/' && transition !== 'undefined') {
      this.routeToAttemptedTransition(transition);
    } else {
      return this.routeToDashboard();
    }
  },

  /**
   * Send the user to the default organization's dashboard
   **/
  async routeToDashboard() {
    await this.setCurrentOrganization();
    this.router.transitionTo(this.currentSession.defaultRoute, this.currentSession.defaultOrganization.id);
  },

  /**
   * Send user to a specific transition. Make sure the current organization and
   * all organizations are loaded.
   **/
  async routeToAttemptedTransition(transition) {
    await this.setCurrentOrganization();
    localStorage.removeItem(this.currentSession.attemptedTransitionKey);
    return this.router.transitionTo(transition);
  },

  /**
   * Clear session overlay
   **/
  clearSessionOverlay() {
    localStorage.setItem('winnebago:showSessionOverlay', 'false');
    localStorage.setItem('winnebago:isRefreshing', 'false');
  },

  /**
   * Track login with Segment
   **/
  async trackLogin() {
    let currentUser = await this.currentSession.currentUser;

    if (currentUser) {
      this.segment.identifyUser(
        currentUser.uuid,
        {
          email: currentUser.email,
          id: currentUser.auth0_id,
          name: currentUser.name,
          signup_date: currentUser.createdAt
        }
      );
    }

    this.segment.trackPageView();
    this.segment.trackEvent('Login');
  },

  /**
   * Called by application route on each respective application to identify the
   * user when first routing into the app.
   **/
  async identifyUser(organization) {
    const currentUser = await this.currentSession.currentUser;
    if (Boolean(currentUser) && Boolean(organization)) {
      this.segment.identifyUser(
        currentUser.uuid,
        {
          email: currentUser.email,
          id: currentUser.auth0_id,
          name: currentUser.name,
          signup_date: currentUser.createdAt,
          account_id: organization.id,
          account_name: organization.name
        }
      );
    }
  },

  /**
   * Set the current organization for the user based on either user preference
   * value for organization or by taking the first organization available to the
   * user. This also loads all organizations for the first time.
   **/
  async setCurrentOrganization() {
    let organizations = this.currentSession.didFetchOrganizations ?
      this.store.peekAll('organization') :
      await this.currentSession.fetchOrganizations();

    let currentUser = await this.currentSession.currentUser;
    let userOrg;

    // Organization load is different between portal & osl so fetching orgs
    // happens on the `currentSesssion` for each app.
    if (currentUser) {
      let userPreference = await currentUser.userPreference;
      let userOrgId = userPreference && userPreference.get('organizationId');
      userOrg = userOrgId && organizations.findBy('id', userOrgId);
    }

    let selectedOrg = userOrg ? userOrg : organizations.get('firstObject');
    this.currentSession.set('defaultOrganization', selectedOrg);
    this.currentOrganization.set('organization', selectedOrg);
  },

  /**
   * Set the raygun user
   **/
  setRaygunUser() {
    let session = this.currentSession.data || {};
    let userId = session.id;
    let email = session.email;

    return Raygun.setUser(
      userId,
      false,
      email
    );
  },

  clearSessionPreferences() {
    const preference = Preference.create({
      preferences: this.preferences,
      currentSession: this.currentSession,
    });

    preference.clearSessionPreferences();
  }
});
