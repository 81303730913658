/* eslint-disable ember/no-classic-components, ember/no-mixins, ember/no-classic-classes, space-before-function-paren, ember/no-component-lifecycle-hooks, quotes, ember/no-actions-hash */
import Component from '@ember/component';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { or } from '@ember/object/computed';
import AutoCompleteSettings from 'shared/mixins/auto-complete-settings';
import { isBlank } from '@ember/utils';
import layout from './template';

export default Component.extend(AutoCompleteSettings, {
  layout,

  tagName: '',

  // Renamed to avoid conflict with `settings` on AutoCompleteSettings mixin
  appSettings: service('settings'),
  store: service(),

  // Override this if model uses different attr name
  recipientsProp: 'recipients',
  didSubmit: true,
  userOptions: [],
  rolesOptions: [],
  values: null,

  isLoading: or('_loadUserOptions.isRunning', '_loadRoleOptions.isRunning'),

  mentionOptions: computed('userOptions', 'rolesOptions', function () {
    let mentionOptions = [];
    this.userOptions.forEach(user => {
      let { id, uuid, name } = user.getProperties('id', 'uuid', 'name');
      mentionOptions.push({ id, uuid, name });
    });

    this.rolesOptions.forEach(role => {
      let { id, name } = role.getProperties('id', 'name');
      mentionOptions.push({ id, 'uuid': `role-${id}`, name });
    });

    return mentionOptions;
  }),

  didReceiveAttrs() {
    this._super(...arguments);
    this._loadUserOptions.perform();
    this._loadRoleOptions.perform();
  },

  /**
   * Get all available user options for @ mention drop down
   */
  _loadUserOptions: task(function *() {
    let organization = yield this.model.organization;

    let params = {
      organization_id: organization.id,
      fields: 'id,uuid,first-name,last-name'
    };
    let users = yield this
      .store
      .query('user', params)
      .catch(() => []);

    this.set('userOptions', users);
  }).drop(),

  /**
   * Get all available role options for @ mention drop down. Roles
   * are converted in the API to all relevant emails at time of mailing
   */
  _loadRoleOptions: task(function *() {
    let organization = yield this.model.organization;

    if (this.appSettings.portal) {
      return;
    }

    let role_params = {
      organization_id: organization.id,
      fields: 'id,name',
    };

    let roles = yield this
      .store
      .query('role', role_params)
      .catch(() => []);

    this.set('rolesOptions', roles);
  }).drop(),

  stringToDom(value) {
    return new DOMParser().parseFromString(value, "text/html");
  },

  actions: {
    updateValue(value) {
      let dom = this.stringToDom(value);

      // Coerces the html dom list into an array
      let mentions = [...dom.getElementsByTagName('user-mention')];

      let uuids = mentions.map(m => m.getAttribute('uuid'));
      this.model.set(this.recipientsProp, uuids.join(','));
    },

    /**
     * Take the recipients (string of uuids seperated by comma) and turn it into
     * the DOM the user is expecting for each user/role
     */
    convertToHtml(value) {
      let values = this.settings[0].values;

      if (!value || !values || !values.length) {
        return '<br>';
      }

      return value.split(',').map(r => {
        if (!isBlank(r)) {
          let item = values.findBy('uuid', r);
          return `<user-mention uuid='${item.uuid}'>@${item.name}</user-mention>&nbsp;`;
        }
      }).join('');
    }
  }
});
