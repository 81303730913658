/* eslint-disable key-spacing, no-multi-spaces, ember/no-get, ember/require-return-from-computed, keyword-spacing, space-before-blocks, indent */
import { belongsTo, hasMany, attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { alias, equal } from '@ember/object/computed';
import DocumentBase from 'shared/models/document-base';

export default DocumentBase.extend({
  // Associations
  project: belongsTo('project', { async: false }),
  requisition: belongsTo('requisition', { async: false }),
  organization: belongsTo('organization', { inverse: 'documents', async: false }),
  report: belongsTo('report'),
  revisions: hasMany('document-revision'),
  signatures: hasMany('signature', { async: true }),
  notes: hasMany('note', { async: true }),
  tags: hasMany('tag', { async: false, inverse: null }),

  // Attributes
  uuid: attr('string'),
  name: attr('string'),
  permissions: attr(),
  downloads: attr(),
  archived: attr('boolean'),
  archivable: attr('boolean'),
  updatedAt: attr('date'),
  entityType: attr('string'),
  // Alias
  visible: alias('permissions.visible'),
  readable: alias('permissions.readable'),
  editable: alias('permissions.editable'),
  deletable: alias('permissions.deletable'),
  wasDownloaded: equal('status', 'downloaded'),

  // Helper methods
  tag: alias('tags.firstObject'),

  entity: computed('{project,requisition,organization}', function() {
    let project = this.project;
    let requisition = this.requisition;
    let organization = this.organization;

    return requisition || project || organization;
  }),

  route: computed('entity', function() {
    let entity = this.entity;
    if (entity) {
      switch (entity.constructor.modelName) {
      case 'organization': return 'organization.folders.show.documents.show';
      case 'project': return 'projects.show.folders.show.documents.show';
      case 'requisition': return 'requisitions.show.folders.show.documents.show';
      }
    }

    return;
  }),

  entityUuid: alias('entity.uuid'),

  // TODO check on returned value for 'approved', consider isApproved
  /*
  approved: Ember.computed('signatures.@each.signedAt', function() {
    let unsigned = this.get('signatures').filter(function(signature) {
      return Ember.isEmpty(signature.get('signedAt'));
    });
    return Ember.isEmpty(unsigned);
  }),
*/

  approvedRevision: computed('revisions.[]', function() {
    let approved = this.revisions.filterBy('revisionStatus', 'approved');
    return approved[approved.length - 1];
  }),

  effectiveRevision: computed('revisions.[]', function() {
    return this.revisions.findBy('revisionStatus', 'effective');
  })
});
