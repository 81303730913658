import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! template-lint-disable no-implicit-this prettier }}\n<textarea\n  ...attributes\n  disabled={{disabled}}\n  value={{value}}\n  oninput={{perform updateValue value=\"target.value\"}}>\n</textarea>", {"contents":"{{! template-lint-disable no-implicit-this prettier }}\n<textarea\n  ...attributes\n  disabled={{disabled}}\n  value={{value}}\n  oninput={{perform updateValue value=\"target.value\"}}>\n</textarea>","moduleName":"shared/components/ui/textarea.hbs","parseOptions":{"srcName":"shared/components/ui/textarea.hbs"}});
/* eslint-disable ember/no-classic-components, ember/no-classic-classes */
import Component from '@ember/component';
import { task, timeout } from 'ember-concurrency';
import { isBlank } from '@ember/utils';

const DEFAULT_TIMEOUT = 100;

export default Component.extend({
  tagName: '',

  timeout: DEFAULT_TIMEOUT,

  updateValue: task(function *(value) {
    yield timeout(this.timeout);

    if (isBlank(value)) {
      value = null;
    }

    this.set('value', value);

    if (this.onChange) {
      this.onChange(value);
    }
  }).restartable()
});
