import Model, { attr, belongsTo } from '@ember-data/model';

export default class IntegrationActionModel extends Model {
  // Associations
  @belongsTo('integration-route', { async: false }) integrationRoute;
  @belongsTo('hl7-mapping', { async: false }) hl7Mapping;
  @belongsTo('project-requisition-template-association', { async: true }) projectRequisitionTemplateAssociation;
  @belongsTo('project', { async: false }) project;
  @belongsTo('requisitionTemplate', { async: false }) requisitionTemplate;

  // Attributes
  @attr('string') name;
  @attr('string') actionType;
  @attr('string') sourceFolderPath;
  @attr('string') destinationFolderPath;
  @attr({
    defaultValue() {
      return {};
    }
  }) configuration;
  @attr('string') panelCode;
  @attr('string') mappingIdentifier;
}
