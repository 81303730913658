/* eslint-disable ember/use-brace-expansion, ember/no-get, ember/no-actions-hash */
import { computed } from '@ember/object';
import { isPresent } from '@ember/utils';
import BaseComponent from 'shared/components/requisitions/requisition-base/component';
import layout from './template';

export default BaseComponent.extend({
  layout,
  classNames: ['row', 'requisition-medications'],

  templateKey: 'ovationMedicationSearch',
  readOnly: false,

  publish: () => {},

  isVisible: computed('template', 'template.ovationMedicationSearch', 'template.medications.strategy', function() {
    const legacyMedications = this.get('template.ovationMedicationSearch');

    return isPresent(legacyMedications);
  }),

  actions: {
    onAdd(medication) {
      const publish = this.get('publish');
      publish('medicationAdded', medication);
    },

    onRemove(medication) {
      const publish = this.get('publish');
      publish('medicationRemoved', medication);
    },

    onUpdate(medication) {
      const publish = this.get('publish');
      publish('medicationUpdated', medication);
    }
  }

});
