define("ember-countries/utils/countries-properties", ["exports", "@ember/utils", "ember-countries/utils/countries-lists", "ember-countries/utils/helpers"], function (_exports, _utils, _countriesLists, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getCountry = getCountry;
  _exports.isCountryWithState = isCountryWithState;
  _exports.isCountryWithoutZip = isCountryWithoutZip;
  let indexedByIso2;
  let indexedByIso3;
  let indexedByIsoNumeric;
  function getCountry(value) {
    let valueFormat = (0, _helpers.getFormat)(value);
    if (valueFormat === null) {
      return;
    }
    let listIndexed = _getCountriesListIndexed(valueFormat);
    let selectedCountry = listIndexed[value.toUpperCase()];
    if (!(0, _utils.isNone)(selectedCountry)) {
      return selectedCountry;
    }
  }
  function isCountryWithoutZip(value) {
    let country = getCountry(value);
    if ((0, _utils.isNone)(country)) {
      return false;
    }

    // return true if the country has not a required ZIP code
    return _countriesLists.COUNTRIES_WITHOUT_ZIP_LIST.indexOf(country.iso2) !== -1;
  }
  function isCountryWithState(value) {
    let country = getCountry(value);
    if ((0, _utils.isNone)(country)) {
      return false;
    }

    // return true if the country has a required State.
    return _countriesLists.COUNTRIES_WITH_STATES_LIST.indexOf(country.iso2) !== -1;
  }
  function _getCountriesListIndexed(code) {
    let index = {};
    if (code === 'iso2') {
      index = (0, _helpers.buildIndex)(_countriesLists.COUNTRIES_LIST, code, indexedByIso2);
      indexedByIso2 = index;
    } else if (code === 'iso3') {
      index = (0, _helpers.buildIndex)(_countriesLists.COUNTRIES_LIST, code, indexedByIso3);
      indexedByIso3 = index;
    } else if (code === 'isoNumeric') {
      index = (0, _helpers.buildIndex)(_countriesLists.COUNTRIES_LIST, code, indexedByIsoNumeric);
      indexedByIsoNumeric = index;
    }
    return index;
  }
});