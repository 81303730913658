/* eslint-disable max-len, ember/no-classic-classes, ember/no-mixins */
import Model, { attr, hasMany, belongsTo } from '@ember-data/model';
import Externalable from 'shared/mixins/externalable';
import Documents from 'shared/mixins/documents';
import { computed } from '@ember/object';
import { equal } from '@ember/object/computed';

const USA = 'United States';
export default Model.extend(Externalable, Documents, {
  // Associations
  organization: belongsTo('organization'),
  salesReps: hasMany('sales-rep'),
  providerAccountGroups: hasMany('provider-account-group'),
  salesGroups: hasMany('sales-group'),
  physicians: hasMany('physician'),
  contacts: hasMany('sales-account-contact', { inverse: 'salesAccounts' }),
  contactsSalesAccounts: hasMany('contacts-sales-account', { inverse: 'salesAccount' }),
  physicianSalesAccounts: hasMany('physician-sales-account'),
  physicianSalesAccountContacts: hasMany('physician-sales-account-contact'),
  resources: hasMany('resource'),
  salesAccountProjectRequisitionTemplateAssociations: hasMany('sales-account-project-requisition-template-associations'),
  savedContactInfos: hasMany('saved-contact-info'),

  physician: belongsTo('physician', { async: false, inverse: null }),

  // Attributes
  uuid: attr('string'),
  name: attr('string'),
  websiteUrl: attr('string'),
  accountNumber: attr('string'),
  phoneNumber: attr('string'),
  primaryName: attr('string'),
  primaryEmail: attr('string'),
  primaryPhoneNumber: attr('string'),
  streetAddress: attr('string'),
  streetAddressLine2: attr('string'),
  streetAddressCity: attr('string'),
  streetAddressState: attr('string'),
  streetAddressZipCode: attr('string'),
  streetAddressCountry: attr('string'),
  mailingAddress: attr('string'),
  mailingAddressLine2: attr('string'),
  mailingAddressCity: attr('string'),
  mailingAddressState: attr('string'),
  mailingAddressZipCode: attr('string'),
  mailingAddressCountry: attr('string'),
  sameAsStreetAddress: attr('boolean', { defaultValue: false }),
  faxSignedReports: attr('boolean', { defaultValue: false }),
  faxNumber: attr('string'),
  externalIdentifier: attr('string'),
  archived: attr('boolean'),
  archivable: attr('boolean'),
  secondaryServiceProvider: attr('boolean', { defaultValue: false }),
  createdAt: attr('date'),
  disableNotifyWhenCreate: attr('boolean', { defaultValue: false }),
  overridePatientConsentVersion: attr('boolean', { defaultValue: false }),
  overrideMedicalConsentVersion: attr('boolean', { defaultValue: false }),
  patientConsentVersionId: attr('number'),
  patientConsentOverride: attr('number', { defaultValue: 0 }),
  medicalConsentVersionId: attr('number'),
  medicalConsentOverride: attr('number', { defaultValue: 0 }),
  allProvidersAllContacts: attr('boolean', { defaultValue: false }),
  overrideDuplicate: attr('boolean', { defaultValue: false }),
  faxSignedReportsContacts: attr('boolean', { defaultValue: false }),
  emailReportReadyNotificationsContacts: attr('boolean', { defaultValue: false }),
  emptyFaxNumberContacts: attr({ defaultValue: () => {
    return [];
  } }),

  fullAddress: computed('streetAddress', 'streetAddressLine2', 'streetAddressCity', 'streetAddressState', 'streetAddressZipCode', function() {
    let parts = ['streetAddress', 'streetAddressLine2', 'streetAddressCity', 'streetAddressState', 'streetAddressZipCode'];
    let address = parts.map(p => this.get(p)).filter(p => Boolean(p)).join(', ');
    return `${address} ${this.streetAddressZipCode}`;
  }),

  salesRepNames: computed('salesReps.@each.fullName', function() {
    return (this.salesReps && this.salesReps.length > 0) ? this.salesReps.mapBy('fullName').join(', ') : '';
  }),

  providerAccountGroupNames: computed('providerAccountGroups.@each.name', function() {
    return (this.providerAccountGroups && this.providerAccountGroups.length > 0) ? this.providerAccountGroups.mapBy('name').join(', ') : '';
  }),

  providerAccountGroupIds: computed('providerAccountGroups.[]', function() {
    return (this.providerAccountGroups && this.providerAccountGroups.length > 0) ? this.providerAccountGroups.mapBy('id') : [];
  }),

  streetAddressCountryIsUSA: equal('streetAddressCountry', USA),
  mailingAddressCountryIsUSA: equal('mailingAddressCountry', USA)
});
