define("ember-preferences/storage/namespaceable", ["exports", "@ember/object", "ember-preferences/storage/decorator"], function (_exports, _object, _decorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _object.default.extend(_decorator.default, {
    namespace: null,
    setItem(key, value) {
      return this._super(this.fullQualifiedKey(key), value);
    },
    getItem(key) {
      return this._super(this.fullQualifiedKey(key));
    },
    removeItem(key) {
      return this._super(this.fullQualifiedKey(key));
    },
    /**
     * @private
     */
    fullQualifiedKey(key) {
      let namespace = this.get('namespace');
      let fqk;
      if (typeof namespace !== 'undefined' && namespace !== null) {
        fqk = `${this.get('namespace')}:${key}`;
      } else {
        fqk = key;
      }
      return fqk;
    }
  });
});