/* eslint-disable ember/no-classic-classes */
import Model, { hasMany, belongsTo, attr } from '@ember-data/model';
import { alias } from '@ember/object/computed';

export default Model.extend({

  // Associations
  workflow: belongsTo('workflow'),
  sample: belongsTo('sample', { async: false }),
  sampleState: belongsTo('sample-state', { inverse: false }),
  requisition: belongsTo('requisition'),
  patient: belongsTo('patient'),

  workflowSampleResults: hasMany('workflow-sample-result'),

  // Attributes
  identifier: attr('string'),
  control: attr('boolean'),
  workflowStatus: attr('string'),
  workflowWorkflowType: attr('string'),
  sampleId: attr('string'),
  sampleStateId: attr('string'),
  sampleRejectStatus: alias('sample.rejectStatus'),
  sampleRejectReason: alias('sample.rejectReason')
});
