/* eslint-disable ember/no-classic-classes, key-spacing, ember/no-get */
import Model, { belongsTo, attr } from '@ember-data/model';
import { equal } from '@ember/object/computed';
import { get } from '@ember/object';
import { isPresent } from '@ember/utils';

export default Model.extend({
  // Associations
  source:       belongsTo('resource', { async: true }),
  parent:       belongsTo('resource-group', { inverse: 'resources', async: true }),

  // Attributes
  name:         attr('string'),
  url:          attr('string'),
  publicUrl:    attr('string'),
  proxyUrl:     attr('string'),
  readUrl:      attr('string'),
  version:      attr('string'),
  label:        attr('string'),

  // needed for file upload
  entityId:     attr('string'),
  contentType:  attr('string'),
  path:         attr('string'),
  copy:         attr('string'),
  sourceUrl:    attr('string'),

  validationResult: attr(),

  permissions:  attr(),

  deletable: equal('permissions.delete', true),

  delete() {
    if (!this.get('isDeleted')) {
      this.deleteRecord();
    }
  },

  hasErrors() {
    let validationErrors = get(this, 'validationResult.errors');

    if (validationErrors) {
      let global = get(validationErrors, 'globalErrors') || [];
      let rows = get(validationErrors, 'rowErrors') || [];

      return isPresent(global) || Object.keys(rows).length > 0;
    } else {
      return false;
    }
  }

});
