/* eslint-disable ember/no-classic-components, ember/no-mixins, ember/no-classic-classes, space-before-function-paren, no-multi-spaces, ember/no-get, prefer-template, quotes, ember/no-actions-hash */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { alias, empty } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import AutoCompleteSettings from 'shared/mixins/auto-complete-settings';
import layout from './template';
import { htmlSafe } from '@ember/template';
import { isBlank } from '@ember/utils';

export default Component.extend(AutoCompleteSettings, {
  layout,
  tagName: '',

  flashMessages: service(),

  notPostable: empty('body'),

  visibilityValue: computed('forLabs', 'forPhysicians', 'forSales', 'visibilityOptions', function () {
    const forLabs       = this.forLabs;
    const forSales      = this.forSales;
    const forPhysicians = this.forPhysicians;

    if (forLabs && forSales && forPhysicians) {
      return this.get('visibilityOptions')[3];
    } else if (forLabs && forSales) {
      return this.get('visibilityOptions')[2];
    } else if (forLabs && forPhysicians) {
      return this.get('visibilityOptions')[1];
    }

    return this.get('visibilityOptions')[0]; // only labs
  }),

  visibilityOptions: [
    {
      label: 'Lab Only',
      forLabs: 1,
      forPhysicians: 0,
      forSales: 0
    },
    {
      label: 'Lab and Providers',
      forLabs: 1,
      forPhysicians: 1,
      forSales: 0
    },
    {
      label: 'Lab and Sales',
      forLabs: 1,
      forPhysicians: 0,
      forSales: 1
    },

    {
      label: 'All Users',
      forLabs: 1,
      forPhysicians: 1,
      forSales: 1
    }
  ],

  initNote() {
    let body = '';
    let forLabs = true;
    let forSales = false;
    let forPhysicians = false;

    if (this.note) {
      body          = this.note.body;
      forLabs       = this.note.forLabs;
      forSales      = this.note.forSales;
      forPhysicians = this.note.forPhysicians;
    } else if (this.notesPermissions) {
      forLabs = this.notesPermissions.isLabUser || true;
      forPhysicians = this.notesPermissions.isPhysicianContact || false;
      forSales = forPhysicians ? false : (this.notesPermissions.isSalesRep || false);
    }

    this.setProperties({
      body,
      forLabs,
      forSales,
      forPhysicians
    });
  },

  isSaving: alias('submitNote.isRunning'),

  submitNote: task(function *() {
    const attributes = this.getProperties('body', 'forLabs', 'forSales', 'forPhysicians');

    yield this
      .onSubmit(attributes)
      .then(() => {
        this.initNote();
      })
      .catch((error) => {
        this.flashMessages.error("An error ocurred when saving the comment. Please try again. \nError:" + error);
        console.error('Error ocurred when saving comment', error);
      });
  }).drop(),

  init() {
    this._super(...arguments);
    this.initNote();
  },

  actions: {
    submit() {
      this.submitNote.perform();
    },

    cancel() {
      this.onCancel();
    },

    setNoteValue(body) {
      this.set('body', body);
    },

    updateVisibilityOptions({ forLabs, forSales, forPhysicians }) {
      this.setProperties({
        forLabs,
        forSales,
        forPhysicians
      });
    },

    convertToHtml(value) {
      let text = htmlSafe(value).toString();

      return text
        .split("\n")
        .map((section) => {
          if (isBlank(section)) {
            return `<div><br></div>`;
          }
          return `<div>${section}</div>`;
        });
    },
  }
});
