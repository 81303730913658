/* eslint-disable ember/no-classic-classes, ember/no-get, no-multi-spaces, indent */
import Service, { inject as service } from '@ember/service';
import RSVP from 'rsvp';
import { isPresent, isBlank } from '@ember/utils';
import { run } from '@ember/runloop';
import config from 'ember-get-config';
import fetch from 'fetch';
import { task, timeout } from 'ember-concurrency';

const { coriell_api_endpoint } = config;
const TIMEOUT = config.environment === 'test' ? 0 : 1000;

export default Service.extend({

  _tokens: {},

  store: service(),
  messages: service('flashMessages'),

  search: task(function *(term, organization) {
    yield timeout(TIMEOUT);

    if (isBlank(term)) {
      return [];
    }

    const token = yield this._tokenFor(organization);
    const response = yield fetch(`${coriell_api_endpoint}/drug/search?q=${term}&namelength=0`, {
      headers: {
        'Authorization': `Bearer ${token.get('bearer')}`,
        'Accept': 'application/json, */*'
      }
    });

    if (response.ok) {
      const data = yield response.json();
      return (data || {}).values || [];
    } else {
      this.messages.error('Failed to perform search request');
      return [];
    }
  }).restartable(),

  _tokenFor(organization) {
    let tokens = this.get('_tokens');
    let token  = tokens[organization.get('id')];

    if (isPresent(token)) {
      return new RSVP.Promise((resolve) => {
        resolve(token);
      });
    }

    return new RSVP.Promise((resolve, reject) => {
      run.debounce(this, this._queryToken, organization, resolve, reject, 1000);
    });
  },

  _queryToken(organization, resolve, reject) {
    let store  = this.get('store');
    let tokens = this.get('_tokens');

    store.queryRecord('service-token', { organization_id: organization.get('id'),
                                         provider: 'coriell' })
      .then(
        (token) => {
          tokens[organization.get('id')] = token;
          resolve(token);
        },
        reject
      );
  },

});
