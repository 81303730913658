/* eslint-disable no-unused-vars, ember/no-classic-classes, max-len, quotes, generator-star-spacing, no-prototype-builtins, indent */
import Service, { inject as service } from '@ember/service';
import { getOwner } from '@ember/application';
import { task, timeout } from 'ember-concurrency';

export default Service.extend({

  store: service(),
  settings: service(),
  router: service(),
  associationModal: service(),
  flashMessages: service(),
  downloadRequestsTracker: service('download-requests-tracker'),

  organization: null,

  setOrganization(organization) {
    this.set('organization', organization);
  },

  updateWorkflowActivityTask: task(function *(queuedJobData) {
    const workflowActivity = this.store.peekRecord('workflow-activity', queuedJobData.entity_id);

    if (workflowActivity) {
      const activity = yield workflowActivity
        .activity
        .then((activity) => activity)
        .catch(() => {});

      if (activity && activity.get('id')) {
        // Ok to reload now
      } else {
        yield timeout(5000);
      }

      // Don't need this as reloading the workflow will include the workflow activity
      // workflowActivity.reload();

      const workflow = this.store.peekRecord('workflow', workflowActivity.get('workflow.id'));

      if (workflow) {
        this.store.findRecord('workflow', workflow.id, {
          include: 'workflow-definition,workflow-activities,workflow-activity,sample-states,container,activity.resource-groups,activity.resources',
          reload: true,
        });
      }
    }
  }).restartable(),

  updateAspReportTask: task(function *(queuedJobData) {
    let fields = [
      'resource.id',
      'resource.name',
      'resource.readUrl',
      'provider_account.id',
      'provider_account.name',
      'status',
      'id'
    ];

    yield this.store.queryRecord('asp-report', {
      id: queuedJobData.entity_id,
      include: 'resource,provider_account',
      fields: fields.join(',')
    });
  }),

  updateCovidReportTask: task(function *(queuedJobData) {
    yield this.store.queryRecord('covid-report', {
      id: queuedJobData.entity_id,
      include: 'user,resources',
      fields: `id,from-date,to-date,status,created-at,resource.id,resource.name,resource.publicUrl,user.id,user.first-name,user.last-name`
    });
  }),

  updateInfectiousDiseaseReportTask: task(function *(queuedJobData) {
    yield this.store.queryRecord('infectious-disease-report', {
      id: queuedJobData.entity_id,
      include: 'user,resources',
      fields: `resource.id,resource.name,resource.publicUrl,user.id,user.first-name,user.last-name`
    });
  }),

  updateAdminReportTask: task(function *(queuedJobData) {
    yield this.store.queryRecord('admin-report', {
      id: queuedJobData.entity_id,
      include: 'user,resources',
      fields: 'id,status,from,to,records,error,created-at,user.id,user.name,resource.id,resource.name,resource.publicUrl'
    });
  }),

  updateOrganizationExportTask: task(function *(queuedJobData) {
    yield this.store.queryRecord('organization-export', {
      id: queuedJobData.entity_id,
      include: 'user',
      fields: 'id,status,from,to,created-at,export-job-id,error,results-expired,can-download-results,records,user.id,user.first-name,user.last-name'
    });
  }),

  updateRequisitionImportTask: task(function* (queuedJobData) {
    const requisitionImport = yield this.store.queryRecord('requisition-import', {
      id: queuedJobData.entity_id,
      includes: 'user,resource,resources'
    });

    if (yield requisitionImport.status === 'Complete') {
      this.flashMessages.success('Successfully imported requisitions');
    }
    if (yield requisitionImport.status === 'Failed') {
      this.flashMessages.error(`Requisitions failed to import: ${requisitionImport.error || 'Unknown error'}`);
    }
  }),

  updateQueuedJob(queuedJobData) {
    let queuedJob = this.store.peekRecord('queued-job', queuedJobData.id);
    if (queuedJob) {
      queuedJob.reload();
    }

    /* eslint-disable no-case-declarations */
    if (queuedJobData.hasOwnProperty('entity_type')) {
      switch (queuedJobData.entity_type) {
        case 'WorkflowActivity': {
          this.updateWorkflowActivityTask.perform(queuedJobData);
          break;
        }
        case 'Requisition': {
          let requisition = this.store.peekRecord('requisition', queuedJobData.entity_id);
          if (requisition) {
            requisition.reload();
          }
          break;
        }
        case 'SalesAccountImport': {
          this.associationModal.reload('providerAccountsTableApi');
          break;
        }
        case 'AspReport': {
          let aspReport = this.store.peekRecord('asp-report', queuedJobData.entity_id);
          if (aspReport) {
            this.updateAspReportTask.perform(queuedJobData);
          }
          break;
        }
        case 'CovidReport': {
          let covidReport = this.store.peekRecord('covid-report', queuedJobData.entity_id);
          if (covidReport) {
            this.updateCovidReportTask.perform(queuedJobData);
          }
          break;
        }
        case 'InfectiousDiseaseReport': {
          let report = this.store.peekRecord('infectious-disease-report', queuedJobData.entity_id);
          if (report) {
            this.updateInfectiousDiseaseReportTask.perform(queuedJobData);
          }
          break;
        }
        case 'AdminReport': {
          let adminReport = this.store.peekRecord('admin-report', queuedJobData.entity_id);
          if (adminReport) {
            this.updateAdminReportTask.perform(queuedJobData);
          }
          break;
        }
        case 'DownloadRequest': {
          this.downloadRequestsTracker.loadDownloadRequests.perform();
          break;
        }
        case 'RequisitionImport': {
          this.updateRequisitionImportTask.perform(queuedJobData);
          break;
        }
        case 'OrganizationExport': {
          let organizationExport = this.store.peekRecord('organization-export', queuedJobData.entity_id);
          if (organizationExport) {
            this.updateOrganizationExportTask.perform(queuedJobData);
          }
          break;
        }
      }
    }
    /* eslint-enable no-case-declarations */
  }

});
