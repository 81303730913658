/* eslint-disable ember/no-classic-components, ember/no-classic-classes */
import Component from '@ember/component';
import { guidFor } from '@ember/object/internals';
import { computed } from '@ember/object';
import layout from './template';

/**
 * Checkbox with custom styles
 *
 * @param @checked {boolean}
 * @param @onClick {action}
 */
export default Component.extend({
  layout,
  tagName: '',

  onClick() {},

  groupId: computed(function() {
    return `${guidFor(this)}-control`;
  }),
});
