/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components, key-spacing, ember/no-get */
import { alias } from '@ember/object/computed';
import { capitalize } from '@ember/string';
import Component from '@ember/component';
import { get, computed } from '@ember/object';
import { isBlank } from '@ember/utils';
import layout from './template';

const toHumanStatus = (status) => {
  if (isBlank(status)) {
    return '';
  }

  return capitalize(status).replace(/-/g, ' ');
};

export default Component.extend({
  layout,
  classNames: ['requisition-status'],

  model: null,

  accessionStatus:  alias('model.accessionStatus'),
  processingStatus: alias('model.processingStatus'),
  reportingStatus:  alias('model.reportingStatus'),
  billingStatus:    alias('model.billingStatus'),

  humanAccessionStatus: computed('model.accessionStatus', function() {
    return toHumanStatus(get(this, 'model.accessionStatus'));
  }),

  humanProcessingStatus: computed('processingStatus', function() {
    return toHumanStatus(get(this, 'processingStatus'));
  }),

  humanReportingStatus: computed('reportingStatus', function() {
    return toHumanStatus(get(this, 'reportingStatus'));
  }),

  humanBillingStatus: computed('billingStatus', function() {
    return toHumanStatus(get(this, 'billingStatus'));
  }),

  cssAccessionStatus: computed('accessionStatus', function() {
    return `accession-${get(this, 'accessionStatus')}`;
  }),

  cssProcessingStatus: computed('processingStatus', function() {
    return `processing-${get(this, 'processingStatus')}`;
  }),

  cssReportingStatus: computed('reportingStatus', function() {
    return `reporting-${get(this, 'reportingStatus')}`;
  }),

  cssBillingStatus: computed('billingStatus', function() {
    return `billing-${get(this, 'billingStatus')}`;
  })

});
