/* eslint-disable key-spacing */
import { belongsTo, hasMany, attr } from '@ember-data/model';
import Tag from 'shared/models/tag';

export default Tag.extend({
  // Associations
  organization:   belongsTo('organization', { async: true, inverse: null }),
  project:        belongsTo('project', { async: true, inverse: null }),
  requisition:    belongsTo('requisition', { async: true, inverse: null }),

  documents: hasMany('documents', { async: true }),

  // Attributes
  name:           attr('string'),

  // Behavior
  behaviorGroup:  'folders',
  behaviors:      attr()

});
