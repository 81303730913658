/* eslint-disable ember/no-classic-components, ember/no-classic-classes, max-len */
import { equal, alias, and } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { computed, set, action } from '@ember/object';
import { isNone, isBlank } from '@ember/utils';
import layout from './template';
import { capitalize } from '@ember/string';

const OTHER = 'Other';

export default Component.extend({
  layout,
  store: service(),
  currentOrganization: service(),

  tagName: '',

  model: null,
  providers: null,
  requiredFields: null,
  hiddenFields: null,
  requiredPreAuthorizationFields: null,
  hiddenPreAuthorizationFields: null,
  readOnly: false,
  dateFormat: 'YYYY-MM-DD',

  isNew: alias('model.isNew'),
  organization: alias('currentOrganization.organization'),
  clearButtonVisible: and('isNew', 'hasFilledValues'),
  otherRelationshipToInsured: equal('model.relationshipToInsured', OTHER),

  relationshipToInsured: Object.freeze([
    'Self',
    'Spouse',
    'Life partner',
    'Child',
    'Ward',
    'Handicapped Dependent',
    'Sponsored Dependent',
    'Dependent of a minor Dependent',
    OTHER
  ]),
  insuredSexOptions: Object.freeze([
    'Male',
    'Female',
    'Undisclosed'
  ]),

  triggerChange() {},

  hasFilledValues: computed('model.{insuranceProvider.id,idNumber,subscriberNumber,groupNumber,relationshipToInsured,nameOfPersonInsured,dobOfInsured,insuredEmail,insuredStreetAddress,insuredCity,insuredState,insuredZipCode,insuredSex,reimbursementEligibilityVerified}',
    function() {

      let model = this.model;

      let has_values_filled = !isBlank(model.get('insuranceProvider.id')) ||
    !isBlank(model.get('idNumber')) ||
    !isBlank(model.get('subscriberNumber')) ||
    !isBlank(model.get('groupNumber')) ||
    !isBlank(model.get('relationshipToInsured')) ||
    !isBlank(model.get('nameOfPersonInsured')) ||
    !isBlank(model.get('dobOfInsured')) ||
    !isBlank(model.get('insuredEmail')) ||
    !isBlank(model.get('insuredStreetAddress')) ||
    !isBlank(model.get('insuredCity')) ||
    !isBlank(model.get('insuredState')) ||
    !isBlank(model.get('insuredZipCode')) ||
    !isBlank(model.get('insuredSex')) ||
    !isBlank(model.get('reimbursementEligibilityVerified'));

      let attributesChanged = Object.keys(model.changedAttributes()).length;
      // 1 is the default type
      return has_values_filled || attributesChanged > 1;
    }),

  preAuthorization: computed('model.preAuthorization', function() {
    let preAuthorization = this.model.preAuthorization;

    if (isNone(preAuthorization) ||
        isNone(preAuthorization.get('insuranceInformation'))) {
      let insuranceInformation = this.model;
      let store = this.store;
      preAuthorization = store.createRecord('pre-authorization', {
        insuranceInformation
      });
      insuranceInformation.set('preAuthorization', preAuthorization);
    }
    return preAuthorization;
  }),

  _setSelfInformation() {
    const patient = this.patient;
    const model = this.model;

    let sex = patient.sex;
    if (sex) {
      sex = capitalize(sex);
    }

    set(model, 'nameOfPersonInsured', patient.firstLastName);
    set(model, 'dobOfInsured', patient.dateOfBirth);
    set(model, 'insuredEmail', patient.email);
    set(model, 'insuredSex', sex);

    this._setSelfAddressInformation();
  },

  _setSelfAddressInformation() {
    const patient = this.patient;
    const model = this.model;

    set(model, 'insuredStreetAddress', patient.streetAddress);
    set(model, 'insuredCity', patient.city);
    set(model, 'insuredState', patient.state);
    set(model, 'insuredZipCode', patient.zipCode);
  },

  updateInsuranceProvider: action(function(provider) {
    this.model.set('insuranceProvider', provider);
    this.triggerChange();
  }),

  setRelationshipToInsured: action(function(relationshipToInsured) {
    this.model.set('relationshipToInsured', relationshipToInsured);
    if (relationshipToInsured === 'Self') {
      this._setSelfInformation();
    }

    this.triggerChange();
  }),

  setInsuredAddressInformation: action(function() {
    this._setSelfAddressInformation();
  }),

  clearForm: action(function() {
    this.model.rollbackAttributes();
    this.triggerChange();
  })
});
