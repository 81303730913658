/* eslint-disable ember/no-new-mixins, object-shorthand, ember/no-get, max-len, ember/no-observers */
import { get, set, observer } from '@ember/object';
import { isPresent, isBlank } from '@ember/utils';
import Mixin from '@ember/object/mixin';

export default Mixin.create({
  _generateIdentifier: function(sampleIndex, requisitionIdentifier) {
    const interpolationConfig = get(this, 'interpolationConfig');

    if (isPresent(interpolationConfig)) {
      return interpolationConfig.replace('{index}', sampleIndex).replace('{requisition.identifier}', requisitionIdentifier);
    } else {
      return '';
    }
  },

  requisitionIdentifierChanged: observer('requisition.identifier', function() {
    this._setSamplesIdentifiers();
  }),

  _setSampleIdentifier(sample, sampleIndex) {
    if (isPresent(sample)) {
      let enforceSingleIdentifier = get(this, 'enforceSingleIdentifier');
      let requisitionIdentifier = get(this, 'requisition.identifier');

      if (isBlank(requisitionIdentifier)) {
        set(sample, 'identifier', '');
      } else if (enforceSingleIdentifier) {
        const sampleIdentifier = this._generateIdentifier(sampleIndex, requisitionIdentifier);
        set(sample, 'identifier', `${sampleIdentifier}`);
      }
    }
  },
});
