/* eslint-disable ember/no-get, ember/no-mixins, ember/no-classic-classes */
import Model, { attr, hasMany, belongsTo } from '@ember-data/model';
import { computed } from '@ember/object';
import { alias, not } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Documents from 'shared/mixins/documents';
import moment from 'moment';

export default Model.extend(Documents, {
  // Associations
  organization: belongsTo('organization', { inverse: 'requisitions' }),
  team: belongsTo('team'),
  memberships: hasMany('membership', { async: true }),
  tags: hasMany('tag', { async: true }),
  owner: belongsTo('user'),
  patient: belongsTo('patient', { async: false }),
  physician: belongsTo('physician'),
  secondaryPhysician: belongsTo('physician'),
  salesAccount: belongsTo('sales-account'),
  secondarySalesAccount: belongsTo('sales-account'),
  billingInformation: belongsTo('billing-information'),
  requisitionTemplate: belongsTo('requisition-template'),
  reimbursementRequest: belongsTo('reimbursement-request'),
  salesAccountProjectRequisitionTemplateAssociation:
    belongsTo('sales-account-project-requisition-template-association', { async: true }),
  resources: hasMany('resource'),
  samples: hasMany('sample', { async: true, inverse: 'requisition' }),
  queuedJobs: hasMany('queued-job'),
  reports: hasMany('report'),
  signatures: hasMany('signature'),
  entityCustomAttributes: hasMany('entity-custom-attribute', { async: false }),
  icdCodes: hasMany('icd-code'),
  heldReasons: hasMany('held-reason'),
  requisitionContactInfos: hasMany('requisition-contact-info', { async: true }),
  additionalPhysicianSalesAccounts: hasMany('physician-sales-account'),
  physicianSalesAccountContacts: hasMany('physician-sales-account-contact', { async: false }),

  // Attributes
  ownerName: attr('string'),
  uuid: attr('string'),
  identifier: attr('string'),
  template: attr('string'),
  dateReceived: attr('date'),
  receivedDateTime: attr('datetime'),
  samplesCount: attr('number'),
  sampleCollectionDate: attr('date'),
  requestedTests: attr({ defaultValue() {
    return {};
  } }),
  reflexedTests: attr({ defaultValue() {
    return {};
  } }),
  diagnosis: attr({ defaultValue() {
    return [];
  } }),
  diagnosisIcdCodes: attr({ defaultValue() {
    return [];
  } }),
  medications: attr({ defaultValue() {
    return {};
  } }),
  medicationsPT: attr({ defaultValue() {
    return [];
  } }),
  contactEmail: attr('string'),
  portal: attr('boolean', { defaultValue: false }),
  sampleType: attr('string'),
  barcodeLink: attr('string'),
  createdAt: attr('number'),
  updatedAt: attr('number'),
  submittedAt: attr('date'),
  lastSubmittedAt: attr('date'),
  formattedCreatedAt: attr('string'),
  formattedUpdatedAt: attr('string'),
  lastUpdatedByName: attr('string'),
  complete: attr('boolean'),
  fullyCompleted: attr('boolean'),
  portalOrderComplete: attr('boolean'),
  status: attr('string'),
  onHold: attr('boolean', { allowNull: true }),
  partial: attr('boolean'),
  projectName: attr('string'),
  projectId: attr('number'),
  requisitionTemplateId: attr('number'),
  customAttributes: attr({ defaultValue() {
    return {};
  } }),
  pregnant: attr('boolean', { allowNull: true }),
  pregnancyDueDate: attr('date'),
  breastFeeding: attr('boolean', { allowNull: true }),
  lifestyleFactors: attr({ defaultValue() {
    return {};
  } }),
  recurrentConditions: attr({ defaultValue() {
    return {};
  } }),
  sampleIdentifiers: attr(),
  templateName: attr(),
  accessionStatus: attr('string'),
  processingStatus: attr('string'),
  reportingStatus: attr('string'),
  billingStatus: attr('string'),
  hasAssociatedReportConfig: attr('boolean'),
  hasMissingReports: attr('boolean'),
  processed: attr('boolean'),
  archivable: attr('boolean'),
  archived: attr('boolean'),
  signatureRequired: attr('boolean'),
  signed: attr('boolean'),
  signedBy: attr('string'),
  signedAt: attr('date'),
  signatureSource: attr('string'),
  physicianPractice: attr('string'),
  expectedTatTime: attr('number'),
  turnaroundTime: attr('number'),
  turnaroundTimeFromReceivedDate: attr('number'),
  testedPanels: attr({ defaultValue() {
    return [];
  } }),
  testType: attr('string'),
  permissions: attr({ defaultValue() {
    return {};
  } }),
  heldReasonsNames: attr(),
  testedPanelsAndGenes: attr(),
  salesAccountName: attr('string'),
  salesRepName: attr('string'),
  salesAccountProjectRequisitionTemplateAssociationId: attr('string'),
  portalOrder: attr('boolean', { defaultValue: false }),
  cancelationReason: attr('string'),
  orderNotes: attr('string'),
  portalSignatureRequired: attr('boolean', { defaultValue: false }),
  portalSigned: attr('boolean'),
  portalSignedBy: attr('string'),
  portalSignedAt: attr('datetime'),
  portalSignatureEncodedImage: attr('string'),
  portalSignatureRole: attr('string'),
  commentCount: attr('number', { defaultValue: 0 }),
  reportDeliveryDisabled: attr('boolean'),
  reportDeliveryEnabled: not('reportDeliveryDisabled'),
  isDraft: attr('boolean', { defaultValue: false }),
  physicianNotes: attr('string'),
  allergies: attr('string'),
  instructionsForPharmacist: attr('string'),
  reportsCount: attr('number'),
  lastReportResult: attr('string', { defaultValue: '' }),
  patientFullName: attr('string'),
  patientDateOfBirth: attr('date'),
  printerOptions: attr(),
  sftpCredentialName: attr('string'),

  // Consents
  hasPatientConsent: attr('number'),
  patientConsentVersionId: attr('number'),
  hasMedicalConsent: attr('number'),
  medicalConsentVersionId: attr('number'),
  entitlementsBitFlag: attr('number'),

  // Sales
  signedReportDates: attr('string'),
  testedPanelNames: attr('string'),

  // Permissions
  isAdmin: attr('boolean'),
  labVisible: attr('boolean'),

  // Response setting
  confirmationOnly: attr('boolean'),

  providerAccountName: attr('string'),
  providerName: attr('string'),

  reportCreatedAt: attr('date'),
  reportLastSignedAt: attr('date'),

  barcode: alias('identifier'),

  adminReportData: attr(),

  portalPermissions: service('portal-permissions'),
  lastReportSignedAt:            attr('date'),

  // Behavior
  behaviorGroup: 'route',
  behaviors: {},

  get selectedPanelOrTests() {
    return this._selectedValuesFromObject(this.requestedTests || {}) ||
           this._selectedValuesFromObject(this.reflexedTests || {});
  },

  get selectedDiagnosis() {
    return this._selectedValuesFromObject(this.diagnosis);
  },

  get testPanelKeys() {
    const requested = Object.keys(this.requestedTests || {})
      .filter(key => this.requestedTests[key] && key.startsWith('p'));
    const reflexed = Object.keys(this.reflexedTests || {})
      .filter(key => this.reflexedTests[key] && key.startsWith('p'));
    return requested.concat(reflexed);
  },

  get testKeys() {
    const requested = Object.keys(this.requestedTests || {})
      .filter(key => this.requestedTests[key] && key.startsWith('t'));
    const reflexed = Object.keys(this.reflexedTests || {})
      .filter(key => this.reflexedTests[key] && key.startsWith('t'));
    return requested.concat(reflexed);
  },

  _selectedValuesFromObject(object) {
    return Object.values(object).filter(Boolean);
  },

  dateCreated: computed('createdAt', function() {
    let createdAt = this.createdAt;
    return moment.unix(createdAt).toDate();
  }),

  dateUpdated: computed('updatedAt', function() {
    let updatedAt = this.updatedAt;
    return moment.unix(updatedAt).toDate();
  }),

  dateSubmitted: computed('submittedAt', function() {
    let submittedAt = this.submittedAt;
    return (submittedAt) ? moment.unix(submittedAt).toDate() : undefined;
  }),

  signedReports: computed('reports', function() {
    let reports = this.reports;
    return reports.filter((r) => r.isSigned);
  }),

  hasNewDownload: computed('signedReports', function() {
    const report = this.signedReports.sortBy('createdAt').lastObject;
    return report.status !== 'downloaded';
  }),

  canView: computed('permissions', function() {
    let permissions = this.permissions;

    return permissions.readable;
  }),

  sampleIdentifier: alias('sampleIdentifiers.firstObject'),

  canPortalSign: computed('portalPermissions', 'salesAccount.id', function() {
    const salesAccountId = this.get('salesAccount.id');
    const permissions = this.portalPermissions;

    return salesAccountId && permissions.canPortalSignWithSalesAccountId(salesAccountId);
  }),

  resetConsent() {
    this.setProperties({
      hasPatientConsent: null,
      hasMedicalConsent: null,
      patientConsentVersionId: null,
      medicalConsentVersionId: null
    });
  }
});
