/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components, max-len, ember/no-get, ember/no-observers, no-multi-spaces, ember/no-actions-hash */
import { alias } from '@ember/object/computed';
import Component from '@ember/component';
import { isNone, isPresent } from '@ember/utils';
import {
  set,
  getProperties,
  get,
  computed,
  observer
} from '@ember/object';
import layout from './template';

export default Component.extend({
  layout,
  classNames: ['responsive-table-wrapper'],
  contentFilter: 'all',
  records: [],
  missingColumns: [],
  resultCount: alias('filteredRecords.length'),
  headerRowIndex: 0,
  page: 1,
  limit: 50,
  sampleIdColumn: alias('resourceConfig.sampleIdColumn'),

  headerNames: computed('headerRowIndex', 'records', 'missingColumns', function() {
    let { headerRowIndex, records, missingColumns } = getProperties(this, ['headerRowIndex', 'records', 'missingColumns']);
    let columnNames = [];

    if (isPresent(headerRowIndex)) {
      columnNames = records[headerRowIndex].values;
    } else {
      columnNames = records[0].values.map((r, index) => `Column ${index + 1}`);
    }

    if (isPresent(missingColumns)) {
      columnNames = columnNames.concat(missingColumns);
    }

    return columnNames;
  }),
  totalPages: computed('limit', 'resultCount', function() {
    return Math.ceil(get(this, 'resultCount') / get(this, 'limit'));
  }),
  showPagination: computed('totalPages', function() {
    return get(this, 'totalPages') > 1;
  }),

  onFilterChange: observer('contentFilter', function() {
    set(this, 'page', 1);
  }),

  filteredRecords: computed('contentErrors', 'contentFilter', 'contentInfos', 'contentWarnings', 'headerRowIndex', 'records', function() {
    let { records, contentFilter, contentErrors, contentWarnings, contentInfos, headerRowIndex } = getProperties(this, ['records', 'contentFilter', 'contentErrors', 'contentWarnings',  'contentInfos', 'headerRowIndex']);
    let firstRowIndex = 0;

    if (isNone(contentErrors)) {
      contentErrors = {};
    }

    if (isPresent(headerRowIndex)) {
      firstRowIndex = headerRowIndex + 1;
    }

    let filtered = records.slice(firstRowIndex).filter((r) => {
      let hasData = isPresent(r.values.join(''));
      let hasDataWithFilter = true;

      if (contentFilter === 'errors') {
        hasDataWithFilter = Object.keys(contentErrors).includes(r.rowIndex.toString());
      } else if (contentFilter === 'warnings') {
        hasDataWithFilter = Object.keys(contentWarnings).includes(r.rowIndex.toString());
      } else if (contentFilter === 'infos') {
        hasDataWithFilter = Object.keys(contentInfos).includes(r.rowIndex.toString());
      }

      return hasData && hasDataWithFilter;
    });

    return filtered;
  }),

  paginatedRecords: computed('filteredRecords', 'limit', 'page', function() {
    let { page, limit, filteredRecords } = getProperties(this, ['page', 'limit', 'filteredRecords']);

    --page;

    return filteredRecords.slice(page * limit, (page + 1) * limit);
  }),

  actions: {
    setPage(page) {
      let totalPages = get(this, 'totalPages');
      let currPage   = get(this, 'page');

      if (page < 1 || page > totalPages || page === currPage) {
        return;
      }

      set(this, 'page', page);
    }
  }
});
