/* eslint-disable ember/no-classic-components, no-unused-vars, no-multiple-empty-lines, ember/no-classic-classes, quotes, indent, dot-notation, ember/no-get, ember/no-actions-hash, semi */
import Component from '@ember/component';
import layout from './template';

import { task, timeout } from 'ember-concurrency';
import fetch from 'fetch';
import moment from 'moment';
import { computed } from '@ember/object';
import { not } from '@ember/object/computed';
import { htmlSafe } from '@ember/template';
import { dasherize } from '@ember/string';
import { equal, alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { warn } from '@ember/debug';
import ENV from 'ember-get-config';


const BASE_CLASS_NAME = 'system-event';

export default Component.extend({
  layout,

  tagName: '',

  displayIncident: null,
  incidentDismissed: null,
  setHasIncident: () => {},

  // incident status
  isInvestigating: equal('displayIncident.status', 'investigating'),

  /**
   * @return true if an incident is severe
   * let an incident be severe if at least one affect component has "Major outage"
   */
  isSevere: computed('displayIncident.affectedComponents.@each.new_status', function() {
    if (!this.displayIncident) {
      return false;
    }

    let affectedComponents = this.displayIncident.affectedComponents;
    if (!affectedComponents) {
      return false;
    }

    let severe = false;
    affectedComponents.forEach(component => {
      if (dasherize(component.new_status) === "major-outage") {
        severe = true;
      }
    });

    return severe;
  }),

  isDismissible: not('isSevere'),

 cssClass: computed('isInvestigating', function() {
   let cssSubClass = this.isInvestigating ? "in-progress" : "pre-post";
   return `${BASE_CLASS_NAME} ${cssSubClass}`;
 }),

 async fetchEvents() {
   return fetch(ENV['statuspage'].incident.endpoint)
     .catch(() => {
       warn("Error getting incidents from status page", false, {
         id: 'ember-debug.status-page.incidents.getting-incidents'
       });
     });
 },

 /**
  * @param {json} incident payload containing one incident given by statuspage
  * @return a json with releant info
  */
 serialize(incident) {
   return {
     id: incident.incident_updates[0].id,
     startedAt: incident.started_at,
     resolvedAt: incident.resolved_at,
     status: dasherize(incident.status),
     notes: incident.incident_updates[0].body,
     affectedComponents: incident.incident_updates[0].affected_components,
     type: "incident"
   };
 },

 /**
  * @return time with the qty added/subtracted to time
  */
 getTime(time, qty) {
   return moment(time, "YYYY-MM-DDTHH:mm:ss.SSSZ").add(qty, 'minutes');
 },

 /**
  * @param {json} incidents an array of JSON incidents
  * @return the most recent incident.
  * if there are no current incidents or no incidents resolved within buffer time, return null
  */
 getDisplayEvent(incidents) {
   if (incidents.length < 1) {
     return null;
   }

   let now = moment.utc();

   for (let i = 0; i < incidents.length; i++) {
     // return (most recent) unresolved incident
     if (!incidents[i].resolvedAt) {
       return incidents[i];
     }
   }
   // if no open incidents; return most recently resolved one that is within time buffer
   for (let i = 0; i < incidents.length; i++) {
     let incident = incidents[i];
     let end = this.getTime(incident.resolvedAt, ENV['statuspage'].incident.event_timeout_minutes);
     if (moment(now).isSameOrBefore(end)) { // if resolved within buffer time
       return incident;
     }
   }

   return null;
 },

 setDisplayEvent: task(function *() {
   let response = yield this.fetchEvents();
   if (response) {
     let json = yield response.json();
     let events = json.incidents.map(this.serialize);

     let displayIncident = this.getDisplayEvent(events);
     this.set('displayIncident', displayIncident);

     this.get('setHasIncident')(displayIncident, this.incidentDismissed);

     return displayIncident;
   } else {
     warn("Error parsing JSON from status page incidents API", false, {
       id: 'ember-debug.status-page.incidents.parsing-JSON'
     });
   }
 }),

 actions: {
   setIncidentDismissed(isDismissed) {
     this.set('incidentDismissed', isDismissed)
   }

 }


});
