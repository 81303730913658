/* eslint-disable ember/no-mixins, ember/no-classic-classes, key-spacing */
import Model, { attr } from '@ember-data/model';
import Revisions from 'shared/mixins/revisions';
import Tags from 'shared/mixins/tags';

export default Model.extend(Revisions, Tags, {
  // Attributes
  rev:                       attr('number'),
  author:                    attr('string'),
  resourceId:                attr('number'),
  resourceVersion:           attr('string'),
  resourceUrl:               attr('string'),
  resourceName:              attr('string'),
  resourceRemoteUrl:         attr('string'),
  effective:                 attr('date'),
  formattedEffective:        attr('string'),
  isEffective:               attr('boolean'),
  documentReferenceId:       attr('number'),
  reference:                 attr('boolean'),
  status:                    attr('string'),
  tagType:                   attr('string'),
  archivedAt:                attr('date')

});
