/* eslint-disable quotes, ember/no-string-prototype-extensions */
import { helper as buildHelper } from '@ember/component/helper';
import { isBlank } from '@ember/utils';
import { sanitize } from './sanitize';

export function simpleFormat(params/*, hash*/) {
  const [ text ] = params;

  if (isBlank(text)) {
    return "";
  }

  return text
    .split("\n")
    .map((line) => {
      return sanitize(line);
    })
    .join("<br>")
    .htmlSafe();
}

export default buildHelper(simpleFormat);
