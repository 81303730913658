/* eslint-disable ember/no-classic-components, quotes, ember/no-classic-classes, ember/require-tagless-components, no-multi-spaces, ember/no-get, ember/no-actions-hash */
import Component from '@ember/component';
import layout from './template';
import { get } from '@ember/object';
import { alias } from '@ember/object/computed';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import ENV from "winnebago/config/environment";

export default Component.extend({
  layout,

  classNames: ['requisition-cancel-order'],
  settings: service(),
  ajax: service(),
  currentSession: service(),
  currentOrganization: service(),
  flashMessages: service(),
  name: 'Order',
  requisition: null,

  isSaving: alias('uncancelRequisition.isRunning'),
  showConfirmation: false,

  uncancelRequisition: task(function *() {
    if (this.requisition) {
      let ajax    = this.ajax;
      let session = this.currentSession;
      let currentOrganization = this.currentOrganization;
      let token   = get(session, 'token');
      let messages = this.get('flashMessages');

      this.requisition.setProperties({
        status: 'held'
      });

      yield ajax.request(`${ENV.api_endpoint}/${ENV.api_namespace}/requisitions/${this.requisition.id}/uncancel`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'X-Organization-Id': currentOrganization.id,
        },
      }).then(() => {
        messages.success('Requisition successfully uncanceled');
      }).catch(() => {
        messages.error('Failed to uncancel requisition');
        this.requisition.rollbackAttributes();
      });
    }
  }),

  actions: {
    show() {
      this.set('showConfirmation', true);
    },

    hide() {
      this.set('showConfirmation', false);
    },

    submit() {
      this.set('showConfirmation', false);
      this.uncancelRequisition.perform();
    }
  }

});
