/* eslint-disable ember/no-mixins, quotes, ember/no-get, indent, ember/use-brace-expansion, max-len, key-spacing, ember/no-actions-hash, object-shorthand */
import { inject as service } from '@ember/service';
import { alias, equal, or } from '@ember/object/computed';
import { computed } from '@ember/object';
import { isBlank, isPresent, isNone } from '@ember/utils';
import BaseComponent from 'shared/components/requisitions/requisition-base/component';
import Tabs from 'shared/mixins/tabs-helper';
import layout from "./template";

const PATIENT_PAY = 'Patient Pay';
const FACILITY_PAY = 'Facility Pay';
const BILL_INSURANCE = 'Bill Insurance';
const WORKERS_COMPENSATION = 'Workers Compensation';
const MEDICARE = 'Medicare';
const TRICARE = 'Tricare';
const MEDICAID = 'Medicaid';
const OTHER = 'Other';

export default BaseComponent.extend(Tabs, {
  layout,
  classNames: ['billing-information', 'row'],

  store: service(),

  patient: null,
  providers: null,
  readOnly: false,
  selectedTab: "primaryInsurance",

  templateKey: "billingInformation",

  preAuthorizationsRequired: alias('template.billingInformation.preAuthorizationsRequired'),

  isVisible: computed('template', 'template.billingInformation', function() {
    return isPresent(this.get('template.billingInformation'));
  }),

  header: alias('template.billingInformation.header'),

  billToOptions: computed(function() {
    return [
      { value: BILL_INSURANCE, text: "Insurance" },
      { value: MEDICARE, text: MEDICARE },
      { value: TRICARE, text: TRICARE },
      { value: MEDICAID, text: MEDICAID },
      { value: WORKERS_COMPENSATION, text: WORKERS_COMPENSATION },
      { value: PATIENT_PAY, text: "Patient Bill" },
      { value: FACILITY_PAY, text: "Institutional Bill" },
      { value: OTHER, text: OTHER }
    ];
  }),

  memberIdRequirements: [
    { key: 'model.memberId', name: 'Member ID' }
  ],

  billInsuranceRequirements: computed('preAuthorizationsRequired',
                                      'secondaryInsuranceFilled',
                                      'secondaryInsurance.changedAttributes',
                                      function() {
    let requirements = [
      { key: 'primaryInsurance.insuranceProvider.id', name: 'Primary insurance' },
      { key: 'primaryInsurance.idNumber', name: 'Primary insurance ID #' },
      { key: 'primaryInsurance.groupNumber', name: 'Primary insurance Group #' },
      { key: 'primaryInsurance.nameOfPersonInsured', name: 'Primary insurance Name of person insured' },
      { key: 'primaryInsurance.relationshipToInsured', name: 'Primary insurance Relationship to insured' }
    ];
    let preAuthorizationsRequired = this.get('preAuthorizationsRequired');
    if (preAuthorizationsRequired) {
      requirements.pushObject({
        key: 'primaryInsurance.preAuthorization.authorizationNumber',
        name: 'Primary Insurance Authorization Number'
      });
    }
    if (this.get('secondaryInsuranceFilled')) {
      requirements = requirements.concat([
        { key: 'secondaryInsurance.insuranceProvider.id', name: 'Secondary insurance' },
        { key: 'secondaryInsurance.idNumber', name: 'Secondary insurance ID #' },
        { key: 'secondaryInsurance.groupNumber', name: 'Secondary insurance Group #' },
        { key: 'secondaryInsurance.nameOfPersonInsured', name: 'Secondary insurance Name of person insured' },
        { key: 'secondaryInsurance.relationshipToInsured', name: 'Secondary insurance Relationship to insured' }
      ]);
    }
    return requirements;
  }),

  secondaryInsuranceFilled: computed('secondaryInsurance.insuranceProvider.id',
                                     'secondaryInsurance.idNumber',
                                     'secondaryInsurance.groupNumber',
                                     'secondaryInsurance.nameOfPersonInsured',
                                     function() {
    let secondaryInsurance = this.get('secondaryInsurance');
    return !isBlank(secondaryInsurance.get('insuranceProvider.id')) ||
           !isBlank(secondaryInsurance.get('idNumber')) ||
           !isBlank(secondaryInsurance.get('groupNumber')) ||
           !isBlank(secondaryInsurance.get('nameOfPersonInsured'));
  }),
  cashPayRequirements: [
    { key: 'model.name', name: 'Billing Name' },
    { key: 'model.email', name: 'Billing Email' },
    { key: 'model.state', name: 'Billing State' },
    { key: 'model.city', name: 'Billing City' },
    { key: 'model.street', name: 'Billing Street address' }
  ],

  workersCompensationRequirements: [
    { key: 'model.dateOfInjury', name: 'Date of Injury' }
  ],

  requirements: computed('billInsuranceRequirements.[]', 'cashPayRequirements', 'memberIdRequirements', 'model.billTo', 'template', 'workersCompensationRequirements', function() {
    let billTo = this.get('model.billTo');

    if (billTo) {
      switch (billTo) {
        case MEDICAID:
        case MEDICARE:
        case TRICARE:
          return this.get('memberIdRequirements');
        case BILL_INSURANCE:
          return this.get('billInsuranceRequirements');
        case WORKERS_COMPENSATION:
          return this.get('workersCompensationRequirements');
        case PATIENT_PAY:
        case FACILITY_PAY:
          return this.get('cashPayRequirements');
        default:
          return [];
      }
    } else {
      return this._super();
    }
  }),
  missingRequirements: computed('requirements', '_dirtyAttributes', function() {
    return this._missingFrom('requirements', this);
  }),

  memberIdRequired:   computed('model.billTo', function() {
    let billTo = this.get('model.billTo');
    return billTo === MEDICARE || billTo === MEDICAID || billTo === TRICARE;
  }),
  patientPay:         equal('model.billTo', PATIENT_PAY),
  facilityPay:        equal('model.billTo', FACILITY_PAY),
  cashPay:            or('patientPay', 'facilityPay'),
  billInsurance:      equal('model.billTo', BILL_INSURANCE),
  workerCompensation: equal('model.billTo', WORKERS_COMPENSATION),

  primaryInsurance: computed('model.insuranceInformations.[]', function() {
    return this._insuranceInformationByType('Primary');
  }),

  secondaryInsurance: computed('model.insuranceInformations.[]', function() {
    return this._insuranceInformationByType('Secondary');
  }),

  _insuranceInformationByType(insuranceType) {
    let billingInformation = this.get('model');

    if (isNone(billingInformation.content)) {
      return;
    }

    let insuranceInformations = billingInformation.get('insuranceInformations') || [];
    let insuranceInformation = insuranceInformations.findBy('insuranceType', insuranceType);
    let store = this.get('store');

    if (isNone(insuranceInformation)) {
      insuranceInformation = store.createRecord('insurance-information', { billingInformation, insuranceType });
      insuranceInformations.pushObject(insuranceInformation);
    }

    return insuranceInformation;
  },

  actions: {
    fillPatientData: function() {
      let patient = this.get('patient');
      if (patient) {
        let model = this.get('model');
        let name = [patient.get('firstName'), patient.get('lastName')].join(' ');
        model.set('name', name);
        model.set('email', patient.get('email'));
        model.set('phoneNumber', patient.get('phoneNumber'));
        model.set('state', patient.get('state'));
        model.set('city', patient.get('city'));
        model.set('street', patient.get('streetAddress'));
        model.set('zip', patient.get('zipCode'));
        this._updateDirtyAttributes();
      }
    },
    setBillTo: function(billTo) {
      let model = this.get('model');
      model.set('billTo', billTo);
      this._updateDirtyAttributes();
    }
  }

});
