import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#let (element @tagName) as |Tag|}}\n  <Tag class={{this.breadCrumbClass}} ...attributes data-test-id={{@data-test-id}}>\n    {{#each this.routeHierarchy as |route|}}\n      {{#if has-block}}\n        {{yield this route}}\n      {{else}}\n        <BreadCrumb @route={{route}} @breadCrumbs={{this}} />\n      {{/if}}\n    {{/each}}\n  </Tag>\n{{/let}}", {"contents":"{{#let (element @tagName) as |Tag|}}\n  <Tag class={{this.breadCrumbClass}} ...attributes data-test-id={{@data-test-id}}>\n    {{#each this.routeHierarchy as |route|}}\n      {{#if has-block}}\n        {{yield this route}}\n      {{else}}\n        <BreadCrumb @route={{route}} @breadCrumbs={{this}} />\n      {{/if}}\n    {{/each}}\n  </Tag>\n{{/let}}","moduleName":"shared/components/bread-crumbs.hbs","parseOptions":{"srcName":"shared/components/bread-crumbs.hbs"}});
import Component from '@glimmer/component';
import { copy } from 'ember-copy';
import { assert } from '@ember/debug';
import { deprecate } from '@ember/application/deprecations';
import { typeOf, isPresent } from '@ember/utils';
import { getOwner } from '@ember/application';
import { A as emberArray } from '@ember/array';
import { classify } from '@ember/string';

export default class BreadCrumbs extends Component {
  linkable = true;
  reverse = false;

  get applicationRoute() {
    const owner = getOwner(this);
    return owner.lookup('route:application');
  }

  get currentUrl() {
    return this.applicationRoute.router.url;
  }

  get currentRouteName() {
    return this.applicationRoute.controller.currentRouteName;
  }

  get routeHierarchy() {
    const currentRouteName = this.currentRouteName || false;

    assert('[ember-crumbly] Could not find a current route', currentRouteName);

    const routeNames = currentRouteName.split('.');
    const filteredRouteNames = this._filterIndexAndLoadingRoutes(routeNames);
    const crumbs = this._lookupBreadCrumb(routeNames, filteredRouteNames);

    return this.reverse ? crumbs.reverse() : crumbs;
  }

  get breadCrumbClass() {
    let className = 'breadcrumb';
    const outputStyle = this.outputStyle || '';

    if (isPresent(outputStyle)) {
      deprecate(
        'outputStyle option will be deprecated in the next major release',
        false,
        { id: 'ember-crumbly.outputStyle', until: '2.0.0' }
      );
    }
    const lowerCaseOutputStyle = outputStyle.toLowerCase();

    if (lowerCaseOutputStyle === 'foundation') {
      className = 'breadcrumbs';
    }

    return className;
  }

  _guessRoutePath(routeNames, name, index) {
    const routes = routeNames.slice(0, index + 1);

    if (routes.length === 1) {
      let path = `${name}.index`;

      return (this._lookupRoute(path)) ? path : name;
    }

    return routes.join('.');
  }

  _filterIndexAndLoadingRoutes(routeNames) {
    return routeNames.filter((name) => !(name === 'index' || name === 'loading'));
  }

  _lookupRoute(routeName) {
    let owner = getOwner(this);
    let route = owner.lookup(`route:${routeName}`);

    assert(`[ember-crumbly] \`route:${routeName}\` was not found`, route);

    return route;
  }

  _lookupLocalRoute(routeName) {
    return getOwner(this).lookup(`route:${routeName}`);
  }

  _lookupBreadCrumb(routeNames, filteredRouteNames) {
    const defaultLinkable = this.linkable;
    const pathLength = filteredRouteNames.length;
    const breadCrumbs = emberArray();

    filteredRouteNames.map((name, index) => {
      let path = this._guessRoutePath(routeNames, name, index);
      const route = this._lookupRoute(path);
      const isHead = index === 0;
      const isTail = index === pathLength - 1;

      const crumbLinkable = (index === pathLength - 1) ? false : defaultLinkable;

      assert(`[ember-crumbly] \`route:${path}\` was not found`, route);

      const multipleBreadCrumbs = route.breadCrumbs;

      if (multipleBreadCrumbs) {
        multipleBreadCrumbs.forEach((breadCrumb) => {
          breadCrumbs.pushObject(breadCrumb);
        });
      } else {
        let breadCrumb = copy((route.breadCrumb === undefined ? {
          title: classify(name)
        } : route.breadCrumb));

        if (typeOf(breadCrumb) === 'null') {
          return;
        } else {
          if (isPresent(breadCrumb.path)) {
            path = breadCrumb.path;
          }

          breadCrumb.query = route.queryParams;
          breadCrumb.path = path;
          breadCrumb.isHead = isHead;
          breadCrumb.isTail = isTail;
          breadCrumb.linkable = breadCrumb?.linkable ? breadCrumb.linkable : crumbLinkable;
        }

        breadCrumbs.pushObject(breadCrumb);
      }
    });

    return emberArray(breadCrumbs.filter((breadCrumb) => typeOf(breadCrumb) !== 'undefined'));
  }
}
