/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components, key-spacing, brace-style, ember/no-get, object-shorthand, ember/no-actions-hash */
import { alias, notEmpty, equal, not } from '@ember/object/computed';
import Component from '@ember/component';
import { isNone } from '@ember/utils';
import { get, getProperties, computed } from '@ember/object';
import { pluralize } from 'ember-inflector';
import layout from './template';
import { inject as service } from '@ember/service';

const reservedTags = ['Complete Reports', 'Incomplete Reports'];

export default Component.extend({
  layout,

  flashMessages: service(),

  classNames: ['wb-taggings'],
  taggable: null,
  tagType: null,
  tags:     [],
  store:    null,
  allowAddTag: true,

  tag: null,

  taggingText: 'folder',
  taggingTextPlural: computed('taggingText', function() { return pluralize(this.get('taggingText')); }),
  taggings: alias('taggable.taggings'),
  hasTaggings: notEmpty('taggings'),
  canNotRemoveTag: equal('taggings.length', 1),
  canRemoveTag: not('canNotRemoveTag'),
  hasPermission: alias('taggable.permissions.editable'),
  isNew: alias('taggable.isNew'),

  sortedTags: computed('tagType', 'taggings.[]', 'tags.[]', function() {
    let tags = this.get('tags');

    if (isNone(tags)) {
      return [];
    }

    let tagType = this.get('tagType');

    tags = tags.filter((tag) => this.canCreate(tag) && !this.tagIsSelected(tag)).sortBy('name');

    if (tagType) {
      return tags.filterBy('tagType', tagType);
    }
    return tags;
  }),

  canCreate: function(tag) {
    return reservedTags.indexOf(get(tag, 'name')) < 0 && get(tag, 'behaviors.create') === true;
  },

  tagIsSelected: function(tag) {
    let taggings = get(this, 'taggings');
    return taggings.filterBy('tag.id', get(tag, 'id')).length > 0;
  },

  editable: computed('', 'allowAddTag', 'hasPermission', 'isNew', function() {
    let { hasPermission, isNew, allowAddTag } = getProperties(this, ['hasPermission', 'isNew', 'allowAddTag']);

    return allowAddTag && (hasPermission || isNew);
  }),

  selectedTag: computed('tag', {
    get() {
      return this.get('tag');
    },
    set(key, id) {
      let tag = null;
      if (id) {
        tag = this.store.peekRecord('tag', id);
      }
      this.set('tag', tag);
      return tag;
    }
  }),

  reloadDocuments: function(tag) {
    let folder = this.get('store').peekRecord('folder', tag.get('id'));
    if (folder) {
      folder.get('documents').reload();
    }
  },

  actions: {
    addRecord: function() {
      let tag = this.get('tag');
      let messages = this.get('flashMessages');

      if (tag) {
        let taggableType = this.get('taggable').get('_internalModel.modelName');
        let componentContext = this;
        let tagging = this.get('store').createRecord('tagging', {
          tag: tag,
          name: tag.name
        });

        if (taggableType === 'control') {
          tagging.set('control', this.get('taggable'));
        } else if (taggableType === 'document') {
          tagging.set('document', this.get('taggable'));
        } else if (taggableType === 'adapter') {
          tagging.set('adapter', this.get('taggable'));
        }

        tagging.save().then(function() {
          messages.success(`${tag.get('name')} tag added`);
          componentContext.set('selectedTag', null);
          componentContext.reloadDocuments(tag);
        }, function() {
          tagging.rollbackAttributes();
          messages.error('Sorry, failed to add tag');
        });
      }
    },

    removeRecord: function(record) {
      let componentContext = this;
      let tag = record.get('tag');
      let messages = this.get('flashMessages');

      record.destroyRecord().then(
        function() {
          messages.success(`${tag.get('name')} tag removed`);
          componentContext.reloadDocuments(tag);
        },
        function() {
          record.rollbackAttributes();
          messages.error('Sorry, failed to delete tag');
        }
      );
    }
  }

});
