import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! template-lint-disable prettier }}\n<span {{did-insert this.initializeList}}> </span>\n{{yield\n  (hash\n    lookupInsuranceProviders=this.lookupInsuranceProviders\n    insuranceProviders=this.insuranceProviders\n  )\n}}", {"contents":"{{! template-lint-disable prettier }}\n<span {{did-insert this.initializeList}}> </span>\n{{yield\n  (hash\n    lookupInsuranceProviders=this.lookupInsuranceProviders\n    insuranceProviders=this.insuranceProviders\n  )\n}}","moduleName":"shared/components/data/insurance-provider-options.hbs","parseOptions":{"srcName":"shared/components/data/insurance-provider-options.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task, timeout } from 'ember-concurrency';
import { action } from '@ember/object';
import ENV from 'ember-get-config';
import validateArgs from 'shared/utils/validate-args';

const TIMEOUT = ENV.environment === 'test' ? 0 : 1000;

/**
 * Use this data wrapper component to access options for Provider Accounts and
 * Providers for a given Organization.
 *
 * Example:
 * ```
 *   <Data::InsuranceProviderOptions
 *     @organization={{@organization}}
 *     as |service|
 *   >
 *     <PowerSelect
 *       @placeholder="All Insurance Providers"
 *       @selected={{this.selectedInsuranceProviders}}
 *       @options={{service.insuranceProviders}}
 *       @search={{perform service.lookupInsuranceProviders}}
 *       @searchEnabled={{true}}
 *       @onchange={{action (mut this.selectedInsuranceProviders)}}
 *       @triggerClass="max-width-none light-gray"
 *       @dropdownClass="light-gray"
 *       @verticalPosition="below"
 *       as |insuranceProvider|>
 *
 *       {{insuranceProvider.name}}
 *
 *     </PowerSelect>
 *   </Data::InsuranceProviderOptions>
 * ```
 */
export default class DataInsuranceProviderOptions extends Component {
  @service settings;
  @service store;
  @service flashMessages;

  @tracked insuranceProviders = [];

  requiredArguments = [
    'organization'
  ];

  constructor() {
    super(...arguments);
    validateArgs(this.requiredArguments, this.args);
  }

  @action
  async initializeList() {
    if (this.args.initializeList) {
      this.insuranceProviders = await this.lookupInsuranceProviders.perform();
    }
  }

  @task({ restartable: true })
  *lookupInsuranceProviders(search) {
    yield timeout(TIMEOUT);

    let exactMatch = false;
    let searchTerm = search;
    if (search) {
      // extract any term from double quotes (") using regex
      const regex = /"([^"]+)"/g;
      let matches = search.match(regex);
      if (matches) {
        exactMatch = true;
        searchTerm = matches[0].replace(/"/g, '');
      }
    }

    const query = {
      organization_id: this.args.organization.id,
      fields: 'id,name',
      search: searchTerm,
      exact: exactMatch,
      per_page: 200,
      page: 1
    };

    // For Portal Query
    if (this.settings.portalOrder) {
      query.for_orders_portal = true;
    }

    return yield this.store
      .query('insurance-provider', query)
      .catch(() => {
        this.flashMessages.error('Failed to lookup Insurance Providers');
        return [];
      });
  }
}
