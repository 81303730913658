/* eslint-disable ember/no-classic-components, ember/no-classic-classes */
import Component from '@ember/component';
import layout from './template';

export default Component.extend({
  layout,
  tagName: '',

  onCancel: null,
});

