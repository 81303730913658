/* eslint-disable ember/no-classic-classes, object-shorthand, quotes */
import Transform from '@ember-data/serializer/transform';
import moment from 'moment';

export default Transform.extend({
  deserialize: function(serialized) {
    let type = typeof serialized;

    if (type === "string" || type === "number") {
      return moment(serialized).toDate();
    } else if (serialized === null || serialized === undefined) {
      return serialized;
    } else {
      return null;
    }
  },

  serialize: function(date) {
    if (date instanceof Date) {
      return moment(date).format('YYYY-MM-DD');
    } else {
      return null;
    }
  }
});
