/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components, max-len, ember/no-get, object-shorthand, no-multi-spaces, ember/no-actions-hash */
import { A } from '@ember/array';
import Component from '@ember/component';
import EmberObject, { set, get, computed } from '@ember/object';
import { isEmpty, isPresent } from '@ember/utils';
import layout from './template';
import { capitalize } from '@ember/string';
import { escapeRegExp } from 'shared/utils/escape-reg-exp';

const transformLabel = function(label) {
  return (label || '')
    .split(/[\s-]/)
    .map((token) => capitalize(token))
    .join(' ');
};

export default Component.extend({
  layout,
  tagName: 'ul',
  classNames: 'unstyled resources',
  resources: [],
  group: false,
  filter: false,
  label: undefined,
  showPreview: true,
  deletable: true,
  currentResourceUrl: null,
  currentResourceFileName: null,
  openPreview: true,
  showModal: false,
  hiddenFeedBack: false,
  previewableFileTypes: ['.csv', '.pdf'],
  showCurrentFilePreview: computed('showModal', 'showPreview', 'currentResourceUrl', 'currentResourceFileName', function() {
    let currentResourceUrl = get(this, 'currentResourceUrl');
    let currentResourceFileName = get(this, 'currentResourceFileName');
    let showPreview = get(this, 'showPreview');
    let showModal = get(this, 'showModal');
    return showModal && showPreview && currentResourceUrl && currentResourceFileName;
  }),

  grouped: computed('filter', 'filtered', 'resources.@each.label', function() {
    let Group = EmberObject.extend({});
    let groups = new A();
    let resources = this.get('resources');
    let items = [];

    if (isEmpty(resources)) {
      return [];
    }

    if (this.get('filter')) {
      items = this.get('filtered');
    } else {
      items = resources.filter(function(item) {
        return item.get('label');
      });
    }

    items.forEach(function(item) {
      let key = transformLabel(item.get('label'));
      let group = groups.findBy('key', key);

      if (isPresent(group)) {
        group.get('items').push(item);
      } else {
        group = Group.create({ key: key, items: [item] });
        groups.push(group);
      }
    });

    return groups;
  }),

  filtered: computed('resources.@each.label', 'label', function() {
    let label  = this.get('label') || '';
    let labels = label.split(',');
    let filter = new RegExp(labels.map(escapeRegExp).join('|'), 'i');
    let resources = this.get('resources');

    if (isEmpty(resources)) {
      return [];
    }

    return resources.filter(function(resource) {
      let label = resource.get('label');
      return label && filter.test(resource.get('label'));
    });
  }),

  actions: {
    showFilePreview(url, name) {
      set(this, 'currentResourceUrl', url);
      set(this, 'currentResourceFileName', name);
      set(this, 'showModal', true);
    },

    hideModal() {
      set(this, 'showModal', false);
    }
  }

});
