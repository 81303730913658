/* eslint-disable ember/no-classic-components, ember/no-mixins, ember/no-classic-classes, ember/require-tagless-components, max-len */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import BarcodeTemplate from 'shared/mixins/barcode-templates';
import layout from './template';

const TUBES = [
  'Bag',
  'TubeBox',
  'TubeBox36',
  'TubeBox64',
  'TubeBox72',
  'TubeBox100',
  'TubeBox200',
  'TubeRack48',
  'TubeRack60',
  'TubeRack96'
];

export default Component.extend(BarcodeTemplate, {
  layout,
  currentSession: service(),
  dymo: service(),
  currentOrganization: service('current-organization'),
  behavior: service('sl-behavior'),

  classNames: ['wb-barcode'],
  model: null,
  hideInput: false,
  manifestOptions: null,
  icon: 'ellipsis-h',
  dataTestId: '',

  organization: alias('currentOrganization.organization'),
  barcodeTemplates: alias('organization.barcodeTemplates'),

  canEditIdentifier: computed('behavior.behaviors.routes', 'organization.id', 'currentSession', function() {
    if (!this.organization) {
      return false;
    }

    return this.behavior.isAble('requisitions.update', 'route') || this.currentSession.hasSupportFullAccessEnabledForOrg(this.organization.id);
  }),

  hasTubes: computed('model.type', function() {
    if (!this.model) {
      return;
    }

    return TUBES.includes(this.model.type);
  })
});
