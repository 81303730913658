/* eslint-disable quotes */
import BaseComponent from "../../requisition-base/component";
import layout from './template';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';

export default BaseComponent.extend({
  layout,
  classNames: ['requisition-address-info'],
  store: service(),
  isNew: true,
  readOnly: false,
  portalOrder: false,
  isVisible: true,

  notesLength: alias('model.notes.length'),
});
