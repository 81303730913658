/* eslint-disable ember/no-get */
import { computed } from '@ember/object';
import { isPresent } from '@ember/utils';
import BaseComponent from 'shared/components/requisitions/requisition-base/component';

export default BaseComponent.extend({
  classNames: ['row', 'requisition-lifestyle-factors'],

  templateKey: 'lifestyleFactors',
  readOnly: false,

  isVisible: computed('template', 'template.lifestyleFactors', function() {
    return isPresent(this.get('template.lifestyleFactors'));
  })

});
