/* eslint-disable object-shorthand, one-var, max-len, ember/no-jquery, no-trailing-spaces */
import { later } from '@ember/runloop';
import $ from 'jquery';

export default {

  display: function() {
    let message, type;
    let template = '<div class="flash alert CLASS"><button type="button" class="close" data-dismiss="alert">×</button>MESSAGE</div>';

    if (typeof arguments[0] === 'string') {
      type = (typeof arguments[1] === 'string') ? `alert- ${arguments[1]}` : 'alert-block';
      message = template.replace('CLASS', type).replace('MESSAGE', arguments[0]);
    }

    $('#main').prepend(message);
    later(function() {
      $('body .flash').alert('close');
    }, 5000);
  }

};
