/* eslint-disable ember/no-classic-classes, key-spacing */
import Model, { belongsTo, attr } from '@ember-data/model';

export default Model.extend({
  // Association
  organization:     belongsTo('organization'),

  // Attributes
  name:             attr('string'),
  entityType:       attr('string'),
  key:              attr('string'),
  renderType:       attr('string'),
  attributeType:    attr('string'),
  codes:            attr('string'),
  selectProperties: attr('string'),
});
