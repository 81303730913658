/* eslint-disable key-spacing */
import ApplicationSerializer from 'shared/serializers/application';

export default ApplicationSerializer.extend({
  attrs: {
    name:       { serialize: false },
    url:        { serialize: false },
    readUrl:    { serialize: false },
    publicUrl:  { serialize: false },
    proxyUrl:   { serialize: false },
    parent:     { key: 'resource_group_id' }
  }
});
