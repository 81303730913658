/* eslint-disable ember/no-classic-components, max-len, ember/no-classic-classes, ember/require-tagless-components, ember/no-get, ember/no-actions-hash */
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { none, and, notEmpty, alias } from '@ember/object/computed';
import { isPresent } from '@ember/utils';
import { set, getProperties, get, computed } from '@ember/object';
import { run } from '@ember/runloop';
import { task } from 'ember-concurrency';
import layout from './template';

const wellLocations = {
  x: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'],
  y: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P']
};

export default Component.extend({
  layout,

  store: service(),
  classNames: 'well-location-samples',
  currentSearch: '',
  rowCount: alias('platePositions.length'),
  columnCount: alias('platePositions.firstObject.length'),
  allowAdapterPlating: true,
  sourceSampleStates: alias('source.sampleStates'),
  showArrows: none('selectedLine'),

  locationSelected: and('row', 'column'),

  adapterContainers: [],

  validAdapterContainers: [],
  hasValidAdapterContainers: notEmpty('validAdapterContainers'),

  isLoadingAdapterContainers: alias('loadAdapterContainers.isRunning'),

  loadAdapterContainers: task(function *() {
    let allowAdapterPlating = this.allowAdapterPlating;
    if (allowAdapterPlating) {
      let organizationId = get(this, 'activity.organization.id');
      let workflowId = get(this, 'activity.workflow.id');

      yield this.store.query('adapter', {
        organization_id: organizationId,
        workflow_id: workflowId,
        for_library_creation: true,
        include: 'valid-adapter-containers'
      }).then((adapters) => {
        let tempContainers = [];
        adapters.forEach((adapter) => {
          adapter.validAdapterContainers.forEach((ac) => {
            ac.set('adapter', adapter);
            tempContainers.push(ac);
          });
        });
        set(this, 'validAdapterContainers', tempContainers);
      });
    } else {
      set(this, 'adapterContainers', get(this, 'activity.adapterContainers'));
    }
  }),

  filteredAdapterContainers: computed('validAdapterContainers', 'currentSearch', 'adapterContainers.[]', function() {
    let { validAdapterContainers, currentSearch, adapterContainers } = getProperties(this, ['validAdapterContainers', 'currentSearch', 'adapterContainers']);

    let containersBarcode = adapterContainers.map((c) => c.barcodeLabel);

    if (!currentSearch || currentSearch === '') {
      return [];
    }

    return validAdapterContainers.filter((ac) => {
      let barcode = ac.barcodeLabel;
      return barcode.toLowerCase().includes(currentSearch.toLowerCase()) && !containersBarcode.includes(barcode);
    });
  }),

  currentLocationLabel: computed('row', 'column', function() {
    let { row, column } = getProperties(this, ['row', 'column']);

    return `${wellLocations.y[row - 1]}${wellLocations.x[column - 1]}`;
  }),

  currentLocationText: computed('column', 'currentLocationLabel', 'row', 'selectedLine', function() {
    let { row, column, selectedLine, currentLocationLabel } = getProperties(this, ['row', 'column', 'selectedLine', 'currentLocationLabel']);
    let text = '';

    if (isPresent(selectedLine)) {
      let lineLabel = selectedLine.line === 'column' ? wellLocations.x[selectedLine.index - 1] : wellLocations.y[selectedLine.index - 1];
      text = `for ${selectedLine.line} <span class="location-label">${lineLabel}</span>`;
    } else if (isPresent(row) && isPresent(column)) {
      text = `for position <span class="location-label">${currentLocationLabel}</span>`;
    }

    return text;
  }),

  currentLocationSampleState: computed('currentLocationLabel', 'source.sampleStates', function() {
    let currentLocationLabel = this.currentLocationLabel;
    return get(this, 'source.sampleStates').findBy('position', currentLocationLabel);
  }),

  currentLocationSample: computed('currentLocationLabel', 'wellAssignments', function() {
    let { currentLocationLabel, wellAssignments } = getProperties(this, ['currentLocationLabel', 'wellAssignments']);
    return wellAssignments.filter((wa) => wa.locations.includes(currentLocationLabel))[0];
  }),

  currentLocationDisabled: computed('currentLocationSample', 'allowAdapterPlating', function() {
    let { currentLocationSample, allowAdapterPlating } = getProperties(this, ['currentLocationSample', 'allowAdapterPlating']);

    return !allowAdapterPlating || !isPresent(currentLocationSample);
  }),

  prevLocation: computed('column', 'columnCount', 'row', function() {
    let { row, column, columnCount } = getProperties(this, ['row', 'column', 'columnCount']);

    let prevRow = row;
    let prevColumn = column - 1;

    if (!prevColumn) {
      prevRow = row - 1;
      prevColumn = columnCount - 1;
    }

    return prevRow ? { x: prevColumn, y: prevRow } : null;
  }),

  prevLocationLabel: computed('prevLocation', function() {
    let prevLocation = this.prevLocation;

    return prevLocation ? `${wellLocations.y[prevLocation.y - 1]}${wellLocations.x[prevLocation.x - 1]}` : null;
  }),

  nextLocation: computed('column', 'columnCount', 'row', function() {
    let { row, column, columnCount } = getProperties(this, ['row', 'column', 'columnCount']);

    let nextRow = row;
    let nextColumn = column + 1;

    if (nextColumn === columnCount) {
      nextRow = row + 1;
      nextColumn = 1;
    }

    return nextRow ? { x: nextColumn, y: nextRow } : null;
  }),

  nextLocationLabel: computed('nextLocation', function() {
    let nextLocation = this.nextLocation;

    return nextLocation ? `${wellLocations.y[nextLocation.y - 1]}${wellLocations.x[nextLocation.x - 1]}` : null;
  }),

  activityAdapterContainerWells: alias('activity.adapterContainerWells'),

  filteredAssignments: computed('currentLocationLabel', 'wellAssignments', function() {
    let { currentLocationLabel, wellAssignments } = getProperties(this, ['currentLocationLabel', 'wellAssignments']);

    return wellAssignments.filter((wa) => wa.locations.includes(currentLocationLabel));
  }),

  showListHeader: computed('allowAdapterPlating', 'row', 'column', function() {
    let { allowAdapterPlating, row, column } = getProperties(this, ['allowAdapterPlating', 'row', 'column']);

    return allowAdapterPlating || (isPresent(row) && isPresent(column));
  }),

  selectionTypeAllowed: computed('selectedLine', function() {
    let selectedLine = this.selectedLine;

    if (isPresent(selectedLine)) {
      return selectedLine.line;
    }

    return 'position';
  }),

  _assignSampleState(adapterContainerWell, sampleState, row, column) {
    if (isPresent(adapterContainerWell) && !adapterContainerWell.notSelectableWell() && isPresent(sampleState)) {
      let activityAdapterContainerWells = this.activityAdapterContainerWells;
      let adapterContainerWellInActivity = activityAdapterContainerWells.findBy('sampleState.id', sampleState.id);

      // remove container well for the sampleState
      if (adapterContainerWellInActivity) {
        set(this, 'activityAdapterContainerWells', activityAdapterContainerWells.filter((acw) => get(acw, 'sampleState.id') !== sampleState.id));
        set(adapterContainerWellInActivity, 'sampleState', null);
      }

      set(adapterContainerWell, 'sampleState', sampleState);
      this.activityAdapterContainerWells.pushObject(adapterContainerWell);

      this.onSelectContainerWell(adapterContainerWell, row, column);
    }
  },

  init() {
    this._super(...arguments);
    run(() => this.loadAdapterContainers.perform());
  },

  actions: {
    removeAdapter(adapterContainer) {
      let adapterContainerWells = adapterContainer.adapterContainerWells;

      // Remove containers wells from activity
      adapterContainerWells.forEach((acw) => {
        set(acw, 'sampleState', null);
        this.activityAdapterContainerWells.removeObject(acw);
      });

      // Remove container
      this.adapterContainers.removeObject(adapterContainer);

      // remove wells from source container plate
      this.onRemoveAdapterContainer(adapterContainerWells);
    },

    onSelectLine(adapterContainerWells) {
      let { sourceSampleStates, selectedLinePositions, row, column, selectedLine } = getProperties(this, ['sourceSampleStates', 'selectedLinePositions', 'row', 'column', 'selectedLine']);

      // sample states by position
      for (let i = 0; i < selectedLinePositions.length; i++) {
        let position = selectedLinePositions[i];
        let sampleState = sourceSampleStates.findBy('position', position);

        if (selectedLine.line === 'row') {
          column = i;
        } else if (selectedLine.line === 'column') {
          row = i;
        }

        this._assignSampleState(adapterContainerWells[i - 1], sampleState, row, column);
      }
    },

    onSelectWell(adapterContainerWell) {
      let { currentLocationSampleState, row, column } = getProperties(this, ['currentLocationSampleState', 'row', 'column']);

      this._assignSampleState(adapterContainerWell, currentLocationSampleState, row, column);
    },

    selectAdapter(adapter) {
      this.adapterContainers.pushObject(adapter);
      set(this, 'currentSearch', '');
    },

    prev() {
      let prevLocation = this.prevLocation;

      this.onChangeLocation(prevLocation.y, prevLocation.x);
    },

    next() {
      let nextLocation = this.nextLocation;

      this.onChangeLocation(nextLocation.y, nextLocation.x);
    }
  }

});
