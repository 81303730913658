/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components, space-before-function-paren, quotes, max-len, ember/use-brace-expansion, ember/no-component-lifecycle-hooks, curly, dot-notation, semi, ember/no-get, no-multi-spaces, ember/no-actions-hash, keyword-spacing, brace-style */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed, set } from '@ember/object';
import { alias, or } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';
import { task, timeout } from 'ember-concurrency';
import moment from 'moment';
import { capitalize } from '@ember/string';
import layout from './template';
import config from 'ember-get-config';
import _ from 'lodash';

import {
  REQUISITION_ATTRIBUTES,
  REQUISITION_ATTRIBUTES_DRAFT_ENABLED,
  SAMPLE_ATTRIBUTES,
  PATIENT_ATTRIBUTES,
  PROVIDER_ATTRIBUTES,
  PANELS_ATTRIBUTES,
  STATUSES_ATTRIBUTES,
  BILLING_ATTRIBUTES,
  MEDICATION_ATTRIBUTES,
  ICD_10_ATTRIBUTES } from 'shared/models/requisition-report-entry';

const TIMEOUT = config.environment === 'test' ? 0 : 250;
const MAX_RANGE_DAYS = 396;

export default Component.extend({
  layout,
  flashMessages: service(),
  adminReports: service(),
  currentOrganization: service(),
  requisitionReportSchedule: service(),
  store: service(),
  portalPermissions: service(),
  settings: service(),
  router: service(),

  fromDate: moment().startOf('day').subtract(1, 'days').toDate(),
  toDate: moment().startOf('day').toDate(),
  selectedAccounts: null,
  selectedPhysicians: null,
  adHoc: false,

  newReportRoute: 'organization.analytics.requisition-reports.reports',
  newScheduleRoute: 'organization.analytics.requisition-reports.schedule',

  accountPhysicians: null,
  frequencies: Object.freeze([
    'Daily',
    'Weekly',
    'Monthly',
    'Quarterly'
  ]),

  portalOrder: alias('settings.portalOrder'),
  isSalesRepWithRequisitionAccess: alias('portalPermissions.isSalesRepWithRequisitionAccess'),
  isPrimaryPhysicianContact: alias('portalPermissions.isPrimaryPhysicianContact'),

  showColumns: false,
  globalColumnsByEntity: null,

  selectedColumns: [],
  validSelectedColumns: computed('selectedColumns', 'reportColumnValues', function () {
    // it can happen that _selectedColumns has invalid column names _(that came from preference.columns)_
    // better to filter them out here because reportColumns is also a computed prop
    return this.selectedColumns.filter(col => this.reportColumnValues.includes(col));
  }),

  statusOptions: Object.freeze([
    'draft',
    'received',
    'held',
    'processing',
    'complete',
    'delivered',
    'delivered_modified',
    'canceled'
  ]),

  draftRequisitionsEnabled: computed('organization.features', function() {
    return this.organization.hasFeature("Draft Requisitions");
  }),

  // By default, these columns should be unselected
  defaultUnselectedColumns: [
    'Revised Report',
    'Correction Reason',
    'Signature Comment',
    'Signed Unified Consent',
    'Test Panel Codes'
  ],

  /**
   * Default columns selection if no preference exists
   * @return {array} of default selected columns
   */
  defaultSelectedColumns: computed('defaultUnselectedColumns', 'reportColumnValues', 'reportColumns', function() {
    return this.reportColumnValues.filter(v => !this.defaultUnselectedColumns.includes(v));
  }),

  isReportValid: computed('model.providerAccounts.[]', 'model.providers.[]', 'fromDate', 'toDate', 'adHoc', function() {
    if (this.adHoc) {
      return this._isValidDateRange();
    }

    return false;
  }),

  isScheduleValid: computed('model.frequency', 'model.startDate', 'adHoc', function() {
    if (!this.adHoc) {
      if (this.model.frequency && this.model.startDate) {
        return true;
      }
    }
    return false;
  }),

  isValid: or('isReportValid', 'isScheduleValid'),

  isSaving: or('saveSchedule.isRunning', 'createReport.isRunning'),

  didReceiveAttrs() {
    this._super(...arguments);

    // Set perferences
    if (this.model.isNew && this.adHoc) {
      this.lookupPreferenceProject.perform();
      this.searchRequisitionTemplates.perform();
      this.lookupPreferenceProviderAccounts.perform();
      this.lookupPreferenceRequisitionTemplate.perform();
      this.lookupPreferenceProviders.perform();

      this._updateSelectedColumns();
    }

    if (!this.adHoc) {
      if (!this.model.isNew) {
        // using model's columns
        this.set('selectedColumns', this.model.columns ? JSON.parse(this.model.columns) : []);

        // load requisition templates so the user can edit them
        this.searchRequisitionTemplates.perform();
      } else {
        // when creating a schedule, default to all columns selected
        this.set('selectedColumns', this.defaultSelectedColumns);
      }
    }

    if (!this.model.providerAccounts) {
      this.model.set('providerAccounts', []);
      this.model.set('providers', []);
    }

    if (!this.model.testPanels) {
      this.model.set('testPanels', []);
    }
  },

  /***
   *Indicates whether user has access to patient information
   * @returns {boolean}
  **/
  showPatientAttributes: computed('portalOrder', 'isSalesRepWithRequisitionAccess', 'isPrimaryPhysicianContact', function() {
    if (!this.portalOrder) return true;
    if (this.isSalesRepWithRequisitionAccess || this.isPrimaryPhysicianContact) return true;

    return false;
  }),

  reportColumns: computed('draftRequisitionsEnabled', 'globalColumnsByEntity', 'isPrimaryPhysicianContact', 'isSalesRepWithRequisitionAccess', 'portalOrder', 'searchGlobalCustomAttrs', 'showPatientAttributes', function () {
    if (!this.globalColumnsByEntity) {
      this.searchGlobalCustomAttrs.perform();
    }

    let globalColumnsByEntity = this.globalColumnsByEntity || {};

    let patientColumns = this.showPatientAttributes ? (globalColumnsByEntity['patient'] || []) : [];
    let sampleColumns = globalColumnsByEntity['sample'] || [];
    let requisitionColumns = globalColumnsByEntity['requisition'] || [];

    const requisitionAttributes = this.draftRequisitionsEnabled ? REQUISITION_ATTRIBUTES_DRAFT_ENABLED : REQUISITION_ATTRIBUTES
    const patientAttributes = this.showPatientAttributes ? PATIENT_ATTRIBUTES : []

    return _.uniqBy(
      [
        ...requisitionAttributes,
        ...SAMPLE_ATTRIBUTES,
        ...sampleColumns,
        ...patientAttributes,
        ...patientColumns,
        ...PROVIDER_ATTRIBUTES,
        ...PANELS_ATTRIBUTES,
        ...STATUSES_ATTRIBUTES,
        ...BILLING_ATTRIBUTES,
        ...MEDICATION_ATTRIBUTES,
        ...ICD_10_ATTRIBUTES,
        ...requisitionColumns
      ],
      'value');
  }),

  reportColumnValues: computed('reportColumns', function() {
    return this.reportColumns.map(col => col.value);
  }),

  lookupProjects: task(function *(search) {
    yield timeout(TIMEOUT);
    let org = this.organization;

    const query = {
      organization_id: org.get('id'),
      fields: 'id,name',
      filter: { organization_id: org.get('id') },
      q: search,
      per_page: 10
    };

    return yield this
      .store
      .query('project', query)
      .catch(() => {
        this.flashMessages.error('Failed to lookup Projects');
      });
  }).restartable(),

  searchRequisitionTemplates: task(function *() {
    yield timeout(TIMEOUT);
    let org = this.organization;

    let requisitionTemplates = [];
    if (this.model.project) {
      const params = {
        organization_id: org.get('id'),
        project_id: this.get('model.project.id'),
        fields: 'id,name',
        page: 1,
        per_page: 4500
      };

      requisitionTemplates = yield this.store.query('requisition-template', params);
    }

    set(this, 'requisitionTemplates', requisitionTemplates);
  }),

  lookupPreferenceProject: task(function *() {
    const projectId = this.get('preference.projectId');
    if (!projectId) {
      return;
    }

    let project = yield this.store.findRecord('project', projectId);
    this.set('model.project', project);
    this.searchGlobalCustomAttrs.perform();
  }),

  lookupPreferenceRequisitionTemplate: task(function *() {
    const requisitionTemplateId =  this.get('preference.requisitionTemplateId');
    if (!requisitionTemplateId) {
      return;
    }

    let requisitionTemplate = yield this.store.findRecord('requisition-template', requisitionTemplateId);
    this.set('model.requisitionTemplate', requisitionTemplate);
    this.searchGlobalCustomAttrs.perform();
  }),

  lookupPreferenceProviderAccounts: task(function *() {
    const providerAccountIds = this.get('preference.providerAccountIds') || [];

    for (let id of providerAccountIds) {
      let account = yield this.store.findRecord('sales-account', id);
      this.model.providerAccounts.pushObject(account);
    }
  }),

  lookupPreferenceProviders: task(function *() {
    const providerIds = this.get('preference.providerId') || [];

    for (let id of providerIds) {
      let provider = yield this.store.findRecord('physician', id);
      this.model.providers.pushObject(provider);
    }
  }),

  searchGlobalCustomAttrs: task(function *() {
    let organizationId = this.get('organization.id');

    let params = {
      requisition_template_global_custom_attributes_requisition_template_projects_organization_id_eq: organizationId,
      requisition_template_global_custom_attributes_requisition_template_projects_id_eq: this.get('model.project.id'),
      requisition_template_global_custom_attributes_requisition_template_id_eq: this.get('model.requisitionTemplate.id'),
    };

    let globalCustomAttrs = yield this.store.query('global-custom-attribute', { q: params });

    let globalColumnsByEntity = {};

    globalCustomAttrs.forEach((customAttr) => {
      if (!globalColumnsByEntity[customAttr.entityType]) {
        globalColumnsByEntity[customAttr.entityType] = [];
      }

      globalColumnsByEntity[customAttr.entityType].push({
        display: `${capitalize(customAttr.entityType)} ${customAttr.name}`,
        value: customAttr.key
      });
    });

    this.set('globalColumnsByEntity', globalColumnsByEntity);
    this._updateSelectedColumns();
  }).restartable(),

  saveSchedule: task(function *() {
    this.model.set('columns', JSON.stringify(
      Array.from(
        new Set(this.selectedColumns).values() // prevent duplication
      )
    ));

    this.model.set('portal', this.settings.portal);

    return yield this.model.save().then(() => {
      this.flashMessages.success('Requisition Report Schedule created');
      this.requisitionReportSchedule.loadDataTask.perform();
      this.router.transitionTo('organization.analytics.requisition-reports.schedule');
    }).catch((error) => {
      let errors = [];
      if (error.errors) {
        errors = error.errors.map(err => err.detail);
      }
      this.flashMessages.error(`Failed to create requisition report schedule:\n ${errors.join("\n")}`);
    });
  }),

  createReport: task(function *() {
    const testPanelIds = this.model.testPanels.mapBy('id').map(id => parseInt(id));
    const providerAccountIds = this.model.providerAccounts.mapBy('id').map(id => parseInt(id));
    const providerIds = this.model.providers.mapBy('id').map(id => parseInt(id));

    this.model.set("searchParams", {
      created_at_gteq: this.fromDate,
      created_at_lt: this.toDate,
      sales_account_id_in: providerAccountIds,
      physician_sales_account_physician_id_in: providerIds,
      test_panels_id_in: testPanelIds,
      project_id_eq: this.get('model.project.id'),
      requisition_template_id_eq: this.get('model.requisitionTemplate.id'),
      status_in: this.get('model.status'),
      columns: this.validSelectedColumns
    });

    this.preference.setProperties({
      columns: this.validSelectedColumns,
      projectId: this.get('model.project.id'),
      requisitionTemplateId: this.get('model.requisitionTemplate.id'),
      providerAccountIds,
      providerIds,
    });

    this.model.set("reportType", "requisition");

    yield this.model.save()
      .then(() => {
        this.flashMessages.success('Report request queued');
        this.model.reload();
        this.adminReports.loadDataTask.perform();
        this.router.transitionTo(this.newReportRoute);
      }).catch(() => {
        this.flashMessages.error('An error occurred trying to generate report. Please try again.');
      });
  }).drop(),

  /**
   * Start date must be in the future
   */
  firstAvailableDate: computed('organization.dateTimeOutputFormat', function() {
    let dateTimeFormat = this.organization.get('dateTimeOutputFormat');
    if (!dateTimeFormat) {
      return;
    }
    let now = moment().toDate();
    return moment(now, dateTimeFormat);
  }),

  _updateSelectedColumns() {
    // if preference contains column that is not currently accessible given permissions, filter it out
    let selectedColumns = !isEmpty(this.selectedColumns) ? this.selectedColumns : this.defaultSelectedColumns;
    let preferenceColumns = this.get('preference.columns');
    if (preferenceColumns) {
      //use the selected column order
      selectedColumns = this.reportColumnValues.filter(c => preferenceColumns.includes(c)).sort((a, b) => preferenceColumns.indexOf(a) - preferenceColumns.indexOf(b));
    }

    this.set('selectedColumns', selectedColumns);
  },

  _isValidDateRange() {
    if (this.fromDate && this.toDate && this.fromDate <= this.toDate) {
      const diff = moment(this.toDate).diff(moment(this.fromDate), 'days', true);

      return !(diff > MAX_RANGE_DAYS);
    }

    return false;
  },

  actions: {
    providerAccountsChanged(selectedAccounts) {
      this.model.set('providerAccounts', selectedAccounts);
      this.model.set('providers', []);
    },

    providersChanged(selectedProviders) {
      this.model.set('providers', selectedProviders);
    },

    projectChanged(selectedProject) {
      this.model.set('requisitionTemplate', null);
      this.model.set('project', selectedProject);
      this.searchRequisitionTemplates.perform();
      this.searchGlobalCustomAttrs.perform();
    },

    requisitionTemplateChanged(selectedRequisitionTemplate) {
      this.model.set('requisitionTemplate', selectedRequisitionTemplate);
      if (selectedRequisitionTemplate === null) {
        this.searchRequisitionTemplates.perform();
      } else {
        this.searchGlobalCustomAttrs.perform();
      }
    },

    testPanelChanged(testPanel) {
      this.model.set('testPanel', testPanel);
    },

    toggleColumn(columnValue) {
      if (this.selectedColumns.includes(columnValue)) {
        this.set('selectedColumns', this.selectedColumns.filter(c => c !== columnValue));
      } else {
        this.set('selectedColumns', this.selectedColumns.concat(columnValue));
      }
    },

    checkAllColumns() {
      if(this.selectedColumns.length >= this.reportColumns.length) {
        this.set('selectedColumns', []);
      } else {
        this.set('selectedColumns', this.reportColumnValues);
      }
    },

    cancel() {
      if (this.adHoc) {
        this.router.transitionTo(this.newReportRoute);
      }
      else {
        this.router.transitionTo(this.newScheduleRoute);
      }
    },

    submit() {
      this.set('didSubmit', true);
      if (this.adHoc) {
        this.createReport.perform();
      } else {
        this.saveSchedule.perform();
      }
    },

    onValidate() {
      if (!this._isValidDateRange()) {
        return "The maximum date range is 13 months.";
      }
    }
  }
});
