/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components */
import { not } from '@ember/object/computed';
import Component from '@ember/component';
import layout from './template';

export default Component.extend({
  layout,
  tagName: 'li',
  resources: [],
  resource: null,
  showDetails: true,
  deletable: false,
  hiddenFeedBack: false,
  showFeedback: not('hiddenFeedBack')
});
