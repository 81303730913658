/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components, ember/no-actions-hash, max-len */
import Component from '@ember/component';
import { alias, empty, equal } from '@ember/object/computed';
import { debounce } from '@ember/runloop';
import {
  setProperties,
  set,
  computed
} from '@ember/object';
import { isEmpty, isPresent } from '@ember/utils';
import _array from 'lodash/array';
import layout from './template';
import { containerMap } from 'shared/const/wb-source-well-plate-config';

const { flatten } = _array;

export default Component.extend({
  layout,
  tagName: 'div',
  classNames: ['well-plate'],
  classNameBindings: ['inactive'],

  model: null,
  locationSelected: false,
  selectedLine: null,
  rowSelected: null,
  columnSelected: null,
  allowPoolAssignment: false,
  validateSameRequisitionOnWell: false,
  showSampleStatus: false,

  numWells: alias('model.numberOfWells'),
  inactive: empty('model'),
  showPlateLayout: true,
  isBag: equal('model.type', 'Bag'),
  isTubeBox: equal('model.type', 'TubeBox'),
  isTubeBox36: equal('model.type', 'TubeBox36'),
  isTubeBox72: equal('model.type', 'TubeBox72'),
  isTubeBox100: equal('model.type', 'TubeBox100'),
  isTubeBox200: equal('model.type', 'TubeBox200'),

  plate: computed('model.type', function() {
    return containerMap[this.model.type] ? containerMap[this.model.type].cssClass : '';
  }),

  disabledWellLocations: alias('model.disabledWellLocations'),

  plateRows: computed('model.{sampleIdentifiers.length,type}', function() {
    let plateRows = [];

    if (isPresent(this.model.type)) {
      let rowsCount = containerMap[this.model.type].columns;

      for (let i = 0; i < this.model.sampleIdentifiers.length; i += rowsCount) {
        plateRows.push(this.model.sampleIdentifiers.slice(i, i + rowsCount));
      }
    }

    return plateRows;
  }),

  selectedLinePositions: computed('selectedLine.{line,index}', 'plateRows', function() {
    let positions = [];

    if (this.selectedLine) {
      if (this.selectedLine.line === 'column') {
        let columnLabel = this.plateRows[0][this.selectedLine.index][0].label;
        this.plateRows.forEach((row) => {
          positions.push(`${row[0][0].label}${columnLabel}`);
        });
      } else if (this.selectedLine.line === 'row') {
        let rowLabel = this.plateRows[this.selectedLine.index][0][0].label;
        this.plateRows[0].forEach((column) => {
          positions.push(`${rowLabel}${column[0].label}`);
        });
      }
    }

    return positions;
  }),

  disableWellSelection: computed('disableSamplesList', 'allowAdapterSelection', function() {
    return this.disableSamplesList && !this.allowAdapterSelection;
  }),

  _highlightLine(row, column) {
    if (row === 0) {
      let samplesInColumn = [];

      this.plateRows.forEach((row) => {
        let sample = row[column][0];

        if (Object.prototype.hasOwnProperty.call(sample, 'id')) {
          samplesInColumn.push(sample);
        }
      });

      if (!isEmpty(samplesInColumn)) {
        set(this, 'currentLine', { line: 'column', index: column });
      }

    } else if (column === 0) {
      let samplesInRow = [];

      this.plateRows[row].forEach((row) => {
        let sample = row[0];

        if (Object.prototype.hasOwnProperty.call(sample, 'id')) {
          samplesInRow.push(sample);
        }
      });

      if (!isEmpty(samplesInRow)) {
        set(this, 'currentLine', { line: 'row', index: row });
      }
    }
  },

  _setLocationSelected(row, column) {
    if (isPresent(this.plateRows)) {
      this._highlightLine(row, column);

      if (this.currentLine) {
        set(this, 'selectedLine', this.currentLine);
      } else {
        set(this, 'selectedLine', null);

      }

      setProperties(this, {
        locationSelected: true,
        rowSelected: row,
        columnSelected: column
      });
    }
  },

  init() {
    this._super(...arguments);
    if (!this.disableSamplesList) {
      this._setLocationSelected(1, 1);
    }
  },

  scrollToSelectedSample() {
    const selectedSampleElement = document.querySelector('.well-location-samples .sample-selected');
    if (selectedSampleElement) {
      selectedSampleElement.scrollIntoView({
        behavior: 'smooth',
      });
    }
  },

  actions: {
    highlightLine(row, column) {
      this._highlightLine(row, column);
    },

    removeLine() {
      set(this, 'currentLine', null);
    },

    onSelectSample(location, sample, row, column) {
      let position = this.plateRows.objectAt(row).objectAt(column);

      let positionValues = [];

      if (this.allowMultipleSamplesForWell) {
        positionValues = position.filter((p) => p.identifier !== 'Empty');

        let sampleInPosition = position.filter((p) => p.identifier === this.sample?.identifier)[0];

        if (sampleInPosition) {
          positionValues.removeObject(sampleInPosition);

          if (positionValues.length === 0) {
            positionValues.pushObject({ identifier: 'Empty' });
          }
        } else {
          positionValues.pushObject({ id: this.sample?.id, identifier: this.sample?.identifier });
        }

      } else {
        positionValues.pushObject({ identifier: 'Empty' });

        if (position[0].identifier !== this.sample?.identifier) { // Changing the identifier for the same position
          positionValues = [{ id: this.sample?.id, identifier: this.smaple?.identifier }];
        }
      }

      position.clear();
      position.pushObjects(positionValues);

      this.onSelectSample(location, sample);
    },

    onSelectContainerWell(well, row, column) {
      let position = this.plateRows.objectAt(row).objectAt(column);
      let positionValue = position[0];

      positionValue.adapter_container_well = well;

      position.clear();
      position.pushObject(positionValue);
    },

    onRemoveAdapterContainer(adapterContainerWells) {
      let containerWells = flatten(this.plateRows);

      containerWells.forEach((w) => {
        let wellData = w[0];
        if (Object.prototype.hasOwnProperty.call(wellData, 'adapter_container_well') && adapterContainerWells.includes(wellData.adapter_container_well)) {
          delete wellData.adapter_container_well;
          w.clear();
          w.pushObject(wellData);
        }
      });
    },

    selectLocation(row, column) {
      debounce(this, this.scrollToSelectedSample, 300);
      this._setLocationSelected(row, column);
    }
  }
});
