/* eslint-disable ember/no-classic-components, ember/no-classic-classes, no-console, ember/no-actions-hash */
import Component from '@ember/component';
import { assert } from '@ember/debug';
import { alias } from '@ember/object/computed';
import ArrayProxy from '@ember/array/proxy';
import { A } from '@ember/array';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import parseQueryData from 'shared/utils/parse-query-data';
import layout from './template';

export default Component.extend({
  layout,
  tagName: '',

  store: service(),

  // Attributes
  organization: null,
  onUse: () => {},
  onCancel: () => {},

  folder: null,
  files: null,
  totalRows: 0,
  isLoading: alias('loadData.isRunning'),

  init() {
    this._super(...arguments);
    assert('`organization` is a required attribute', this.organization);
    this.set('files', A());
  },

  getFolderProxy() {
    const proxy = this._folderProxy || ArrayProxy.create({ content: [] });
    this.set('_folderProxy', proxy);

    return proxy;
  },

  getFileProxy() {
    const pool = this._pool || {};
    const proxy = pool[this.folder.id] || (pool[this.folder.id] = ArrayProxy.create({ content: [] }));
    this.set('_pool', pool);

    return proxy;
  },

  loadData: task(function *(params) {
    let model;

    console.log(params);
    const proxy = params.proxy;
    const query = parseQueryData(params);
    query.filter.organization_id = this.organization.id;

    if (this.folder) {
      model = 'instrument-file';
      query.filter.folder = this.folder.id;

    } else {
      model = 'instrument-folder';
    }

    const collection = yield this
      .store
      .query(model, query);

    this.set('totalRows', collection.meta.total_entries);
    this.set('data', collection);

    proxy.set('content', collection.toArray());
    return collection;
  })
    .restartable(),

  actions: {
    loadData(params) {
      if (this.folder) {
        params.proxy = this.getFileProxy();
      } else {
        params.proxy = this.getFolderProxy();
      }

      this.loadData.perform(params);

      return params.proxy;
    },

    moveUp() {
      this.set('folder', null);
    },

    selectFolder(folder) {
      this.set('totalRows', null);
      this.set('folder', folder);
    },

    selectFile(file) {
      if (this.files.includes(file)) {
        this.files.removeObject(file);
      } else {
        this.files.addObject(file);
      }
    },

    use() {
      this.onUse(this.files);
    },

    cancel() {
      this.onCancel();
    }
  }

});
