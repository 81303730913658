/* eslint-disable ember/no-classic-classes, quotes, brace-style */
import { alias } from '@ember/object/computed';
import Service from '@ember/service';
import config from 'ember-get-config';

export default Service.extend({
  config,

  portal: alias('config.settings.portalOrder'),
  portalOrder: alias('config.settings.portalOrder'),

  eventSource() {
    if (this.portal) {
      return "Portal";
    }
    else {
      return "LIMS";
    }
  }

});
