/* eslint-disable object-shorthand */
import { ActiveModelSerializer } from 'active-model-adapter';
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import { underscore } from '@ember/string';
import containerModels from 'shared/const/container-models';

const userKeys = ['owners', 'trainers'];

export default class extends ActiveModelSerializer.extend(EmbeddedRecordsMixin) {

  isEmbeddedRecordsMixinCompatible = true;
  containerKeys = containerModels.map(underscore);

  serialize(snapshot, options) {
    return super.serialize(snapshot, options);
  }

  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
  }

  isPrimaryType(store, typeName, primaryTypeClass) {
    // store.query('tube') has response payload
    //   { containers: [...] }
    if (typeName === 'container') {
      return containerModels.includes(primaryTypeClass.modelName);
    }
    // store.findRecord('container', id) has response payload
    //   { plate96: {...}, sample_states: [...] }
    if (primaryTypeClass.modelName === 'container') {
      return containerModels.includes(typeName);
    }
    return super.isPrimaryType(store, typeName, primaryTypeClass);
  }

  modelNameFromPayloadKey(payloadKey) {
    if (userKeys.includes(payloadKey)) {
      return super.modelNameFromPayloadKey('users');
    } else {
      return super.modelNameFromPayloadKey(payloadKey);
    }
  }

  payloadKeyFromModelName(modelName) {
    if (containerModels.includes(modelName)) {
      modelName = 'container';
    }
    return super.payloadKeyFromModelName(modelName);
  }
}
