/* eslint-disable ember/no-new-mixins, ember/no-get, space-in-parens */
// jscs:disable
import { isEmpty } from '@ember/utils';

import { get } from '@ember/object';
import { inject as service } from '@ember/service';
import Mixin from '@ember/object/mixin';

/**
 * @module
 * @augments ember/Mixin
 */
export default Mixin.create({
  router: service(),

  // -------------------------------------------------------------------------
  // Dependencies

  // -------------------------------------------------------------------------
  // Attributes

  /**
   * Route to direct user to when they have been denied access to a route
   *
   * @type {?String}
   */
  unableRoute: null,

  // -------------------------------------------------------------------------
  // Actions

  // -------------------------------------------------------------------------
  // Events

  // -------------------------------------------------------------------------
  // Properties

  /**
   * The behavior service used to check if behavior is allowed
   *
   * @type {module:addon/services/sl-behavior}
   */
  behaviorService: service('sl-behavior'),

  // -------------------------------------------------------------------------
  // Observers

  // -------------------------------------------------------------------------
  // Methods

  /**
   * Enforce permission-based access restrictions
   *
   * @function
   * @param {ember/RSVP/Promise} transition
   * @returns {undefined}
   */
  beforeModel(transition) {
    get(this, '_super')(...arguments);

    let permissionRoute = this.get('permissionRoute') || this.routeName;

    if (this.get('behaviorService' ).isUnable(permissionRoute, 'route' ) ) {
      const unableRoute = this.get('unableRoute' ) || 'unauthorized';

      console.error(`Permission denied for Route: ${permissionRoute}`);

      if (isEmpty(unableRoute ) ) {
        transition.abort();

      } else {
        this.router.transitionTo(unableRoute);
      }
    }
  }
});
