/* eslint-disable ember/no-new-mixins, quotes, brace-style, space-before-blocks, ember/no-get, dot-notation, semi */
import { inject as service } from '@ember/service';
import { task, timeout } from 'ember-concurrency';
import { get, set, computed } from '@ember/object';
import { gt, alias } from '@ember/object/computed';
import { copy } from 'ember-copy';
import Mixin from '@ember/object/mixin';

export default Mixin.create({
  store: service(),
  organization: null,
  displayColumns: null,
  displayColumnsSize: computed("displayColumns", function(){ return this.get('displayColumns').length; }),
  totalRows: 0,
  dateFormat: 'yyyy-mm-dd',
  fromLabel: 'From',
  toLabel: 'To',
  klass: 'date',
  defaultQuery: {},
  params: {},
  extraParams: {},
  disableCSVButton: false,
  queryIncludes: [],
  fields: [],
  data: [],
  updateParams: () => {},

  statusOptions: ['draft', 'received', 'held', 'processing', 'complete', 'delivered', 'delivered_modified', 'canceled'],
  signatureOptions: [{ text: 'Yes', value: '1' }, { text: 'No', value: '0' }],

  selectedStatus: null,
  selectedSignature: null,
  lastQuery: null,
  recordsFound: gt('totalRows', 0),

  notesEnabled: alias('organization.notesEnabled'),

  _setParams(paginationData, sortData, filterData) {
    const organizationId = this.get('organization.id');
    const queryIncludes = this.queryIncludes.join(',');
    const extraParams = this.extraParams;
    const fields = this.fields.join(',');

    const defaultQuery = copy(this.get('defaultQuery'), true);

    defaultQuery['organization_id_eq'] = organizationId;

    (filterData.columnFilters || []).forEach((columnCondition) => {
      if (columnCondition.filterUsing) {
        for (let query of Object.keys(columnCondition.filterUsing)) {
          let queryCondition = columnCondition.filterUsing[query];
          if (queryCondition) {
            defaultQuery[query] = columnCondition.filterUsing[query];
          }
        }
      }
    });

    const ransackOrder = [];
    sortData.forEach((columnSort) => {
      ransackOrder.push(columnSort.prop);
      ransackOrder.push(columnSort.direction);
    });
    defaultQuery['s'] = ransackOrder.join(" ");

    this.set('lastQuery', defaultQuery);

    const params = {
      organization_id: organizationId,
      q: defaultQuery,
      page: paginationData.pageNumber,
      per_page: paginationData.pageSize,
      fields,
      include: queryIncludes
    };

    set(this, 'params', Object.assign({}, params, extraParams));
    this.updateParams(this.params);
  },

  loadRequisitionsTask: task(function *({ paginationData, sortData, filterData }) {
    yield timeout(250);

    this._setParams(paginationData, sortData, filterData);

    const params = get(this, 'params');
    const requisitions = yield get(this, 'store').query('requisition', params)

    // we need to inform Yeti Table about the total number of rows
    // for pagination to work correctly. Check out the pagination guide.
    const recordsFound = requisitions.get('meta.total_entries');

    set(this, 'metaData', requisitions.get('meta'));
    set(this, 'totalRows', recordsFound);
    set(this, 'numRecords', requisitions.length);
    set(this, 'data', requisitions);
    return requisitions;
  }).restartable(),
});
