/* eslint-disable ember/no-classic-components, ember/no-mixins, ember/no-classic-classes, ember/require-tagless-components, key-spacing, ember/no-get, ember/require-return-from-computed, object-shorthand */
import { inject as service } from '@ember/service';
import { alias, sort } from '@ember/object/computed';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { isNone } from '@ember/utils';
import PlateStorageLocationOptions from 'shared/mixins/plate-storage-location-options';
import layout from './template';

export default Component.extend(PlateStorageLocationOptions, {
  layout,
  store: service(),

  // Attributes
  template: null,
  model: null,
  readOnly: false,
  // register: callback action

  providerSortKey: ['name'],
  panelSortKey:    ['name'],

  organization:       alias('model.organization'),
  providers:          sort('model.providers', 'providerSortKey'),
  physicians:         alias('model.physicians'),
  requisition:        alias('model.requisition'),
  resources:          alias('requisition.resources'),
  patient:            alias('requisition.patient'),
  physician:          alias('requisition.physician'),
  billingInformation: alias('requisition.billingInformation'),
  samples:            alias('requisition.samples'),

  // Samples
  sample1: computed('template.samples.[]', function() {
    let samples = this.get('template.samples');
    if (isNone(samples)) {
      return false;
    }
    return samples[0];
  }),

  sample2: computed('template.samples.[]', function() {
    let samples = this.get('template.samples');
    if (isNone(samples)) {
      return false;
    }
    return samples[1];
  }),

  sample3: computed('template.samples.[]', function() {
    let samples = this.get('template.samples');
    if (isNone(samples)) {
      return false;
    }
    return samples[2];
  }),

  sample4: computed('template.samples.[]', function() {
    let samples = this.get('template.samples');
    if (isNone(samples)) {
      return false;
    }
    return samples[3];
  }),

  s1: computed('samples.isFulfilled', function() {
    if (this.get('samples')) {
      return this.get('samples').objectAt(0);
    }
  }),

  s2: computed('samples.isFulfilled', function() {
    if (this.get('samples')) {
      return this.get('samples').objectAt(1);
    }
  }),

  s3: computed('samples.isFulfilled', function() {
    if (this.get('samples')) {
      return this.get('samples').objectAt(2);
    }
  }),

  s4: computed('samples.isFulfilled', function() {
    if (this.get('samples')) {
      return this.get('samples').objectAt(3);
    }
  }),

  // Sample States
  s1SampleState: computed('s1.sampleStates.[]', function() {
    return this._getSampleState('s1');
  }),
  s2SampleState: computed('s2.sampleStates.[]', function() {
    return this._getSampleState('s2');
  }),
  s3SampleState: computed('s3.sampleStates.[]', function() {
    return this._getSampleState('s3');
  }),
  s4SampleState: computed('s4.sampleStates.[]', function() {
    return this._getSampleState('s4');
  }),

  _getSampleState: function(name) {
    let sample = this.get(name);
    let store = this.get('store');

    if (isNone(sample)) {
      return;
    }

    if (sample.get('sampleStates.length') < 1) {
      sample.get('sampleStates').pushObject(store.createRecord('sample-state'));
    }

    return sample.get('sampleStates.firstObject');
  }

});
