/* eslint-disable ember/no-classic-classes, key-spacing */
import Model, { belongsTo, hasMany, attr } from '@ember-data/model';

export default Model.extend({
  // Associations
  user:     belongsTo('user', { async: true }),
  team:     belongsTo('team', { async: true }),
  membershipRoles: hasMany('membership-role', { async: true }),

  // Attributes
  added:    attr('date'),
  name:     attr('string'),
});
