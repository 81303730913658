/* eslint-disable ember/no-classic-components, ember/no-classic-classes */
import Component from '@ember/component';
import { assert } from '@ember/debug';
import layout from './template';

export default Component.extend({
  layout,
  tagName: '',

  isLoading: null,

  init() {
    this._super(...arguments);
    assert('Missing required `@isLoading` attr', this.isLoading !== null);
  }
});
