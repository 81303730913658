import Model, { belongsTo, attr } from '@ember-data/model';

export default class DownloadRequest extends Model {

  @belongsTo('organization') organization;
  @belongsTo('user') user;
  @belongsTo('resource', { async: false }) resource;

  // Attributes
  @attr('string') downloadType;
  @attr('string') state;
  @attr('datetime') createdAt;
  @attr('string') path;
  @attr parameters;
  @attr('boolean') removed;

  get isDownloadReady() {
    return this.state === 'ready';
  }

  get url() {
    return this.resource && this.resource.publicUrl;
  }

  get name() {
    return this.resource && this.resource.name;
  }

}
