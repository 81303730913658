/* eslint-disable quotes, no-multiple-empty-lines, ember/no-get, ember/no-actions-hash */
import { reads } from '@ember/object/computed';
import { computed, get, set } from '@ember/object';
import { isPresent } from '@ember/utils';
import config from 'ember-get-config';
import BaseComponent from 'shared/components/requisitions/requisition-base/component';
import layout from "./template";


const { SUPPORTED_ETHNICITIES, SUPPORTED_RACES } = config;

const SEX_OPTIONS = [
  { value: 'male', name: 'Male' },
  { value: 'female', name: 'Female' },
  { value: 'undisclosed', name: 'Undisclosed' }
];


export default BaseComponent.extend({
  layout,
  classNames: ['patient-information', 'entry-details-content', 'row'],
  sexOptions: SEX_OPTIONS,
  ethnicityOptions: SUPPORTED_ETHNICITIES,
  raceOptions: SUPPORTED_RACES,
  templateKey: 'patient',
  readOnly: false,

  isVisible: computed('template', 'template.patient', function() {
    return isPresent(this.get('template.patient'));
  }),

  showSex: computed('template.patient.sex', function() {
    let sexConfiguration = get(this, 'template.patient.sex');

    if (isPresent(sexConfiguration)) {
      if (typeof sexConfiguration === 'object') {
        return sexConfiguration.display;
      } else {
        return sexConfiguration;
      }
    }

    return false;
  }),

  isSexRequired: computed('requiredFields', function() {
    return this.requiredFields.includes('sex');
  }),

  showEthnicity: computed('template.patient.ethnicity', function() {
    let ethnicityConfiguration = get(this, 'template.patient.ethnicity');

    if (isPresent(ethnicityConfiguration)) {
      if (typeof ethnicityConfiguration === 'object') {
        return ethnicityConfiguration.display;
      } else {
        return ethnicityConfiguration;
      }
    }

    return false;
  }),

  isEthnicityRequired: computed('requiredFields', function() {
    return this.requiredFields.includes('ethnicity');
  }),

  isRaceRequired: reads('isEthnicityRequired'),

  requiredFields: computed('template.fields', 'templateKey', function() {
    const templateKey = this.get('templateKey');
    const template = this.get(`template.${templateKey}`);

    return template
      .fields
      .flatten()
      .filterBy('required', true)
      .mapBy('key');
  }),

  _requirements() {
    let templateKey = this.get('templateKey');
    let template = this.get(`template.${templateKey}`);
    let suffix = this.get('propertyNameSuffix');
    let requirements = [];

    if (template && template.fields) {
      if (suffix) {
        requirements = template.fields.flatten().filterBy('required', true)
          .map((object) => {
            object.name = `${object.name} ${suffix}`;
            return object;
          });

      } else {
        requirements = template.fields.flatten().filterBy('required', true);
      }
    }

    if (get(this, 'isSexRequired') && !requirements.findBy('key', 'sex')) {
      requirements.push({ key: 'sex', name: 'Sex' });
    }

    if (get(this, 'isEthnicityRequired') && !requirements.findBy('key', 'ethnicity')) {
      requirements.push({ key: 'ethnicity', name: 'Ethnicity', valueType: 'string' });
    }

    if (get(this, 'isRaceRequired') && !requirements.findBy('key', 'race')) {
      requirements.push({ key: 'race', name: 'Race', valueType: 'string' });
    }

    return requirements;
  },

  init() {
    this._super(...arguments);

    if (this.model && this.model.isNew) {
      if (this.showSex) {
        this.model.set('sex', '');
      }

      if (this.showEthnicity) {
        this.model.set('ethnicity', '');
        this.model.set('race', '');
      }
    }
  },

  actions: {
    updateEthnicity(value) {
      set(this, 'model.ethnicity', value);
    },

    updateRace(value) {
      set(this, 'model.race', value);
    },

    updatePatientAttr(key, value) {
      let attr = {};
      let model = get(this, 'model');
      attr[value] = true;

      set(model, key, attr);
    }
  }

});
