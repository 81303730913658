/* eslint-disable ember/no-classic-classes, key-spacing, ember/no-get, indent, no-multi-spaces */
import Model, { hasMany, belongsTo, attr } from '@ember-data/model';
import { computed } from '@ember/object';

export default Model.extend({

  // Associations
  workflowDefinition:     belongsTo('workflow-definition'),
  organization:           belongsTo('organization'),
  workflowActivities:     hasMany('workflow-activity', { async: true }),
  activities:             hasMany('activity', { async: true, polymorphic: true }),
  workflowSamples:        hasMany('workflow-sample', { async: true }),
  workflowSampleResults:  hasMany('workflow-sample-result', { async: true }),
  samples:                hasMany('sample', { async: true }),
  samplesWithStateData:   hasMany('sample', { async: true }),
  reports:                hasMany('report', { async: true }),

  // Attributes
  maxBatchSize:                   attr('number'),
  batchName:                      attr('string'),
  status:                         attr('string'),
  workflowType:                   attr('string'),
  name:                           attr('string'),
  isRepeatWorkflow:               attr('boolean'),
  rejectCutOff:                   attr('number', { defaultValue: 3 }),
  createdAt:                      attr('date'),
  controlsConfiguration:          attr(),
  negativeControlWellLocations:   attr(),
  positiveControlWellLocations:   attr(),
  archived:                       attr('boolean'),
  archivable:                     attr('boolean'),
  currentActivity:                attr('string'),

  // Helper methods
  currentActivities: computed('workflowActivities.@each.status', function() {
    let workflowActivities = this.get('workflowActivities');

    let rootActivity = workflowActivities.findBy('parentIds.length', 0);
    let pendingActivities = workflowActivities.filterBy('status', 'pending');
    let candidates = pendingActivities.filterBy('parents.firstObject.status', 'completed');

    if (pendingActivities.length === 0) {
      return;
    }

    if (candidates.length) {
      return candidates;
    } else {
      return [rootActivity];
    }
  }),

  humanStatus: computed('status', function() {
    let status = this.get('status');

    // :pending, :processing, :ready_for_delivery, :completed
    switch (status) {
      case 'pending':            return 'Pending';
      case 'processing':         return 'Processing';
      case 'ready_for_delivery': return 'Ready for Delivery';
      case 'completed':          return 'Completed';
      default: return;
    }
  })

});
