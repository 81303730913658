/* eslint-disable ember/no-get, ember/require-computed-property-dependencies, max-len, ember/no-actions-hash */
import { inject as service } from '@ember/service';
import { alias, and } from '@ember/object/computed';
import { isEmpty, isPresent } from '@ember/utils';
import { set, get, computed } from '@ember/object';
import BaseComponent from 'shared/components/requisitions/requisition-base/component';
import layout from './template';
import { task, timeout } from 'ember-concurrency';
import ENV from 'ember-get-config';
import moment from 'moment';

const TIMEOUT = ENV.environment === 'test' ? 0 : 1000;

export default BaseComponent.extend({
  layout,
  classNames: ['row', 'requisition-physician'],

  store: service(),

  portalOrder: false,

  currentOrganization: service('current-organization'),
  organization: alias('currentOrganization.organization'),
  physician: alias('model.physician'),
  salesAccount: alias('model.salesAccount'),
  requisitionTemplate: alias('model.requisitionTemplate'),
  extendedPhysicians: null,
  defaultPhysicians: [],

  projectId: alias('model.projectId'),

  templateKey: 'physician',
  readOnly: false,

  salesAccountReadOnly: computed('readOnly', 'model.isNew', 'portalOrder', function() {
    return this.readOnly || (this.portalOrder && !this.model.get('isNew'));
  }),

  physicianReadOnly: computed('readOnly', 'salesAccount', function() {
    let readOnly = this.readOnly || false;
    let salesAccount = this.salesAccount;

    return readOnly || isEmpty(salesAccount) || isEmpty(get(salesAccount, 'id'));
  }),

  isVisible: computed('portalOrder', 'requisitionTemplate', 'template.physician', function() {
    let providerAccountsFilterEnabled = this.organization.providerAccountsFilterEnabled;
    let selectedTemplateId = this.requisitionTemplate.get('id');

    // to load sales account when the req template changes
    if (providerAccountsFilterEnabled && !this.portalOrder && selectedTemplateId !== this._selectedTemplateId) {
      this.reloadSalesAccounts();
    }

    return isPresent(this.get('template.physician')) || get(this.requisitionTemplate, 'isFormV1Template') || this.portalOrder;
  }),

  isRequired: computed('portalOrder', 'requirements.[]', 'template.salesAccount.enabled', function() {
    return this.portalOrder || get(this, 'template.salesAccount.enabled') || this.requirements.length > 0;
  }),

  minRequirements: computed('_dirtyAttributes', 'isRequired', 'model', 'template', function() {
    if (this.isRequired || this.model.get('salesAccount.id')) {
      return [
        { key: 'salesAccount.id', name: 'Provider Account' },
        { key: 'physician.id', name: 'Provider' }
      ];
    }

    return [];
  }),

  missingRequirements: computed('_dirtyAttributes', 'physician', 'requirements', function() {
    let model = this.physician;
    return this._missingFrom('requirements', model);
  }),
  hasOneSalesAccount: null,
  defaultSalesAccounts: null,
  salesAccounts: [],

  singleSalesAccount: and('portalOrder', 'hasOneSalesAccount'),

  searchSalesAccount: task(function *(search) {
    yield timeout(TIMEOUT);

    let params = {
      organization_id: this.organization.get('id'),
      fields: [
        'id',
        'name',
        'overridePatientConsentVersion',
        'patientConsentVersionId',
        'patientConsentOverride',
        'overrideMedicalConsentVersion',
        'medicalConsentVersionId',
        'medicalConsentOverride',
      ].join(','),
      per_page: 10,
      for_orders_portal: this.portalOrder,
      filter: {
        req_template_id: this.requisitionTemplate.get('id'),
        secondary_service_provider: false,
        project_id: this.projectId,
        search
      }
    };

    if (this.portalOrder) {
      params.include = [
        'sales-account-project-requisition-template-associations',
        'sales-account-project-requisition-template-association.requisition-template',
        'provider-account-groups'
      ].join(',');
    } else {
      params.include = 'provider-account-groups';
    }

    let salesAccountsOptions = yield this.store.query('sales-account', params);
    set(this, 'salesAccounts', salesAccountsOptions);

    if (!search && salesAccountsOptions.length === 1) {
      set(this, 'hasOneSalesAccount', true);
    }

    // to load the default options
    if (!this.defaultSalesAccounts) {
      set(this, 'defaultSalesAccounts', salesAccountsOptions);
    }
  }),

  searchPhysicians: task(function *(search) {
    yield timeout(TIMEOUT);

    let salesAccount = this.salesAccount;
    let organization = this.organization;
    let physicians = null;

    if (salesAccount.get('id')) {
      const params = {
        sales_account_id: salesAccount.get('id'),
        organization_id: organization.get('id'),
        filter: { search },
        page: 1,
        per_page: 10,
      };

      if (this.portalOrder) {
        params.for_orders_portal = true;
      }

      physicians = yield this.store.query('physician', params);
    }
    set(this, 'extendedPhysicians', physicians);

    if (isEmpty(search)) {
      set(this, 'defaultPhysicians', physicians);
    }
  }),

  extendedSalesAccounts: computed('salesAccount.archived', 'salesAccounts.isFulfilled', function() {
    let salesAccount = this.salesAccount;
    let salesAccounts = (this.salesAccounts || []).toArray();

    const selectedId = get(salesAccount, 'id');

    // Add the currently selected salesAccount to the result list if it's not included
    if (!isEmpty(selectedId) && undefined === (salesAccounts.find(account => account.get('id') === selectedId))) {
      salesAccounts.pushObject(salesAccount);

      return salesAccounts.sortBy('name');
    }

    return salesAccounts;
  }),

  formattedAttestationDate: computed('model.submittedAt', 'organization', 'requisition.submittedAt', function() {
    let organization = this.organization;

    let date = moment();
    if (isPresent(this.model.submittedAt)) {
      date = moment.unix(this.model.submittedAt);
    }

    return date.format(organization.dateOutputFormat);
  }),

  async init() {
    this._super(...arguments);

    if (typeof this.setPhysicianComponent === 'function') {
      this.setPhysicianComponent(this);
    }
    // set initial selected template id
    let selectedTemplateId = this.requisitionTemplate.get('id');
    this.set('_selectedTemplateId', selectedTemplateId);

    await this.searchSalesAccount.perform();

    if (!this.portalOrder) {
      this.searchPhysicians.perform();
    }

    if (this.portalOrder && this.model.get('isNew') && this.salesAccounts.length === 1) {
      this.model.set('salesAccount', this.salesAccounts.get('firstObject'));
      this.searchPhysicians.perform();
    }
  },

  restoreDefaultSalesAccountOptions() {
    set(this, 'salesAccounts', this.defaultSalesAccounts);
  },

  restoreDefaultPhysicians() {
    set(this, 'extendedPhysicians', this.defaultPhysicians);
  },

  reloadSalesAccounts() {
    this.set('_selectedTemplateId', this.requisitionTemplate.get('id'));
    this.set('extendedPhysicians', null);
    this.set('model.salesAccount', null);
    this.set('model.physician', null);
    this.set('defaultSalesAccounts', null);
    this.model.resetConsent();
    this.searchSalesAccount.perform();
  },

  actions: {
    updatePhysician(physician) {
      if (physician) {
        set(this.model, 'physician', physician);
      } else {
        set(this.model, 'physician', null);
        this.restoreDefaultPhysicians();
      }
      this._updateDirtyAttributes();
      this.change();
    },

    updateSalesAccount(salesAccount) {
      let model = this.model;

      if (salesAccount) {
        set(model, 'salesAccount', salesAccount);
        set(model, 'secondarySalesAccount', null);
        set(model, 'secondaryPhysician', null);
        this.searchPhysicians.perform();
      } else {
        set(model, 'salesAccount', null);
        set(this, 'extendedPhysicians', null);
        this.restoreDefaultSalesAccountOptions();
      }
      set(model, 'physician', null);
      model.resetConsent();
      this.change();
    },

    checkSearchProviderTextLength(text, /* select, event */) {
      //if search text was cleared. Restore the default options
      if (text.length === 0) {
        this.restoreDefaultSalesAccountOptions();
      }
    },

    checkSearchPhysicianTextLength(text, /* select, event */) {
      //if search text was cleared. Restore the default options
      if (text.length === 0) {
        this.restoreDefaultPhysicians();
      }
    },

    onCloseSalesAccount() {
      this.restoreDefaultSalesAccountOptions();
    },

    onClosePhysician() {
      this.restoreDefaultPhysicians();
    }

  }

});
