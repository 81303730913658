/* eslint-disable ember/no-mixins */
import ApplicationSerializer from 'shared/serializers/application';
import ResourceValidation from 'shared/mixins/resource-validation';

export default ApplicationSerializer.extend(ResourceValidation, {
  attrs: {
    trainingRequirements: { embedded: 'always' },
    document: { deserialize: 'ids', serialize: 'ids' },
    documentRevision: { deserialize: 'ids', serialize: false },
    trainers: { deserialize: 'ids', serialize: 'ids' },
    resources: { deserialize: 'ids', serialize: false },
    role: { deserialize: 'ids', serialize: 'ids' }
  }
});
