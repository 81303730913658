/* eslint-disable ember/no-new-mixins, ember/no-get */
import Mixin from '@ember/object/mixin';
import { inject as service } from '@ember/service';
import layout from './template';
import Error from '@ember/error';
import { typeOf } from '@ember/utils';
import { assert } from '@ember/debug';
import { computed } from '@ember/object';

/**
 * Extracted from https://github.com/softlayer/sl-ember-behavior which is no
 * longer maintained
 */
export default Mixin.create({
  behaviorService: service('sl-behavior'),

  layout,
  tagName: 'span',
  possible: true,

  isViewable(activity, resource, possible) {
    assert(
      'The mixins/component.isViewable() method should be implemented on the derived class'
    );

    // A return statement in case the user strips out Ember.assert when deploying.
    return Boolean(activity && resource && possible && false);
  },

  showContent: computed(
    'activity', 'behaviorService.behaviors', 'possible', 'resource',
    function() {
      const possible = this.get('possible');

      if ('boolean' !== typeOf(possible)) {
        throw new Error(
          'Expects `possible` property to be a Boolean'
        );
      }

      return this.isViewable(
        this.get('activity'),
        this.get('resource'),
        possible
      );
    }
  )
});
