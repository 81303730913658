/* eslint-disable ember/no-computed-properties-in-native-classes, ember/classic-decorator-hooks, max-len */
import BaseComponent from 'shared/components/requisitions/requisition-base/component';
import { action, set, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import layout from './template';

// Classic Component
// Need to change usage of BaseComponent for @glimmer/component
export default class RequisitionAdditionalProviderAccounts extends BaseComponent {
  @service store;
  @service flashMessages;

  layout = layout;
  classNames = ['requisition-additional-pas'];
  isVisible = true;

  @tracked currentProviderAccountId = null;

  @computed('model.additionalPhysicianSalesAccounts.[]', 'model.additionalPhysicianSalesAccounts.@each.{salesAccount,physician}')
  get additionalPhysicianSalesAccounts() {
    return this.model.additionalPhysicianSalesAccounts;
  }

  @computed('model.requisitionTemplate')
  get requisitionTemplateId() {
    return this.model.requisitionTemplate?.get('id');
  }

  @computed('providerAccount.id')
  get noMainProviderAccount() {
    return Boolean(!this.providerAccount?.get('id'));
  }

  init() {
    super.init(...arguments);

    set(this, 'currentProviderAccountId', this.providerAccount?.get('id'));
  }

  didReceiveAttrs() {
    super.didReceiveAttrs(...arguments);

    let newProviderAccountId = this.providerAccount?.get('id');

    // when the main provider account is changed
    if (this.currentProviderAccountId !== newProviderAccountId) {
      // set the new currentProviderAccountId
      set(this, 'currentProviderAccountId', newProviderAccountId);

      if (this.additionalPhysicianSalesAccounts.length > 0) {
        // remove all additional provider accounts
        set(this, 'model.additionalPhysicianSalesAccounts', []);
      }
    }
  }

  willDestroy() {
    super.willDestroy(...arguments);

    this.model.additionalPhysicianSalesAccounts.forEach((apsa) => {
      apsa?.rollbackAttributes();
    });

    this.store.peekAll('physician-sales-account').forEach((psa) => {
      psa?.rollbackAttributes();
    });
  }

  isCombinationSelected(provider, record) {
    let salesAccount = record.salesAccount;
    let isSelected = this.model.salesAccount.get('id') === record.salesAccount.get('id') &&
      this.model.physician.get('id') === provider.get('id');

    if (isSelected) {
      return true;
    }

    return this.model.additionalPhysicianSalesAccounts.any((record) => {
      return record.salesAccount.get('id') === salesAccount.get('id') &&
        record.physician.get('id') === provider.get('id');
    });
  }

  @action
  updateProvider(index, provider) {
    let record = this.model.additionalPhysicianSalesAccounts.objectAt(index);

    if (this.isCombinationSelected(provider, record)) {
      this.flashMessages.error('Provider Account / Provider combination has been selected already');
      return;
    }

    set(record, 'physician', provider);
  }

  @action
  updateProviderAccount(index, providerAccount) {
    set(this.model.additionalPhysicianSalesAccounts.objectAt(index), 'salesAccount', providerAccount);
    set(this.model.additionalPhysicianSalesAccounts.objectAt(index), 'physician', null);
  }

  @action
  addProviderAccount() {
    const newRecord = this.store.createRecord('physician-sales-account', {});
    this.model.additionalPhysicianSalesAccounts.pushObject(newRecord);
  }

  @action
  removeProviderAccount(record) {
    this.model.additionalPhysicianSalesAccounts.removeObject(record);
  }
}
