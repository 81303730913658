/* eslint-disable ember/no-classic-classes, key-spacing */
import Model, { hasMany, belongsTo, attr } from '@ember-data/model';

export default Model.extend({
  // Associations
  requisition:            belongsTo('requisition'),
  insuranceInformations:  hasMany('insurance-information'),

  // Attributes
  billTo:                 attr(),
  dateOfInjury:           attr('date'),
  name:                   attr('string'),
  email:                  attr('string'),
  phoneNumber:            attr('string'),
  memberId:               attr('string'),
  street:                 attr('string'),
  city:                   attr('string'),
  state:                  attr('string'),
  zip:                    attr('string')

});
