/* eslint-disable ember/no-classic-classes, quotes, ember/no-get */
import Model, { belongsTo, attr } from '@ember-data/model';
import { equal } from '@ember/object/computed';
import { computed } from '@ember/object';

export default Model.extend({
  // Associations
  document: belongsTo('document'),
  requisition: belongsTo('requisition'),
  role: belongsTo('role'),
  user: belongsTo('user'),
  digitalSignature: belongsTo('digitalSignature'),

  // Attributes
  status: attr('string', { defaultValue: 'pending' }),
  statusSetAt: attr('date'),
  signature: attr('string'),
  roleName: attr('string'),
  userName: attr('string'),
  permissions: attr(),
  comments: attr("string"),
  parentId: attr("number"),
  portalSignature: attr('boolean'),

  childIds: attr({
    defaultValue: () => {
      return [];
    }
  }),

  isRoot: equal('parentIds.length', 0),

  children: computed('childIds.[]', 'store', function() {
    let childIds = this.get('childIds');

    return childIds.map((id) => {
      return this.store.peekRecord('signature', id);
    });
  })

});
