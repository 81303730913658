import ApplicationSerializer from 'shared/serializers/application';
import config from 'ember-get-config';

export default ApplicationSerializer.extend({

  /**
   * `config` property on {Model.TestConfiguration} is a JSON object coming
   * from the backend that gets mapped to a polymorphic fragment model on the
   * frontend. Originally - there was no type on these JSON objects. This allows
   * backwards compatibility with older JSON hashes for `cnofig` by appending
   * the `$type` property required for polymorphism.
   */
  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    if (payload.test_configurations) {
      for (let i = 0; i < payload.test_configurations.length; i++) {
        if (payload.test_configurations[i].config) {
          if (payload.test_configurations[i].test_type === config.CONSTANTS.ID) {
            payload.test_configurations[i].config.$type = 'infectious-disease-config';
          } else if (payload.test_configurations[i].test_type === config.CONSTANTS.RG) {
            payload.test_configurations[i].config.$type = 'resistance-gene-config';
          }
        }
      }
    }

    return this._super.apply(this, [store, primaryModelClass, payload, id, requestType]);
  }
});
