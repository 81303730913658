/* eslint-disable ember/no-classic-classes, key-spacing, brace-style, ember/no-get */
import Model, { attr, hasMany, belongsTo } from '@ember-data/model';
import { computed } from '@ember/object';

export default Model.extend({
  // Associations
  organization:         belongsTo('organization', { async: true }),
  testGeneAssociations: hasMany('test-gene-association', { async: false }),
  testGenes:            hasMany('test-gene', { async: false }),
  requisitionTemplateAssociations: hasMany('requisition-template-association', { async: true }),
  requisitionTemplates: hasMany('requisition-template', { async: true }),

  // Attributes
  name:               attr('string'),
  code:               attr('string'),
  key:                attr('string'),
  cptCode:            attr('string'),
  zCode:              attr('string'),
  testType:           attr('string'),
  loinc:              attr('string'),
  archived:           attr('boolean'),
  archivable:         attr('boolean'),
  expectedTatTime:    attr('number'),
  customAttributes: attr({ defaultValue: () => { return {}; } }),

  // Display Helper
  fullName: computed('name', 'code', function() {
    return `${this.get('name')} (${this.get('code')})`;
  })

});
