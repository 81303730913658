/* eslint-disable ember/no-new-mixins */
import Mixin from '@ember/object/mixin';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Mixin.create({

  currentOrganization: service(),
  organization: alias('currentOrganization.organization')

});
