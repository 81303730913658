import Base from 'ember-simple-auth/authenticators/base';
import { resolve, reject } from 'rsvp';
import { inject as service } from '@ember/service';

/**
 * Basic Auth0 Authenticator that acts as a catalyst between ember-simple-auth
 * and Auth0 login.
 **/
export default class Auth0Authenticator extends Base {
  @service auth0;

  async restore(data) {
    if (data?.accessToken) {
      this.auth0.setUser(data.accessToken);
      this.auth0.set('data.authenticated', data);

      return resolve(data);
    } else {
      data = await this.auth0.checkLogin();

      if (data) {
        return resolve(data);
      } else {
        return reject(false);
      }
    }
  }

  async authenticate() {
    await this.auth0.login();
  }

  async invalidate() {
    return await this.auth0.logout();
  }
}
