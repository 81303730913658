/* eslint-disable ember/no-classic-components, ember/no-mixins, ember/no-classic-classes, ember/require-tagless-components, ember/no-actions-hash, ember/no-get */
import Component from '@ember/component';
import { alias } from '@ember/object/computed';
import layout from './template';
import GroupedReports from 'shared/mixins/grouped-reports';

export default Component.extend(GroupedReports, {
  layout,
  organization: null,
  archived: null,
  requisition: null,

  reportOverallResultEnabled: alias('organization.reportOverallResultEnabled'),

  actions: {
    addQueuedJob() {
      this.get('addQueuedJob')();
    }
  }
});
