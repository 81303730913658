/* eslint-disable ember/no-new-mixins */
import { inject as service } from '@ember/service';
import Mixin from '@ember/object/mixin';
import Ember from 'ember';

export default Mixin.create({
  pusher: service(),
  store: service(),
  behaviorService: service('sl-behavior'),
  queuedJobsTracker: service(),
  eventLogsTracker: service(),

  pusherJobEvents: Object.freeze(['queued-job', 'sftp-requisition-export', 'organization-modified']),
  _wiredOrganization: null,

  updatePusherOrganization(organization) {
    if (!Ember.testing) {
      if (this._wiredOrganization) {
        this.pusher.unwire(this, this._wiredOrganization.uuid);
      }
      // Signature for wire is wire(target, channelName, events)
      if (organization) {
        this.pusher.wire(this, organization.uuid, this.pusherJobEvents);
        this.set('_wiredOrganization', organization);
      }
    }
  },

  actions: {
    queuedJob(message) {
      this.queuedJobsTracker.updateQueuedJob(message.message);
    },
    sftpRequisitionExport(message) {
      this.eventLogsTracker.updateEventLog('integration', message.message);
    },
    organizationModified(message) {
      if (!message.message?.organizationId) {
        return;
      }

      // Load the organization and behavior data again, so changes in access are reflected
      this.store.queryRecord('organization', { id: message.message.organizationId, reload: true });
      this.store.queryRecord('behavior-data', { organization_id: message.message.organizationId }).then(
        (record) => {
          this.behaviorService.setBehaviors(record.get('behaviors'));
        }
      );
    }
  }
});
