import { camelize } from '@ember/string';
import { typeOf } from '@ember/utils';
const { keys } = Object;

export function replaceKeys(obj, transform) {
  let type = typeOf(obj);
  if (type !== 'object' && type !== 'array') {
    return obj;
  }
  if (type === 'array') {
    return obj.map(item => replaceKeys(item, transform));
  }
  return keys(obj).reduce((prev, key) => {
    let item = obj[key];
    prev[transform(key)] = replaceKeys(item, transform);
    return prev;
  }, {});
}

export function camelizeKeys(obj) {
  return replaceKeys(obj, camelize);
}
