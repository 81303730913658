/* eslint-disable ember/no-mixins */

import ApplicationSerializer from 'shared/serializers/application';
import ResourceValidation from 'shared/mixins/resource-validation';

export default ApplicationSerializer.extend(ResourceValidation, {
  attrs: {
    salesReps: { serialize: 'ids', deserialize: 'ids' },
    providerAccountGroups: { serialize: 'ids', deserialize: 'ids' },
    salesGroups: { serialize: 'ids', deserialize: 'ids' },
    contacts: { serialize: 'records', deserialize: false },
    physicians: { serialize: 'records', deserialize: 'ids' },
    resources: { deserialize: 'records', serialize: false }
  }
});
