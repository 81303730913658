import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! template-lint-disable no-action prettier }}\n<BsDatepicker\n  @value={{@value}}\n  @onChange={{action this.onChange}}\n  @format={{@format}}\n  @placeholder={{@placeholder}}\n  data-test-id={{if @isFrom \"ui-table-filters-date-picker-from\" \"ui-table-filters-date-picker-to\"}} />\n\n{{#if @isFrom}}\n  <div class=\"input-group-middle\">\n    -\n  </div>\n{{/if}}", {"contents":"{{! template-lint-disable no-action prettier }}\n<BsDatepicker\n  @value={{@value}}\n  @onChange={{action this.onChange}}\n  @format={{@format}}\n  @placeholder={{@placeholder}}\n  data-test-id={{if @isFrom \"ui-table-filters-date-picker-from\" \"ui-table-filters-date-picker-to\"}} />\n\n{{#if @isFrom}}\n  <div class=\"input-group-middle\">\n    -\n  </div>\n{{/if}}","moduleName":"shared/components/ui/table/thead/row/filters/date-range/date-picker.hbs","parseOptions":{"srcName":"shared/components/ui/table/thead/row/filters/date-range/date-picker.hbs"}});
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { set } from '@ember/object';
import { action } from '@ember/object';

export default class DatePicker extends Component {
  tagName = '';

  @action
  onChange(value) {
    set(this, 'value', value);
  }
}
