/* eslint-disable ember/no-classic-classes, key-spacing, ember/no-get */
import Model, { attr, belongsTo } from '@ember-data/model';
import { computed } from '@ember/object';

export default Model.extend({
  // Associations
  organization:  belongsTo('organization', { async:  true }),

  // Associations
  workflowActivity:  belongsTo('workflow-activity', { async: false }),
  project:           belongsTo('project'),

  // Attributes
  status:           attr('string'),
  progress:         attr('number'),
  progressMax:      attr('number'),
  message:          attr('string'),
  label:            attr('string'),

  progressPercentage: computed('progressMax', 'progress', function() {
    let progressMax = this.get('progressMax');
    let progress = this.get('progress');

    if (progressMax > 0) {
      return Math.round((progress / progressMax) * 100);
    } else {
      return 0;
    }
  })

});
