/* eslint-disable ember/no-get, quotes */
import { helper as buildHelper } from '@ember/component/helper';
import { get } from '@ember/object';

export function hasFeature(params/*, hash*/) {
  let [organization, feature] = params;

  if (organization && get(organization, 'features')) {
    return get(get(organization, 'features'), feature) === "true";
  }

  return false;
}

export default buildHelper(hasFeature);
