/* eslint-disable ember/no-new-mixins, no-multi-spaces, ember/no-get, no-trailing-spaces, semi */
import Mixin from '@ember/object/mixin';
import { computed, get } from '@ember/object';
import { sanitize } from 'shared/helpers/sanitize';

/**
 * Mixin that defines settings used for the @mention functionality
 * throughout apps.
 *
 * This mixin MUST be used in tandem with {Mixins.CurrentUser}
 *
 * Settings defined by zurb/tributejs library: https://github.com/zurb/tribute
 */
export default Mixin.create({
  mentionOptions: [],

  listMentionOptions: computed('mentionOptions', 'currentUser.uuid', function()  {
    let options = get(this, 'mentionOptions');
    let currentUserId = get(this, 'currentUser.uuid');

    // escape options names
    let menuOptions = [];
    options.forEach(opt => {
      if (opt.uuid !== currentUserId) {
        let menuOpt = opt;
        if (opt.name) {
          menuOpt.name = sanitize(opt.name);
        }
        menuOptions.push(menuOpt);
      }      
    });
    return menuOptions;
  }),

  settings: computed('listMentionOptions', function() {
    return [{
      lookup: 'name',
      fillAttr: 'name',
      values: get(this, 'listMentionOptions'),
      trigger: '@',
      selectTemplate(item) {
        return `<user-mention uuid='${item.original.uuid}'>@${item.original.name}</user-mention>`
      },
      startWithSpace: false,
      menuItemLimit: 5
    }];
  })
});
