/* eslint-disable ember/no-get, ember/no-volatile-computed-properties */
import { computed } from '@ember/object';
import { isPresent } from '@ember/utils';
import BaseComponent from 'shared/components/requisitions/requisition-base/component';
import layout from './template';

export default BaseComponent.extend({
  layout,
  classNames: ['row', 'requisition-medical-necessity'],

  templateKey: 'medicalNecessity',
  readOnly: false,

  isVisible: computed('templateKey', function() {
    let templateKey = this.get('templateKey');
    return isPresent(this.get(`template.${templateKey}`));
  }).volatile(),

  header: computed('templateKey', function() {
    return `template.${this.get('templateKey')}.header`;
  }).volatile(),

  fields: computed('templateKey', function() {
    return `template.${this.get('templateKey')}.fields`;
  }).volatile(),

});
