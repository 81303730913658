/* eslint-disable ember/no-classic-classes, key-spacing, no-trailing-spaces */
import Model, { belongsTo, attr } from '@ember-data/model';
import { alias } from '@ember/object/computed';

export default Model.extend({
  // Associations
  document:     belongsTo('document'),
  user:         belongsTo('user'),
  requisition:  belongsTo('requisition'),

  // Attributes
  body:         attr('string'),
  date:         attr('string'),
  permissions:  attr(),
  documentReferenceId: attr('number'),

  forLabs:       attr('boolean'),
  forSales:      attr('boolean'),
  forPhysicians: attr('boolean'),

  // Helper Methods
  author: alias('user.name')
});
