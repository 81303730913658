/* eslint-disable ember/no-classic-classes, key-spacing */
import Model, { belongsTo, attr } from '@ember-data/model';

export default Model.extend({
  // Associations
  requisitionTemplate:   belongsTo('requisition-template'),
  globalCustomAttribute: belongsTo('global-custom-attribute'),
  requisitionTemplateSamplesGroup:   belongsTo('requisition-template-samples-group'),

  // Attributes
  lab:                   attr('string', { defaultValue: 'optional' }),
  portal:                attr('string', { defaultValue: 'optional' }),
  group:                 attr('string'),
  columnWidth:           attr('number', { defaultValue: 1 }),
  fieldOrder:            attr('number', { defaultValue: 0 }),
  previousGroupId:       attr('string')
});
