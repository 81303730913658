/* eslint-disable ember/no-classic-classes, key-spacing */
import Model, { belongsTo, attr } from '@ember-data/model';

export default Model.extend({
  // Associations
  organization: belongsTo('organization'),
  project:      belongsTo('project'),

  // Attributes
  name:         attr('string')
});
