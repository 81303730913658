/* eslint-disable ember/no-new-mixins, ember/no-get */
import Mixin from '@ember/object/mixin';
import { alias, equal, filterBy } from '@ember/object/computed';
import { computed } from '@ember/object';
import { isNone } from '@ember/utils';
import { assert } from '@ember/debug';

export default Mixin.create({
  _registeredComponents: [],
  events: {},

  isLegacyTemplate: equal('requisitionTemplate.templateType', 'legacy'),
  isFormV1Template: equal('requisitionTemplate.templateType', 'form_v1'),
  notes: alias('model.notes'),
  notesEnabled: alias('organization.notesEnabled'),
  organization: alias('model.organization'),
  registeredComponents: filterBy('_registeredComponents', 'isDestroyed', false),
  requisition: alias('model.requisition'),
  requisitionTemplate: alias('requisition.requisitionTemplate'),
  templates: alias('model.templates'),

  template: computed('requisitionTemplate.template', function() {
    let template = this.get('requisitionTemplate.template');

    if (isNone(template)) {
      return null;
    }

    return JSON.parse(template);
  }),

  actions: {
    register(component) {
      this._registeredComponents.pushObject(component);
    },

    unregister(component) {
      this._registeredComponents.removeObject(component);
    },

    subscribe(event, action) {
      let events = this.get('events');
      events[event] = events[event] || [];
      events[event].pushObject(action);
    },

    publish(event, ...args) {
      let events = this.get('events');
      let actions = events[event] || [];

      actions.forEach((action) => {
        if (typeof action === 'function') {
          action(...args);
        }
      });
    },

    updateTemplate() {
      assert('This method does not exist on parent {Mixin.SharedProperties}', false);
    }
  }

});
