/* eslint-disable ember/no-get, ember/no-side-effects, no-multi-spaces, max-len, brace-style, ember/no-assignment-of-untracked-properties-used-in-tracking-contexts, ember/no-component-lifecycle-hooks */
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { A } from '@ember/array';
import ArrayProxy from '@ember/array/proxy';
import { copy } from 'ember-copy';
import { next } from '@ember/runloop';
import { set, get, computed } from '@ember/object';
import RequestedTestsComponent from '../form-v1/requested-tests/component';
import { task, taskGroup } from 'ember-concurrency';
import config from 'ember-get-config';
import layout from './template';

const { CONSTANTS } = config;

const {
  SCREEN,
  CONFIRMATION,
  SVT,
  CONFIRM_ALWAYS
} = CONSTANTS;

export default RequestedTestsComponent.extend({
  layout,

  store: service(),

  // Attributes
  showTitle: true,

  // Override
  requestedTests: alias('model.reflexedTests'),

  _medications: null,
  _filteredTestPanels: null,

  subscribe: () => {},

  customAttributes: alias('model.customAttributes'),
  testType: alias('model.testType'),

  filteredTypeTestPanels: computed('_filteredTestPanels', 'filteredTestPanels.[]', function() {
    const content = get(this, '_filteredTestPanels') || A();
    const proxy = new ArrayProxy({ content });

    set(this, '_filteredTestPanels', content);

    return proxy;
  }),

  medicationAdded(medication) {
    this
      .get('_medications')
      .pushObject(medication);

    this.get('_addPanels').perform(medication);
  },

  medicationUpdated(medication) {
    const id   = get(medication, 'id');
    const type = get(medication, 'type');
    const medications = this.get('_medications');
    const oldMedication = medications
      .filter((medicationOrDrug) => {
        return (!type && get(medicationOrDrug, 'id') === id) ||
                (type && get(medicationOrDrug, 'id') === id && get(medicationOrDrug, '_internalModel.modelName') === type);
      })[0];

    this.medicationRemoved(oldMedication);
    this.medicationAdded(medication);
  },

  medicationRemoved(medication) {
    this
      .get('_medications')
      .removeObject(medication);

    this.get('_removePanels').perform(medication);
  },

  // jscs:disable disallowSpacesInFunction
  panelUpdates: taskGroup().enqueue(),

  _addPanels: task(function *(medicationPT) {
    const _filteredTestPanels = this.get('_filteredTestPanels');
    const store = this.get('store');
    const addedPanels = A();

    const id   = get(medicationPT, 'id');
    const type = get(medicationPT, 'type') || 'medication';
    const medication = yield store.findRecord(type, id);
    const panels     = yield get(medication, 'testPanels');

    if (get(medicationPT, 'screen')) {
      const screenPanels = panels.filterBy('testType', SCREEN);

      if (screenPanels.length && !_filteredTestPanels.any((panel) => { return screenPanels.includes(panel); })) {
        addedPanels.pushObject(screenPanels[0]);
      }
    }

    if (get(medicationPT, 'confirmation') === CONFIRM_ALWAYS) {
      const confirmationPanels = panels.filterBy('testType', CONFIRMATION);

      if (confirmationPanels.length && !_filteredTestPanels.any((panel) => { return confirmationPanels.includes(panel); })) {
        addedPanels.pushObject(confirmationPanels[0]);
      }
    }

    addedPanels
      .forEach((panel) => {
        this.updateSelectedTestPanel(get(panel, 'key'));
        this.set('selectedTestPanel', get(panel, 'key'));
      });

    _filteredTestPanels.pushObjects(addedPanels);

    set(this, '_filteredTestPanels', _filteredTestPanels);
  }).group('panelUpdates'),

  _addSvtPanels: task(function *() {
    const testPanels = yield this.get('filteredTestPanels');
    const _filteredTestPanels = this.get('_filteredTestPanels');
    const addedPanels = A();

    // Always add SVT panel
    let svtPanels = testPanels.filterBy('testType', SVT);
    let svtPanel  = svtPanels.get('firstObject');

    if (svtPanel && !_filteredTestPanels.includes(svtPanel)) {
      addedPanels.pushObject(svtPanel);
    }

    addedPanels
      .forEach((panel) => {
        this.updateSelectedTestPanel(get(panel, 'key'));
        this.set('selectedTestPanel', get(panel, 'key'));
      });

    _filteredTestPanels.pushObjects(addedPanels);

    set(this, '_filteredTestPanels', _filteredTestPanels);
  }),

  _removePanels: task(function *(medicationPT) {
    const store = this.get('store');
    const _filteredTestPanels = this.get('_filteredTestPanels');
    const medicationsPT = this.get('model.medicationsPT');
    const requestedTests = this.get('requestedTests');
    const otherTestPanels = A();

    const isCandidate = function(panel) {
      return requestedTests[get(panel, 'key')];
    };

    for (let idx = 0; idx < medicationsPT.length; idx++) {
      if (medicationPT !== medicationsPT[idx]) {
        let medication = yield store.findRecord(medicationsPT[idx].type || 'medication', medicationsPT[idx].id);
        let testPanels = yield get(medication, 'testPanels');
        let filteredTestPanels = testPanels.filter(isCandidate);

        otherTestPanels.pushObjects(filteredTestPanels);
      }
    }

    let id   = get(medicationPT, 'id');
    let type = get(medicationPT, 'type') || 'medication';
    let medication = yield store.findRecord(type, id);
    let testPanels = yield get(medication, 'testPanels');

    let removedPanels = testPanels
      .filter((panel) => {
        return isCandidate(panel) && !otherTestPanels.includes(panel);
      });

    removedPanels
      .forEach((panel) => {
        this.updateSelectedTestPanel(get(panel, 'key'));
        this.set('selectedTestPanel', get(panel, 'key'));
      });

    _filteredTestPanels.removeObjects(removedPanels);

    set(this, '_filteredTestPanels', _filteredTestPanels);
  }).group('panelUpdates'),
  // jscs:enable disallowSpacesInFunction

  init() {
    this._super(...arguments);
    this._medications = A();
    this._filteredTestPanels = A();
  },

  didReceiveAttrs() {
    this._super(...arguments);

    const isVisible = this.get('isVisible');
    const subscribe = this.get('subscribe');
    const medicationsPT = this.get('model.medicationsPT');
    const requestedTests = this.get('requestedTests');
    const testPanels = this.get('filteredTestPanels');

    const selectedTestPanels = testPanels
      .filter((panel) => {
        return requestedTests[get(panel, 'key')];
      });

    this
      .get('_medications')
      .clear()
      .pushObjects(copy(medicationsPT, true));

    this
      .get('_filteredTestPanels')
      .clear()
      .pushObjects(selectedTestPanels);

    next(() => {
      this.get('_addSvtPanels').perform();
    });

    if (isVisible && typeof subscribe === 'function') {
      next(this, () => {
        subscribe('medicationAdded', this.medicationAdded.bind(this));
        subscribe('medicationRemoved', this.medicationRemoved.bind(this));
        subscribe('medicationUpdated', this.medicationUpdated.bind(this));
      });
    }
  }

});
