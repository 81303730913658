/* eslint-disable key-spacing, indent, ember/no-get */
import { belongsTo, hasMany, attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { alias, notEmpty } from '@ember/object/computed';
import DocumentBase from 'shared/models/document-base';

export default DocumentBase.extend({
  // Associations
  document:   belongsTo('document'),
  signatures: hasMany('signature'),

  // Attributes
  adminSignatureAt:           attr('string'),
  customerSignatureAt:        attr('string'),
  adminSignature:             attr('string'),
  customerSignature:          attr('string'),
  adminSignatureProvided:     attr('string'),
  customerSignatureProvided:  attr('string'),
  approved:                   attr('boolean'),
  date:                       attr('string'),
  revisionStatus:             attr('string'),

  // Delegation
  uuid:                       alias('document.uuid'),
  name:                       alias('document.name'),
  // Deprecated
  adminSignatureRequired:     alias('document.adminSignatureRequired'),
  customerSignatureRequired:  alias('document.customerSignatureRequired'),

  // Helper methods
  hasResource:                notEmpty('resourceUrl'),
  hasSignatures:              notEmpty('signatures'),
  revision:                   computed('rev', function() {
                                return `v${this.get('rev')}`;
                              })
});
