/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components, ember/no-get, key-spacing, max-len, ember/use-brace-expansion, ember/no-side-effects, no-trailing-spaces, ember/no-actions-hash */
import Component from '@ember/component';
import { alias } from '@ember/object/computed';
import { computed, set } from '@ember/object';
import { FORM_OPTIONS } from './constants';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import layout from './template';
import moment from 'moment';

const REPORT_EMAIL_FREQUENCY_OPTIONS = [
  'Instantly',
  'Daily',
];
const REPORT_EMAIL_TIME_OPTIONS = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
];

export default Component.extend({
  layout,
  classNames: ['notification-settings'],

  flashMessages: service(),
  settings: service(),
  router: service(),

  model: null,
  user: alias('model.user'),
  userPreference: alias('model.userPreference'),
  organization: alias('model.organization'),
  timeInputFormat: alias('organization.timeInputFormat'),
  orderEmailFrequency: alias('userPreference.orderEmailFrequency'),
  orderEmailTime: alias('userPreference.orderEmailTime'),
  externalOrderEmailFrequency: alias('userPreference.externalOrderEmailFrequency'),
  externalOrderEmailTime: alias('userPreference.externalOrderEmailTime'),
  reportCompletedEmailFrequency: alias('userPreference.reportCompletedEmailFrequency'),
  reportCompletedEmailTime: alias('userPreference.reportCompletedEmailTime'),
  reportSignRequiredEmailFrequency: alias('userPreference.reportSignRequiredEmailFrequency'),
  reportSignRequiredEmailTime: alias('userPreference.reportSignRequiredEmailTime'),
  reportFailedEmailFrequency: alias('userPreference.reportFailedEmailFrequency'),
  reportFailedEmailTime: alias('userPreference.reportFailedEmailTime'),

  frequencyOptions: REPORT_EMAIL_FREQUENCY_OPTIONS,
  defaultFrequency: REPORT_EMAIL_FREQUENCY_OPTIONS.firstObject,
  timeOptions: computed('timeInputFormat', function() {
    const timeInputFormat = this.get('timeInputFormat');
    const baseDate = moment().startOf('day');

    const options = REPORT_EMAIL_TIME_OPTIONS.map((hour) => {
      const time = baseDate.hour(hour);

      return { label: time.format(timeInputFormat), value: time.format('HH:mm') };
    });

    return options;
  }),
  defaultTime:  computed('timeOptions', function() {
    const options = this.get('timeOptions');

    return options.length > 8 ? options[8] : options[0];
  }),

  formComponents: computed('model.organization.notesEnabled', 'model.userPreference.notificationsEnabled', 'organization.notesEnabled', 'settings.portal', 'userPreference.notificationsEnabled', function() {
    const notesEnabled = this.get('model.organization.notesEnabled');
    const roleEnabled = this.get('model.userPreference.notificationsEnabled') || this.settings.portal;
    let options = JSON.parse(JSON.stringify(FORM_OPTIONS));

    if (this.settings.portal) {
      options = options.reject((option) => {
        return option.group === 'SYSTEM_OPTION' && !(option.key === 'sendReportCompletedEmail' || option.key === 'reportCompletedEmailFrequency');
      });
    } else {
      options = options.reject((option) => {
        return option.key === 'sendReportCompletedEmail' || option.key === 'reportCompletedEmailFrequency';
      });
    }

    if (notesEnabled || roleEnabled) {
      options.map((option) => {
        if (option.group === 'SYSTEM_OPTION' && roleEnabled) {
          set(option, 'hidden', !option.hidden);
        } else if (option.group === 'COMMENT_OPTION' && notesEnabled) {
          set(option, 'hidden', !option.hidden);
        }
        return option;
      });
    }
    return options;
  }),

  selectedOrderFrequency: computed('orderEmailFrequency', 'defaultFrequency', function() {
    const selected = this.get('orderEmailFrequency');

    return !isEmpty(selected) ? selected : this.get('defaultFrequency');
  }),

  selectedOrderTime: computed('orderEmailTime', 'defaultTime', function() {
    const selectedTime = this.get('orderEmailTime');
    const selectedOption = this.findTimeOption(selectedTime);

    if (isEmpty(selectedOption)) {
      const timeOption = this.get('defaultTime');
      this.set('orderEmailTime', this.convertTimeOptionToDate(timeOption));

      return timeOption;
    }

    return selectedOption;
  }),

  selectedExternalOrderFrequency: computed('externalOrderEmailFrequency', 'defaultFrequency', function() {
    const selected = this.get('externalOrderEmailFrequency');

    return !isEmpty(selected) ? selected : this.get('defaultFrequency');
  }),

  selectedExternalOrderTime: computed('externalOrderEmailTime', 'defaultTime', function() {
    const selectedTime = this.get('externalOrderEmailTime');
    const selectedOption = this.findTimeOption(selectedTime);

    if (isEmpty(selectedOption)) {
      const timeOption = this.get('defaultTime');
      this.set('externalOrderEmailTime', this.convertTimeOptionToDate(timeOption));

      return timeOption;
    }

    return selectedOption;
  }),

  selectedReportCompletedFrequency: computed('reportCompletedEmailFrequency', 'defaultFrequency', function() {
    const selected = this.get('reportCompletedEmailFrequency');

    return !isEmpty(selected) ? selected : this.get('defaultFrequency');
  }),

  selectedReportCompletedTime: computed('reportCompletedEmailTime', 'defaultTime', function() {
    const selectedTime = this.get('reportCompletedEmailTime');
    const selectedOption = this.findTimeOption(selectedTime);

    if (isEmpty(selectedOption)) {
      const timeOption = this.get('defaultTime');
      this.set('reportCompletedEmailTime', this.convertTimeOptionToDate(timeOption));

      return timeOption;
    }

    return selectedOption;
  }),

  selectedReportSignRequiredFrequency: computed('reportSignRequiredEmailFrequency', 'defaultFrequency', function() {
    const selected = this.get('reportSignRequiredEmailFrequency');

    return !isEmpty(selected) ? selected : this.get('defaultFrequency');
  }),

  selectedReportSignRequiredTime: computed('reportSignRequiredEmailTime', 'defaultTime', function() {
    const selectedTime = this.get('reportSignRequiredEmailTime');
    const selectedOption = this.findTimeOption(selectedTime);

    if (isEmpty(selectedOption)) {
      const timeOption = this.get('defaultTime');
      this.set('reportSignRequiredEmailTime', this.convertTimeOptionToDate(timeOption));

      return timeOption;
    }

    return selectedOption;
  }),

  selectedReportFailedFrequency: computed('reportFailedEmailFrequency', 'defaultFrequency', function() {
    const selected = this.get('reportFailedEmailFrequency');

    return !isEmpty(selected) ? selected : this.get('defaultFrequency');
  }),

  selectedReportFailedTime: computed('reportFailedEmailTime', 'defaultTime', function() {
    const selectedTime = this.get('reportFailedEmailTime');
    const selectedOption = this.findTimeOption(selectedTime);

    if (isEmpty(selectedOption)) {
      const timeOption = this.get('defaultTime');
      this.set('reportFailedEmailTime', this.convertTimeOptionToDate(timeOption));

      return timeOption;
    }

    return selectedOption;
  }),

  findTimeOption(datetime) {
    const selectedLabel = moment(datetime).format(this.timeInputFormat);

    return this.timeOptions.find(option => option.label === selectedLabel);
  },

  convertTimeOptionToDate(option) {
    const time = option.value;
    const nowLocalDate = moment().format('YYYY-MM-DD');

    return moment(`${nowLocalDate} ${time}`).toDate();
  },

  actions: {
    updateOrderTime(option) {
      set(this.userPreference, 'orderEmailTime', this.convertTimeOptionToDate(option));
    },

    updateExternalOrderTime(option) {
      set(this.userPreference, 'externalOrderEmailTime', this.convertTimeOptionToDate(option));
    },

    updateReportCompletedTime(option) {
      set(this.userPreference, 'reportCompletedEmailTime', this.convertTimeOptionToDate(option));
    },

    updateReportSignRequiredTime(option) {
      set(this.userPreference, 'reportSignRequiredEmailTime', this.convertTimeOptionToDate(option));
    },

    updateReportFailedTime(option) {
      set(this.userPreference, 'reportFailedEmailTime', this.convertTimeOptionToDate(option));
    },

    save(userPreference) {
      let messages = this.get('flashMessages');

      userPreference
        .save()
        .then(() => {
          messages.success('Notification settings updated');
        })
        .catch(() => {
          messages.error('Failed to update notification settings');
        });
    },
  }
});
