/* eslint-disable ember/no-classic-classes, key-spacing */
import Model, { belongsTo, attr } from '@ember-data/model';

export default Model.extend({
  // Associations
  organization: belongsTo('organization', { async: true }),
  testPanel:    belongsTo('test-panel', { async: true }),
  testGene:     belongsTo('test-gene', { async: false }),

  position: attr('number')

});
