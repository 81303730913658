/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components, ember/no-get, ember/no-side-effects, ember/no-actions-hash */
import Component from '@ember/component';
import { computed } from '@ember/object';
import layout from './template';

export default Component.extend({
  layout,
  received: null,
  model: null,

  init() {
    this._super(...arguments);
    let received = this.get('received');
    if (received) {
      this.set('disabled', true);
    }
  },

  disabled: computed('model', 'received', function() {
    let model = this.get('model');
    if (model) {
      let receivedChanged = model.changedAttributes().received;
      if (receivedChanged) {
        if (receivedChanged[0]) {
          this.set('received', true);
          return true;
        }
      }
    }
    return false;
  }),

  actions: {
    clicked() {
      if (this.get('received')) {
        this.set('received', false);
      } else {
        this.set('received', true);
      }
    }
  }

});
