/* eslint-disable max-len, ember/no-classic-classes, prefer-template, no-implicit-coercion */
import EmberObject, { computed } from '@ember/object';
import { isBlank } from '@ember/utils';
import { alias } from '@ember/object/computed';

const DATETIME_FIELDS = [
  'createdAtGteq', 'createdAtLteq', 'submittedAtGteq', 'submittedAtLteq', 'samplesDateReceivedGteq', 'samplesDateReceivedLteq',
  'dateReceivedGteq', 'dateReceivedLteq', 'requestDateGteq', 'requestDateLteq', 'collectionDateGteq',
  'collectionDateLteq', 'from', 'to', 'dateOfBirthGteq', 'dateOfBirthLteq', 'startedAtLteq', 'startedAtGteq',
  'reportSignedAtGteq', 'reportSignedAtLteq', 'reimbursementRequestsCreatedAtGteq', 'reimbursementRequestsCreatedAtLteq'
];

const PERMANENT_KEY = 'permanent';
const SESSION_KEY = 'session';

const PERMANTENT_PREFERENCES = [
  'pageSize', 'searchRequisitions', 'searchSamples', 'searchProviderAccounts', 'searchSalesReps', 'searchProviders', 'searchContainers'
];

export default EmberObject.extend({

  preferences: null,
  currentSession: null,
  routeName: null,

  userId: alias('currentSession.data.id'),

  userPrefix: computed('userId', function() {
    return `user-${(this.userId + '').replace(/\./g, '-')}`;
  }),

  routePrefix: computed('routeName', function() {
    return this.routeName.replace(/\./g, '/');
  }),

  defaultPreferences() {
    return {
      [PERMANENT_KEY]: {},
      [SESSION_KEY]: {}
    };
  },

  userPreferences() {
    let preferencesUser = this.preferences.get(this.userPrefix);
    return preferencesUser || this.defaultPreferences();
  },

  getPreferenceByKey(key) {
    let scope = this.getScopeKey(key);
    let userPreferences = this.userPreferences();
    let scopePreferences = userPreferences[scope] || {};
    let preferenceKey = `${this.routePrefix}:${key}`;

    return scopePreferences[preferenceKey];
  },

  setPreferenceByKey(key, value) {
    let scope = this.getScopeKey(key);

    let userPreferences = this.userPreferences();
    let scopePreferences = userPreferences[scope] || {};
    let preferenceKey = `${this.routePrefix}:${key}`;
    scopePreferences[preferenceKey] = value;
    userPreferences[scope] = scopePreferences;
    this.preferences.set(`${this.userPrefix}`, userPreferences);
  },

  getScopeKey(key) {
    if (PERMANTENT_PREFERENCES.includes(key)) {
      return PERMANENT_KEY;
    }
    return SESSION_KEY;
  },

  hasKeyMatching(pattern, scope) {
    scope = scope || SESSION_KEY;
    let userPreferences = this.userPreferences();
    let scopePreferences = userPreferences[scope] || {};
    let re = new RegExp(`${this.routePrefix}:${pattern}`);
    let keys = Object.keys(scopePreferences);

    return keys.any((key) => key.match(re) && scopePreferences[key]);
  },

  unknownProperty(key) {
    let preferenceValue = this.getPreferenceByKey(key);

    if (preferenceValue && DATETIME_FIELDS.includes(key)) {
      return new Date(preferenceValue);
    } else {
      return preferenceValue;
    }
  },

  setUnknownProperty(key, value) {
    // do not update object nor trigger observers if value is the same
    if (!this.alreadySetValue(key, value)) {
      this.setPreferenceByKey(key, value);
      this.notifyPropertyChange(key);
    }
    return value;
  },

  alreadySetValue(key, value) {
    let existingValue = this.unknownProperty(key);
    if (DATETIME_FIELDS.includes(key) && !isBlank(value) && !isBlank(existingValue)) {
      return value.getTime() === existingValue.getTime();
    } else {
      return value === existingValue;
    }
  },

  clearSessionPreferences() {
    let userPreferences = this.userPreferences();

    userPreferences[SESSION_KEY] = {};
    this.preferences.set(`${this.userPrefix}`, userPreferences);
  },

  initField(field, value) {
    if (!this.get(field)) {
      this.set(field, value);
    }
  }

});
