/* eslint-disable ember/no-mixins, max-len, ember/no-component-lifecycle-hooks, ember/no-get, ember/require-computed-property-dependencies, ember/no-actions-hash */
import { inject as service } from '@ember/service';
import { alias, equal, readOnly } from '@ember/object/computed';
import { computed, get } from '@ember/object';
import { isBlank, isNone } from '@ember/utils';
import { A } from '@ember/array';
import BaseComponent from '../../requisition-base/component';
import Tabs from 'shared/mixins/tabs-helper';
import layout from './template';
import { task } from 'ember-concurrency';

const PATIENT_PAY = 'Patient Pay';
const FACILITY_PAY = 'Facility Pay';
const BILL_INSURANCE = 'Bill Insurance';

const INSURANCE_FIELDS = {
  insuranceProvider: { key: 'insuranceProvider.id', name: 'Insurance' },
  idNumber: { key: 'idNumber', name: 'Insurance ID #' },
  subscriberNumber: { key: 'subscriberNumber', name: 'Insurance Subscriber #' },
  groupNumber: { key: 'groupNumber', name: 'Insurance Group #' },
  relationshipToInsured: { key: 'relationshipToInsured', name: 'Relationship To Insured' },
  nameOfPersonInsured: { key: 'nameOfPersonInsured', name: 'Name of Person Insured' },
  dobOfInsured: { key: 'dobOfInsured', name: 'Insured Date of Birth' },
  insuredEmail: { key: 'insuredEmail', name: 'Insured Email' },
  insuredStreetAddress: { key: 'insuredStreetAddress', name: 'Insured Street Address' },
  insuredCity: { key: 'insuredCity', name: 'Insured City' },
  insuredState: { key: 'insuredState', name: 'Insured State' },
  insuredZipCode: { key: 'insuredZipCode', name: 'Insured Zip Code' },
  insuredSex: { key: 'insuredSex', name: 'Insured Sex' },
  reimbursementEligibilityVerified: { key: 'reimbursementEligibilityVerified', name: 'Reimbursement Eligibility Verified' }
};

const PRE_AUTHORIZATIONS_FIELDS = {
  authorizationNumber: { key: 'authorizationNumber', name: 'Authorization Number' },
  effectiveStartDate: { key: 'effectiveStartDate', name: 'Effective Start Date' },
  effectiveEndDate: { key: 'effectiveEndDate', name: 'Effective End Date' },
  numAllowedTests: { key: 'numAllowedTests', name: 'Allowed Tests' },
  contactName: { key: 'contactName', name: 'Contact Name' },
  contactPhoneNumber: { key: 'contactPhoneNumber', name: 'Contact Phone Number' }
};

const CASH_PAY_FIELDS = {
  name: { key: 'model.name', name: 'Billing Name' },
  email: { key: 'model.email', name: 'Billing Email' },
  phoneNumber: { key: 'model.phoneNumber', name: 'Phone Number' },
  state: { key: 'model.state', name: 'Billing State' },
  city: { key: 'model.city', name: 'Billing City' },
  street: { key: 'model.street', name: 'Billing Street address' },
  zip: { key: 'model.zip', name: 'Billing Zip Code' },
};

export default BaseComponent.extend(Tabs, {
  layout,
  classNames: ['billing-information'],

  settings: service(),
  store: service(),

  patient: null,
  providers: null,
  readOnly: false,
  selectedTab: 'primaryInsurance',

  templateKey: 'billingInformation',

  preAuthorizationsRequired: alias('template.billingInformation.preAuthorizationsRequired'),

  didReceiveAttrs() {
    this._super(...arguments);

    // Initialize the Bill To option - display values are different than values
    // sent to API so we need to use a hash for Power Select
    const billTo = this.model.get('billTo');
    const billToOption = this.billToOptions.findBy('value', billTo);
    if (billToOption) {
      this.set('selectedBillTo', billToOption);
    }
  },

  secondaryBillInsuranceEnabled: computed('templateOptions.enabledBillings', function() {
    const availableOptions = this.templateOptions.enabledBillings;
    return availableOptions.includes('Secondary Bill Insurance');
  }),

  portalOrder: readOnly('settings.portalOrder'),

  requiredBillInsuranceFields: computed('{templateOptions,portalOrder}', function() {
    return this._fieldNamesFor('billInsurance', 'required');
  }),
  hiddenBillInsuranceFields: computed('{templateOptions,portalOrder}', function() {
    return this._fieldNamesFor('billInsurance', 'hidden');
  }),

  requiredPrimaryPreAuthorizationFields: computed('{templateOptions,portalOrder}', function() {
    return this._fieldNamesFor('primaryPreAuthorization', 'required');
  }),
  hiddenPrimaryPreAuthorizationFields: computed('{templateOptions,portalOrder}', function() {
    return this._fieldNamesFor('primaryPreAuthorization', 'hidden');
  }),

  requiredSecondaryBillInsuranceFields: computed('{templateOptions,portalOrder}', function() {
    return this._fieldNamesFor('secondaryBillInsurance', 'required');
  }),
  hiddenSecondaryBillInsuranceFields: computed('{templateOptions,portalOrder}', function() {
    return this._fieldNamesFor('secondaryBillInsurance', 'hidden');
  }),

  requiredSecondaryPreAuthorizationFields: computed('{templateOptions,portalOrder}', function() {
    return this._fieldNamesFor('secondaryPreAuthorization', 'required');
  }),
  hiddenSecondaryPreAuthorizationFields: computed('{templateOptions,portalOrder}', function() {
    return this._fieldNamesFor('secondaryPreAuthorization', 'hidden');
  }),

  requiredPatientPayFields: computed('{templateOptions,portalOrder}', function() {
    return this._fieldNamesFor('patientPay', 'required');
  }),
  hiddenPatientPayFields: computed('{templateOptions,portalOrder}', function() {
    return this._fieldNamesFor('patientPay', 'hidden');
  }),

  requiredFacilityPayFields: computed('{templateOptions,portalOrder}', function() {
    return this._fieldNamesFor('facilityPay', 'required');
  }),
  hiddenFacilityPayFields: computed('{templateOptions,portalOrder}', function() {
    return this._fieldNamesFor('facilityPay', 'hidden');
  }),

  isVisible: true,

  billToOptions: computed('templateOptions.enabledBillings', function() {
    const enabledBillings = get(this, 'templateOptions.enabledBillings') || [];
    const billTos = [];

    if (enabledBillings.includes(BILL_INSURANCE)) {
      billTos.push({ value: BILL_INSURANCE, text: 'Insurance' });
    }

    if (enabledBillings.includes(PATIENT_PAY)) {
      billTos.push({ value: PATIENT_PAY, text: 'Patient Bill' });
    }

    if (enabledBillings.includes(FACILITY_PAY)) {
      billTos.push({ value: FACILITY_PAY, text: 'Institutional Bill' });
    }

    return billTos;
  }),

  billInsuranceRequirements: computed('_dirtyAttributes', 'preAuthorizationsRequired', 'requiredBillInsuranceFields', 'requiredPrimaryPreAuthorizationFields', 'requiredSecondaryBillInsuranceFields', 'requiredSecondaryPreAuthorizationFields', 'secondaryBillInsuranceEnabled', 'secondaryInsurance.insuranceProvider', 'secondaryInsuranceFilled', 'this.{requiredBillInsuranceFields,requiredPrimaryPreAuthorizationFields,requiredSecondaryBillInsuranceFields,requiredSecondaryPreAuthorizationFields}',
    function() {

      // accessing primary and secondary insurance here is required to workaround a double mutation error
      // if none of the insurance fields are required. Basically if not accessed here only the secondary
      // insurance is accessed below and the primary insurance is accessed when the template is rendered
      // at that point the `informationInformations` array would get mutated for a second time
      this.primaryInsurance;
      this.secondaryInsurance;

      let requirements = this
        .requiredBillInsuranceFields
        .map((field) => {
          let { key, name } = INSURANCE_FIELDS[field];
          return { key: `primaryInsurance.${key}`, name: `Primary ${name}` };
        });

      let primaryPreAuthorizationRequirements = this
        .requiredPrimaryPreAuthorizationFields
        .map((field) => {
          let { key, name } = PRE_AUTHORIZATIONS_FIELDS[field];
          return { key: `primaryInsurance.preAuthorization.${key}`, name: `Primary ${name}` };
        });

      requirements = requirements.concat(primaryPreAuthorizationRequirements);

      if (this.secondaryBillInsuranceEnabled && this.secondaryInsuranceFilled) {
        let secondaryRequirements = this
          .requiredSecondaryBillInsuranceFields
          .map((field) => {
            let { key, name } = INSURANCE_FIELDS[field];
            return { key: `secondaryInsurance.${key}`, name: `Secondary ${name}` };
          });

        requirements = requirements.concat(secondaryRequirements);

        let secondaryPreAuthorizationRequirements = this
          .requiredSecondaryPreAuthorizationFields
          .map((field) => {
            let { key, name } = PRE_AUTHORIZATIONS_FIELDS[field];
            return { key: `secondaryInsurance.preAuthorization.${key}`, name: `Secondary ${name}` };
          });

        requirements = requirements.concat(secondaryPreAuthorizationRequirements);
      }

      if (this.preAuthorizationsRequired) {
        requirements.pushObject({
          key: 'primaryInsurance.preAuthorization.authorizationNumber',
          name: 'Primary Insurance Authorization Number'
        });
      }

      return requirements;
    }),

  secondaryInsuranceFilled: computed(
    'secondaryInsurance.{insuranceProvider,idNumber,subscriberNumber,groupNumber,relationshipToInsured,nameOfPersonInsured,dobOfInsured,insuredEmail,insuredStreetAddress,insuredCity,insuredState,insuredZipCode,insuredSex,reimbursementEligibilityVerified}',
    '_dirtyAttributes',
    function() {

      let secondaryInsurance = this.secondaryInsurance;

      let has_values_filled = !isBlank(secondaryInsurance.get('insuranceProvider.id')) ||
        !isBlank(secondaryInsurance.get('idNumber')) ||
        !isBlank(secondaryInsurance.get('subscriberNumber')) ||
        !isBlank(secondaryInsurance.get('groupNumber')) ||
        !isBlank(secondaryInsurance.get('relationshipToInsured')) ||
        !isBlank(secondaryInsurance.get('nameOfPersonInsured')) ||
        !isBlank(secondaryInsurance.get('dobOfInsured')) ||
        !isBlank(secondaryInsurance.get('insuredEmail')) ||
        !isBlank(secondaryInsurance.get('insuredStreetAddress')) ||
        !isBlank(secondaryInsurance.get('insuredCity')) ||
        !isBlank(secondaryInsurance.get('insuredState')) ||
        !isBlank(secondaryInsurance.get('insuredZipCode')) ||
        !isBlank(secondaryInsurance.get('insuredSex')) ||
        !isBlank(secondaryInsurance.get('reimbursementEligibilityVerified'));

      return has_values_filled;
    }),

  patientPayRequirements: computed('requiredPatientPayFields', 'this.requiredPatientPayFields', function() {
    return this
      .requiredPatientPayFields
      .map((field) => {
        return CASH_PAY_FIELDS[field];
      });
  }),

  facilityPayRequirements: computed('requiredFacilityPayFields', 'this.requiredFacilityPayFields', function() {
    return this
      .requiredFacilityPayFields
      .map((field) => {
        return CASH_PAY_FIELDS[field];
      });
  }),

  requirements: computed('_dirtyAttributes', 'billInsuranceRequirements', 'facilityPayRequirements', 'model.billTo', 'patientPayRequirements', 'requiredBillInsuranceFields', 'requiredFacilityPayFields', 'requiredPatientPayFields', 'template', 'templateOptions.enabledBillings', function() {
    let billTo = this.get('model.billTo');
    const requirements = A();

    const enabledBillings = get(this, 'templateOptions.enabledBillings') || [];
    let requiredFields = [];

    if (enabledBillings.length > 0) {
      enabledBillings.forEach(function(option) {

        switch (option) {
        case BILL_INSURANCE:
          requiredFields = requiredFields.concat(this.requiredBillInsuranceFields);
          break;
        case PATIENT_PAY:
          requiredFields = requiredFields.concat(this.requiredPatientPayFields);
          break;
        case FACILITY_PAY:
          requiredFields = requiredFields.concat(this.requiredFacilityPayFields);
          break;
        }
      }, this);
    }

    // require Billing Information iff any fields are required
    if (requiredFields.length > 0) {
      requirements.pushObject({ key: 'model.billTo', name: 'Billing Information' });
    }

    if (billTo) {
      switch (billTo) {
      case BILL_INSURANCE:
        requirements.pushObjects(this.billInsuranceRequirements);
        break;
      case PATIENT_PAY:
        requirements.pushObjects(this.patientPayRequirements);
        break;
      case FACILITY_PAY:
        requirements.pushObjects(this.facilityPayRequirements);
        break;
      }
    }

    return requirements;
  }),

  missingRequirements: computed('requirements', '_dirtyAttributes', function() {
    return this._missingFrom('requirements', this);
  }),

  patientPay: equal('model.billTo', PATIENT_PAY),
  facilityPay: equal('model.billTo', FACILITY_PAY),
  billInsurance: equal('model.billTo', BILL_INSURANCE),

  primaryInsurance: computed('model.insuranceInformations.[]', function() {
    return this._insuranceInformationByType('Primary');
  }),

  secondaryInsurance: computed('model.insuranceInformations.[]', function() {
    return this._insuranceInformationByType('Secondary');
  }),

  _fieldNamesFor(name, value) {
    const fields = this.get(`templateOptions.${name}.fields`);

    if (!fields) {
      return [];
    }

    return fields
      .filterBy(this.portalOrder ? 'portal' : 'lab', value)
      .mapBy('key');
  },

  _insuranceInformationByType(insuranceType) {
    let billingInformation = this.model;

    if (isNone(billingInformation.content)) {
      return;
    }

    let insuranceInformations = billingInformation.get('insuranceInformations') || [];
    let insuranceInformation = insuranceInformations.findBy('insuranceType', insuranceType);
    let store = this.store;

    if (isNone(insuranceInformation)) {
      insuranceInformation = store.createRecord('insurance-information', { billingInformation, insuranceType });
      insuranceInformations.pushObject(insuranceInformation);
    }

    return insuranceInformation;
  },

  loadAndFillProviderData: task(function *(providerAccountId) {
    const providerAccount = yield this.store.findRecord('sales-account', providerAccountId, { reload: true });

    let model = this.model;
    model.set('name', providerAccount.get('name'));
    model.set('phoneNumber', providerAccount.get('phoneNumber'));
    model.set('state', providerAccount.get('mailingAddressState'));
    model.set('city', providerAccount.get('mailingAddressCity'));
    model.set('street', providerAccount.get('mailingAddress'));
    model.set('zip', providerAccount.get('mailingAddressZipCode'));
    this._updateDirtyAttributes();
  }).drop(),

  clear() {
    this.primaryInsurance?.rollbackAttributes();
    this.secondaryInsurance?.rollbackAttributes();
  },

  actions: {
    fillPatientData() {
      let patient = this.patient;
      if (patient) {
        let model = this.model;
        let name = [patient.get('firstName'), patient.get('lastName')].join(' ');
        model.setProperties({
          name,
          email: patient.get('email'),
          phoneNumber: patient.get('phoneNumber'),
          state: patient.get('state'),
          city: patient.get('city'),
          street: patient.get('streetAddress'),
          zip: patient.get('zipCode'),
        });
        this._updateDirtyAttributes();
      }
    },

    fillProviderData() {
      let providerAccount = this.get('requisition.salesAccount');
      if (providerAccount) {
        this.loadAndFillProviderData.perform(providerAccount.get('id'));
      }
    },

    setBillTo(billTo) {
      this.set('selectedBillTo', billTo);
      this.model.set('billTo', billTo?.value);
    },

    triggerChange() {
      this.change();
    },
  }

});
