/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components, quotes, ember/no-jquery, ember/no-get */
import Component from '@ember/component';
import { get } from '@ember/object';
import $ from 'jquery';
import layout from './template';

export default Component.extend({
  layout,
  tagName: "a",
  target: '',
  click(event) {
    let link = $(this.element).find(event.target);

    if (!link.hasClass('active')) {
      let tab = link.parents('.sidebar-tab');

      tab.find('.sidebar-nav li').removeClass('active');
      link.parents('li').addClass('active');

      tab.find('.tab-pane').removeClass('active');
      $(get(this, 'target')).addClass('active');
    }
  }
});
