/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/no-actions-hash, ember/no-get, quotes */
import Component from '@ember/component';
import { set, get } from '@ember/object';
import layout from './template';

export default Component.extend({
  layout,
  tagName: '',

  // Attributes
  message: 'Are you sure?',
  warning: '',
  action: null,
  confirmationButtonClass: 'button-red',

  isActive: false,

  actions: {
    activate() {
      set(this, 'isActive', true);
    },

    confirm() {
      let action = get(this, 'action');

      let maybePromise;
      if (typeof action === "function") {
        maybePromise = action();
      }

      if (maybePromise && maybePromise.then) {
        this.set('isProcessing', true);
        maybePromise.then(() => {
          !this.isDestroyed && this.set('isProcessing', false);
        });
      }

      set(this, 'isActive', false);
    },

    cancel() {
      set(this, 'isActive', false);
    }
  }

});
