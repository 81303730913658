const containerModels = [
  'bag',
  'container',
  'destination',
  'flow-cell1-lane',
  'flow-cell2-lane',
  'flow-cell4-lane',
  'flow-cell8-lane',
  'nanoquant16',
  'open-array',
  'plate16',
  'plate24',
  'plate384',
  'plate48',
  'plate96',
  'source',
  'tube-box200',
  'tube-box36',
  'tube-box64',
  'tube-box72',
  'tube-box100',
  'tube-box',
  'tube-rack48',
  'tube-rack60',
  'tube-rack96',
  'tube-strip8',
  'tube8',
  'tube',
];

export default containerModels;
