export default class WellLocation {

  static X_POSITION = Object.freeze([
    '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12',
    '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24',
  ]);

  static Y_POSITION = Object.freeze([
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J',
    'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T',
  ]);

  static VALID_POSITIONS = Object.freeze(
    this.Y_POSITION.map(row => this.X_POSITION.map(col => `${row}${col}`)).flat()
  );

  static to_number(location, size = 12) {
    if (String(Number(location)) === location) {
      return Number(location);
    }
    const rowIndex = this.Y_POSITION.indexOf(location.charAt(0).toUpperCase());
    const colIndex = this.X_POSITION.indexOf(location.substring(1).padStart(2, '0'));
    return rowIndex * size + colIndex + 1;
  }
}
