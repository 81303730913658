/* eslint-disable ember/no-classic-classes, key-spacing */
import Model, { belongsTo, hasMany, attr } from '@ember-data/model';
import { mapBy } from '@ember/object/computed';

export default Model.extend({
  // Associations
  project:                               belongsTo('project'),
  reportConfiguration:                   belongsTo('report-configuration'),
  requisitionTemplate:                   belongsTo('requisition-template'),
  projectRequisitionTemplateAssociation: belongsTo('project-requisition-template-association'),
  configurationWorkflowTypes:            hasMany('configuration-workflow-type'),

  // Attributes
  configuration:                         attr(),
  displayName:                           attr('string'),
  requireSignature:                      attr('boolean'),
  requireSignatureCorrection:            attr('boolean'),
  includePharmacyGuidance:               attr('boolean'),
  signatureRequests:                     attr(),
  appendAddendumPage:                    attr('boolean'),
  customReportFilename:                  attr('string'),
  filenameAssociatedTests:               attr(),

  workflowTypes: mapBy('configurationWorkflowTypes', 'workflowType')
});
