/* eslint-disable ember/no-mixins, ember/no-classic-classes, no-multi-spaces, ember/no-get, no-trailing-spaces, object-shorthand, semi */
import Service from '@ember/service';
import { computed, get, getProperties } from '@ember/object';
import CurrentUser from 'shared/mixins/current-user';
import { sanitize } from 'shared/helpers/sanitize';

/**
 * Mixin that defines settings used for the @mention functionality
 * throughout apps.
 *
 * This mixin MUST be used in tandem with {Mixins.CurrentUser}
 *
 * Settings defined by zurb/tributejs library: https://github.com/zurb/tribute
 */
export default Service.extend(CurrentUser, {
  mentionOptions: [],

  listMentionOptions: computed('currentUser.uuid', 'mentionOptions', function()  {
    let data = [];

    let options = get(this, 'mentionOptions');

    if (!options) {
      return [];
    }

    let currentUserId = get(this, 'currentUser.uuid');
    options.forEach(function(member) {
      let { id, uuid, name } = getProperties(member, 'id', 'uuid', 'name');
      if (uuid !== currentUserId) {
        name = sanitize(name);
        data.push({ 'id': id, 'uuid': uuid, 'name': name });
      }
    });

    return data;
  }),

  settings: computed('listMentionOptions', function() {
    return [{
      lookup: 'name',
      fillAttr: 'name',
      trigger: '@',
      selectTemplate(item) {
        return `<user-mention uuid='${item.original.uuid}'>@${item.original.name}</user-mention>`
      },
      startWithSpace: false,
      menuItemLimit: 5
    }];
  })
});
