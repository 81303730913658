/* eslint-disable quotes */
export default function toBoolean(params/*, hash*/) {
  let stringBoolean;
  if (Array.isArray(params)) {
    stringBoolean = params[0] || '';
  } else {
    stringBoolean = params || '';
  }

  return stringBoolean.toString().toLowerCase() === "true";
}
