/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components, max-len, ember/no-get, dot-notation, quotes, ember/no-actions-hash, object-shorthand, indent */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { get } from '@ember/object';
import { alias } from '@ember/object/computed';
import layout from './template';

export default Component.extend({
  layout,
  store: service(),
  flashMessages: service(),

  organization: null,
  totalRows: 0,
  dateFormat: alias('organization.datePickerFormat'),
  fromLabel: 'From',
  toLabel: 'To',
  klass: 'date',
  defaultQuery: {},
  disableCSVButton: false,
  lastQuery: null,
  recordsFound: false,

  fields: 'id,identifier,template-name,created-at,sample-collection-date,date-received,turnaround_time,project-id,' +
    'archived,archivable,accession-status,processing-status,reporting-status,billing-status,status,sample-identifiers,' +
    'held-reasons-names,comment-count,admin-report-data',

  loadDataTask: task(function *({ paginationData, sortData, filterData }) {
    let organizationId = this.get('organization.id');

    let ransackQuery = {};

    filterData.columnFilters.forEach((columnCondition) => {
      if (columnCondition.filterUsing) {
        for (let query of Object.keys(columnCondition.filterUsing)) {
          let queryCondition = columnCondition.filterUsing[query];
          if (queryCondition) {
            ransackQuery[query] = columnCondition.filterUsing[query];
          }
        }
      }
    });

    let ransackOrder = [];
    sortData.forEach((columnSort) => {
      ransackOrder.push(columnSort.prop);
      ransackOrder.push(columnSort.direction);
    });

    ransackQuery['s'] = ransackOrder.join(" ");

    this.set('lastQuery', ransackQuery);

    let params = {
      organization_id: organizationId,
      q: ransackQuery,
      page: paginationData.pageNumber,
      per_page: paginationData.pageSize
    };

    let adminReportEntries = yield get(this, 'store').query('requisition-report-entry', params);
    let recordsFound = adminReportEntries.meta.total_entries;
    this.set('totalRows', recordsFound);
    this.set('recordsFound', recordsFound > 0);
    return adminReportEntries;
  }).restartable(),

  actions: {
    resetFilters() {
      this.get('preference').setProperties({
        identifier: '',
        requisitionTemplateName: '',
        ownerName: '',
        sampleIdentifier: '',
        createdAtGteq: '',
        createdAtLteq: '',
        collectionDateGteq: '',
        collectionDateLteq: '',
        sampleReceivedDateGteq: '',
        sampleReceivedDateLteq: '',
        sampleLabel: '',
        patientFirstName: '',
        patientLastName: '',
        patientDob: '',
        patientGender: '',
        providerName: '',
        providerNpi: '',
        providerAccountName: '',
        providerRepName: '',
        providerGroup: '',
      });
    },

    requestRequisitionReport() {
      let store = this.get('store');
      let messages = this.get('flashMessages');
      let organization = this.get('organization');
      let lastQuery = this.get('lastQuery');

      let record = store.createRecord('admin-report', {
        organization: organization,
        searchParams: lastQuery
      });

      record
      .save()
      .then(() => {
        messages.success('Request for Requisition Report has been Queued');
      }).catch((error) => {
        let message = error.message;
        if (error.payload && error.payload.message) {
          message = error.payload.message;
        }
        messages.error(message);
        console.error(error.message);
      });
    }
  }
});
