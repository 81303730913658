/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components, quotes, ember/no-component-lifecycle-hooks, space-before-blocks, ember/no-get, ember/no-actions-hash */
import Component from '@ember/component';
import { get } from '@ember/object';
import layout from './template';

export default Component.extend({
  layout,
  tagName: 'tr',

  showGenes: false,
  manualOverride: false,
  countSelectedTestsLabel: '0 Tests Selected',
  count: 0,

  didReceiveAttrs() {
    this._super(...arguments);
    this._countSelectedTests();
  },

  _testGeneSelected(testGene, testPanel, requestedTests, testGeneAssociations) {
    let testGeneKey = testGene.get('key');
    let value = get(requestedTests, testGeneKey);
    if (!value) {
      return false;
    }
    let associatedTestPanels = get(testGeneAssociations, testGeneKey);

    if (associatedTestPanels === undefined) {
      return false;
    }

    if (associatedTestPanels.length <= 1) {
      return true;
    } else {
      return get(requestedTests, testPanel.get('key'));
    }
  },

  _countSelectedTests() {
    let panel = this.panel;
    let count = 0;
    let context = this;
    let testGenes = this.panel.testGenes;
    let requestedTests = this.get('model.requestedTests');
    let testGeneAssociations = this.testGeneAssociations;
    testGenes?.forEach((gene) => {
      let selected = this._testGeneSelected(gene, panel, requestedTests, testGeneAssociations);
      if (selected) {
        count += 1;
      }
    });
    let countSelectedTestsLabel = `${count} Tests Selected`;
    if (count === 0) {
      countSelectedTestsLabel = '';
    } else if (count === 1) {
      countSelectedTestsLabel = `${count} Test Selected`;
    }
    context.set('countSelectedTestsLabel', countSelectedTestsLabel);
    context.set('count', count);
  },

  actions: {
    selectPanel() {
      if (!this.showGenes) {
        this.toggleProperty('showGenes');
      }
      this.onSelectPanel();
    },
    toggleGene(value) {
      this.onSelectGene(value);
      // select panel if not selected yet
      const panelKey = `requestedTests.${this.panel.key}`;
      if (value && !this.get(panelKey)) {
        this.toggleProperty(panelKey);
        this.updateAssociatedTestPanelsFor(this.panel.key, value);
      }

      // deselect panel if last selected test is unchecked
      if (this.count === 1 && this.get(`requestedTests.${value}`) === false) {
        this.onSelectPanel();
      }
    },
    toggleGeneDisplay() {
      this.toggleProperty('showGenes');
      this.set('manualOverride', true);
    }
  }
});
