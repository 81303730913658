/* eslint-disable ember/no-classic-classes */
import Service from '@ember/service';

export default Service.extend({

  getRouteForUrl(organization, url) {
    if (url.startsWith('https')) {
      url = url.split(/orgs\//).lastObject;
      return `/orgs/${url}`;
    }

    return `/orgs/${organization.get('id')}/${url}`;
  }

});
