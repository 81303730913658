/* eslint-disable ember/no-classic-components, ember/no-classic-classes, object-curly-spacing, max-len, ember/use-brace-expansion, keyword-spacing, indent, semi, ember/no-component-lifecycle-hooks, ember/no-actions-hash */
import Component from '@ember/component';
import { alias } from '@ember/object/computed';
import { computed, set } from '@ember/object';
import { task, all } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import fetch from 'fetch';
import config from 'ember-get-config';
import layout from './template';
import { classify } from '@ember/string';

const today = new Date();

export default Component.extend({
  layout,

  currentSession: service(),
  flashMessages: service(),
  store: service(),

  drugNames: [],
  organismContent: null,
  recommendations: {},
  selectedOrganism: null,
  tagName: '',
  today,

  isLoading: alias('getSanfordRecommendations.isRunning'),
  pathogensSorting: ['name'],

  hasRecommendations(item) {
    if (!this.recommendations) {
      return false;
    }

    let recommendations = this.recommendations[classify(item.name)];

    return recommendations && recommendations.length > 0;
  },

  loadPercentagesTotal: computed('organismsDetectedFiltered.@each.load_percentage', function() {
    return (this.organismsDetectedFiltered || [])
      .reduce((t, r) => t + r.load_percentage, 0);
  }),

  unmatchedPathogens: computed('organismsDetectedFiltered.@each.name', 'recommendations.[]', function() {
    return (this.organismsDetectedFiltered || [])
      .reject(o => this.hasRecommendations(o))
      .sortBy('name');
  }),

  matchedPathogens: computed('organismsDetectedFiltered.@each.name', 'recommendations.[]', function() {
    return (this.organismsDetectedFiltered || [])
      .filter(o => this.hasRecommendations(o))
      .map((o, i) => Object.assign({}, {index: i + 1}, o));
  }),

  resistanceGenesDetected: computed('medicationResult.resistanceGenesDetected', 'medicationResult.resistanceGenesDetectedFromMedications.[]', function() {
    if (!this.medicationResult) {
      return [];
    }

    if (this.medicationResult.resistanceGenesDetectedFromMedications.length) {
      return this.medicationResult.resistanceGenesDetectedFromMedications;
    }

    return this.medicationResult.resistanceGenesDetected;
  }),

  resistanceGenesDisplay: computed('organization.showClinicallyRelevantResistanceGenes', 'resistanceGenesDetected.@each.name', function() {
    if (this.organization.showClinicallyRelevantResistanceGenes) {
      return this.resistanceGenesDetected.filter((item) => {
        return item.related_pathogens && item.related_pathogens.length > 0;
      });
    } else {
      return this.resistanceGenesDetected;
    }
  }),

  organismsDetected: computed('medicationResult.organismsDetected', 'medicationResult.organismsDetectedFromMedications.[]', function() {
    if (!this.medicationResult) {
      return [];
    }

    if (this.medicationResult.organismsDetectedFromMedications.length) {
      return this.medicationResult.organismsDetectedFromMedications;
    }

    return this.medicationResult.organismsDetected;
  }),

  /**
  * Always filter out the control detected organisms.
  * Additionally filter out those that are not in requested panel if the
  * organization attribute `showOnlyTestsOrdered` is set
  */
  organismsDetectedFiltered: computed('organismsDetected.@each.{in_requested_panel,is_control}', 'organization.showOnlyTestsOrdered', function() {
    return (this.organismsDetected || [])
      .reject(o => o.is_control)
      .reject(o => this.organization.showOnlyTestsOrdered && !o.in_requested_panel);
  }),

  drugsAndRecommendations: computed('drugNames.@each.name', 'matchedPathogens.[]', 'recommendations.@each.symbol', 'this.drugNames', function() {
    const mapping = function(symbol) {
      switch(symbol) {
        case '\u00b1':
          return 1;
        case '+':
          return 2;
        case '++':
          return 4;
      }
      return 0;
    }

    const scoreColorMap = {
      '++': 'blue',
      '+': 'green',
      '\u00b1': 'yellow',
      0: 'red'
    }

    return this
      .drugNames
      .map((drug) => {
        let score = 0;
        let recommendations = this.matchedPathogens.length &&
          this.matchedPathogens
          .map((pathogen) => {
            let recommendations = this.recommendations[classify(pathogen.name)];

            let recommendation = recommendations && recommendations.findBy('name', drug.name);
            if (recommendation) {
              score = score + mapping(recommendation.symbol);

              return {
                symbol: recommendation.symbol,
                color: scoreColorMap[recommendation.symbol]
              }
            } else {
              return {
                symbol: '',
                color: ''
              }
            }
          });

        if (score > 0) {
          set(drug, 'score', -score);
          set(drug, 'displayScore', score);
          set(drug, 'recommendations', recommendations);

          return drug;
        }
      })
      .compact()
      .sortBy('name')
      .sortBy('score');
  }),

  /**
   * For a selected pathogen, request the html content from Sanford to display.
   * Once received, set the organismContent to the `content` property of the
   * returned json.
   */
  fetchSanfordContent: task(function *() {
    let id = yield this.selectedOrganism.sanford_id;

    if (!id) {
      this.set('selectedOrganism', null);
      this.flashMessages.error('Error encountered when fetching Sanford Guide content.');
      return;
    }

    // If user has already opened up content for this in current session then
    // do not re-request the content.
    let existingContent = this.fetchedContent && this.fetchedContent[id];
    if (existingContent) {
      this.set('organismContent', existingContent.content);
      return;
    }

    let token = this.currentSession.token;

    let response = yield fetch(
      `${config.api_endpoint}/${config.api_namespace}/recommendations/${id}`,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'X-Organization-Id': this.organization.id,
          'Accept': 'application/json'
        }
      }
    ).catch(() => {
      this.flashMessages.error('Error encountered when fetching Sanford Guide content.');
      this.set('selectedOrganism', null);
    });

    if (response.ok) {
      let recommendation = yield response.json();
      this.setFetchedContent(id, recommendation);
      this.set('organismContent', recommendation.content)
    } else {
      this.set('selectedOrganism', null);
      this.flashMessages.error('Error encountered when fetching Sanford Guide content.');
      return;
    }
  }).restartable(),

  /**
   * Store the fetched Sanford content for this session so we do not need to
   * re-request.
   */
  setFetchedContent(id, recommendation) {
    if (!this.fetchedContent) {
      this.set('fetchedContent', []);
    }

    this.fetchedContent.set(id, recommendation);
  },

  /**
   * For any of the detected pathogens - see if there are therapy recommendations
   * from Sanford Guide and display them.
   */
  getSanfordRecommendations: task(function *() {
    let promises = [];

    yield this.getSanfordAgents.perform();

    this.organismsDetected.forEach((pathogen) => {
      if (pathogen.sanford_id) {
        promises.push(this.fetchSanfordRecommendation.perform(pathogen));
      }
    });

    yield all(promises);
  }).drop(),

  getSanfordAgents: task(function *() {
    let agents = yield this.store.query('agent', {});

    let agentsMap = {};
    agents.forEach((agent) => {
      agentsMap[agent.sanfordId] = agent;
    });

    this.set('agents', agentsMap);
  }),

  /**
   * For each detected pathogen, request the sanford recommendations (drug
   * recommendations) for that pathogen. This does some normalization of the
   * data returned from the API for "spectra map"
   */
  fetchSanfordRecommendation: task(function *(pathogen) {
    let recommendations = yield this.store.query('recommendation', {
      sanford_id: pathogen.sanford_id,
      organization_id: this.organization.id
    }).catch(() => {
      this.flashMessages.error('Error encountered when fetching Sanford Guide recommendations.');
      return;
    });

    if (!recommendations || !recommendations.length) {
      return;
    }

    let mappedRecommendations = yield recommendations.map((recommendation) => {
      let symbol = recommendation.symbol;

      return recommendation.interactions.map((interaction) => {
        interaction = interaction.agent;
        let drugName = this.drugNames.findBy('sanford_id', interaction.id);
        if (!drugName) {
          let agent = this.agents[interaction.id] || {};
          this.drugNames.push({
            sanford_id: interaction.id,
            name: interaction.agent_name,
            antibioticClass: agent.group,
            antibioticClassName: agent.groupDisplayName,
          });
        }

        return {
          symbol,
          name: interaction.agent_name,
          sanford_id: interaction.agent_id
        };
      });
    });

    // Some of the pathogen names have periods in them which don't work well with property lookups in ember. Here
    // Classify is used because it strips the periods and creates a string value from the pathogen name that can also
    // be used in lookups from the template.
    // ie: "Actinomyces sp." --> "ActinomycesSp"
    this.set(`recommendations.${classify(pathogen.name)}`, mappedRecommendations.flat());
    this.notifyPropertyChange('drugNames');
    this.notifyPropertyChange('recommendations');
  }),

  didReceiveAttrs() {
    this._super(...arguments);
    this.getSanfordRecommendations.perform();
  },

  actions: {
    /**
     * When user selects a new pathogen in the UI - request or reuse existing
     * Sanford content to populate the modal with details.
     */
    updateSelectedOrganism(selectedOrganism) {
      this.set('selectedOrganism', selectedOrganism);
      this.fetchSanfordContent.perform();
    },

    /**
     * Close the modal and unset organism content
     */
    unsetSelectedOrganism() {
      this.set('selectedOrganism', null);
      this.set('organismContent', null);
    }
  }
});
