/* eslint-disable ember/no-mixins, ember/no-classic-classes, key-spacing, object-curly-spacing, brace-style, ember/no-volatile-computed-properties, max-len */
import Model, { attr, hasMany, belongsTo } from '@ember-data/model';
import { computed } from '@ember/object';
import Externalable from 'shared/mixins/externalable';

export default Model.extend(Externalable, {
  // Associations

  organization:   belongsTo('organization'),
  salesAccounts: hasMany('sales-account', { async: true, inverse: 'contacts' }),
  contactsSalesAccounts:             hasMany('contacts-sales-account', { inverse: 'salesAccountContact'}),
  physicianSalesAccountContacts:     hasMany('physician-sales-account-contact', { inverse: 'salesAccountContact' }),

  // Attributes
  // name:         attr('string'),
  firstName:    attr('string'),
  lastName:     attr('string'),
  email:        attr('string'),
  externalIdentifier: attr('string'),
  officePhoneNumber: attr('string'),
  cellPhoneNumber:   attr('string'),
  communicationPreference: attr('string'),
  faxNumber:    attr('string'),
  archived:     attr('boolean'),
  archivable:   attr('boolean'),
  physicianSalesAccountContactResetId: attr('number'),
  canResetPassword: attr('boolean'),
  physicianContact: attr('boolean'),
  notes: attr('string', { defaultValue: null }),
  defaultRole: attr('string'),
  physicianNames: attr({ defaultValue: () => { return []; } }),
  primaryContact: attr('boolean'),
  salesAccountId: attr('number'),
  anyFaxSignedReportsEnabled: attr('boolean'),

  // bulk attributes
  role: attr('string'),
  faxSignedReports: attr('boolean', { allowNull: true }),
  emailReportReadyNotifications: attr('boolean', { allowNull: true }),

  name: computed('firstName', 'lastName', function() {
    return [this.firstName, this.lastName].compact().join(' ');
  }).volatile(),

  providerAccountsNames: computed('salesAccounts', function() {
    let salesAccounts = this.salesAccounts;
    if (salesAccounts.length > 1 || salesAccounts.length === 0) {
      return salesAccounts.map((providerAccount) => {
        return providerAccount.name;
      }).join(', ');
    } else {
      return salesAccounts.firstObject.name;
    }
  }).volatile(),

  providerNames: computed('physicianNames', function() {
    return this.physicianNames.join(', ');
  }).volatile(),

  attachPhysicianSalesAccounts(physicianSalesAccountIds) {
    let modelName = this.constructor.modelName;
    let adapter = this.store.adapterFor(modelName);
    return adapter.attachPhysicianSalesAccounts.perform(this.id, { physician_sales_account_ids: physicianSalesAccountIds });
  },

  attachProviders(salesAccountId, physicianIds) {
    let modelName = this.constructor.modelName;
    let adapter = this.store.adapterFor(modelName);
    return adapter.attachProviders.perform(this.id, { sales_account_id: salesAccountId, physician_ids: physicianIds });
  }
});
