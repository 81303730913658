/* eslint-disable ember/no-new-mixins */
import Mixin from '@ember/object/mixin';

export default Mixin.create({

  serialize(snapshot) {
    let json = this._super(...arguments);

    if (snapshot.id) {
      return json;
    }

    let resources = snapshot.hasMany('resources');

    if (resources) {
      json._resource_ids = resources.map((resource) => resource.id);
    }

    return json;
  }

});
