/* eslint-disable max-len */
export const SYSTEM_OPTIONS = [
  'sendOrderEmail',
  'orderEmailFrequency',
  'orderEmailTime',
  'sendExternalOrderEmail',
  'externalOrderEmailFrequency',
  'externalOrderEmailTime',
  'sendFaxFailEmail',
  'sendReportCompletedEmail',
  'reportCompletedEmailFrequency',
  'reportCompletedEmailTime',
  'sendReportSignRequiredEmail',
  'reportSignRequiredEmailFrequency',
  'reportSignRequiredEmailTime',
  'sendReportFailedEmail',
  'reportFailedEmailFrequency',
  'reportFailedEmailTime',
  'sendProviderAccountCreatedEmail',
  'sendSecondaryProviderAccountCreatedEmail',
];

export const FORM_OPTIONS = [
  {
    renderType: 'title',
    text: 'System Email Notifications',
    styleName: 'settings-block-title',
  },
  {
    renderType: 'message',
    group: 'SYSTEM_OPTION',
    hidden: false,
    text: 'You don\'t have permissions to receive system email notifications. Contact your administrator to be granted permission.',
    styleName: 'settings-block-message',
  },
  {
    key: 'sendOrderEmail',
    group: 'SYSTEM_OPTION',
    hidden: true,
    name: 'Order',
    renderType: 'toggle',
    required: false,
    label: 'Requisitions are submitted from an online order'
  },
  {
    key: 'orderEmailFrequency',
    group: 'SYSTEM_OPTION',
    hidden: true,
    name: 'OrderEmailFrequency',
    required: false,
  },
  {
    key: 'sendExternalOrderEmail',
    group: 'SYSTEM_OPTION',
    hidden: true,
    name: 'External',
    renderType: 'toggle',
    required: false,
    label: 'Requisitions are submitted from an external system'
  },
  {
    key: 'externalOrderEmailFrequency',
    group: 'SYSTEM_OPTION',
    hidden: true,
    name: 'ExternalOrderEmailFrequency',
    required: false,
  },
  {
    key: 'sendFaxFailEmail',
    group: 'SYSTEM_OPTION',
    hidden: true,
    name: 'Fax',
    renderType: 'toggle',
    required: false,
    label: 'Fax report fails to send'
  },
  {
    key: 'sendReportCompletedEmail',
    group: 'SYSTEM_OPTION',
    hidden: true,
    name: 'ReportCompleted',
    renderType: 'toggle',
    required: false,
    label: 'Report is complete'
  },
  {
    key: 'reportCompletedEmailFrequency',
    group: 'SYSTEM_OPTION',
    hidden: true,
    name: 'ReportCompletedEmailFrequency',
    required: false,
  },
  {
    key: 'sendReportSignRequiredEmail',
    group: 'SYSTEM_OPTION',
    hidden: true,
    name: 'ReportSign',
    renderType: 'toggle',
    required: false,
    label: 'Report requires signature'
  },
  {
    key: 'reportSignRequiredEmailFrequency',
    group: 'SYSTEM_OPTION',
    hidden: true,
    name: 'ReportSignEmailFrequency',
    required: false,
  },
  {
    key: 'sendReportFailedEmail',
    group: 'SYSTEM_OPTION',
    hidden: true,
    name: 'ReportFailed',
    renderType: 'toggle',
    required: false,
    label: 'When a report fails to generate.'
  },
  {
    key: 'reportFailedEmailFrequency',
    group: 'SYSTEM_OPTION',
    hidden: true,
    name: 'ReportFailedEmailFrequency',
    required: false,
  },
  {
    key: 'sendProviderAccountCreatedEmail',
    group: 'SYSTEM_OPTION',
    hidden: true,
    name: 'ProviderAccountCreated',
    renderType: 'toggle',
    required: false,
    label: 'Provider Account or Provider is created'
  },
  {
    key: 'sendSecondaryProviderAccountCreatedEmail',
    group: 'SYSTEM_OPTION',
    hidden: true,
    name: 'SecondaryProviderAccountCreated',
    renderType: 'toggle',
    required: false,
    label: 'Secondary Provider Account or Secondary Provider is created'
  },
  {
    renderType: 'separator',
  },
  {
    renderType: 'title',
    text: 'Comment Email Notifications',
    styleName: 'settings-block-title',
  },
  {
    renderType: 'message',
    group: 'COMMENT_OPTION',
    hidden: false,
    text: 'Comments are not enabled for your organization. Contact your Administrator to enable commenting.',
    styleName: 'settings-block-message',
  },
  {
    key: 'sendCommentEmail',
    group: 'COMMENT_OPTION',
    hidden: true,
    name: 'Comment',
    renderType: 'toggle',
    required: false,
    label: 'Comments are added'
  },
  {
    key: 'sendUserCommentEmail',
    group: 'COMMENT_OPTION',
    hidden: true,
    name: 'UserComment',
    renderType: 'toggle',
    required: false,
    label: 'Person mentions me in a comment'
  },
];
