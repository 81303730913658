/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components, ember/no-component-lifecycle-hooks, ember/no-jquery */
import Component from '@ember/component';
import $ from 'jquery';

export default Component.extend({

  didInsertElement() {
    this._super(...arguments);
    $('.application-loading').hide();
  }

});
