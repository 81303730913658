/* eslint-disable space-before-blocks */
const parseSortData = function(sortData) {
  sortData = sortData || [];

  let sort = [];
  sortData.forEach((sorting) => {
    sort.push(`${sorting.prop} ${sorting.direction}`);
  });

  return sort;
};

const parseFilterData = function(filterData) {
  let search = {};
  filterData = filterData || { columnFilters: [] };

  Object.keys(filterData.filterUsing || {}).forEach((filter) => {
    let value = filterData[filter];
    if (value) {
      search[filter] = value;
    }
  });

  if (filterData.columnFilters){
    filterData.columnFilters.forEach((option) => {
      Object.keys(option.filterUsing || {}).forEach((filter) => {
        let value = option.filterUsing[filter];
        if (value) {
          search[filter] = value;
        }
      });
    });
  }

  return search;
};

const parsePaginationData = function(paginationData) {
  paginationData = paginationData || { pageNumber: 1, pageSize: 100 };

  const { pageNumber, pageSize } = paginationData;
  return { number: pageNumber, size: pageSize };
};

const parseQueryData = function({ paginationData, sortData, filterData }) {
  let sort = parseSortData(sortData);
  let query = parseFilterData(filterData);
  let page = parsePaginationData(paginationData);

  let q = query;
  q.s = sort;
  return { q, page: page.number || 1, per_page: page.size || 10 };
};

export default parseQueryData;
