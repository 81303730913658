import Model, { attr, belongsTo } from '@ember-data/model';

export default class InsuranceProviderModel extends Model {
  @belongsTo('organization', { async: false }) organization;

  @attr('string') code;
  @attr('string') name;
  @attr('string') clearingHouse;
  @attr('string') clearingHousePayerId;
}
