/* eslint-disable ember/no-classic-classes, key-spacing */
import Model, { belongsTo, attr } from '@ember-data/model';

export default Model.extend({
  // Associations
  associatedReportConfiguration: belongsTo('associated-report-configuration', { async: true }),
  project:                       belongsTo('project', { async: true }),
  requisitionTemplate:           belongsTo('requisition-template', { async: true }),

  // Attributes
  workflowType:                  attr('string')
});
