/* eslint-disable ember/no-new-mixins, ember/no-get, object-shorthand */
import { computed } from '@ember/object';
import Mixin from '@ember/object/mixin';
import { htmlSafe } from '@ember/template';

export default Mixin.create({
  progress: 0,

  progressDisplay: computed('progress', function() {
    let progress = this.get('progress');

    return progress > 0 && progress < 100;
  }),
  progressWidth: computed('progress', function() {
    return htmlSafe(`width: ${this.get('progress')}%`);
  }),

  updateProgress: function(percent) {
    this.set('progress', percent);
  },

  actions: {
    updateProgressAction: function(percent) {
      this.updateProgress(percent);
    }
  }
});
