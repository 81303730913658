/* eslint-disable ember/no-actions-hash */
import { assert } from '@ember/debug';
import { alias } from '@ember/object/computed';
import BaseComponent from '../../requisition-base/component';
import { inject as service } from '@ember/service';
import layout from './template';
import { computed } from '@ember/object';

export default BaseComponent.extend({
  layout,
  classNames: ['requisition-icd-codes'],

  store: service(),

  model: null,
  readOnly: false,

  isVisible: true,

  requisitionIcdCodes: alias('model.icdCodes'),
  diagnosis: alias('model.diagnosis'),

  init() {
    this._super(...arguments);
    assert('`model` is a required attribute', this.model);
    assert('`organization` is a required attribute', this.organization);
  },

  required: alias('templateOptions.required'),

  requirements: computed('required', function() {
    if (this.required) {
      return ['At least 1 ICD-10 Code is required'];
    }

    return [];
  }),

  missingRequirements: computed('required', 'requirements', 'requisitionIcdCodes.[]', function() {
    if (this.required && this.requisitionIcdCodes.length === 0) {
      return this.requirements;
    }

    return [];
  }),

  actions: {
    addIcdCode(icdCode) {
      if (!this.requisitionIcdCodes.findBy('id', icdCode.id)) {
        this.requisitionIcdCodes.pushObject(icdCode);
        this.diagnosis.pushObject(icdCode.code);
      }
    },

    removeIcdCode(icdCode) {
      this.requisitionIcdCodes.removeObject(icdCode);
      this.diagnosis.removeObject(icdCode.code);
    }
  }
});
