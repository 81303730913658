/* eslint-disable ember/no-volatile-computed-properties, ember/no-actions-hash */
import { alias, mapBy } from '@ember/object/computed';
import { isPresent, isNone } from '@ember/utils';
import { get, computed } from '@ember/object';
import BaseComponent from 'shared/components/requisitions/requisition-base/component';
import { inject as service } from '@ember/service';
import layout from './template';

export default BaseComponent.extend({
  layout,
  classNames: ['requested-tests', 'entry-details-content', 'row'],

  store: service(),

  readOnly: false,
  requestedTests: alias('model.requestedTests'),
  requisitionTemplateAssociations: alias('model.requisitionTemplate.requisitionTemplateAssociations'),
  testPanels: mapBy('requisitionTemplateAssociations', 'testPanel'),

  allowMultipleTests: alias('template.test.allow_multiple'),

  testGeneAssociations: computed('testPanels.[]', function() {
    let testGeneAssociations = {};
    this.testPanels.forEach((testPanel) => {
      let testGenes = testPanel.testGenes;
      testGenes?.forEach((testGene) => {
        if (isNone(testGeneAssociations[testGene.key])) {
          testGeneAssociations[testGene.key] = [];
        }
        testGeneAssociations[testGene.key].pushObject(testPanel.key);
      });
    });
    return testGeneAssociations;
  }),

  isVisible: computed('template', 'template.test', function() {
    return isPresent(this.template?.test);
  }),

  requirements: computed('template.test.required', function() {
    if (this.template?.test?.required) {
      return [{ key: 'selectedPanelOrTests', name: 'Select 1 test' }];
    } else {
      return [];
    }
  }),

  // selectedTestPanel: computed.readOnly('_selectedTestPanel', function() {
  selectedTestPanel: computed('_selectedTestPanel', 'requestedTests', function() {
    let selection = this._selectedTestPanel;

    if (isNone(selection)) {
      let requestedTests = this.requestedTests;

      return this._activePanel(requestedTests);
    }

    return selection;
  }).volatile(),

  _activePanel(requestedTests) {
    for (let key in requestedTests) {
      if (key.match(/^p/) && requestedTests[key]) {
        return key;
      }
    }
    this._updateDirtyAttributes();
    return null;
  },

  _updateTestGeneSelection(key, value) {
    let testPanel = this.testPanels.findBy('key', key);

    if (!testPanel) {
      return;
    }

    testPanel.testGenes.forEach((gene) => {
      let testGeneKey = gene.get('key');
      let compositeKey = `requestedTests.${testGeneKey}`;
      if (value) {
        this.set(compositeKey, value);
      } else {
        if (!this.anyPanelSelected(testGeneKey)) { // change value if no other related test panel is selected
          this.set(compositeKey, value);
        }
      }
    });
  },

  _toggleTest(value) {
    let compositeKey = `requestedTests.${value}`;
    this.toggleProperty(compositeKey);
  },

  anyPanelSelected(testGeneKey) {
    let testGeneAssociations = this.testGeneAssociations;
    let testPanelKeys = get(testGeneAssociations, testGeneKey);
    let self = this;
    let selected = false;
    testPanelKeys.forEach((testPanelKey) => {
      if (self.get(`requestedTests.${testPanelKey}`)) {
        selected = true;
        return selected;
      }
    });
    return selected;
  },

  updateSelectedTestPanel(value) {
    let allowMultiple = this.allowMultipleTests;
    let requestedTests = this.requestedTests;
    let activePanel = this._activePanel(requestedTests);

    // disable active panel
    if (activePanel && !allowMultiple) {
      this.set(`requestedTests.${activePanel}`, false);
      this._updateTestGeneSelection(activePanel, false);
    }

    this._toggleTest(value);
    let compositeKey = `requestedTests.${value}`;
    this._updateTestGeneSelection(value, this.get(compositeKey));

    this.set('_selectedTestPanel', value);
    this._updateDirtyAttributes();
  },

  _publish() {
    if (typeof this.publish === 'function') {
      this.publish('requestedTestsChanged');
    }
  },

  actions: {
    updateSelectedTestPanel(value) {
      this.updateSelectedTestPanel(value);
      this._publish();
    },

    updateSelectedTestGene(value) {
      this._toggleTest(value);
      this._publish();
    }
  }
});
