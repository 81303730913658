/* eslint-disable ember/no-new-mixins, ember/no-observers, ember/no-get, dot-notation, no-multi-spaces */
import { get, observer, set } from '@ember/object';
import Mixin from '@ember/object/mixin';
import { inject as service } from '@ember/service';
import config from 'ember-get-config';

export default Mixin.create({
  ajax: service(),
  currentSession: service(),
  currentOrganization: service(),

  validLicenceRegistries: Object.freeze([
    'NPI',
    'Australian Health Practitioner Registry',
    'European Physician',
    'ex-US / clinical trial',
    'Indonesian Medical Council',
    'Malaysian Medical Council',
    'Philippines PRC',
    'Singapore Medical Council',
    'South American Physician',
    'Thailand Medical Council'
  ]),

  currentSearch: '',
  searching: false,
  invalidNpi: false,

  reset() {
    this.set('currentSearch', '');
  },

  onSearchChanges: observer('currentSearch', function() {
    let term = get(this, 'currentSearch') || '';

    set(this, 'invalidNpi', false);

    if (term.length === 10) {
      set(this, 'searching', true);
      return this._searchPhysician(term);
    }

    this._setPhysicianInfo({});

    return false;
  }),

  async _setPhysicianInfo(attributes) {
    let record = null;

    if (attributes['id']) {
      record = await this.store.findRecord('physician', attributes['id']);
    }

    if (record) {
      return this.set('model', record);
    }

    if (this.model.isNew) {
      return this.model.setProperties(attributes);
    }

    record = this.store.createRecord('physician', {
      licensingRegistry: 'NPI', //by default
      disableNotifyWhenCreate: true,
      ...attributes
    });

    this.set('model', record);
  },

  _searchPhysician(term) {
    let host      = config.api_endpoint;
    let namespace = config.api_namespace;
    let ajax    = get(this, 'ajax');
    let session = get(this, 'currentSession');
    let currentOrganization = get(this, 'currentOrganization');
    let token   = get(session, 'token');

    return ajax.request(`${host}/${namespace}/physicians/search`, {
      method: 'GET',
      data: { number: term },
      headers: {
        'Authorization': `Bearer ${token}`,
        'X-Organization-Id': currentOrganization.id,
      }
    }).then((response) => {
      if (response.physicians.length > 0) {
        let physicianInfo = response.physicians[0];

        if (physicianInfo['id']) {
          physicianInfo['id'] = `${physicianInfo['id']}`;
        }
        this._setPhysicianInfo(physicianInfo);
      } else {
        this._setPhysicianInfo({});
        set(this, 'invalidNpi', true);
      }
      set(this, 'searching', false);
    });
  },

  actions: {
    updateLicenceRegistry(licenceRegistry) {
      this._setPhysicianInfo({});
      this.set('currentSearch', '');
      this.model.set('name', null);
      this.model.set('npi', null);
      this.model.set('licensingRegistry', licenceRegistry);
    },
  },
});
