/* eslint-disable ember/no-classic-classes */
import Model, { attr } from '@ember-data/model';

export default Model.extend({
  name: attr('string'),
  containerType: attr('string'),
  size: attr('number'),
  wells: attr('number'),
  group: attr('number'),
  allowOnReqSettings: attr('boolean')
});
