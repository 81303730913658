/* eslint-disable ember/no-classic-classes, max-len */
import Model, { hasMany, belongsTo, attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { equal, not, or } from '@ember/object/computed';
import config from 'ember-get-config';
import { fragment } from 'ember-data-model-fragments/attributes';
import { validator, buildValidations } from 'ember-cp-validations';
import { capitalize, underscore } from '@ember/string';

const DYNAMIC_PROPERTIES = [
  'results',
  'flags'
];

const Validations = buildValidations({
  instrumentGroupCode: {
    description: 'Instrument Group Code',
    validators: [
      validator('presence', {
        presence: true,
        disabled: not('model.isInfectiousDiseaseOrResistanceGene')
      })
    ]
  },

  testCode: {
    description: 'Test Code',
    validators: [
      validator('presence', {
        presence: true,
        disabled: not('model.isInfectiousDiseaseOrResistanceGene')
      })
    ]
  },

  config: {
    description: 'Config',
    validators: [
      validator('belongs-to', {
        disabled: not('model.isInfectiousDiseaseOrResistanceGene')
      })
    ]
  }
});

export default Model.extend(Validations,
  {
    organization: belongsTo('organization', { async: true }),
    test: belongsTo('test-gene', { async: true }),
    associatedInstruments: hasMany('test-configuration-instrument', { async: false }),

    /**
     * `config` is returned from API as a plain JSON object. This project uses
     * [ember-data-model-fragments](https://github.com/lytics/ember-data-model-fragments)
     * to mimic the behaviors of {DS.Model} for a JSON object. Each "Test Type"
     * that has a {Model.TestConfiguration} can have a differently structured
     * `config` value, so this is a polymorphic relationship and a different
     * config fragment model is created for each test type.
     * See {Model.InfectiousDiseaseConfig} or {Model.ResistanceGeneConfig} for
     * examples.
     */
    config: fragment('test-configuration-config', {
      polymorphic: true,
      typeKey: '$type',
      defaultValue: {}
    }),

    archivable: attr('boolean'),
    archived: attr('boolean'),
    displayName: attr('string'),
    flags: attr('test-configuration-flag'),
    high: attr('number'),
    instrumentGroupCode: attr('string'),
    low: attr('number'),
    results: attr('test-configuration-result'),
    testCode: attr('string'),
    testType: attr('string'),
    threshold: attr('number'),
    unit: attr('string'),

    shortTestType: computed('testType', function() {
      return config.TEST_TYPES_MAPPING[this.get('testType')];
    }),

    isInfectiousDisease: equal('testType', config.CONSTANTS.ID),
    isInfectiousDiseaseOrResistanceGene: or('isInfectiousDisease', 'isResistanceGene'),
    isResistanceGene: equal('testType', config.CONSTANTS.RG),
    isMolecular: equal('testType', config.CONSTANTS.MOLECULAR),

    unknownProperty(key) {
      if (!key) {
        return this._super(...arguments);
      }

      let _segments = underscore(key).split('_');
      let property = `${_segments.pop()}s`;
      let label = _segments.join(' ');

      if (!DYNAMIC_PROPERTIES.includes(property)) {
        return this._super(...arguments);
      }

      let values = this.get(property) || [];
      let value = values.findBy('label', capitalize(label));

      if (!value && property === 'results') {
        value = { label: capitalize(label), template: label, consistentInterpretation: '', inconsistentInterpretation: '', reflex: '' };
        values.push(value);
      }

      if (!value && property === 'flags') {
        value = { label: capitalize(label), template: label, low: '', high: '', unit: '' };
        values.push(value);
      }

      this.set(property, values);

      return value;
    }
  }
);
