/* eslint-disable ember/no-classic-classes */
import Model, { hasMany, attr } from '@ember-data/model';

export default Model.extend({

  sampleStates: hasMany('sample-state', { async: false, inverse: null }),

  position: attr('string'),
  internalPosition: attr('number'),
  containerBarcode: attr('string'),
  containerLocation: attr('string'),

});
