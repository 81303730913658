/* eslint-disable ember/no-classic-classes, max-len */
import Model, { attr, belongsTo } from '@ember-data/model';

import { computed } from '@ember/object';

const getReportDeliveryOptions = (managed) => {
  const managedText = managed ? "Enabled" : "Disabled";
  return [
    { text: `Managed by Provider Account (${managedText})`, value: "managed" },
    { text: "Enabled", value: "enabled" },
    { text: "Disabled", value: "disabled" },
  ];
};

export default Model.extend({
  // Associations

  salesAccount: belongsTo('sales-account', { async: false }),
  physician: belongsTo('physician'),
  user: belongsTo('user'),
  physicianSalesAccount: belongsTo('physician-sales-account'),
  salesAccountContact: belongsTo('sales-account-contact'),

  // Attributes
  archived:     attr('boolean'),
  archivable:   attr('boolean'),
  canResetPassword: attr('boolean'),
  primaryContact: attr('boolean'),
  faxSignedReports: attr('string', { defaultValue: 'managed' }),
  emailReportReadyNotifications: attr('string', { defaultValue: 'managed' }),
  role: attr('string', { defaultValue: null }),

  faxSignedReportsOptions: computed('salesAccount.faxSignedReportsContacts', function () {
    return getReportDeliveryOptions(this.salesAccount ? this.salesAccount.faxSignedReportsContacts : false);
  }),

  emailReportReadyNotificationsOptions: computed('salesAccount.emailReportReadyNotificationsContacts', function () {
    return getReportDeliveryOptions(this.salesAccount ? this.salesAccount.emailReportReadyNotificationsContacts : false);
  }),
});
