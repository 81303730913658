/* eslint-disable ember/no-classic-classes, semi */
import Model, { belongsTo, attr } from '@ember-data/model';
import { alias, gt } from '@ember/object/computed';

export default Model.extend({

  salesAccount: belongsTo('sales-account', { async: false }),
  secondarySalesAccount: belongsTo('sales-account', { async: false }),

  archivedAt: attr('number'),

  archivable: true,
  name: alias('secondarySalesAccount.name'),
  archived: gt('archivedAt', 0),

})