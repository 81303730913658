import { helper as buildHelper } from '@ember/component/helper';

export function inArray(params) {
  const array = params[0] || [];
  const value = params[1];

  return array.indexOf(value) !== -1;
}

export default buildHelper(inArray);
