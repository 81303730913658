/* eslint-disable ember/no-classic-classes, key-spacing */
import Model, { hasMany, attr } from '@ember-data/model';

export default Model.extend({
  // Associations
  documents: hasMany('document'),
  controls:  hasMany('control'),

  // Attributes
  name: attr('string'),
  organization_id: attr('number'),
  tagType: attr('string'),
  category: attr('string'),
  behaviors: attr()

});
