/* eslint-disable ember/no-classic-classes, ember/no-get, no-prototype-builtins */
import Model, { hasMany, attr, belongsTo } from '@ember-data/model';
import {
  alias,
  mapBy,
  notEmpty,
  equal,
  reads
} from '@ember/object/computed';
import { isNone, isBlank } from '@ember/utils';
import { getProperties, get, computed } from '@ember/object';
import moment from 'moment';
import isValidGlobalId from 'shared/helpers/is-valid-global-id';

const STATUS_NOT_RECEIVED = 'Not Received';
const STATUS_RECEIVED = 'Received';
// const STATUS_REJECTED = 'Rejected';
const STATUS_PENDING_REJECTION = 'Pending Rejection';
const STATUS_ACCEPTED = 'Accepted';

const MIN_DATE = new Date(1900, 0, 0);

export default Model.extend({
  // Associations
  organization: belongsTo('organization', { async: true }),
  requisition: belongsTo('requisition', { async: true }),
  patient: belongsTo('patient', { async: false }),
  requisitionTemplateSamplesGroup: belongsTo('requisition-template-samples-group', { async: false }),
  controlContainer: belongsTo('control-container'),
  rejectedBy: belongsTo('user'),

  sampleStates: hasMany('sample-state', { async: true, inverse: 'sample' }),
  queueAssignments: hasMany('queue-assignments', { async: true }),
  activities: hasMany('activity', { async: false }),
  // workflows: hasMany('workflow', { inverse: 'samples', async: false }),
  reports: hasMany('report', { async: false }),
  workflowSamples: hasMany('workflow-sample', { async: false }),
  entityCustomAttributes: hasMany('entity-custom-attribute', { async: false }),
  testPanels: hasMany('test-panel', { async: false }),
  testGenes: hasMany('test-gene', { async: false }),
  sampleTaggings: hasMany('sample-tagging', { async: false }),
  sampleTags: hasMany('sample-tags', { async: false, inverse: null }),

  // Attributes
  identifier: attr('string'),
  lot: attr('string'),
  partial: attr('boolean'),
  status: attr('string'),
  workflowType: attr('string'),
  dateReceived: attr('date'),
  receivedDateTime: attr('datetime'),
  approvedAt: attr('datetime'),
  rejectedAt: attr('datetime'),
  received: attr('boolean'),
  selectedWorkflowType: attr('string'),
  sampleCollectionDate: attr('date'),
  sampleCollectedBy: attr('string'),
  sampleCollectionTime: attr('string'),
  collectionDateTime: attr('datetime'),
  control: attr('boolean'),
  controlType: attr('string'),
  group: attr('string'),
  sampleType: attr('string'),
  archivable: attr('boolean'),
  archived: attr('boolean', { defaultValue: false }),
  customGlobalId: attr('string'),
  biobankingEligible: attr('boolean', { default: false }),
  relabeled: attr('boolean', { defaultValue: false }),
  biobankingMessage: attr('string'),
  entitlements: attr(),
  globalIdIdentifier: attr('string'),
  globalIdCreatedAt: attr('date'),
  biobankUpdatedAt: attr('datetime'),
  updatedAt: attr('datetime'),

  /**
   * Summarized TAT values are calculated every time a report is signed
   */
  analyticTat: attr('number', { defaultValue: 0 }),
  approvalTat: attr('number', { defaultValue: 0 }),
  labTat: attr('number', { defaultValue: 0 }),
  overallTat: attr('number', { defaultValue: 0 }),
  postAnalyticTat: attr('number', { defaultValue: 0 }),
  processTat: attr('number', { defaultValue: 0 }),
  sampleTat: attr('number', { defaultValue: 0 }),

  tagIds: attr({
    defaultValue: () => {
      return [];
    }
  }),
  customAttributes: attr({
    defaultValue: () => {
      return {};
    }
  }),
  controlContainerBarcodeLabel: attr('string'),

  queuableWorkflowTypes: attr(),
  queuedQueueAssignments: attr(),
  // For sample call that includes sample states information (for batch creation and containers)
  // sample-states fields
  sampleStateContainerCurrentLocation: attr('string'),
  sampleStatePosition: attr('string'),
  sampleStateContainerBarcode: attr('string'),
  sampleStatePoolIndex: attr('string'),
  sampleStateMetadata: attr({
    defaultValue: () => {
      return {};
    }
  }),

  // source sample state fields
  sourceSampleStatePosition: attr('string'),
  sourceSampleStateContainerBarcode: attr('string'),

  // requisition data
  requisitionPermissions: attr({
    defaultValue: () => {
      return {};
    }
  }),
  requisitionStatuses: attr({
    defaultValue: () => {
      return {};
    }
  }),
  panels_and_genes: attr({
    defaultValue: () => {
      return {};
    }
  }),

  // rejected model
  rejectStatus: attr('string'),
  rejectReason: attr('string'),
  statusReasonId: attr('number'),
  rejectOptions: attr(),
  selectedRouteOption: attr('string'),
  singleSample: attr('boolean'),
  rejectionStepTaken: attr('string'),

  // for analytics tat table
  applicationType: attr('string'),
  projectName: attr('string'),
  providerAccountName: attr('string'),
  secondaryProviderAccountName: attr('string'),
  providerName: attr('string'),
  clinicName: attr('string'),
  extractionDate: attr('date'),
  reportingDate: attr('date'),
  signedDate: attr('date'),
  reportId: attr('number'),
  workflowId: attr('number'),

  isRejected: equal('status', 'Rejected'),
  isReceived: equal('status', 'Received'),
  isAccepted: equal('status', 'Accepted'),
  isNotReceived: equal('status', null),

  workflows: computed('activities.[]', function() {
    let uniqWorkflows = [];

    get(this, 'activities').forEach((a) => {
      if (!uniqWorkflows.isAny('id', get(a, 'workflow.id'))) {
        uniqWorkflows.push(get(a, 'workflow'));
      }
    });

    return uniqWorkflows;
  }),

  // Helper methods
  age: computed('dateReceived', function() {
    let dateReceived = this.get('dateReceived');

    if (dateReceived) {
      return moment().diff(dateReceived, 'days');
    } else {
      return 'Unknown';
    }
  }),

  formattedDateReceived: computed('dateReceived', 'organization', function() {
    let date = this.get('dateReceived');
    let organization = this.get('organization');
    return date ? moment(date).format(organization.get('dateOutputFormat')) : '';
  }),

  statusName: computed('status', function() {
    let status = get(this, 'status');
    if (!status) {
      return STATUS_NOT_RECEIVED;
    }

    return status;
  }),

  _firstSampleState: alias('sampleStates.firstObject'),

  sampleStateLabel: computed('_firstSampleState', function() {
    let sampleState = this._firstSampleState;
    return sampleState ? sampleState.label : null;
  }),

  containerBarcode: computed('_firstSampleState.containerBarcode', 'sampleStateContainerBarcode', function() {
    let sampleStateAttr = this.get('sampleStateContainerBarcode');
    return isNone(sampleStateAttr) ? this.get('_firstSampleState.containerBarcode') : sampleStateAttr;
  }),

  containerCurrentLocation: computed(
    '_firstSampleState.containerCurrentLocation',
    'sampleStateContainerCurrentLocation',
    function() {
      let sampleStateAttr = this.get('sampleStateContainerCurrentLocation');
      return isNone(sampleStateAttr) ? this.get('_firstSampleState.containerCurrentLocation') : sampleStateAttr;
    }
  ),

  position: computed('_firstSampleState.position', 'sampleStatePosition', function() {
    let sampleStateAttr = this.get('sampleStatePosition');
    return isNone(sampleStateAttr) ? this.get('_firstSampleState.position') : sampleStateAttr;
  }),

  containerBarcodeAndPosition: computed('containerBarcode', 'sampleStatePosition', function() {
    let { containerBarcode, sampleStatePosition } = getProperties(this, ['containerBarcode', 'sampleStatePosition']);

    return `${containerBarcode}-${sampleStatePosition}`;
  }),

  containerPositionAndBarcode: computed('containerBarcode', 'sampleStatePosition', function() {
    let { containerBarcode, sampleStatePosition } = getProperties(this, ['containerBarcode', 'sampleStatePosition']);

    return `${sampleStatePosition}-${containerBarcode}`;
  }),

  samplesInPosition: computed('containerBarcode', 'sampleStatePosition', 'store', function() {
    let { containerBarcode, sampleStatePosition } = getProperties(this, ['containerBarcode', 'sampleStatePosition']);

    if (!sampleStatePosition || !containerBarcode) {
      return [];
    }

    return get(this, 'store').peekAll('sample')
      .filterBy('containerBarcode', containerBarcode)
      .filterBy('sampleStatePosition', sampleStatePosition);
  }),

  requisitionIdentifiersByPosition: computed(
    'containerBarcode',
    'identifier',
    'sampleStatePosition',
    'samplesInPosition',
    function() {
      let samplesInPosition = get(this, 'samplesInPosition');

      if (!samplesInPosition || !samplesInPosition.length) {
        return this.identifier;
      }

      return samplesInPosition.map((s) => get(s, 'identifier')).join(', ');
    }
  ),

  metadataByPosition: computed('containerBarcode', 'sampleStatePosition', 'samplesInPosition', function() {
    let samplesInPosition = get(this, 'samplesInPosition');
    let result = {};

    samplesInPosition.forEach((s) => {
      result[get(s, 'identifier')] = get(s, 'sampleStateMetadata');
    });

    return result;
  }),

  containerBarcodes: mapBy('sampleStates', 'containerBarcode'),
  containerCurrentLocations: mapBy('sampleStates', 'containerCurrentLocation'),
  positions: mapBy('sampleStates', 'position'),

  hasControlContainer: notEmpty('controlContainer.id'),

  canViewRequisition: computed('requisitionPermissions', function() {
    let permissions = get(this, 'requisitionPermissions');

    return permissions.hasOwnProperty('readable') && permissions.readable;
  }),

  requisitionId: computed('requisition.id', function() {
    return this.belongsTo('requisition').id();
  }),

  requisitionIdentifier: reads('patient.identifier'),

  groupName: computed('group', 'requisitionTemplateSamplesGroup.groupName', function() {
    let groupName = this.get('requisitionTemplateSamplesGroup.groupName');

    if (isBlank(groupName)) {
      groupName = this.group;
    }

    return groupName;
  }),

  receivedDateTimeValid: computed('receivedDateTime', function() {
    if (this.receivedDateTime && (this.receivedDateTime >= new Date() || this.receivedDateTime < MIN_DATE)) {
      return null;
    }

    return true;
  }),

  isCustomGlobalIdValid: computed('customGlobalId', function() {
    if (!this.customGlobalId) {
      return true;
    }

    return isValidGlobalId(this.customGlobalId);
  }),

  collectionDateTimeValid: computed('collectionDateTime', function() {
    if (this.collectionDateTime && (this.collectionDateTime > new Date())) {
      return null;
    }

    return true;
  }),

  receivedDateAndColectionDateValid: computed('{receivedDateTime,collectionDateTime}', function() {
    if (this.receivedDateTime && this.collectionDateTime) {
      return this.receivedDateTime >= this.collectionDateTime ? true : null;
    }

    return true;
  }),

  // legacy validations
  dateReceivedValid: computed('dateReceived', function() {
    if (this.dateReceived && (this.dateReceived >= new Date() || this.dateReceived < MIN_DATE)) {
      return null;
    }
    return true;
  }),

  dateReceivedAndSampleColectionDateValid: computed('{dateReceived,sampleCollectionDate}', function() {
    if (this.dateReceived && this.sampleCollectionDate) {
      return this.dateReceived >= this.sampleCollectionDate ? true : null;
    }

    return true;
  }),

  accept() {
    this.set('status', STATUS_ACCEPTED);
  },

  receive(receivedDateTime) {
    if (receivedDateTime) {
      this.set('receivedDateTime', receivedDateTime);
    }

    this.set('status', STATUS_RECEIVED);
    this.set('singleSample', true); // triggers update on the requisition
  },

  reject(rejectReason, statusReasonId) {
    this.setProperties({
      rejectReason,
      statusReasonId,
      status: STATUS_PENDING_REJECTION
    });
  },

  setSearchIdentifier(value) {
    this.set('searchIdentifier', value);
  },

  testPanelNames: computed('testPanels.@each.name', function() {
    return this.testPanels.map(tp => tp.name).sort().join(', ');
  }),

  testGeneNames: computed('testGenes.@each.name', function() {
    return this.testGenes.map(tg => tg.name).sort().join(', ');
  }),

  sampleTagNames: computed('sampleTags.@each.name', function() {
    return this.sampleTags.map(t => t.name).sort().join(', ');
  })
});
