import ApplicationSerializer from 'shared/serializers/application';

export default ApplicationSerializer.extend({
  attrs: {
    container: { serialize: 'ids' },
    identifier: { serialize: false },
    barcode: { serialize: false },
    lot: { serialize: false },
    processed: { serialize: false },
    createdAt: { serialize: false },
    dateReceived: { serialize: false },
    partial: { serialize: false },
    containerBarcode: { serialize: false },
    containerBarcodeLink: { serialize: false },
    containerCurrentLocation: { serialize: false },
    sourcePosition: { serialize: false },
    sourceContainerCurrentLocation: { serialize: false },
    sourceContainerBarcode: { serialize: false },
    assay: { serialize: false },
    containerID: { serialize: false }
  },
});
