/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components, ember/no-get, dot-notation */
import Component from '@ember/component';
import { task, timeout } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { get } from '@ember/object';
import layout from './template';

export default Component.extend({
  layout,
  classNames: [''],

  store: service(),
  searchResult: [],

  search: task(function *(params) {
    yield timeout(250);
    const searchParams = { organization_id: get(this, 'organization.id'), term: params };

    if (this.portalOrder) {
      searchParams['for_orders_portal'] = true;
    }

    return yield get(this, 'store').query('icd-code', searchParams);
  })

});
