define("ember-bootstrap-datepicker/components/bootstrap-datepicker-inline", ["exports", "@ember/component", "ember-bootstrap-datepicker/components/datepicker-support"], function (_exports, _component, _datepickerSupport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(_datepickerSupport.default, {
    tagName: 'div'
  });
});