import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

export default class ProviderAccountGroup extends Model {
  @belongsTo('organization', { async: false }) organization;
  @belongsTo('user', { async: false }) user;
  @hasMany('sales-accounts', { async: false }) salesAccounts;

  @attr('string') name;
  @attr('date') createdAt;
  @attr('boolean') archived;
  @attr('boolean', { defaultValue: true }) archivable;
}
