/* eslint-disable ember/no-classic-classes, key-spacing, ember/no-get */
import Model, { hasMany, belongsTo, attr } from '@ember-data/model';
import { equal } from '@ember/object/computed';

export default Model.extend({

  parent:         belongsTo('resource-group', { inverse: 'resourceGroups', async: true }),
  resourceGroups: hasMany('resource-group', { inverse: 'parent' }),
  resources:      hasMany('resource', { inverse: 'parent' }),

  activityId:     attr('string'),
  name:           attr('string'),

  permissions:  attr(),

  deletable: equal('permissions.delete', true),

  delete() {
    if (!this.get('isDeleted')) {
      this.deleteRecord();
    }
    this.get('resourceGroups').forEach((resource) => resource.delete());
    this.get('resources').forEach((resource) => resource.delete());
  }

});
