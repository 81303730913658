/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/no-actions-hash */
import Component from '@ember/component';
import { set } from '@ember/object';
import layout from './template';

export default Component.extend({
  layout,
  tagName: '',
  page: null,
  archived: null,

  actions: {

    toggleArchived() {
      set(this, 'page', 1);
      this.toggleProperty('archived');
    }
  }
});
