define("ember-preferences/storage/defaultable", ["exports", "@ember/object", "ember-preferences/storage/decorator"], function (_exports, _object, _decorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _object.default.extend(_decorator.default, {
    getItem(key) {
      return this.calculateValue(key, this._super(key));
    },
    calculateValue(key, value) {
      if (value === undefined || value === null) {
        value = this.get('defaults')[key];
        if (typeof value === 'function') {
          value = value();
        }
      }
      return value;
    }
  });
});