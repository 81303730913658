/* eslint-disable ember/no-new-mixins, ember/no-get, space-before-blocks */
import Mixin from '@ember/object/mixin';
import { computed } from '@ember/object';

export default Mixin.create({
  availablePrinters: undefined,
  disabled: computed('availablePrinters.[]', function() {
    let availablePrinters = this.get('availablePrinters');
    // if it is undefined, means that was not overridden by the parent component and should not do the verification
    if (availablePrinters === undefined){
      return false;
    }

    return this.get('availablePrinters.length') == 0;
  })
});
