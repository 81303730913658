export const containerMap = Object.freeze({
  'Bag': { columns: 10, cssClass: 'bag' },
  'Plate': { columns: 13, cssClass: 'well-plate-96' },
  'Plate96': { columns: 13, cssClass: 'well-plate-96' },
  'Plate384': { columns: 25, cssClass: 'well-plate-384' },
  'Plate16': { columns: 17, cssClass: 'well-plate-16' },
  'Plate48': { columns: 9, cssClass: 'well-plate-48' },
  'Plate24': { columns: 7, cssClass: 'well-plate-24' },
  'NanoQuant16': { columns: 3, cssClass: 'well-nano-quant-16' },
  'OpenArray': { columns: 13, cssClass: 'open-array' },
  'Tube': { columns: 2, cssClass: 'tube' },
  'Tube8': { columns: 9, cssClass: 'tube-8' },
  'TubeBox': { columns: 10, cssClass: 'tube-box' },
  'TubeBox36': { columns: 7, cssClass: 'tube-box-36' },
  'TubeBox64': { columns: 9, cssClass: 'tube-box-64' },
  'TubeBox72': { columns: 7, cssClass: 'tube-box-72' },
  'TubeBox100': { columns: 11, cssClass: 'tube-box-100' },
  'TubeBox200': { columns: 11, cssClass: 'tube-box-200' },
  'TubeRack96': { columns: 13, cssClass: 'tube-rack-96' },
  'TubeRack60': { columns: 13, cssClass: 'tube-rack-60' },
  'TubeRack48': { columns: 9, cssClass: 'tube-rack-48' },
  'TubeStrip8': { columns: 2, cssClass: 'tube-strip-8' },
  'FlowCell8Lane': { columns: 9, cssClass: 'flow-cell' },
  'FlowCell4Lane': { columns: 5, cssClass: 'flow-cell' },
  'FlowCell2Lane': { columns: 3, cssClass: 'flow-cell' },
  'FlowCell1Lane': { columns: 2, cssClass: 'flow-cell' }
});
