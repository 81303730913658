/* eslint-disable ember/no-classic-classes, no-trailing-spaces */
import Service, { inject as service } from '@ember/service';
import config from 'ember-get-config';
import fetch from 'fetch';

export default Service.extend({

  currentSession: service(),

  async post(message) {
    const token = this.currentSession.token;
    const data = this.currentSession.tokenPayload;

    // data can be undefined if this method is called on a 
    // delay and the user logs out.
    if (data) {
      data.message = message;

      await fetch(
        `${config.api_endpoint}/${config.api_namespace}/logs`,
        {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }
      );
    }
  }
});
