/* eslint-disable ember/no-classic-classes, key-spacing, object-shorthand, ember/no-get */
import Model, { belongsTo, hasMany, attr } from '@ember-data/model';

export default Model.extend({
  // Associations
  organization: belongsTo('organization'),
  project:      belongsTo('project'),
  document:     belongsTo('document'),
  documentRevision:     belongsTo('document-revision'),
  trainingRequirements: hasMany('training-requirement'),
  trainers:     hasMany('user'),
  resources:    hasMany('resource'),
  role:         belongsTo('role'),

  // Attributes
  uuid:         attr('string'),
  issued:       attr('date'),
  name:         attr('string'),
  entityName:   attr('string'),
  rev:          attr('number'),
  effective:    attr('date'),
  expires:      attr('date'),
  recurring:    attr('string'),
  due:          attr('string'),

  downloadUrl:  attr('string'),

  // Helper Methods
  requirementExists: function(role) {
    let trainingRequirements = this.get('trainingRequirements');

    return trainingRequirements.any(function(item) {
      return item.get('role') === role;
    });
  },

  addRole: function(role) {
    let existingRecord = this.requirementExists(role);
    if (existingRecord) {
      return;
    }

    this.store.createRecord('training-requirement', {
      trainingPack: this,
      role:         role
    });
  },

  removeRequirement: function(trainingRequirement) {
    let trainingRequirements = this.get('trainingRequirements');

    trainingRequirements.removeObject(trainingRequirement);
    trainingRequirement.deleteRecord();
  },

  addTrainer: function(trainer) {
    let trainers = this.get('trainers');
    trainers.addObject(trainer);
  },

  removeTrainer: function(trainer) {
    let trainers = this.get('trainers');

    trainers.removeObject(trainer);
  }

});
