/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components, ember/no-get */
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import Component from '@ember/component';
import { computed, get } from '@ember/object';
import moment from 'moment';
import layout from './template';

const component = Component.extend({
  layout,
  _currentOrganization: service('current-organization'),
  organization: alias('_currentOrganization.organization'),

  formattedDate: computed('dateTime', 'dateTimeFormat', 'organization.dateTimeOutputFormat', function() {
    let dateTime = get(this, 'dateTime');
    let dateTimeFormat = get(this, 'dateTimeFormat');

    if (!dateTime) {
      return null;
    }
    if (!dateTimeFormat) {
      dateTimeFormat = this.get('organization.dateTimeOutputFormat');
    }

    let dateFormat = null;
    let timeFormat = null;
    if (typeof dateTimeFormat === 'string') {
      dateFormat = dateTimeFormat;
    } else if (typeof dateTimeFormat === 'object') {
      dateFormat = dateTimeFormat.date;
      timeFormat = dateTimeFormat.time;
    } else {
      return null;
    }

    let momentDate = moment(dateTime);
    let momentDateStart = moment(dateTime).startOf('day');

    // checks if dateTime time is equal to 00:00:00
    if (momentDate.isSame(momentDateStart) || !timeFormat) {
      return momentDate.format(dateFormat);
    } else {
      return momentDate.format(`${dateFormat} ${timeFormat}`);
    }
  })
});

component.reopenClass({
  positionalParams: ['dateTime', 'dateTimeFormat']
});

export default component;
