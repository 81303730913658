/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components, ember/no-get, max-len, ember/no-actions-hash, semi */
import Component from '@ember/component';
import { assert } from '@ember/debug';
import { computed, get } from '@ember/object';
import { alias, oneWay } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import config from 'ember-get-config';
import layout from './template';

export default Component.extend({
  layout,
  classNames: 'requisition-print-templates',

  currentSession: service(),
  settings: service(),
  currentOrganization: service(),
  organization: alias('currentOrganization.organization'),
  requisition: null,

  init() {
    this._super(...arguments);
    assert('Missing required `requisition`', this.requisition);
  },

  requisitionTemplate: alias('requisition.requisitionTemplate'),
  printTemplates: alias('organization.printTemplates'),

  definedPrintTemplate: alias('requisitionTemplate.printTemplate'),

  defaultPrintTemplate: computed('printTemplates', function() {
    let printTemplates = this.get('printTemplates') || [];
    return printTemplates.filterBy('organizationDefault', true).firstObject;
  }),

  selectedPrintTemplate: oneWay('printTemplates.firstObject'),

  usedPrintTemplate: computed('defaultPrintTemplate.id', 'definedPrintTemplate.id', 'selectedPrintTemplate.id', function() {
    if (this.get('definedPrintTemplate.id')) {
      return this.get('definedPrintTemplate');
    } else if (this.get('defaultPrintTemplate.id')) {
      return this.get('defaultPrintTemplate');
    } else if (this.get('selectedPrintTemplate.id')) {
      return this.get('selectedPrintTemplate');
    }
    return null;
  }),

  token: alias('currentSession.token'),

  actions: {
    print() {
      if (this.usedPrintTemplate) {
        let printTemplateId = get(this.usedPrintTemplate, 'id');
        const portal = this.settings.portal ? '&portal_order=true' : '';

        const link = document.createElement('a');
        link.target = '_blank';
        link.href = `${config.api_endpoint}/print.pdf?tid=${printTemplateId}&id=${this.requisition.id}${portal}&token=${this.token}`;
        link.click();
      }
    }
  }

})
