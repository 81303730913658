/* eslint-disable ember/no-classic-components, ember/no-mixins, ember/no-classic-classes, space-before-function-paren, ember/no-get, no-implicit-coercion, quotes, ember/no-actions-hash */
import Component from '@ember/component';
import CurrentUser from 'shared/mixins/current-user';
import AutoCompleteSettings from 'shared/mixins/auto-complete-settings';
import { computed, get } from '@ember/object';
import layout from './template';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';

export default Component.extend(CurrentUser, AutoCompleteSettings, {
  layout,
  tagName: '',

  flashMessages: service(),

  isOwner: computed('note.user.uuid', 'currentUser.uuid', function () {
    let note = get(this, 'note.user.uuid');
    let current = get(this, 'currentUser.uuid');

    return !!note && !!current && note === current;
  }),

  visibilityValue: computed('note.{forLabs,forPhysicians,forSales}', 'visibilityOptions', function () {
    const { forLabs, forSales, forPhysicians } = this.note.getProperties('forLabs', 'forSales', 'forPhysicians');

    if (forLabs && forSales && forPhysicians) {
      return this.get('visibilityOptions')[3];
    } else if (forLabs && forSales) {
      return this.get('visibilityOptions')[2];
    } else if (forLabs && forPhysicians) {
      return this.get('visibilityOptions')[1];
    }

    return this.get('visibilityOptions')[0]; // only labs
  }),

  visibilityOptions: [
    {
      label: 'Lab Only',
    },
    {
      label: 'Lab and Providers',
    },
    {
      label: 'Lab and Sales',
    },

    {
      label: 'All Users',
    }
  ],

  deleteNote: task(function *() {
    yield this
      .note
      .destroyRecord()
      .then(() => {
        this.flashMessages.success("Comment removed");
        this.onDelete(this.note);
      })
      .catch((error) => {
        this.flashMessages.error("An error ocurred when removing comment. Please try again.");
        console.error('Error ocurred when removing comment', error);
      });
  }).drop(),

  updateNote: task(function *(attributes) {
    this.note.setProperties(attributes);

    return yield this
      .note
      .save()
      .then(() => {
        this.flashMessages.success("Comment updated");
        this.set("showEdit", false);
      })
      .catch((error) => {
        this.flashMessages.error("An error ocurred when saving comment. Please try again.");
        console.error('Error ocurred when saving comment', error);
      });
  }).drop(),

  actions: {
    cancel() {
      this.note.rollbackAttributes();
      this.set('showEdit', false);
    },

    delete() {
      this.deleteNote.perform();
    },

    update(attributes) {
      return this.updateNote.perform(attributes);
    },
  }
});
