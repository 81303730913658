/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components, ember/no-incorrect-computed-macros, ember/require-computed-property-dependencies, ember/no-actions-hash */
import { inject as service } from '@ember/service';
import { or, not } from '@ember/object/computed';
import Component from '@ember/component';
import { assert } from '@ember/debug';
import { computed, set } from '@ember/object';
import layout from './template';

export default Component.extend({
  layout,
  router: service(),
  flashMessages: service(),
  segment: service(),

  isEditing: not('model.isNew'),
  saveDisabled: or('model.isSaving'),

  init() {
    this._super(...arguments);
    assert('`model` is a required attribute', this.model);
    assert('`model.salesAccount` is a required attribute', this.salesAccount);
    set(this.model, 'salesAccount', this.salesAccount);
  },

  notesLength: computed('model.notes', function() {
    if (this.model.notes) {
      return this.model.notes.length;
    } else {
      return 0;
    }
  }),

  create() {
    this.model.save().then(
      () => {
        this.flashMessages.success('Address created.');
        this.segment.trackEvent('Address created.');
        this.afterSubmit();
      })
      .catch((err) => {
        let allErrors = '';
        if (err.errors) {
          allErrors = err.errors.map((error) => error.detail);
        }
        this.flashMessages.error(`Failed to create address. ${allErrors}`);
        this.segment.trackEvent('Address creation failed.');
      });
  },

  update() {
    this.model.save().then(
      () => {
        this.flashMessages.success('Address updated.');
        this.segment.trackEvent('Address updated.');
        this.afterSubmit();
      })
      .catch((err) => {
        let allErrors = '';
        if (err.errors) {
          allErrors = err.errors.map((error) => error.detail);
        }
        this.flashMessages.error(`Failed to update address. ${allErrors}`);
        this.segment.trackEvent('Address update failed.');
      });
  },

  actions: {
    onSubmit() {
      if (this.model.get('isNew')) {
        this.create();
      } else {
        this.update();
      }
    },

    onCancel() {
      this.afterSubmit();
    },
  },
});
