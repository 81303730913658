/* eslint-disable ember/no-classic-classes, object-shorthand, no-multi-spaces, no-console, ember/no-get, indent, key-spacing, ember/no-jquery */
import { run } from '@ember/runloop';
import { Promise } from 'rsvp';
import $ from 'jquery';
import Evented from '@ember/object/evented';
import EmberObject from '@ember/object';
import { isEmpty } from '@ember/utils';

export default EmberObject.extend(Evented, {

  entityId: null,
  docId: null,
  path: null,
  resourceGroup: null,
  store: null,

  defaultContentType: 'application/octet-stream',

  resource: null,

  upload: function(file) {
    let uploader = this;

    this.set('isUploading', true);

    return this.createResource(file)
      .then(function(resource) {
        uploader.set('resource', resource);
        return uploader.process(resource, file);
      })
      .then(function(data) {
        let response = data.data;
        let xhr      = data.xhr;

        // Set directory on resource?
        uploader.set('versionId', xhr.getResponseHeader('x-amz-version-id'));
        return uploader.finish(response);
      },
      function(xhr) {
        console.log(xhr);

        let resource = uploader.get('resource');
        if (resource) {
          resource.destroyRecord();
        }

        return uploader.didFail();
      });
  },

  // get the presigned URL for AWS S3 upload
  createResource: function(file) {
    let docId       = this.get('docId');
    let entityId    = this.get('entityId');
    let contentType = file.type || this.get('defaultContentType');
    let path        = file.name;
    let resourceGroup = this.get('resourceGroup');

    if (!isEmpty(docId)) {
      path = `${docId}/${path}`;
    }

    let store = this.get('store');
    let resource = store.createRecord('resource', {
                     entityId:     entityId,
                     contentType:  contentType,
                     path:         path,
                     parent:       resourceGroup
                   });

    return resource.save();
  },

  process: function(resource, file) {
    let uploader = this;
    let url      = resource.get('url'); // presigned AWS write url

    let settings = {
      url: url,
      type: 'PUT',
      contentType: file.type || this.get('defaultContentType'),
      processData: false,
      data: file,
      xhr: function() {
        let xhr = $.ajaxSettings.xhr();
        xhr.upload.onprogress = function(e) {
          uploader.didProgress(e);
        };
        return xhr;
      }
    };

    return this._ajax(settings);
  },

  finish: function(response) {
    this.didUpload();
    return response;
  },

  didFail: function() {
    this.set('isUploading', false);
    this.trigger('didFail');
  },

  didUpload: function() {
    let resource  = this.get('resource');
    let versionId = this.get('versionId');
    resource.set('version', versionId);

    this.set('isUploading', false);
    this.trigger('didUpload', resource);
  },

  didProgress: function(e) {
    e.percent = e.loaded / e.total * 100;
    this.trigger('progress', e);
  },

  _ajax: function(settings) {
    return new Promise(function(resolve, reject) {
      settings.success = function(data, status, xhr) {
        data = { data: data };
        data.xhr = xhr;
        run(null, resolve, data);
      };

      settings.error = function(xhr) {
        run(null, reject, xhr);
      };

      $.ajax(settings);
    });
  }

});
