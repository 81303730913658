/* eslint-disable ember/no-get, no-implicit-coercion */
import { helper as buildHelper } from '@ember/component/helper';
import { get } from '@ember/object';

export function testGeneSelectedForConfirmation(params/*, hash*/) {
  let test = params[0];
  let requestedTests = params[1];

  let testKey = get(test, 'key');
  let requestedTest = get(requestedTests, testKey);

  if (!requestedTest) {
    return false;
  }

  return !!get(requestedTest, 'confirmation');
}

export default buildHelper(testGeneSelectedForConfirmation);
