/* eslint-disable ember/no-component-lifecycle-hooks, ember/require-super-in-lifecycle-hooks, ember/no-get */
import { computed } from '@ember/object';
import { isPresent } from '@ember/utils';
import BaseComponent from 'shared/components/requisitions/requisition-base/component';
import layout from './template';
import ArrayProxy from '@ember/array/proxy';
import { A } from '@ember/array';

export default BaseComponent.extend({
  layout,
  classNames: ['row', 'requisition-medications'],

  templateKey: 'medication',
  readOnly: false,

  init() {
    this.rxnormMedications = ArrayProxy.create({ content: A() });
    this._super(...arguments);
  },

  didReceiveAttrs() {
    let rxnormDefined = isPresent(this.get('template.rxnormMedications'));
    if (rxnormDefined) {
      let medicationsPT = this.get('model.medicationsPT');
      this.set('rxnormMedications.content', medicationsPT);
    }
  },

  isVisible: computed('template', 'template.medication', function() {
    return isPresent(this.get('template.medication'));
  })

});
