/* eslint-disable ember/no-classic-classes, key-spacing */
import Model, { belongsTo, attr } from '@ember-data/model';

export default Model.extend({
  // Associations
  projectRequisitionTemplateAssociation: belongsTo('project-requisition-template-association'),
  billingConfiguration:                  belongsTo('billing-configuration', { async: true }),

  // Attributes
  configuration:                         attr()
});
