/* eslint-disable ember/no-computed-properties-in-native-classes */
import { inject as service } from '@ember/service';
import ActiveModelAdapter from 'active-model-adapter';
import config from 'ember-get-config';
import { getOwner } from '@ember/application';
import { computed } from '@ember/object';
import Ember from 'ember';

const {
  api_endpoint,
  api_namespace,
  tokenPropertyName
} = config;

export default class ApplicationAdapter extends ActiveModelAdapter {
  @service flashMessages;
  @service currentOrganization;
  @service currentSession;
  @service router;
  @service settings;
  @service session;

  namespace = api_namespace;
  host = api_endpoint;

  @computed(
    'session.{data.authenticated.access_token,isAuthenticated}',
    'currentOrganization.organization.id',
    'settings.portal')
  get headers() {
    const headers = {};
    if (this.session.isAuthenticated && !Ember.testing) {
      headers.Authorization = `Bearer ${this.session.data.authenticated[tokenPropertyName]}`;
    }

    let orgId = this.currentOrganization.get('organization.id');
    if (orgId) {
      headers['X-Organization-Id'] = orgId;
    }

    headers['X-Source'] = this.settings.portal ? 'Portal' : 'Lab';

    return headers;
  }

  handleResponse(status, headers, payload) {
    if (status === 401 && this.session.isAuthenticated) {
      this.session.invalidate();
    }

    if (status === 404) {
      this.flashMessages.error(
        'The requested resource could not be found, ' +
        'or you don\'t have sufficient permissions for the requested operation'
      );
    }

    if (status === 403 && payload && payload.mfa_required) {
      // attempting to clear currentOrganization to properly render app template
      let owner = getOwner(this);
      let controller = owner.lookup('controller:application'); // should return your the application/controller instance
      controller.set('currentOrganization', null);

      //forcing this error to only show once incase it comes from multiple requests
      this.flashMessages.queue.clear();
      this.flashMessages.error('Access to that organization requires two-step authentication, please enable it below');
      this.router.transitionTo('security');

      return;
    }

    if (status === 403) {
      if (this.currentSession.isSupportUser()) {
        this.flashMessages.queue.clear();
        this.flashMessages.error(
          'You do not appear to have access to this resource, ' +
          'please try elevating using Support Access'
        );

        this.router.transitionTo('support-access.index');
      } else {
        this.session.set('unauthorizedUser', true);
        this.router.transitionTo('unauthorized');
      }

      return;
    }

    return super.handleResponse(...arguments);
  }

  /**
   * @private
   * @method urlForQueryRecord
   * @param {Object} query
   * @param {String} modelName
   * @return {String} url
   */
  urlForQueryRecord(query, modelName) {
    if (query.id) {
      let url = this._buildURL(modelName, query.id);

      delete query.id;

      return url;
    }

    return this._buildURL(modelName);
  }

  buildQuery(snapshot) {
    let query = {};

    if (snapshot) {
      let { include, adapterOptions } = snapshot;
      let { fields } = (adapterOptions || {});
      let { for_portal } = (adapterOptions || {});

      if (include) {
        query.include = include;
      }

      if (fields) {
        query.fields = fields;
      }

      if (for_portal) {
        query.for_portal = for_portal;
      }
    }

    return query;
  }

}
