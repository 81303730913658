define("ember-preferences/setup", ["exports", "ember-preferences/service", "ember-preferences/storage/memory", "ember-preferences/storage/serializable", "ember-preferences/storage/namespaceable", "ember-preferences/storage/expirable", "ember-preferences/storage/defaultable", "ember-preferences/storage/compressible"], function (_exports, _service, _memory, _serializable, _namespaceable, _expirable, _defaultable, _compressible) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.register = register;
  // FIXME: How can I test this? `window.localStorage = ...` is disabled in most browsers
  // See: https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
  function isLocalStorageAvailable() {
    let isAvailable;
    try {
      window.localStorage.setItem('ember-preferences-test', true);
      isAvailable = window.localStorage.getItem('ember-preferences-test');
      window.localStorage.removeItem('ember-preferences-test');
    } catch (error) {
      isAvailable = false;
    }
    return isAvailable;
  }
  function localStorage(namespace) {
    let storage = _serializable.default.create({
      content: window.localStorage
    });
    if (namespace) {
      storage = _namespaceable.default.create({
        namespace,
        content: storage
      });
    }
    return storage;
  }
  function register(container, preferences) {
    // Configure the service
    let storage;
    if (isLocalStorageAvailable()) {
      storage = localStorage(preferences.namespace);
    } else {
      storage = _memory.default.create();
    }
    storage = _compressible.default.create({
      configuration: preferences.configuration || {},
      content: storage
    });
    storage = _expirable.default.create({
      expirations: preferences.expirations || {},
      content: storage
    });
    storage = _defaultable.default.create({
      defaults: preferences.defaults || {},
      content: storage
    });
    container.register('service:preferences', _service.default.create({
      _storage: storage
    }), {
      instantiate: false
    });
  }
});