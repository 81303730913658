import ApplicationSerializer from 'shared/serializers/application';

export default ApplicationSerializer.extend({
  attrs: {
    team: { deserialize: 'records', serialize: 'ids' },
    boxIntegration: { deserialize: 'records', serialize: 'ids' },
    trainingReportUrl: { serialize: false },
    notificationSetting: { embedded: 'always' },
    customAttributes: { serialize: false },
    supportedDateFormats: { serialize: false },
    supportedTimeFormats: { serialize: false },
    supportedTimeZones: { serialize: false }
  }
});
