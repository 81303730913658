/* eslint-disable ember/no-classic-classes, comma-spacing, dot-notation */
import Service from '@ember/service';
import config from 'ember-get-config';
import fetch from 'fetch';
import { serializeQueryParams } from 'ember-fetch/utils/serialize-query-params';

const { coriell_api_endpoint } = config;

export default Service.extend({
  trustedHosts: [
    /localhost/,
    coriell_api_endpoint.split('//')[1].split(':')[0]
  ],

  async request(url, options) {
    let throwOnError = false;
    if (options && options.throwOnError) {
      throwOnError = true;
      // This is our option, delete it so it won't be passed along
      delete options.throwOnError;
    }

    // Serialize query params for GET requests and append to the URL
    if (options && options.method.toLowerCase() === 'get' && options.data) {
      url = `${url}?${serializeQueryParams(options.data)}`;
      delete options.data;
    }

    // Pass the JSON string as `body` for POST, PUT, PATCH requests
    if (options && ['post','patch','put'].includes(options.method.toLowerCase()) && options.data) {
      options.body = JSON.stringify(options.data);
      delete options.data;
    }

    if (options && !options.headers || !options.headers['Content-Type'] || !options.headers['content-type']) {
      options.headers = options.headers || {};
      options.headers['Content-Type'] = 'application/json';
    }

    if (options && !options.headers || !options.headers['Accept'] || !options.headers['accept']) {
      options.headers = options.headers || {};
      options.headers['Accept'] = 'application/json';
    }

    return await fetch(url, options).then(async (response) => {
      if (response.ok) {
        return response.json();
      }

      if (throwOnError) {
        throw response;
      }

      return response;
    });
  }
});
