/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components */
import { readOnly, not, or } from '@ember/object/computed';
import Component from '@ember/component';
import { computed, get } from '@ember/object';
import { task, timeout } from 'ember-concurrency';
import layout from './template';

export default Component.extend({
  layout,
  // Attributes
  model: null,
  isSaving: null,
  noChanges: not('model.hasDirtyAttributes'),

  onSubmit: () => {},
  onCancel: () => {},
  callAfterSubmit: () => {},

  isValid: false,
  isInvalid: not('isValid'),

  init() {
    this._super(...arguments);
    if (!this.model) {
      this.set('model', {});
    }
    this.validate.perform();
  },

  buttonLabel: computed('model.id', function() {
    if (this.model.id) {
      return 'Save';
    }
    return 'Create';
  }),

  saveDisabled: or('isSaving', 'isInvalid', 'noChanges'),
  cancelDisabled: readOnly('isSaving'),

  requiredFields: computed('formComponents', 'this.formComponents', function() {
    return this
      .formComponents
      .flatten()
      .filterBy('required', true)
      .mapBy('key');
  }),

  validate: task(function *() {
    yield timeout(250);

    const invalid = this
      .requiredFields
      .any((field) => !get(this.model, field));

    this.set('isValid', !invalid);
  }).restartable(),

});
