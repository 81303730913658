/* eslint-disable no-unused-vars, ember/no-classic-classes, ember/require-computed-property-dependencies, max-len, ember/require-computed-macros */
import { isPresent } from '@ember/utils';
import { computed, get, set } from '@ember/object';
import { alias } from '@ember/object/computed';
import Service, { inject as service } from '@ember/service';

export default Service.extend({
  currentSession: service(),
  _currentOrganization: service('current-organization'),

  currentUser: alias('currentSession.currentUser'),
  organizationId: alias('_currentOrganization.organization.id'),

  permissionsForOrganization: computed('organizationId', 'portalPermissions.@each.organization_id', function() {
    if (!this.organizationId) {
      return;
    }

    if (isPresent(this.portalPermissions) &&
        this.portalPermissions.find &&
        typeof this.portalPermissions.find === 'function') {
      return this.portalPermissions.find((p) => p.organization_id === parseInt(this.organizationId));
    }

    return;
  }),

  isPhysicianContact: computed('organizationId', 'permissionsForOrganization.physician_contact', function() {
    return isPresent(this.permissionsForOrganization) && this.permissionsForOrganization.physician_contact;
  }),

  isPrimaryPhysicianContact: computed('organizationId', 'permissionsForOrganization.primary_physician_contact', function() {
    return isPresent(this.permissionsForOrganization) && this.permissionsForOrganization.primary_physician_contact;
  }),

  hasPortalOrderingEnabled: computed('_currentOrganization.organization', 'organizationId', function() {
    return this._currentOrganization.organization.hasFeature('Portal Ordering');
  }),

  canCreateOrders: computed('hasPortalOrderingEnabled', 'isPhysicianContact', function() {
    return (this.hasPortalOrderingEnabled && this.isPhysicianContact);
  }),

  isSalesRep: computed('organizationId', 'permissionsForOrganization.sales_rep', function() {
    return isPresent(this.permissionsForOrganization) && this.permissionsForOrganization.sales_rep;
  }),

  isSalesRepWithRequisitionAccess: computed('organizationId', 'permissionsForOrganization.is_sales_rep_with_requisition_access', function() {
    return isPresent(this.permissionsForOrganization) && this.permissionsForOrganization.is_sales_rep_with_requisition_access;
  }),

  canPortalSign: computed('organizationId', 'permissionsForOrganization.{physician_contact,physician_or_delegate}', function() {
    return isPresent(this.permissionsForOrganization) &&
      this.permissionsForOrganization.physician_contact &&
      this.permissionsForOrganization.physician_or_delegate;
  }),

  canPortalSignWithSalesAccountId(salesAccountId) {
    if (!isPresent(this.permissionsForOrganization)) {
      return false;
    }

    const sales_account_ids = this.permissionsForOrganization.physician_or_delegate_for_sales_account || [];
    return sales_account_ids.includes(parseInt(salesAccountId));
  },

  setPortalPermissions(permissions) {
    this.set('portalPermissions', permissions);
  }
});
