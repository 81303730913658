/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components, ember/no-actions-hash */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import layout from './template';

export default Component.extend({
  layout,
  flashMessages: service(),
  requisitionReportSchedule: service(),

  loadDataTask: alias('requisitionReportSchedule.loadDataTask'),
  totalRows: alias('requisitionReportSchedule.totalRows'),

  actions: {
    delete(schedule) {
      schedule.destroyRecord()
        .then(() => {
          this.flashMessages.success('Report schedule has been deleted.');
        }).catch(() => {
          this.flashMessages.error('An error occurred deleting report schedule. Please try again.');
        });
    }
  }
});
