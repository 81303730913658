/* eslint-disable ember/no-classic-classes, key-spacing */
import Model, { belongsTo, attr } from '@ember-data/model';

export default Model.extend({
  hl7Mapping:               belongsTo('hl7-mapping', { async: false }),

  name:                     attr('string'),
  configuration:            attr(),
  configurationSchema:      attr(),
  deliveryType:             attr('string'),
  requireSignature:         attr('boolean'),
  signatureRequests:        attr()
});
