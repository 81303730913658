/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components, ember/no-component-lifecycle-hooks, ember/no-jquery, quotes */
import Component from '@ember/component';
import Tribute from 'tributejs';
import layout from './template';
import { htmlSafe } from '@ember/template';
import $ from 'jquery';

export default Component.extend({
  layout,
  attributeBindings: ['contenteditable'],
  classNames: [ 'auto-complete-note' ],
  contenteditable: true,

  updateValue() {},

  /**
   * Pass this in as an action if you need a different manipulation of the DOM
   */
  convertToHtml() {},

  input() {
    let value = this._convertToPlainString();
    this.updateValue(value);
  },

  mouseLeave() {
    let value = this._convertToPlainString();
    this.updateValue(value);
  },

  focusOut() {
    let value = this._convertToPlainString();
    this.updateValue(value);
  },

  didInsertElement() {
    this._super(...arguments);
    this.setHTMLFromValue();
    this._initTribute();
  },

  willDestroyElement() {
    this._super(...arguments);
    this._destroyTribute();
  },

  didReceiveAttrs() {
    this._super(...arguments);

    if (this.value === '') {
      this.setHTMLFromValue();
    }

    // This updates the values once they've been computed
    if (this.tribute) {
      this.tribute.collection.firstObject.values = this.values;
    }
  },

  _initTribute() {
    let tribute = new Tribute({
      collection: this.options
    });
    tribute.attach(this.element);
    this.set('tribute', tribute);
  },

  _destroyTribute() {
    this.tribute.hideMenu();
    this.tribute.detach(this.element);
  },

  setHTMLFromValue() {
    const element = $(this.element);

    if (element) {
      element.html(this.convertToHtml(this.value));
    }
  },

  _convertToPlainString() {
    let markup = htmlSafe($(this.element).html()).toString();
    let sections = [];
    let text = null;

    // capture first line
    if (markup.indexOf('<div>')) {
      text = markup
        .split('<div>')[0]
        .replace(/&nbsp;/g, ' ')
        .replace(/<br>/g, '');

      sections.push(text);
    }

    let lines = markup.match(/<div>.*?<\/div>/g) || [];

    lines.forEach((section) => {
      text = section
        .match(/<div>(.*?)<\/div>/)[1]
        .replace(/&nbsp;/g, ' ')
        .replace(/<br>/g, '');

      sections.push(text);
    });

    return sections.join("\n");
  }
});
