/* eslint-disable indent, key-spacing, space-before-function-paren, ember/no-get */
import { alias } from '@ember/object/computed';
import { get, computed } from '@ember/object';
import layout from './template';
import BaseComponent from '../../../requisition-base/component';

export default BaseComponent.extend({
  layout,

  classNameBindings: ['columnWith'],

  namespace: undefined,

  selectMappingColor: {
      default:  'light-gray'
    },

  columnWith: computed('options.columnWidth', function () {
    const columnWidth = get(this, 'options.columnWidth');

    return columnWidth ? `span-columns-${columnWidth}` : '';
  }),

  isVisible: true,
  requiredAttribute: alias('options.required'),

  requirements: computed('model.value', 'options.name', 'requiredAttribute', function () {
    if (get(this, 'requiredAttribute')) {
      return [{ key: 'value', name: get(this, 'options.name') }];
    } else {
      return [];
    }
  })
});
