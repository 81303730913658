/* eslint-disable ember/no-classic-components, object-curly-spacing, quotes, ember/no-classic-classes, ember/require-tagless-components, ember/no-get, max-len, ember/no-actions-hash */
import layout from './template';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { assert } from '@ember/debug';
import { get, set } from '@ember/object';
import {task, timeout} from "ember-concurrency";
import ENV from 'ember-get-config';

const TIMEOUT = ENV.environment === 'test' ? 0 : 1000;
export default Component.extend({
  layout,

  store: service(),
  showSavedAddressForm: false,
  savedAddress: null,

  init() {
    this._super(...arguments);
    assert('Missing required attribute `salesAccount`', this.salesAccount);
    this.loadData.perform();
  },

  loadData: task(function *() {
    yield timeout(TIMEOUT);
    let query = {
      sales_account_id: get(this, 'salesAccount.id'),
    };
    query.fields = 'id,name,streetAddress,streetAddressLine2,city,state,zipCode,phoneNumber,faxNumber,email,notes,updatedAt';

    let data = yield get(this, 'store').query('saved-contact-info', query);
    set(this, 'savedContactInfos', data);
    return data;
  }).restartable(),

  _createSavedAddress() {
    let savedAddress = this.store.createRecord('saved-contact-info', {
      salesAccountId: this.salesAccount.get('id')
    });
    this.set('savedAddress', savedAddress);
  },

  actions: {
    toggleModal() {
      this.toggleProperty('showSavedAddressForm');
      this.loadData.perform();
    },

    createSavedAddress() {
      this._createSavedAddress();
      this.title = 'Create New Saved Address';
      this.toggleProperty('showSavedAddressForm');
    },

    updateSavedAddress(savedContactInfo) {
      this.savedAddress = savedContactInfo;
      this.title = 'Edit Saved Address';
      this.toggleProperty('showSavedAddressForm');
    },
  },
});
