/* eslint-disable ember/no-classic-components, ember/no-mixins, ember/no-classic-classes, ember/require-tagless-components, key-spacing, ember/no-get, ember/require-return-from-computed, space-before-function-paren, keyword-spacing, object-shorthand, ember/no-actions-hash */
import { inject as service } from '@ember/service';
import { alias, bool, sort } from '@ember/object/computed';
import Component from '@ember/component';
import { computed, get, getProperties } from '@ember/object';
import { isNone } from '@ember/utils';
import PlateStorageLocationOptions from 'shared/mixins/plate-storage-location-options';
import EnforceSampleIdentifier from 'shared/mixins/enforce-sample-identifier';
import isEqual from 'lodash/isEqual';
import layout from './template';

export default Component.extend(PlateStorageLocationOptions, EnforceSampleIdentifier, {
  layout,
  store: service(),

  // Attributes
  template: null,
  model: null,
  readOnly: false,
  portalOrder: false,
  // register: callback action

  providerSortKey: ['name'],
  panelSortKey:    ['name'],

  organization:       alias('model.organization'),
  project:            alias('model.project'),
  providers:          sort('model.providers', 'providerSortKey'),
  physicians:         alias('model.physicians'),
  salesAccounts:      alias('model.salesAccounts'),
  requisition:        alias('model.requisition'),
  resources:          alias('requisition.resources'),
  patient:            alias('requisition.patient'),
  physician:          alias('requisition.physician'),
  billingInformation: alias('requisition.billingInformation'),
  samples:            alias('requisition.samples'),
  requisitionTemplate: alias('requisition.requisitionTemplate'),
  enforceSingleIdentifier: bool('requisitionTemplate.enforceSingleIdentifier'),

  interpolationConfig: alias('template.requisition.sampleIdentifierInterpolation'),

  // Samples
  sample1: computed('template.samples.[]', function() {
    let samples = this.get('template.samples');
    if (isNone(samples)) {
      return false;
    }
    return samples[0];
  }),

  sample2: computed('template.samples.[]', function() {
    let samples = this.get('template.samples');
    if (isNone(samples)) {
      return false;
    }
    return samples[1];
  }),

  sample3: computed('template.samples.[]', function() {
    let samples = this.get('template.samples');
    if (isNone(samples)) {
      return false;
    }
    return samples[2];
  }),

  sample4: computed('template.samples.[]', function() {
    let samples = this.get('template.samples');
    if (isNone(samples)) {
      return false;
    }
    return samples[3];
  }),

  s1: computed('samples.isFulfilled', function() {
    if (this.get('samples')) {
      return this.get('samples').objectAt(0);
    }
  }),

  s2: computed('samples.isFulfilled', function() {
    if (this.get('samples')) {
      return this.get('samples').objectAt(1);
    }
  }),

  s3: computed('samples.isFulfilled', function() {
    if (this.get('samples')) {
      return this.get('samples').objectAt(2);
    }
  }),

  s4: computed('samples.isFulfilled', function() {
    if (this.get('samples')) {
      return this.get('samples').objectAt(3);
    }
  }),

  samplesShown: [],

  showSample1: computed('sample1', 'samplesShown.[]', function () {
    return this.samplesShown.any((sample) => isEqual(sample, this.sample1));
  }),

  showSample2: computed('sample2', 'samplesShown.[]', function () {
    return this.samplesShown.any((sample) => isEqual(sample, this.sample2));
  }),

  showSample3: computed('sample3', 'samplesShown.[]', function () {
    return this.samplesShown.any((sample) => isEqual(sample, this.sample3));
  }),

  showSample4: computed('sample4', 'samplesShown.[]', function () {
    return this.samplesShown.any((sample) => isEqual(sample, this.sample4));
  }),

  showAddSampleButton: computed('requisition', 'samplesShown.[]', 'template.samples.[]', function() {
    let { requisition, samplesShown } = getProperties(this, ['requisition', 'samplesShown']);
    let samples = get(this, 'template.samples');

    if (isNone(samples)) {
      return false;
    }

    return get(requisition, 'isNew') && samples.length !== samplesShown.length;
  }),

  // Sample States
  s1SampleState: computed('s1.sampleStates.[]', function() {
    return this._getSampleState('s1');
  }),
  s2SampleState: computed('s2.sampleStates.[]', function() {
    return this._getSampleState('s2');
  }),
  s3SampleState: computed('s3.sampleStates.[]', function() {
    return this._getSampleState('s3');
  }),
  s4SampleState: computed('s4.sampleStates.[]', function() {
    return this._getSampleState('s4');
  }),

  _setSamplesIdentifiers() {

    if(get(this, 'showSample1')) {
      this._setSampleIdentifier(get(this, 's1'), 1);
    }

    if(get(this, 'showSample2')) {
      this._setSampleIdentifier(get(this, 's2'), 2);
    }

    if(get(this, 'showSample3')) {
      this._setSampleIdentifier(get(this, 's3'), 3);
    }

    if(get(this, 'showSample4')) {
      this._setSampleIdentifier(get(this, 's4'), 4);
    }

  },

  _setSamplesToShow() {
    let samples = get(this, 'template.samples');
    let numberOfExistingSamples = get(this, 'requisition.samples.length');

    if (isNone(samples)) {
      return false;
    } else {
      let samplesShown = get(this, 'samplesShown');

      samples.forEach((sample, idx) => {
        if (sample.required || numberOfExistingSamples > idx) {
          samplesShown.pushObject(sample);
        }
      });
    }
  },

  init() {
    this._super(...arguments);

    this._setSamplesToShow();
  },

  _getSampleState: function(name) {
    let sample = this.get(name);
    let store = this.get('store');

    if (isNone(sample)) {
      return;
    }

    if (sample.get('sampleStates.length') < 1) {
      sample.get('sampleStates').pushObject(store.createRecord('sample-state'));
    }

    return sample.get('sampleStates.firstObject');
  },

  actions: {
    addSample() {
      let samples = this.get('template.samples');
      let samplesShown = get(this, 'samplesShown');

      samplesShown.pushObject(samples[samplesShown.length]);
      this._setSamplesIdentifiers();
    },

    removeSample(sample) {
      let samplesShown = get(this, 'samplesShown');

      samplesShown.removeObject(sample);
    }
  }

});
