/* eslint-disable ember/no-classic-classes, key-spacing */
import Model, { belongsTo, attr } from '@ember-data/model';

export default Model.extend({
  // Associations
  organization:   belongsTo('organization'),
  user:           belongsTo('user'),

  // Attributes
  name:               attr('string'),
  email:              attr('string'),
  firstName:          attr('string'),
  lastName:           attr('string'),
  jobTitle:           attr('string'),
  contactInformation: attr('string'),
  emergencyContact:   attr('string'),
  userId:             attr('string'),
});
