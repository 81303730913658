/* eslint-disable ember/no-classic-components, ember/no-mixins, ember/no-classic-classes, ember/require-tagless-components, no-trailing-spaces, ember/no-component-lifecycle-hooks, ember/require-super-in-lifecycle-hooks, key-spacing, no-multiple-empty-lines, max-len, object-shorthand, space-before-blocks, ember/no-actions-hash, no-multi-spaces */
import Component from '@ember/component';
import { assert } from '@ember/debug';
import { get, set, computed } from '@ember/object';
import { equal, not } from '@ember/object/computed';
import { isBlank } from '@ember/utils';
import { task, timeout } from 'ember-concurrency';
import layout from './template';

import OnClickOutElement from 'shared/mixins/on-click-out-element';

export default Component.extend(OnClickOutElement, {

  init() {
    this._super(...arguments);

    assert('Missing required `options`', this.options);
    assert('Missing required `displayName`', this.displayName);
    assert('Missing required `filterKey`', this.filterKey);

    set(this, 'filteredOptions', this.options);
    set(this, 'optionsSelected', []);
  },

  didUpdateAttrs() {
    set(this, 'filteredOptions', this.options);
    set(this, 'optionsSelected', []);
  },

  layout,
  classNames: ['wb-multi-select-dropdown'],

  onChangeSelection: () => {},
  onConfirmSelection: () => {},

  confirmSelection: false,
  placeholder: 'Choose options',
  confirmText: 'Add options',
  displayName: null,
  filterKey: null,
  currentSearch: '',
  options: [],

  filteredOptions:[],
  optionsSelected: [],

  confirmDisabled: equal('optionsSelected.length', 0),

  // custom attributes
  showOptions: false,

  noOptions: equal('filteredOptions.length', 0),
  hasOptions: not('noOptions'),

  allSelected: computed('options.[]', 'optionsSelected.[]', function() {
    if (this.options.length > 0) {
      return this.options.length == this.optionsSelected.length;
    }

    return false;
  }),


  filterOptionsTask: task(function *() {
    yield timeout(250);

    let options = this.options;
    let searchValue = this.currentSearch;
    if (!isBlank(searchValue)) {
      let filteredOptions = options.filter(option => get(option, this.filterKey).toLowerCase().includes(searchValue.toLowerCase()));
      set(this, 'filteredOptions', filteredOptions);
    } else {
      set(this, 'filteredOptions', options);
    }
  }),

  triggerOnSelectionChanged: function(optionsSelected) {
    if (!this.confirmSelection) {
      this.onChangeSelection(optionsSelected);
    }
  },

  // Override my custom callback
  onOutsideClick: function() {
    if (!this.isDestroyed){
      this.send('hideOptions');
    }
  },

  actions: {

    toogleOptions() {
      this.toggleProperty('showOptions');
    },

    hideOptions() {
      set(this, 'showOptions', false);
    },

    confirmSelection() {
      this.onConfirmSelection();
    },

    updateSelectionForAll() {
      let newStatus =  !(this.optionsSelected.length === this.options.length);
      const options = this.options;
      let optionsSelected = [];
      if (newStatus) {
        optionsSelected = options;
      } 

      set(this, 'optionsSelected', optionsSelected);
      this.triggerOnSelectionChanged(optionsSelected);
    },

    updateSelectionFor(option) {
      let optionsSelected = this.optionsSelected;
      let indexSelected =  optionsSelected.indexOf(option);

      if (indexSelected >= 0) {
        // so I need to remove the option
        optionsSelected = optionsSelected.filter(item => item !== option);
      } else {
        optionsSelected.pushObject(option);
      }

      set(this, 'optionsSelected', optionsSelected);
      this.triggerOnSelectionChanged(optionsSelected);
    }
  }
});
