/* eslint-disable ember/no-new-mixins, ember/use-brace-expansion, ember/no-get */
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import Mixin from '@ember/object/mixin';

export default Mixin.create({
  approved: alias('currentRevision.approved'),

  currentRevision: computed('revisions.[]', 'revisions.content', function() {
    return this.get('revisions.content')[0];
  }),

  approvedRevision: computed('revisions.[]', function() {
    return this.get('revisions').find(function(revision) {
      return revision.approved;
    });
  }),

  approvedRevisions: computed('revisions.[]', function() {
    return this.get('revisions').filter(function(revision) {
      return revision.approved;
    });
  })
});
