/* eslint-disable ember/no-new-mixins, space-before-function-paren, ember/no-get, max-len */
import Mixin from '@ember/object/mixin';
import { computed, get } from '@ember/object';
import { isPresent } from '@ember/utils';
import { alias } from '@ember/object/computed';

export default Mixin.create({
  requisitionTemplateFields: alias('reqTemplate.requisition.fields'),

  showCollectionDate: computed('requisitionTemplateFields', function () {
    let requisitionTemplateFields = get(this, 'requisitionTemplateFields');

    return isPresent(requisitionTemplateFields) && requisitionTemplateFields.map((field) => field.key).includes('sampleCollectionDate');
  }),

  requiredCollectionDate: computed('requisitionTemplateFields', function () {
    const requisitionTemplateFields = get(this, 'requisitionTemplateFields');
    const collectionDateConfig = requisitionTemplateFields.findBy('key', 'sampleCollectionDate');

    return isPresent(collectionDateConfig) && collectionDateConfig.required;
  }),

  showCollectionTime: computed('requisitionTemplateFields', function () {
    const requisitionTemplateFields = get(this, 'requisitionTemplateFields');

    return isPresent(requisitionTemplateFields) && requisitionTemplateFields.map((field) => field.key).includes('sampleCollectionTime');
  }),

  requiredCollectionTime: computed('requisitionTemplateFields', function () {
    const requisitionTemplateFields = get(this, 'requisitionTemplateFields');
    const collectionTimeConfig = requisitionTemplateFields.findBy('key', 'sampleCollectionTime');

    return isPresent(collectionTimeConfig) && collectionTimeConfig.required;
  }),

  showCollectedBy: computed('requisitionTemplateFields', function () {
    let requisitionTemplateFields = get(this, 'requisitionTemplateFields');

    return isPresent(requisitionTemplateFields) && requisitionTemplateFields.map((field) => field.key).includes('sampleCollectedBy');
  }),

  requiredCollectedBy: computed('requisitionTemplateFields', function () {
    const requisitionTemplateFields = get(this, 'requisitionTemplateFields');
    const collectedByConfig = requisitionTemplateFields.findBy('key', 'sampleCollectedBy');

    return isPresent(collectedByConfig) && collectedByConfig.required;
  })
});
