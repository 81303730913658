/* eslint-disable ember/no-get, quotes, ember/no-actions-hash, semi, object-shorthand, no-multiple-empty-lines */
import { assert } from '@ember/debug';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { task, timeout } from 'ember-concurrency';
import BaseComponent from 'shared/components/requisitions/requisition-base/component';
import layout from './template';

export default BaseComponent.extend({
  layout,
  classNames: ['row', 'rxnorm-medications'],

  store: service(),

  // Attributes
  model: null,
  template: null,

  // Internal
  selectedMedication: null,

  init() {
    this._super(...arguments);
    assert('`model` is a required attribute', this.model);
  },

  isVisible: computed('template', 'template.rxnormMedicationsV2', function() {
    const legacyMedications = this.get('template.rxnormMedicationsV2');

    return isPresent(legacyMedications);
  }),

  configuration: computed('template', 'template.{medications,rxnormMedicationsV2}', function() {
    const configuration = this.get('template.medications') || this.get('template.rxnormMedicationsV2');

    if (typeof(configuration) === "object") {
      return configuration;
    }

    return { strategy: 'medications' };
  }),

  medications: computed('model.medicationsPT', function() {
    return this.model.medicationsPT || [];
  }),

  search: task(function *(search) {
    yield timeout(250);

    const strategy = this.configuration.strategy;
    const medications = yield this.store.query('medication', { search, strategy, per_page: 50 });
    const results = medications.toArray();

    return results.sortBy('name');
  }).restartable(),

  actions: {
    select(medication) {
      this.set("selectedMedication", medication);
    },

    add() {
      let name = this.selectedMedication.genericName || this.selectedMedication.name

      if (this.selectedMedication.brandName) {
        name = `${name} [${this.selectedMedication.brandName}]`
      }
      this.medications.addObject({
        id: this.selectedMedication.id,
        rxcui: this.selectedMedication.rxcui,
        name: name,
        genericName: this.selectedMedication.genericName,
        brandName: this.selectedMedication.brandName,
      });

      this.set("selectedMedication", null);
    },

    remove(medication) {
      this.medications.removeObject(medication);
    }
  }


});
