/* eslint-disable ember/no-observers, curly, ember/no-get, no-multi-spaces, object-shorthand, quotes */
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { observer } from '@ember/object';
import { notEmpty } from '@ember/object/computed';
import Uploader from 'shared/utils/uploader';
import FileField from 'shared/components/file-field/component';
import layout from './template';

export default FileField.extend({
  layout,
  entityId: '',
  docId: '',
  label: '',
  store: service(),
  session: service(),

  isUploading: notEmpty('pendingUploads'),

  filesDidChange: observer('files', function() {
    if (isEmpty(this.files)) return;

    this.set('pendingUploads', []);

    for (let i = 0; i < this.files.length; i++) {
      this._uploadFile(this.files[i]);
    }

    this.set('files', []);
  }),

  _uploadFile(file) {
    let fileUpload = this;
    let entityId = this.get('entityId');
    let docId    = this.get('docId');
    let label    = this.get('label');
    let store    = this.get('store');

    this.pendingUploads.push(true);

    let uploader = Uploader.create({
      entityId: entityId,
      docId: docId,
      store: store
    });

    uploader.on('didUpload', function(resource) {
      fileUpload.pendingUploads.pop();
      if (!isEmpty(label)) {
        resource.set('label', label);
      }
      if (typeof fileUpload.attrs.setUploading === "function") {
        fileUpload.attrs.setUploading(false);
      }
      if (typeof fileUpload.attrs.validateOnSelect === "function") {
        fileUpload.attrs.validateOnSelect(resource);
      }
      if (typeof fileUpload.attrs.updateResource === "function") {
        fileUpload.attrs.updateResource(resource);
      }
      if (typeof fileUpload.attrs.postProcessing === "function") {
        fileUpload.attrs.postProcessing(file);
      }
      if (typeof fileUpload.updateProgress === "function") {
        fileUpload.updateProgress(0);
      }
    });

    uploader.on('didFail', function() {
      fileUpload.pendingUploads.pop();
      fileUpload.updateProgress(0);
    });

    uploader.on('progress', function(e) {
      if (typeof fileUpload.updateProgress === "function") {
        fileUpload.updateProgress(e.percent);
      }
      fileUpload.session.refreshSession();
    });

    if (!isEmpty(file)) {
      if (typeof fileUpload.attrs.setUploading === "function") {
        fileUpload.attrs.setUploading(true);
      }
      uploader.upload(file); // Uploader will send a sign request then upload to S3
    }
  },
});
