/* eslint-disable ember/no-classic-components, ember/no-mixins, ember/no-classic-classes, ember/require-tagless-components, ember/no-actions-hash */
import Component from '@ember/component';
import { computed } from '@ember/object';
import UserPreferences from 'shared/mixins/user-preferences';
import layout from './template';

const requisitionSaveOption = 'requisitionSave';

const options = {
  'save': {
    code: 'save',
    label: 'Save',
    action: 'on-save'
  },
  'submit': {
    code: 'submit',
    label: 'Save & Finish',
    action: 'on-submit'
  }
};

export default Component.extend(UserPreferences, {
  layout,
  classNames: ['requisition-save-dropdown'],
  _selected: null,

  selected: computed('_selected', 'userPreference', function() {
    if (this._selected) {
      return this._selected;
    } else {
      return options.save;
    }
  }),

  buttonOptions: computed('options', function() {
    let buttonOptions = [];
    for (let key in options) {
      buttonOptions.pushObject(options[key]);
    }
    return buttonOptions;
  }),

  saveUserPreference(code) {
    this.updateLastAction(requisitionSaveOption, code);
  },

  userPreferenceLoaded(userPreference) {
    if (userPreference) {
      this.set('userPreference', userPreference);
      let code = userPreference.lastAction[requisitionSaveOption];
      if (code) {
        this.set('_selected', options[code]);
      }
    }
  },

  actions: {
    select(code, toggle) {
      this.set('_selected', options[code]);
      toggle();
    },

    doAction() {
      let selected = this.selected;
      let action = selected.action;
      this.saveUserPreference(selected.code);
      this.get(action)();
    }
  }

});
