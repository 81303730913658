/* eslint-disable quotes, ember/no-get */
import { computed } from '@ember/object';
import { isPresent } from '@ember/utils';
import BaseComponent from 'shared/components/requisitions/requisition-base/component';
import layout from "./template";

export default BaseComponent.extend({
  layout,
  classNames: ['row', 'requisition-recurrent-conditions'],

  templateKey: 'recurrentConditions',
  readOnly: false,

  isVisible: computed('template', 'template.recurrentConditions', function() {
    return isPresent(this.get('template.recurrentConditions'));
  })

});
