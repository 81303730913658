/* eslint-disable semi */
import SegmentService from 'ember-cli-segment/services/segment';
import { inject as service } from '@ember/service';

export default class WinnebagoSegmentService extends SegmentService {
  @service settings;

  trackEvent(event, properties, options, callback) {
    properties = Object.assign({}, {
      eventSource: this.settings.eventSource()
    }, properties);

    return super.trackEvent(event, properties, options, callback)
  }
}
