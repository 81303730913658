import Model, { hasMany, belongsTo, attr } from '@ember-data/model';

export default class ProjectRequisitionTemplateAssocitaionModel extends Model {
  // Associations
  @belongsTo('organization', { async: true }) organization;
  @belongsTo('project', { async: true }) project;
  @belongsTo('requisition-template', { async: false }) requisitionTemplate;
  @belongsTo('associated-billing-configuration', { async: false }) associatedBillingConfiguration;
  @hasMany('requisition-sample-routing', { async: false }) requisitionSampleRoutings;
  @hasMany('associated-report-configuration', { async: false }) associatedReportConfigurations;

  // Attributes
  @attr('string') requisitionTemplateName;
  @attr('number') projectId;
  @attr('string') projectName;
  @attr('string') exportUrl;
  @attr('string') copyId;

  get name() {
    return this.requisitionTemplateName;
  }

  get requisitionSampleRouting() {
    return this.requisitionSampleRoutings?.firstObject;
  }

}
