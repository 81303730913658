/* eslint-disable semi, quotes, brace-style, prefer-template, comma-spacing, space-infix-ops */
import { helper as buildHelper } from '@ember/component/helper';
import { isBlank } from '@ember/utils';

export function truncate(textAndLength) {
  const text = textAndLength[0]
  const number = textAndLength[1] || 5

  if (isBlank(text)) {
    return "";
  }

  else if (text.length > number) {
    return text
      .slice(0,(number - 1))+"...";
  }

  else {
    return text
  }

}

export default buildHelper(truncate);
