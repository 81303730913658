/* eslint-disable ember/no-classic-classes, ember/no-get, indent, object-shorthand, ember/no-jquery, quotes */
import { alias } from '@ember/object/computed';
import { run } from '@ember/runloop';
import $ from 'jquery';
import { Promise } from 'rsvp';
import Service, { inject as service } from '@ember/service';
import config from 'ember-get-config';

const { api_endpoint, api_namespace } = config;

export default Service.extend({

  currentSession: service('current-session'),
  _currentOrganization: service('current-organization'),
  organizationId: alias('_currentOrganization.organization.id'),

  sendResourceForValidation(resourceId, entityId, entityType, label, additionalData) {
    let token = this.get('currentSession.token');
    let organizationId = this.get('organizationId');
    let data = Object.assign({ resource_id: resourceId, entity_id: entityId,
            entity_type: entityType, label: label }, additionalData);

    return new Promise(function(resolve) {
      $.ajax({
        url: `${api_endpoint}/${api_namespace}/data_parse/validate`,
        data: data,
        method: 'POST',
        dataType: "json",
        headers: {
          'Authorization': `Bearer ${token}`,
          'X-Organization-Id': organizationId
        },
        success: function(data) {
          let validationResult = data.message;
          run(null, resolve, validationResult);
        },
        error: function(xhr) {
          console.error(`Failed to validate resource: ${xhr.statusText}`);
          run(null, resolve, { validationFailed: true });
        }
      });
    });
  },

  sendFileContentForValidation(fileContent, entityType, additionalData) {
    let token = this.get('currentSession.token');
    let organizationId = this.get('organizationId');
    let data = Object.assign({ entity_type: entityType,
            organization_id: organizationId,
            file_content: fileContent }, additionalData);
    return new Promise(function(resolve) {
      $.ajax({
        url: `${api_endpoint}/${api_namespace}/data_parse/validate`,
        data: data,
        method: 'POST',
        dataType: "json",
        headers: {
          'Authorization': `Bearer ${token}`,
          'X-Organization-Id': organizationId
        },
        success: function(data) {
          let validationResult = data.message;
          run(null, resolve, validationResult);
        },
        error: function(xhr) {
          console.error(`Failed to validate file content: ${xhr.statusText}`);
          run(null, resolve, { validationFailed: true });
        }
      });
    });
  }
});
