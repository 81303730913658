/* eslint-disable ember/no-classic-classes, quotes */
import EmberObject from '@ember/object';

export default EmberObject.extend({

  _type: 'custom-attributes',

  unknownProperty(key) {
    let obj = this.constructor.create({});

    this[key] = obj;

    return obj;
  },

  isEqual() {
    return false;
  },

  toString() {
    return "";
  }

});
