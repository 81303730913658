/* eslint-disable quotes */
import { capitalize } from '@ember/string';
import { helper as buildHelper } from '@ember/component/helper';

export function batchName(params) {
  const workflowType = capitalize(params[0]);
  const workflowId = params[1].toString();
  let formattedWorkflowId = workflowId;

  if (workflowId.length < 4) {
    formattedWorkflowId = "0".repeat(4 - workflowId.length) + workflowId;
  }

  return `${workflowType}-${formattedWorkflowId}`;
}

export default buildHelper(batchName);