/* eslint-disable ember/no-new-mixins, ember/no-jquery */
import Ember from 'ember';
import Mixin from '@ember/object/mixin';
import { computed } from '@ember/object';
import $ from 'jquery';

const {
  K
} = Ember;

const bound = function(fnName) {
  return computed(fnName, function() {
    return this.get(fnName).bind(this);
  });
};

export default Mixin.create({
  windowClick: K,
  clickHandler: bound('windowClickHandler'),

  windowClickHandler(e) {
    this.handleWindowClick(e);
  },

  addClickListener() {
    $(window).on('click', this.clickHandler);
  },

  removeClickListener() {
    $(window).off('click', this.clickHandler);
  }
});
