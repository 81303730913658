/* eslint-disable no-unused-vars, quotes, max-len, ember/no-get, no-trailing-spaces, indent */
import { not, alias } from '@ember/object/computed';
import { computed, get } from '@ember/object';
import BaseComponent from 'shared/components/requisitions/requisition-base/component';
import { isPresent } from '@ember/utils';
import layout from "./template";
import { inject as service } from '@ember/service';

const SAMPLE_FIELDS = ['sampleCollectionDate', 'sampleCollectionTime', 'sampleCollectedBy'];

export default BaseComponent.extend({
  currentOrganization: service('current-organization'),
  currentSession: service(),
  behavior: service('sl-behavior'),
  layout,
  classNames: ['row', 'requisition-details'],

  // Attributes
  template: null,
  model: null,
  readOnly: false,
  templateKey: 'requisition',
  isExisting: not('model.isNew'),

  isVisible: true,

  identifierDisabled: computed('behavior.behaviors.routes', 'isExisting', 'organization.id', 'requisition.processingStatus', function() {
    let status = this.get('requisition.processingStatus');
    if (status === "processing" || status === "complete") {
      return !this.currentSession.hasSupportFullAccessEnabledForOrg(this.organization.id);
    }
    return !this.behavior.isAble('requisitions.update', 'route');
  }),

  allowedFields: computed('template.requisition.fields', function() {
    let fields = get(this, 'template.requisition.fields');

    if (isPresent(fields)) {
      return fields.filter((field) => !SAMPLE_FIELDS.includes(field.key));
    } else {
      return [];
    }
  }),

  minRequirements: computed('template', function() {
    return [{ key: 'identifier', name: 'Identifier' }];
  }),

  requirements: computed('minRequirements', 'template', function() {
    let minRequirements = this.get('minRequirements');
    return minRequirements.concat(this._requirements());
  }),

  errors: computed('model.errors.[]', function() {
    let errors = this.get('model.errors');
    let mappedErrors = [];

    if (errors) {
      errors.forEach(function(error) {
        switch (error.attribute) {
          case 'base':
            mappedErrors.push(error.message);
            break;
          case 'samples.identifier':
            mappedErrors.push(`Sample identifier ${error.message}`);
            break;
          case 'patient.sex':
            mappedErrors.push(`Patient sex is required`);
            break;
          case 'patient.race':
            mappedErrors.push(`Patient race is required`);
            break;
          case 'patient.ethnicity':
            mappedErrors.push(`Patient ethnicity is required`);
            break;
          default:
            mappedErrors.push(`${error.attribute} ${error.message}`);
        }
      });
    }

    return mappedErrors.uniq();
  })

});
