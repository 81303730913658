/* eslint-disable ember/no-classic-classes, key-spacing */
import Model, { attr } from '@ember-data/model';
import { reads } from '@ember/object/computed';

export default Model.extend({

  name:  attr('string'),
  rxcui: attr('string'),

  nameWithDose: reads('name')

});
