/* eslint-disable ember/no-new-mixins */
import Mixin from '@ember/object/mixin';
import { isEmpty } from '@ember/utils';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

/**
 * @module
 * @augments ember/Mixin
 */
export default Mixin.create({
  currentSession: service(),
  currentOrganization: service(),
  portalPermissions: service(),
  router: service(),

  hasSalesPermission: computed('permissionRoute', 'portalPermissions', function() {
    if (this.portalPermissions.get(this.permissionRoute)) {
      return false;
    }

    return true;
  }),

  _checkPermissions() {
    if (this.permissionRoute && !this.portalPermissions.get(this.permissionRoute)) {
      console.error(`Permission denied for Route: ${this.permissionRoute}`);

      if (isEmpty(this.unableRoute)) {
        this.router.transitionTo('unauthorized');
      } else {
        this.router.transitionTo(this.unableRoute);
      }
    }
  },

  async beforeModel() {
    this._super(...arguments);

    // Wait for organization to be set
    if (this.currentOrganization?.organization) {
      return this._checkPermissions();
    }

    return;
  }
});
