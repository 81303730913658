/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components, space-before-blocks, ember/no-get, max-len, ember/no-component-lifecycle-hooks, semi, ember/no-actions-hash, indent, no-multiple-empty-lines */
import { inject as service } from '@ember/service';
import { get, set, computed } from '@ember/object';
import { notEmpty, equal, alias } from '@ember/object/computed';
import Component from '@ember/component';
import layout from './template';

export default Component.extend({
  layout,
  onChange: () => {},
  model: null,
  sampleTypes: [],
  hasSampleTypes: notEmpty('sampleTypes'),
  oneSampleTypeOption: equal('sampleTypes.length', 1),
  sampleType: alias('sampleTypes.firstObject'),
  selectedLabel: null,
  sample: null,
  requisition: alias('sample.requisition'),
  processingStatus: alias('requisition.processingStatus'),
  notInWorkflow: computed('processingStatus', 'requisition', function(){
    const nonProcessingStatus = ['held', 'unprocessed'];
    let processingStatus = get(this, 'processingStatus');
    return nonProcessingStatus.includes(processingStatus);
  }),

  showRouteModal: false,
  modalMss: computed('model', 'notInWorkflow', 'processingStatus', 'selectedLabel', function(){
    let notInWorkflow = get(this, 'notInWorkflow');
    let model = get(this, 'model');
    let selectedLabel = get(this, 'selectedLabel');
    if (notInWorkflow) {
      return `You are changing sample state label from '${model.label}' to '${selectedLabel}', would you like to update existing queue assignment to ${model.label}?`;
    } else {
      let processingStatus = get(this, 'processingStatus');
      return `Req form status is ${processingStatus}, you are changing sample state label from '${model.label}' to '${selectedLabel}', would you like to re-queue it again? If you select no, the selected sample label will still change but the sample will not be re-queued`;
    }
  }),
  flashMessages: service(),
  store: service(),

  didReceiveAttrs() {
    this._super(...arguments);
    if (this.model.label && !this.sampleTypes.includes(this.model.label)) {
      this.sampleTypes.push(this.model.label);
    }

    if (this.oneSampleTypeOption) {
      this.set('model.label', this.sampleType);
      this.onChange()
    }
  },

  _hideModal(){
    set(this, 'selectedLabel', null);
    set(this, 'showRouteModal', false);
  },

  actions: {
    hideModal() {
      this._hideModal();
    },

    changeLabel(label){

      let model = get(this, 'model');

      if (get(model, 'label') == label){
        return;
      }

      if (!model.isNew) {
        set(this, 'selectedLabel', label);
        this.toggleProperty('showRouteModal');
      } else {
        set(model, 'label', label);
        this.onChange();
      }
    },

    updateLabelAndChangeQueue(){
      let model = get(this, 'model');
      let selectedLabel = get(this, 'selectedLabel');
      let messages = get(this, 'flashMessages');
      let sample = get(this, 'sample');

      set(model, 'label', selectedLabel);
      set(model, 'requeue', true);

      sample.save().then(
        () => {
        messages.success('Sample saved');
    },
      () => {
        messages.error('Sample failed to save');
      }
    );

      this._hideModal();
    },

    updateLabelAndDontChangeQueue(){
      let selectedLabel = get(this, 'selectedLabel');
      let model = get(this, 'model');
      let messages = this.get('flashMessages');

      set(model, 'label', selectedLabel);
      let sample = get(this, 'sample');
      sample.save().then(
        () => {
        messages.success('Sample saved');
    },
      () => {
        messages.error('Sample failed to save');
      }
    );


      this._hideModal();
    }
  }

});
