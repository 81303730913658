/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components, ember/no-component-lifecycle-hooks, ember/no-actions-hash */
import Component from '@ember/component';
import layout from './template';

export default Component.extend({
  layout,
  classNames: ['filter-input'],

  focus: false,
  onKeyPress: () => { },

  value: '',
  type: 'text',
  placeholder: '',

  didRender() {
    this._super(...arguments);

    if (this.focus) {
      const searchInput = document.querySelector(`#${this.elementId} input`);

      if (searchInput) {
        searchInput.focus();
      }
    }
  },

  actions: {
    onChange() {
      this.onKeyPress();
    },

    reset() {
      this.set('value', null);
      this.onKeyPress();
    }
  }
});
