/* eslint-disable ember/no-classic-components, ember/no-classic-classes */
import Component from '@ember/component';
import { task, timeout } from 'ember-concurrency';
import { isBlank } from '@ember/utils';
import layout from './template';

const DEFAULT_TIMEOUT = 100;

export default Component.extend({
  layout,
  tagName: '',

  type: 'text',

  timeout: DEFAULT_TIMEOUT,

  updateValue: task(function *(value) {
    yield timeout(this.timeout);

    if (isBlank(value)) {
      value = null;
    }

    this.set('value', value);

    if (this.onChange) {
      this.onChange(value);
    }
  }).restartable()
});
