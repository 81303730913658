/* eslint-disable ember/no-classic-components, no-unused-vars, ember/no-classic-classes, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { task, timeout } from 'ember-concurrency';
import ENV from 'ember-get-config';
import layout from './template';

const TIMEOUT = ENV.environment === 'test' ? 0 : 1000;

export default Component.extend({
  layout,
  tagName: '',

  store: service(),
  flashMessages: service(),

  organization: null,
  projects: null,

  lookupProjects: task(function *(search) {
    yield timeout(TIMEOUT);

    if (!this.organization) {
      this.set('projects', []);
      return;
    }

    const query = {
      organization_id: this.organization_id,
      fields: 'id,name',
      q: search,
      page: 1,
      per_page: 10
    };

    const projects = yield this
      .store
      .query('project', query)
      .catch(() => {
        this.flashMessages.error('Failed to lookup Projects');
        return [];
      });

    return projects;
  }).restartable(),

  didReceiveAttrs() {
    this._super(...arguments);
    this.set('projects', this.lookupProjects.perform());
  }
});
