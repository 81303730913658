/* eslint-disable ember/no-classic-classes, key-spacing, ember/no-get, no-multi-spaces */
import Model, { belongsTo, hasMany, attr } from '@ember-data/model';
import { isPresent } from '@ember/utils';
import { get, computed } from '@ember/object';
import { alias } from '@ember/object/computed';

export default Model.extend({
  // Associations
  roles:            hasMany('role', { async: true }),
  userPreference:   belongsTo('user-preference', { async: true }),
  // Attributes
  uuid:             attr('string'),
  firstName:        attr('string'),
  lastName:         attr('string'),
  email:            attr('string'),
  token:            attr('string'),
  currentPassword:  attr('string'),
  password:         attr('string'),
  portalPermissions: attr(),
  createdAt:        attr('string'),
  officePhoneNumber:       attr('string'),
  cellPhoneNumber:         attr('string'),
  faxNumber:               attr('string'),
  communicationPreference: attr('string'),
  userPreferenceId:        attr('number'),
  digitalSignature: belongsTo('digital-signature'),
  currentSignature: alias('digitalSignature'),

  name: computed('firstName', 'lastName', function() {
    const firstName = this.get('firstName');
    const lastName  = this.get('lastName');

    return [firstName, lastName].compact().join(' ');
  }),

  initials: computed('{firstName,lastName}', function() {
    return [
      (this.firstName || '')[0],
      (this.lastName || '')[0],
    ]
      .compact()
      .join('');
  }),

  isPhysicianContact(organizationId) {
    const portalPermissions = get(this, 'portalPermissions');
    const permissionForOrg = portalPermissions.find((p) => p.organization_id === parseInt(organizationId));

    return isPresent(permissionForOrg) && permissionForOrg.physician_contact;
  }

});
