/* eslint-disable ember/no-new-mixins, ember/no-get, no-multi-spaces, quotes, max-len */
import { inject as service } from '@ember/service';
import { alias, empty } from '@ember/object/computed';
import Mixin from '@ember/object/mixin';
import { computed, get, set } from '@ember/object';
import { isPresent } from '@ember/utils';
import { task } from 'ember-concurrency';

export default Mixin.create({
  store: service(),

  init() {
    this._super(...arguments);
    this.get('_loadDataTask').perform();
  },

  resultTypes: computed('configuration.{resultType,resultTypes}', function() {
    const resultType  = this.get('configuration.resultType');
    const resultTypes = this.get('configuration.resultTypes');

    if (resultTypes) {
      return resultTypes;
    }

    if (typeof resultType === "string") {
      return [resultType];
    }

    if (typeof(resultType) === "object") {
      return resultType;
    }

    return [];
  }),

  missingSampleResults: computed('workflowSampleResults', 'workflowSamples', 'workflow', function() {
    let workflowSamples = get(this, 'workflowSamples');
    let workflowSampleResultsIdentifier = this.get('workflowSampleResults').map((result) => {
      return get(result, 'identifier');
    });

    if (workflowSamples == null) {
      return [];
    }

    if (workflowSampleResultsIdentifier.length === 0) {
      return workflowSamples.filter(function(sample) {
        return sample.get('control') === false;
      });
    }

    let workflowMissingSamples = workflowSamples.filter(function(sample) {
      return (workflowSampleResultsIdentifier.indexOf(sample.get('identifier')) === -1 && sample.get('control') !== true);
    });

    return workflowMissingSamples;
  }),

  // workflowSampleResults: computed('_workflowSampleResults.isFulfilled', 'resultTypes.[]', 'sourceContainerIds.[]', function() {
  workflowSampleResults: computed('_workflowSampleResults.isFulfilled', 'isNew', function() {
    let workflowSampleResults = this.get('_workflowSampleResults');
    let isNew = this.get('isNew');

    if (isNew) {
      return workflowSampleResults.map(wsr => this.calculateOutcome(wsr, this));
    }

    return workflowSampleResults.map(wsr => this.handleWsrAfterSubmitted(wsr, this));
  }),

  workflowSampleResultsEmpty: empty('workflowSampleResults.[]'),
  // _workflowSampleResults: computed('workflow.id', 'resultTypes.[]', 'sourceContainerIds.[]', function() {
  _workflowSampleResults: computed('resultTypes', 'sourceContainerIds', 'this.store', 'workflow.id', function() {
    const workflowId = this.get('workflow.id');
    const containers = this.get('sourceContainerIds');
    const resultTypes = this.get('resultTypes');

    const query = {
      workflow_id: workflowId,
      result_type: resultTypes
    };

    if (isPresent(containers)) {
      query.includes = ['sample_states'];
      query.containers = containers;
    }

    return this
      .store
      .query('workflow-sample-result', query);
  }),
  workflowSampleResultsLoading: alias('_workflowSampleResults.isPending'),

  _loadDataTask: task(function *() {
    let workflow = get(this, 'workflow');

    let organization = get(this, 'organization');

    if (workflow === undefined) {
      return;
    }

    let workflowSamples = yield this.store.query('workflow-sample', {
      workflow_id: workflow.id,
      include: 'sample'
    });

    let rejectReasons = yield this.store.query('reject-reason', {
      organization_id: organization.get('id') });

    if (rejectReasons) {
      set(this, 'rejectReasons', rejectReasons.toArray());
    }

    set(this, 'workflowSamples', workflowSamples);
  })
});
