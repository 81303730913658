import moment from 'moment';
import { helper as buildHelper } from '@ember/component/helper';

export function formatDate(params/*, hash*/) {
  let date = params[0];
  let format = params[1];
  return moment(date).format(format);
}

export default buildHelper(formatDate);
