/* eslint-disable no-unused-vars, max-len, ember/no-component-lifecycle-hooks */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */

import Component from '@ember/component';
import layout from './template';
import { computed } from '@ember/object';
import { gt, or } from '@ember/object/computed';
import { assert } from '@ember/debug';
import config from 'ember-get-config';
import { isBlank } from '@ember/utils';

const { DUMMY_TOTAL_ENTRIES, DUMMY_TOTAL_CUTOFF } = config;

/**
 * Contextual component a part of {Ui::Table} that normalizes UI
 * for lower footer of tables throughout the app. From left to right the items
 * displayed are:
 * 1. The number of records shown of the total records
 * 2. Dropdown to select the number of records per page
 * 3. The pagination controls as well as the current page
 *
 * Example usage:
 * ```
 * <Ui::Table ... as |table|>
 *   ...
 *   <table.footer />
 * </Ui::Table>
 * ```
 * Since this component already has access to the table component (parent) it is
 * not necessary any longer to pass it in. If `@data` is set on the {Ui::Table}
 * component then the component will use this for calculating `numRecords`. If
 * not (as is the case with some {Ui::Table} instances) then the use of `data`
 * on this component is required.
 *
 * Optional parameters:
 * * `downloadCsv` - an action that can be called when user selects to
 *     "Download CSV".
 * * `data` - an array of objects/records displayed (including meta data)
 * * `totalEntries` - for tables that do not use `meta` and pagination the
 *      component can be provided a number for the total number of entries, or
 *      total rows
 **/
export default Component.extend({
  layout,
  tagName: '',

  disableCsvButton: false,
  dummyTotalEntries: DUMMY_TOTAL_ENTRIES,
  dummyTotalCutoff: DUMMY_TOTAL_CUTOFF,

  /**
   * The current number of records displayed visible to the user (the paged
   * data currently shown in the table)
   */
  numRecords: or('tableData.content.length', 'tableData.length'),

  recordsFound: gt('numRecords', 0),

  /**
   * * Static Data passed into {Ui::Table} definition as `@data` will live on
   *   `table.data`
   * * Async Data resolved by `@loadData` task defined on `Ui`::Table will live
   *   on `table.resolvedData` and will contain `meta` information as well if
   *   API includes it in the response
   * * Alternatively you can pass in `@data` to `<table.footer>` directly to
   *   override any of the afore mentioned items
   */
  tableData: or('data', 'table.resolvedData', 'table.data'),

  /**
   * This component should be able to infer the number of records based on:
   * * the `meta` returned with `tableData`
   * * the `paginationData.totalRows` which is acquired from the {Ui::Table}
   *   definition as `totalRows`
   * * explicitely passed in on the `<table.footer>` definition with `@totalEntries`
   */
  totalRows: or('tableData.meta.total_entries', 'table.paginationData.totalRows', 'totalEntries'),

  pageEnd: computed('numRecords', 'table.paginationData.{pageSize,pageStart,pageEnd}', function() {
    if (this.numRecords && this.table?.paginationData && this.numRecords < this.table.paginationData.pageSize) {
      return this.table.paginationData.pageStart + this.numRecords - 1;
    }

    return this.table?.paginationData?.pageEnd;
  }),

  isLastPage: computed('numRecords', 'table.paginationData.{pageSize,isLastPage}', function() {
    return this.numRecords && this.numRecords < this.table.paginationData.pageSize || this.table.paginationData.isLastPage;
  }),

  didReceiveAttrs() {
    this._super(...arguments);
    // assert('`table` must be provided to ui/table/footer component', isBlank(this.table));
    // assert('`table.data` or `data` must be set on ui/table/footer component', isBlank(this.table.data) && isBlank(this.data));
  }
});
