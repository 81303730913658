/* eslint-disable ember/no-classic-classes, key-spacing */
import Model, { belongsTo, attr } from '@ember-data/model';
import { computed } from '@ember/object';

export default Model.extend({
  // Associations
  salesAccount:  belongsTo('sales-account', { async: true, inverse: 'contactsSalesAccounts' }),
  salesAccountContact: belongsTo('sales-account-contact', { async: true, inverse: 'contactsSalesAccounts' }),
  user: belongsTo('user', { async: true }),

  // Attributes
  archived:     attr('boolean'),
  archivable:   attr('boolean'),
  salesAccountId: attr('number'),
  salesAccountContactId: attr('number'),
  primaryContact: attr('boolean'),

  setPrimaryContact(value) {
    this.set('primaryContact', value);
  },

  shouldShowReset: computed('salesAccountContact.canResetPassword', 'archived', function() {
    return this.salesAccountContact && this.salesAccountContact.get('canResetPassword') && !this.archived;
  })
});
