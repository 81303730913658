/* eslint-disable ember/no-computed-properties-in-native-classes */
import { alias } from '@ember/object/computed';
import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class CurrentOrganizationService extends Service {

  @tracked organization = null;

  @alias('organization.id') id;

}
