/* eslint-disable ember/no-classic-classes, ember/no-get */
import Service, { inject as service } from '@ember/service';
import { task, timeout } from 'ember-concurrency';
import { alias, gt } from '@ember/object/computed';
import { get } from '@ember/object';
import parseQueryData from 'shared/utils/parse-ransack';
import config from 'ember-get-config';

const TIMEOUT = config.environment === 'test' ? 0 : 250;

export default Service.extend({
  store: service(),
  currentOrganization: service(),
  settings: service(),

  fromLabel: 'From',
  toLabel: 'To',
  klass: 'date',

  reportType: null,
  lastQuery: null,

  adminReports: alias('loadDataTask.lastSuccessful.value'),
  dateFormat: alias('organization.datePickerFormat'),
  organization: alias('currentOrganization'),
  portalOrder: alias('settings.portalOrder'),
  recordsFound: gt('totalRows', 0),
  totalRows: alias('data.meta.total_entries'),

  reload() {
    this.loadDataTask.perform();
  },

  loadDataTask: task(function *(params) {
    params = params || this.params;
    this.set('params', params);

    let query = parseQueryData(params);
    query.q.report_type_eq = this.reportType;
    query.organization_id = this.get('organization.id');
    query.include = 'user,resource';
    query.fields = [
      'id',
      'name',
      'user',
      'resource',
      'request-date',
      'status',
      'search-params',
      'associated-sales-accounts',
      'associated-physicians',
      'associated-project',
      'associated-requisition-template',
      'associated-workflow',
      'generatedFromSchedule',
    ].join(',');

    yield timeout(TIMEOUT);

    this.set('lastQuery', query);

    let adminReports = yield get(this, 'store').query('admin-report', query);
    this.set('data', adminReports);

    return adminReports;
  }).restartable(),
});
