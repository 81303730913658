define("ember-preferences/storage/expirable", ["exports", "@ember/object", "ember-preferences/storage/decorator"], function (_exports, _object, _decorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.expirable = expirable;
  _exports.isExpirable = isExpirable;
  _exports.isExpired = isExpired;
  /**
   * @private
   */
  function isExpirable(value) {
    return typeof value === 'object' && value !== null && value.type === 'expirable';
  }

  /**
   * @private
   */
  function isExpired(value) {
    return typeof value.expirationTime === 'number' && +new Date() > value.expirationTime;
  }

  /**
   * @private
   *
   * Creates a new expirable value
   *
   * @param {Number} expirationTime - absolute time in milliseconds since UNIX epoch
   * @param {Any} value - value to store
   * @return {Object}
   */
  function expirable(expirationTime, value) {
    return {
      type: 'expirable',
      expirationTime,
      value
    };
  }
  var _default = _exports.default = _object.default.extend(_decorator.default, {
    getItem(key) {
      let obj = this._super(key);
      if (isExpirable(obj)) {
        if (isExpired(obj)) {
          this.removeItem(key);
          return;
        }
        return obj.value;
      }
      return obj;
    },
    setItem(key, value) {
      this._super(key, this.wrapValue(key, value));
      return value;
    },
    wrapValue(key, value) {
      if (typeof this.get('expirations')[key] === 'function') {
        return expirable(this.get('expirations')[key](), value);
      } else {
        return value;
      }
    }
  });
});