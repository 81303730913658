/* eslint-disable ember/no-computed-properties-in-native-classes */
import BaseComponent from 'shared/components/requisitions/requisition-base/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import layout from './template';

// Classic Component
// Need to change usage of BaseComponent for @glimmer/component
export default class RequisitionAdditionalProviderAccount extends BaseComponent {
  @service store;
  @service settings;

  layout = layout;
  classNames = ['row', 'requisition-additional-pa'];
  isVisible = true;
  readOnly = false;

  @computed('settings.portalOrder')
  get settingsPortalOrder() {
    return this.settings.portalOrder;
  }

  @computed('model.salesAccount')
  get noProviderAccount() {
    return Boolean(!this.model.salesAccount?.get('id'));
  }

  @computed('_dirtyAttributes', 'model.{physician,salesAccount}')
  get minRequirements() {
    if (this.model.salesAccount?.get('id') || this.model.physician?.get('id')) {
      return [
        { key: 'salesAccount.id', name: 'Additional Provider Account' },
        { key: 'physician.id', name: 'Additional Provider' }
      ];
    }

    return [];
  }

  @computed('_dirtyAttributes', 'model.physician', 'requirements')
  get missingRequirements() {
    return this._missingFrom('requirements', this.model.physician);
  }
}
