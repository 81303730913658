/* eslint-disable ember/no-classic-classes, key-spacing, ember/no-get */
import Model, { attr, belongsTo } from '@ember-data/model';
import moment from 'moment';

export default class ControlContainerModel extends Model {
  // Associations
  @belongsTo('organization', { inverse: null, async: false }) organization;
  @belongsTo('control', { inverse: null, async: false }) control;
  @belongsTo('user') owner;

  @attr('string') identifier;
  @attr('string') lotNumber;
  @attr('date') dateReceived;
  @attr('date') openDate;
  @attr('date') expirationDate;
  @attr('boolean') isEmpty;
  @attr('boolean', { defaultValue: false }) archived;
  @attr('boolean', { defaultValue: false }) archivable;
  @attr('string') currentLocation;
  @attr('string') barcodeLabel;
  @attr('string') controlName;

  get formattedDateReceived() {
    let date = this.dateReceived;
    return moment(date).format(this.organization.dateOutputFormat);
  }
}
