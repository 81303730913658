/* eslint-disable ember/no-classic-classes */
import Model, { belongsTo, hasMany, attr } from '@ember-data/model';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  frequency: {
    description: 'Frequency',
    validators: [ validator('presence', true) ]
  },

  startDate: {
    description: 'Start date',
    validators: [
      validator('presence', true),
      validator('date', { after: 'now' })
    ]
  },

  organization: {
    description: 'Organization',
    validators: [ validator('presence', true) ]
  }
});

export default Model.extend(Validations, {
  name: attr('string'),
  frequency: attr('string'),
  startDate: attr('datetime'),
  createdAt: attr('date'),
  lastRunDate: attr('date'),
  columns: attr('string'),
  recipients: attr('string'),
  recipientEmails: attr('string'),
  portal: attr('boolean', { defaultValue: false }),

  organization: belongsTo('organization', { async: false }),
  project: belongsTo('project', { async: false }),
  provider: belongsTo('physician', { async: false }),
  providerAccount: belongsTo('sales-account', { async: false }),
  requisitionTemplate: belongsTo('requisition-template', { async: false }),
  providerAccounts: hasMany('sales-account', { async: false }),
  providers: hasMany('physician', { async: false }),
  testPanel: belongsTo('test-panel', { async: false }),
  user: belongsTo('user', { async: false }),

});
