/* eslint-disable ember/no-get */
import { typeOf } from '@ember/utils';
import { assert } from '@ember/debug';
import { computed } from '@ember/object';
import SlAble from 'shared/components/sl-able';
import layout from './template';

export default SlAble.extend({
  layout,
  tagName: '',

  showContent: computed(
    'behavior', 'behaviorService.behaviors', 'possible', 'provider.behaviors',
    function() {
      const possible = this.get('possible');

      assert(
        'Expects `possible` property to be a Boolean',
        'boolean' === typeOf(possible)
      );

      return this.isViewable(
        this.get('behavior'),
        this.get('provider'),
        possible
      );
    }
  )

});
