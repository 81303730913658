/* eslint-disable ember/no-classic-components, ember/no-classic-classes, key-spacing, max-len, ember/no-get, indent, no-multi-spaces, no-trailing-spaces, quotes, ember/no-actions-hash */
import { isNone, isBlank } from '@ember/utils';
import { alias, equal, and } from '@ember/object/computed';
import Component from '@ember/component';
import EmberObject, { set, get, computed } from '@ember/object';
import moment from 'moment';
import layout from './template';
import { camelize, capitalize } from '@ember/string';

export default Component.extend({
  layout,
  tagName: '',

  model:  undefined,
  field:  undefined, // { name: 'First Name', key: 'firstName', type: 'string', required: true, placeholder: '', pattern: '' }
  value:  undefined,
  prefix: undefined,
  namespace: undefined,

  name:         alias('field.name'),
  key:          alias('field.key'),
  required:     alias('field.required'),
  placeholder:  alias('field.placeholder'),
  pattern:      alias('field.pattern'),
  columns:      alias('field.columns'),
  multiple:     alias('field.multiple'),
  match:        alias('field.value'),
  options:      alias('field.options'),
  prompt:       alias('field.prompt'),
  blank:        alias('field.blank'),
  instruction:  alias('field.instruction'),
  label:        alias('field.label'),
  startDate:    alias('field.startDate'),
  endDate:      alias('field.endDate'),

  dateFormat: 'YYYY-MM-DD',

  isDisabled: computed('disabled', function() {
    if (this.get('disabled')) {
      return true;
    } else {
      return undefined;
    }
  }),

  areOptionsWithLabels: computed('options', function() {
    let options = this.get('options');
    return Array.isArray(options);
  }),

  type: computed('field.renderType', function() {
    let type = this.get('field.renderType');
    return camelize(type.replace(/^simple/, ''));
  }),

  klass: computed('columns', function() {
    let columns = this.get('columns');
    let klass = null;

    switch (columns) {
      case 1:  klass = 'span-columns-1'; break;
      case 2:  klass = 'span-columns-2'; break;
      case 3:  klass = 'span-columns-3'; break;
      case 4:  klass = 'span-columns-4'; break;
      case 5:  klass = 'span-columns-5'; break;
      case 6:  klass = 'span-columns-6'; break;
      case 7:  klass = 'span-columns-7'; break;
      case 8:  klass = 'span-columns-8'; break;
      default: klass = '';
    }

    return klass;
  }),

  isCheckbox: equal('field.renderType', 'checkbox'),
  isRadio:    equal('field.renderType', 'radio'),
  isText:     equal('field.renderType', 'text'),
  isTextarea: equal('field.renderType', 'textarea'),
  isDate:     equal('field.renderType', 'date'),
  isSimpleText: equal('field.renderType', 'simpleText'),
  isSelect:   equal('field.renderType', 'select'),
  isMultiSelect: equal('field.renderType', 'multi-select'),
  isSpacer:   equal('field.renderType', 'spacer'),
  isToggle:   equal('field.renderType', 'toggle'),
  isInstruction: equal('field.renderType', 'instruction'),
  isNumber:   equal('field.renderType', 'number'),

  multipleCheckbox: and('multiple', 'isCheckbox'),

  compositeKey: computed('prefix', 'key', function() {
    let prefix = this.get('prefix');
    let key = this.get('key');

    if (isNone(prefix)) {
      return key;
    }

    return prefix + capitalize(key);
  }),
  cssCompositeKey: computed('compositeKey', function() {
    return this.get('compositeKey').replace(/\./g, '-');
  }),

  computedModel: computed('compositeKey', 'isCheckbox', 'isDate', 'model', function() {
    const model = this.model;
    const computedKey = this.compositeKey.replace(/[. ()]/g, '-');
    const compositeKey = this.compositeKey;
    const isDate = this.isDate;
    const isCheckbox = this.isCheckbox;
    const isMultiSelect = this.isMultiSelect;

    return EmberObject
      .extend({
        model: null,
        value: computed(`model.${computedKey}`, 'model', {
          get() {
            const model = this.model;
            const value = get(model, compositeKey);

            if (isCheckbox && isBlank(value)) {
              set(model, compositeKey, false);
              return false;
            }

            if (value && value._type && value._type === 'custom-attributes') {
              set(model, compositeKey, undefined);
              return undefined;
            }

            if (isDate && (typeof value === "string")) {
              return moment(value).toDate();
            }

            if (isMultiSelect && (typeof value === "string")) {
              return JSON.parse(value);
            }

            return value;
          },
          set(_, value) {
            const model = this.model;

            if (isDate && !isNone(value)) {
              set(model, compositeKey, moment(value).utcOffset(0, true).toDate());
            } else {
              set(model, compositeKey, value);
            }

            return value;
          }
        })
      })
      .create({ model });
  }),

  actions: {
    datePickerFocusOutAction(component, event) {
      let targetValue = event.target.value;
      let currentDateValue = this.get('computedModel.value');

      if (targetValue) {
        let targetValueDate = moment(targetValue).toDate();
        if (isNone(currentDateValue) || targetValueDate.getTime() != currentDateValue.getTime()) {
          this.set('computedModel.value', targetValueDate);
        }
      }
    }
  }

});
