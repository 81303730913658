/* eslint-disable ember/no-classic-components, no-unused-vars, no-multiple-empty-lines, quotes, ember/no-classic-classes, ember/no-component-lifecycle-hooks, dot-notation, ember/no-actions-hash */
import Component from '@ember/component';
import layout from './template';

import { task, timeout } from 'ember-concurrency';
import fetch from 'fetch';
import moment from 'moment';
import { computed } from '@ember/object';
import { htmlSafe } from '@ember/template';
import { dasherize } from '@ember/string';
import { equal, alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Cookies from 'js-cookie';
import ENV from 'ember-get-config';


const DISMISSED = 'dismissed';
const BASE_CLASS_NAME = 'system-event';

const INCIDENTS = "incidents";

export default Component.extend({
  layout,

  tagName: '',
  displayEvent: null,
  isVisible: null,

  setIncidentDismissed: () => {},

  /**
   * @return message attribute that will be displayed in notification
   */
  details: computed('displayEvent.notes', function() {
    if (!this.displayEvent) {
      return "";
    }
    return htmlSafe(this.displayEvent.notes);
  }),

  /**
   * @return if the event has been dismissed
   */
  isDismissed: computed('displayEvent.id', function() {
    if (!this.displayEvent) {
      return;
    }

    return Cookies.get(this.displayEvent.id) === DISMISSED;
  }),

  /**
   * creates a cookie when the event is dismissed by user
   * write(name, value, options = {})
   */
  createCookie(id) {
    Cookies.set(id, DISMISSED);
  },

  // polls StatusPage and parses out relevant JSON response to give to components
  poll: task(function *() {
    let displayEvent = yield this.setDisplayEvent.perform();
    this.set('displayEvent', displayEvent);

    if (this.displayEvent && !this.isDismissed) {
      this.set('isVisible', true);
      this.setIncidentDismissed(this.isDismissed); // reset incidentDismissed
    } else {
      this.set('isVisible', false);
    }

    if (ENV.environment !== 'test') {
      yield timeout(30000);
      this.poll.perform();
    }
  }).restartable(),

  didReceiveAttrs() {
    this._super(...arguments);
    if (!this.displayEvent && (ENV['statuspage'].maintenance.enabled || ENV['statuspage'].incident.enabled)) {
      this.poll.perform();
    }
  },

  actions: {
    dismiss() {
      if (!Cookies.get(this.displayEvent.id)) {
        this.createCookie(this.displayEvent.id);
      }
      this.set('isVisible', false);
      this.setIncidentDismissed(true);
    }
  },
});
