/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components, no-trailing-spaces, ember/no-actions-hash, keyword-spacing */
import Component from '@ember/component';
import { copy } from 'ember-copy';
import layout from './template';

export default Component.extend({
  layout,

  labelSelector: null,

  onColumnSelectionChange: () => {},

  // Selected Columns handler
  reportColumns: [],
  selectedColumns: [],
  showColumns: false,  

  actions: {
    toggleColumn(column) {
      if (this.selectedColumns.includes(column)) {
        this.selectedColumns.removeObject(column);
      } else {
        this.selectedColumns.pushObject(column);
      }
      this.onColumnSelectionChange(this.selectedColumns);
    },

    checkAllColumns() {
      if(this.selectedColumns.length === this.reportColumns.length) {
        this.set('selectedColumns', []);
      } else {
        this.set('selectedColumns', copy(this.reportColumns));
      }
      this.onColumnSelectionChange(this.selectedColumns);
    }
  }
});
