/* eslint-disable ember/no-new-mixins, space-before-blocks, quotes */
import config from 'ember-get-config';
import { A } from '@ember/array';
import { inject as service } from '@ember/service';
import Mixin from '@ember/object/mixin';

const { api_endpoint } = config;

export default Mixin.create({
  currentSession: service(),
  selectedResources: [],
  resourcesParamName: 'resource_ids',
  isDownloading: false,
  forPortal: false,

  init() {
    this._super(...arguments);
    let resources = A();
    this.set('selectedResources', resources);
  },

  toggleIsDownloading(value){
    this.set('isDownloading', value);
  },

  actions: {
    bulkDownloadSelectedResources() {
      this.toggleIsDownloading(true);

      const form = document.createElement('form');
      form.method = 'post';
      form.action = `${api_endpoint}/resources/0/bulk_download`;

      const hiddenField = document.createElement('input');
      hiddenField.type = 'hidden';
      hiddenField.name = this.resourcesParamName;
      hiddenField.value = this.selectedResources;
      form.appendChild(hiddenField);

      const hiddenField2 = document.createElement('input');
      hiddenField2.type = 'hidden';
      hiddenField2.name = "is_bulk_download";
      hiddenField2.value = true;
      form.appendChild(hiddenField2);

      const hiddenField3 = document.createElement('input');
      hiddenField3.type = 'hidden';
      hiddenField3.name = "for_portal";
      hiddenField3.value = this.forPortal;
      form.appendChild(hiddenField3);

      const hiddenField4 = document.createElement('input');
      hiddenField4.type = 'hidden';
      hiddenField4.name = "token";
      hiddenField4.value = this.currentSession.token;
      form.appendChild(hiddenField4);

      document.body.appendChild(form);
      form.submit();
      setTimeout(this.toggleIsDownloading(false), 10000);
    }
  }
});
