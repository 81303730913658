/* eslint-disable ember/no-classic-classes, key-spacing */
import Model, { attr, belongsTo } from '@ember-data/model';

export default Model.extend({
  // Associations
  organization:  belongsTo('organization'),

  // Attributes
  supportEmail:  attr('string')
});
