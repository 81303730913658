import { attr, belongsTo, hasMany } from '@ember-data/model';
import { classify } from '@ember/string';
import { isPresent } from '@ember/utils';
import Source from 'shared/models/source';

export default class Container extends Source {

  @belongsTo('container-information', { async: false, inverse: 'container' }) containerInformation;
  @hasMany('tube', { async: true, inverse: 'container' }) tubes;

  @attr('string') name;
  @attr('string') location;
  @attr('string') label;
  @attr sampleIdentifiers;
  @attr('number') numberOfWells;
  @attr('boolean') qs;
  @attr('string') setupLink;
  @attr('string') qsSetupLink;
  @attr('string') viia7SetupLink;
  @attr('string') ab7500Link;
  @attr('string') qsCovidLink;
  @attr('string') abiCovidLink;
  @attr('boolean') forOrdersPortal;
  @attr positions;
  @attr('string') position;
  @attr('number') internalPosition;
  @attr barcodes;
  @attr({ defaultValue: () => [] }) wellAssignments;
  @attr({ defaultValue: () => {} }) printerOptions;
  @attr('boolean', { defaultValue: false }) removeOrigin;
  @attr('boolean', { defaultValue: false }) remove;
  @attr('number') containerId;
  @attr('boolean') shipmentContainerWithSubmittedShipment;
  @attr('boolean') shipmentContainerWithDeidentifiedSamples;
  @attr('boolean', { defaultValue: false }) relabelingByReceiver;

  get containerType() {
    return this.name;
  }

  get type() {
    return classify(this.constructor.modelName);
  }

  get samples() {
    return this.sampleStates?.models.map(sampleState => sampleState.sample);
  }

  get locationDescription() {
    let loc = this.location;
    if (isPresent(loc)) {
      return loc;
    }

    return 'Unknown';
  }

  get reversePosition() {
    const numericPosition = parseInt(this.position);
    if (numericPosition) {
      return numericPosition;
    }

    const position = this.position || '';
    const row = position.slice(0, 1);
    const column = position.slice(1, 3);

    return `${column}${row}`;
  }
}
