/* eslint-disable ember/no-classic-classes, no-multi-spaces, ember/no-get */
// /* global md5 */
import Service from '@ember/service';
import { get } from '@ember/object';

export default Service.extend({

  defaultImage: '',

  getUrl(email, defaultImage) {
    let def  = defaultImage || get(this, 'defaultImage');
    // let hash = md5(email);
    let hash =  email;

    return `//www.gravatar.com/avatar/${hash}?d=${def}&r=pg`;
  }

});
