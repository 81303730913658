/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components, ember/require-computed-property-dependencies */
import { computed } from '@ember/object';
import { sort } from '@ember/object/computed';
import Component from '@ember/component';
import layout from './template';

export default Component.extend({
  layout,

  classNames: [ 'organisms-detected', 'table', 'table-striped' ],

  hasHeader: false,
  isSorted: true,
  tagName: 'table',
  numbered: false,
  organismSort: ['load_percentage:desc'],

  sortedOrganisms: sort('organisms', 'organismSort'),

  // Component can receive an external `loadTotal` to calculate totals from
  // for when organisms are being split into different lists like in
  // medication-results-activity
  loadPercentagesTotal: computed('organisms.@each.load_percentage', function() {
    return this.loadTotal || this.organisms.reduce((t, r) => t + r.load_percentage, 0);
  }),

  organismsDisplay: computed('isSorted', 'sortedOrganisms', 'organisms', function() {
    if (this.isSorted) {
      return this.sortedOrganisms;
    }

    return this.organisms;
  })
});
