/* eslint-disable quotes, ember/no-component-lifecycle-hooks, max-len, ember/no-get, ember/require-computed-property-dependencies, indent, ember/no-observers, no-trailing-spaces, keyword-spacing, space-before-blocks, no-multi-spaces, ember/no-actions-hash */
import { alias, not, empty, filterBy } from '@ember/object/computed';
import { computed, get, set, observer } from '@ember/object';
import { isPresent } from "@ember/utils";
import { inject as service } from '@ember/service';
import BaseComponent from '../../requisition-base/component';
import layout from './template';
import config from 'ember-get-config';

const { DEFAULT_HELD_REASONS } = config;

export default BaseComponent.extend({
  layout,
  classNames: ['requisition-details'],

  ajax: service(),
  currentSession: service(),
  flashMessages: service(),
  store: service(),

  // Attributes
  template: null,
  processed: null,
  model: null,
  readOnly: false,
  templateKey: 'requisition',
  canEditIdentifier: false,
  isVisible: true,
  portalOrder: false,

  manuallyEditableHeldReasons: filterBy('heldReasons', 'manuallyEditable', true),
  isExisting: not('model.isNew'),
  requisitionCustomAttributes: alias('model.entityCustomAttributes'),
  requisitionHeldReasons: alias('model.heldReasons'),

  didReceiveAttrs() {
    this._super(...arguments);

    if (this.template && this.model && this.template.requisition.autoGenerateIdentifier && this.model.isNew) {
      this._loadAutogeneratedIdentifier();
    }
  },

  requisitionGlobalCustomAttributes: computed('portalOrder', 'requisitionTemplate.requisitionTemplateGlobalCustomAttributes.@each.{lab,portal}', 'this.requisitionTemplate', function() {
    return this
      .requisitionTemplate
      .get('requisitionTemplateGlobalCustomAttributes')
      .filterBy('globalCustomAttribute.entityType', 'requisition')
      .rejectBy(this.portalOrder ? 'portal' : 'lab', 'hidden');
  }),

  identifierDisabled: computed('canEditIdentifier', 'model', 'template', function() {
    let requisition = this.model;
    let template = get(this, 'template');
    let autoGenerateIdentifier = template.requisition.autoGenerateIdentifier;
    return (autoGenerateIdentifier && requisition.isNew) || (!this.canEditIdentifier && !requisition.isNew);
  }),

  minRequirements: computed('template.requisition.autoGeneratedIdentifier', function() {
    if (this.template.requisition.autoGenerateIdentifier) {
      return [];
    } else {
      return [{ key: 'identifier', name: 'Identifier' }];
    }
  }),

  requirements: computed('minRequirements', 'template', function() {
    let minRequirements = this.get('minRequirements');
    return minRequirements.concat(this._requirements());
  }),

  errors: computed('model.errors.[]', function() {
    let errors = this.get('model.errors');
    let mappedErrors = [];

    this.callRaygun(errors.toArray());

    if (errors) {
      errors.forEach(function(error) {
        switch (error.attribute) {
          case 'base':
            mappedErrors.push(error.message);
            break;
          case 'samples.identifier':
            mappedErrors.push(`Sample identifier ${error.message}`);
            break;
            case 'patient.sex':
              mappedErrors.push(`Patient sex is required`);
              break;
            case 'patient.race':
              mappedErrors.push(`Patient race is required`);
              break;
            case 'patient.ethnicity':
              mappedErrors.push(`Patient ethnicity is required`);
              break;
            default:
              if (error.attribute.match(/^[0-9]+$/)) {
                mappedErrors.push(error.message);
              } else {
                mappedErrors.push(`${error.attribute} ${error.message}`);
              }
        }
      });
    }

    return mappedErrors.uniq();
  }),

  callRaygun(errors) {
    if (!errors.length || !errors.any(error => error?.message?.match('Invalid Test Panel'))) {
      return;
    }

    try {
      throw new Error('Invalid Test Panel selection');
    } catch(e) {
      let test_panels = this.store.peekAll('test-panel').map(tp => tp.serialize());
      let test_genes = this.store.peekAll('test-gene').map(tg => tg.serialize());
      let test_gene_associations = this.store.peekAll('test-gene-association').map(tga => tga.serialize());

      window.Raygun.send(e, {
        test_panels,
        test_genes,
        test_gene_associations,
        requested_tests: this.model.requestedTests,
        errors
      });
    }
  },

  physicianChanged: observer('model.physician', function() {
    this._updateDirtyAttributes();
  }),

  _addHeldReason(heldReason) {
    if (!heldReason) {
      return;
    }

    const requisitionHeldReasons = this.requisitionHeldReasons;

    if (!requisitionHeldReasons.findBy('id', heldReason.id)) {
      requisitionHeldReasons.pushObject(heldReason);
    }
  },

  _removeHeldReason(heldReason) {
    if (this.requisitionHeldReasons.isDestroyed) {
      return;
    }
    this.requisitionHeldReasons.removeObject(heldReason);
  },

  cannotUnHold: empty('samplesReceived'),

  samplesReceived: computed('model.samples.@each.received', function() {
    const requisition = this.model;
    const samples = get(requisition, 'samples');

    if (samples && samples.length) {
      const samplesReceived = samples.filterBy('received', true);

      return samplesReceived;
    }

    return [];
  }),

  checkOnHold: observer('model.onHold', function() {
    const samplesReceived = this.samplesReceived;
    const requisition = this.model;

    if (requisition.onHold) {
      if (samplesReceived.length) {
        this._removeHeldReason(this.noSamplesReceivedReason);
        this._addHeldReason(this.manuallyEditableHeldReason);
      } else {
        this._removeHeldReason(this.manuallyEditableHeldReason);
        this._addHeldReason(this.noSamplesReceivedReason);
      }
    } else {
      this._removeHeldReason(this.manuallyEditableHeldReason);
      this._removeHeldReason(this.noSamplesReceivedReason);
    }
  }),

  hasBeenManuallyHeld: computed('requisitionHeldReasons.[]', function() {
    return this.requisitionHeldReasons.filterBy('manuallyEditable', true).length;
  }),

  checkSamplesReceived: observer('samplesReceived', function() {
    const requisition = this.model;

    if (this.samplesReceived.length) {
      this._removeHeldReason(this.noSamplesReceivedReason);
      if (!this.hasBeenManuallyHeld) {
        set(requisition, 'onHold', false);
      }
    } else if(requisition.onHold) {
      this._removeHeldReason(this.manuallyEditableHeldReason);
      this._addHeldReason(this.noSamplesReceivedReason);
    } else {
      set(requisition, 'onHold', true);
    }
  }),

  noSamplesReceivedReason: computed('heldReasons.[]', function() {
    return this.heldReasons.findBy('name', DEFAULT_HELD_REASONS.NO_SAMPLES_RECEIVED);
  }),

  manuallyEditableHeldReason: computed('heldReasons.[]', function() {
    return this.heldReasons.findBy('name', DEFAULT_HELD_REASONS.MANUALLY_EDITABLE);
  }),

  requiresBillingVerificationReason: computed('heldReasons.[]', function() {
    return this.heldReasons.findBy('name', DEFAULT_HELD_REASONS.REQUIRES_BILLING_VERIFICATION);
  }),

  async _loadAutogeneratedIdentifier(){
    const token   = this.currentSession.token;

    const organizationId = get(this, 'organization.id');
    const requisitionTemplate = get(this, 'requisitionTemplate');

    let response = await this.ajax.request(`${config.api_endpoint}/${config.api_namespace}/requisitions/next_req_identifier`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'X-Organization-Id': organizationId,
      },
      data: {
        requisition_template_id: requisitionTemplate.get('id'),
        organization_id: organizationId,
        for_portal: this.portalOrder
      }
    }).catch(() => {
      this.flashMessages.error('Failed to auto generate identifier');
    });

    this.model.set('identifier', response.identifier);
  },

  init() {
    this._super(...arguments);

    const requisition = this.model;
    const billingTemplateOptions = this.billingTemplateOptions;

    if (requisition.isNew) {
      this._addHeldReason(this.noSamplesReceivedReason);

      if (isPresent(billingTemplateOptions) && billingTemplateOptions.requireBillingVerification) {
        this._addHeldReason(this.requiresBillingVerificationReason);
      }
    }
  },

  actions: {
    addHeldReason(heldReasonId) {
      const heldReason = this.heldReasons.findBy('id', heldReasonId);
      this._addHeldReason(heldReason);
    },
    removeHeldReason(heldReason) {
      this._removeHeldReason(heldReason);
    }
  }
});
