/* eslint-disable ember/no-classic-classes */
import Service, { inject as service } from '@ember/service';

export default Service.extend({
  store: service(),

  updateEventLog(type, eventLogData) {
    // expect type to be "integration"
    let eventLog = this.store.peekAll(type).findBy('eventId', eventLogData.id);
    if (eventLog) {
      this.store.queryRecord(type, { event_id: eventLogData.id, record_id: eventLog.id });
    }
  }
});
