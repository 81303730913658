/* eslint-disable ember/no-classic-components, no-multiple-empty-lines, ember/no-classic-classes, ember/require-tagless-components, ember/no-get, dot-notation, semi */
import { get, set } from '@ember/object';
import { alias, empty, and } from '@ember/object/computed';
import Component from '@ember/component';
import { task, timeout } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { assert } from '@ember/debug';
import layout from './template';


export default Component.extend({
  layout,

  store: service(),

  params: null,
  searchParams: null,
  searchResult: [],

  hasSearched: false,
  hasName: empty('searchParams.first_name_cont'),
  hasLastName: empty('searchParams.last_name_cont'),
  hasMrn: empty('searchParams.mrn_cont'),
  hasDob: empty('searchParams.date_of_birth_eq'),

  hasParams: and('hasName', 'hasLastName', 'hasMrn', 'hasDob'),


  init() {
    this._super(...arguments);
    assert('Missing required attribute `requisition`', this.requisition);
    assert('Missing required attribute `organization`', this.organization);

    if (this.params) {
      this.set('searchParams', {
        first_name_cont: this.params.firstName,
        last_name_cont: this.params.lastName,
        mrn_cont: this.params.mrn,
        date_of_birth_eq: this.params.dateOfBirth,
        s: 'created_at desc'
      });

      if (!this.hasParams) {
        this.search.perform();
      }
    } else {
      this.set('searchParams', {});
    }
  },

  search: task(function *() {
    yield timeout(250);
    const searchParams = {
      organization_id: get(this, 'organization.id'),
      q: this.searchParams
    };

    searchParams['q']['sales_account_id_eq'] = this.requisition.get('salesAccount.id');

    if (this.portalOrder) {
      searchParams['for_orders_portal'] = true;
    }

    searchParams['include'] = [
      'billing-information',
      'billing-information.insurance-informations',
      'insurance-information.insurance-provider',
      'insurance-information.pre-authorization',
    ].join(',');

    const patients = yield get(this, 'store').query('patient', searchParams);

    let existing;
    const keys = Object.keys(this.fields);
    const uniqPatients = [];

    const samePatient = function(patient, other, keys) {
      return !keys
        .reject((key) => (get(patient, key) || '').toString() === (get(other, key) || '').toString())
        .length;
    }

    keys.push('sex');

    patients.forEach((patient) => {
      existing = uniqPatients.find((other) => samePatient(patient, other, keys));
      if (existing) {
        existing.identifier = `${existing.identifier}, ${patient.identifier}`;
      } else {
        uniqPatients.push(patient.getProperties(...keys, 'identifier', 'fullName', 'address', 'createdAt'));
      }
    });

    set(this, 'searchResult', uniqPatients);
    set(this, 'hasSearched', true);
  }),

  isSearching: alias('search.isRunning')
});
