/* eslint-disable ember/no-mixins, space-before-function-paren, ember/no-get */
import { inject as service } from '@ember/service';
import { not } from '@ember/object/computed';
import { get, computed } from '@ember/object';
import BaseComponent from '../../requisition-base/component';
import Progress from 'shared/mixins/progress';
import Resources from 'shared/mixins/resources';
import layout from './template';

export default BaseComponent.extend(Progress, Resources, {
  layout,
  classNames: ['row', 'requisition-file-upload'],

  store: service(),

  readOnly: false,
  editable: not('readOnly'),

  columnListClass: computed('editable', function () {
    return this.editable ? 'span-columns-9' : 'span-columns-12';
  }),
  resources: computed('model.resources', 'label', function() {
    let label = get(this, 'label');
    let resources = get(this, 'model.resources');
    if (label) {
      return resources.reject((resource) => {
        return resource.get('label') !== label;
      });
    }

    return resources;
  }),

  isVisible: true,

  incomplete: not('model.complete')

});
