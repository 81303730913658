define("ember-preferences/storage/compressible", ["exports", "@ember/object", "ember-preferences/storage/decorator"], function (_exports, _object, _decorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* global LZString */
  /**
   * @private
   */
  var _default = _exports.default = _object.default.extend(_decorator.default, {
    configuration: null,
    getItem(key) {
      let obj = this._super(key);
      if (this.isWhiteListed(key)) {
        return LZString.decompressFromUTF16(obj);
      }
      return obj;
    },
    setItem(key, value) {
      let compressed;
      if (this.isWhiteListed(key)) {
        compressed = LZString.compressToUTF16(value);
      } else {
        compressed = value;
      }
      this._super(key, compressed);
      return value;
    },
    isWhiteListed(key) {
      let config = this.get('configuration');
      return config[key] && config[key].compression;
    }
  });
});