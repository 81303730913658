export function fileContents(file) {
  let reader = new FileReader();
  reader.readAsText(file);
  return reader.result;
}

export function readFile(file, onLoadCallback) {
  let reader = new FileReader();
  reader.onload = onLoadCallback;
  reader.readAsText(file);
}
