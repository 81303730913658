/* eslint-disable ember/no-classic-components, ember/no-mixins, ember/no-classic-classes, ember/require-tagless-components, space-before-function-paren, ember/no-get, object-shorthand, semi, dot-notation, quotes, space-before-blocks, no-multiple-empty-lines, ember/no-component-lifecycle-hooks, ember/no-actions-hash, prefer-template */
import Ember from 'ember';
import Component from '@ember/component';
import CurrentUser from 'shared/mixins/current-user';
import { get, set, computed, getProperties } from '@ember/object';
import { inject as service } from '@ember/service';
import { sort, alias, or } from '@ember/object/computed';
import { task } from 'ember-concurrency';
import layout from './template';

const { Logger: { error } } = Ember;

export default Component.extend(CurrentUser, {
  layout,
  classNames: ['timeline'],

  store: service(),
  flashMessages: service(),
  currentOrganization: service(),
  settings: service(),

  showEntityName: false,
  showHeader: false,

  isPosting: false,
  showModal: false,
  scrollToNoteId: null,
  noteSortKey: ['date:desc'],
  sortedNotes: sort('notes', 'noteSortKey'),
  notes: [],

  userOptions: [],
  rolesOptions: [],
  mentionOptions: computed('userOptions', 'rolesOptions', function () {
    const userOptions = this.get('userOptions');
    const rolesOptions = this.get('rolesOptions');

    const mentionOptions = [];
    userOptions.forEach(user => {
      let { id, uuid, name } = getProperties(user, 'id', 'uuid', 'name');
      mentionOptions.push({ 'id': id, 'uuid': uuid, 'name': name });
    });

    rolesOptions.forEach(role => {
      let { id, name } = getProperties(role, 'id', 'name');
      mentionOptions.push({ 'id': id, 'uuid': `role-${id}`, 'name': name });
    })

    return mentionOptions;
  }),

  _loadDataTask: task(function *() {
    const organization = get(this, 'currentOrganization.organization');
    let params = {};
    params["organization_id"] = organization.id;
    params["requisition_id"] = this.get("model.id");
    if (this.salesPortal){
      params["sales_portal"] = true;
    }
    if (this.providerPortal){
      params["provider_portal"] = true;
    }


    const notesPromise = this.store.query('note', params);
    const notesList = yield notesPromise;

    this.set('notes', notesList.toArray());
  }),

  _loadUsersTask: task(function *() {
    const organization = get(this, 'currentOrganization.organization');

    let params = {
      organization_id: organization.id,
      fields: 'id,uuid,first-name,last-name',
      include: 'physicians,sales-reps'
    };
    const users = yield this
      .store
      .query('user', params)
      .catch(() => []);

    this.set('userOptions', users);

    if (!this.settings.portal) {
      let role_params = {
        organization_id: organization.id,
        fields: 'id,name',
      };

      const roles = yield this
        .store
        .query('role', role_params)
        .catch(() => []);

      this.set('rolesOptions', roles);
    }
  }),

  isLoading: or('_loadDataTask.isRunning', '_loadUsersTask.isRunning'),

  showVisibilityOptions: alias('notesPermissions.isLabUser'),

  didUpdateAttrs() {
    this._super(...arguments);

    const currentNote = get(this, 'newNote');
    const permissions = this.get('notesPermissions');

    const forPhysicians = permissions.isPhysicianContact || false;
    const forSales = forPhysicians ? false : (permissions.isSalesRep || false);

    currentNote.set('forSales', forSales);
    currentNote.set('forPhysicians', forPhysicians);

    set(this, 'newNote', currentNote);

    this.get('_loadDataTask').perform();
  },

  init() {
    this._super(...arguments);

    let newNote = this.initNewNote();

    set(this, 'newNote', newNote);

    this.get('_loadUsersTask').perform();
    this.get('_loadDataTask').perform();
  },

  initNewNote() {
    const currentOrganization = get(this, 'currentOrganization.organization');
    const notesPermissions = this.get('notesPermissions');

    const forLabs = notesPermissions.isLabUser || true;
    const forPhysicians = notesPermissions.isPhysicianContact || false;
    const forSales = forPhysicians ? false : (notesPermissions.isSalesRep || false);


    let newNote = get(this, 'store').createRecord('note', {
      editEnabled: true,
      body: '',
      parent: get(this, 'parentModel'),
      organization: currentOrganization,
      requisition: get(this, 'model'),
      user: get(this, 'currentUser'),
      forLabs: forLabs,
      forSales: forSales,
      forPhysicians: forPhysicians
    });

    return newNote;
  },

  willDestroyElement() {
    this._super(...arguments);

    get(this, 'newNote').rollbackAttributes();
  },

  updateRequisitionCount(value){
    let requisition = this.get('store').peekRecord('requisition', this.model.id);
    let currentCount = requisition.commentCount;

    set(requisition, 'commentCount', currentCount + value);

    set(this, 'model', requisition);
  },

  actions: {
    createNote({ body, forLabs, forSales, forPhysicians }) {
      const organization = this.get('currentOrganization.organization');
      const requisition = this.get('model');
      const user = this.currentUser;

      const note = this
        .store
        .createRecord('note', {
          body,
          forLabs,
          forSales,
          forPhysicians,
          organization,
          requisition,
          user,
          editEnabled: false,
        });

      return note
        .save()
        .then(() => {
          this.flashMessages.success('Comment added');
          this.notes.pushObject(note);
          this.updateRequisitionCount(1);
        });
    },

    saveNote(note) {
      let isNew = get(note, 'isNew');

      if (isNew) {
        set(note, 'date', new Date());
      }

      set(note, 'editEnabled', false);

      let messages = this.get('flashMessages');

      return note.save().then(() => {
        let textMessage = 'Comment updated';

        if (isNew) {
          this.get('_loadDataTask').perform();
          textMessage = 'Comment added';
        }

        let newNote = this.initNewNote();
        set(this, 'newNote', newNote);
        messages.success(textMessage);
      }).catch((err) => {
        set(this, 'isPosting', false);
        get(this, 'model.notes').removeObject(note);

        messages.error("An error ocurred when saving comment. Please try again. \nError:" + err);
        error('Error ocurred when saving comment', err);
      });
    },

    removeNote(note) {
      this.notes.removeObject(note);
      this.updateRequisitionCount(-1);
    }
  }
});
