/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components, key-spacing, space-before-blocks, ember/no-get, comma-spacing, space-in-parens, ember/no-actions-hash, semi */
import { readOnly, equal, or, not } from '@ember/object/computed';
import Component from '@ember/component';
import layout from './template';
import { computed, get } from '@ember/object';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';

export default Component.extend({
  layout,

  classNames: ['requisition-cancel-order'],

  flashMessages: service(),
  settings: service(),

  name: 'Order',
  requisition: null,

  isCanceled: equal('requisition.status', 'canceled'),
  isDisabled: or('isSaving', 'isCanceled'),

  isSaving:  readOnly('updateTask.isRunning'),

  hasValidReason: computed('requisition.cancelationReason', function(){
    let reason = get(this,'requisition.cancelationReason');
    return reason && reason.length;
  }),

  hasInvalidReason: not('hasValidReason'),

  isSaveDisabled: or('hasInvalidReason', 'isDisabled' ),

  updateTask: task(function *() {
    if (this.requisition) {
      this.requisition.setProperties({
        status: 'canceled',
        portalOrder: this.settings.portalOrder
      });

      yield this
        .requisition
        .save()
        .then(() => {
          this.flashMessages.success(`${this.name} marked as Canceled`);
          if (!this.isDestroyed) {
            this.set('showConfirmation', false);
          }
        })
        .catch(() => {
          this.flashMessages.error(`Failed to update ${this.name}`);
          this.requisition.rollbackAttributes();
        });
    }
  }),

  actions: {
    show() {
      this.set('showConfirmation', true);
    },

    hide() {
      this.set('showConfirmation', false);
    },

    submit() {
      this.updateTask.perform();
    }
  }

})
