/* eslint-disable ember/no-classic-components, ember/no-mixins, ember/no-classic-classes, ember/require-tagless-components, ember/no-get */
import Component from '@ember/component';
import ComponentMixin from 'shared/mixins/sl-component/mixin';

/**
 * Extracted from https://github.com/softlayer/sl-ember-behavior which is no
 * longer maintained
 */
export default Component.extend(ComponentMixin, {
  isViewable(activity, resource, possible) {
    return this.get('behaviorService').isUnable(activity, resource, possible);
  }
});
