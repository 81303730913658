/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components, ember/no-get, ember/no-observers */
import { A } from '@ember/array';
import Component from '@ember/component';
import { isPresent, isNone } from '@ember/utils';
import {
  computed,
  observer,
  set,
  getProperties,
  get
} from '@ember/object';
import PapaParse from 'papaparse';
import layout from './template';

export default Component.extend({
  layout,

  classNames: ['csv-file-viewer'],

  records: null,
  showPreview: true,
  contentErrors: null,
  contentInfos: null,
  isUrl: false,
  headerRowIndex: 0,
  missingColumns: [],

  processedContentInfos: computed('additionalColumns', 'contentInfos', 'records', function() {
    let infos = get(this, 'contentInfos') || {};
    let records = get(this, 'records');
    let additionalColumns = get(this, 'additionalColumns');

    // Add info message for additional columns
    if (isPresent(additionalColumns)) {
      records.forEach((r, rowIndex) => {
        additionalColumns.forEach((columnData) => {
          let columnIndex = columnData[Object.keys(columnData)[0]];

          if (isNone(infos[rowIndex])) {
            infos[rowIndex] = {};
          }

          if (isNone(infos[rowIndex][columnIndex])) {
            infos[rowIndex][columnIndex] = [];
          }

          infos[rowIndex][columnIndex].push('Unexpected Column');
        });
      });
    }

    return infos;
  }),

  processedContentErrors: computed('contentErrors', 'missingColumns', 'records', function() {
    let errors = get(this, 'contentErrors');
    let records = get(this, 'records');
    let missingColumns = get(this, 'missingColumns');
    let columnCount = records[0].values.length;

    // Add errors for missing columns
    if (isPresent(missingColumns)) {
      records.forEach((r, rowIndex) => {
        for (let i = 0; i < missingColumns.length; i++) {
          let columnIndex = columnCount - 1 + i;

          if (isNone(errors[rowIndex])) {
            errors[rowIndex] = {};
          }

          if (isNone(errors[rowIndex][columnIndex])) {
            errors[rowIndex][columnIndex] = [];
          }

          errors[rowIndex][columnIndex].push('Missing Column');
        }
      });
    }

    return errors;
  }),

  fileUrl: null,

  _fillEmptyData(dataArray) {
    let { missingColumns, headerRowIndex } = getProperties(this, ['missingColumns', 'headerRowIndex']);
    let columnsCounts = Math.max.apply(null, dataArray.map((row) => row.values.length));

    if (isPresent(missingColumns)) {
      columnsCounts = columnsCounts + missingColumns.length;
    }

    dataArray.forEach((row, rowIndex) => {
      let missingData = columnsCounts - row.values.length;

      if (isPresent(missingColumns) && headerRowIndex === rowIndex) {
        missingData = missingData - missingColumns.length;
      }

      for (let i = 0; i < missingData; i++) {
        row.values.push('');
      }
    });

    set(this, 'records', dataArray);
  },

  didFileUrlChange: observer('contentSource', function() {
    let self = this;
    let isUrl = get(self, 'isUrl');
    let fileUrl = get(self, 'contentSource');
    let rowCount = 0;

    if (fileUrl) {

      let dataArray = A();

      PapaParse.parse(fileUrl, {
        download: isUrl,
        header: false,
        step(results) {
          dataArray.push({ rowIndex: rowCount, values: results.data });
          rowCount = rowCount + 1;
        },
        complete() {
          if (self.isDestroyed) {
            return;
          }
          self._fillEmptyData(dataArray);
        }
      });
    }
  }).on('init')

});
