/* eslint-disable ember/no-classic-classes, key-spacing */
import Model, { attr, hasMany, belongsTo } from '@ember-data/model';

export default Model.extend({
  // Associations
  organization: belongsTo('organization', { async: true }),
  sampleStates: hasMany('sample-state', { async: false, inverse: 'container' }),

  // Attributes
  identifier:   attr('string'),
  barcode:      attr('string'),
  barcodeLink:  attr('string')
});
