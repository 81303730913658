/* eslint-disable ember/no-classic-components, ember/no-classic-classes, max-len, ember/use-brace-expansion, ember/no-get, quotes */
import { readOnly, none, or, not, alias } from '@ember/object/computed';
import Component from '@ember/component';
import { computed } from '@ember/object';
import layout from './template';
import { inject as service } from '@ember/service';

export default Component.extend({
  behavior: service('sl-behavior'),
  currentSession: service(),
  currentOrganization: service('current-organization'),

  organization: alias('currentOrganization.organization'),

  layout,
  tagName: '',
  model: null,
  sample: null,
  options: null,
  allowNone: false,
  editable: false,

  noOptions: none('options'),
  readOnly: not('editable'),
  showLot: or('noOptions', 'options.lot'),
  showType: readOnly('model.label'),

  identifierDisabled: computed('behavior.behaviors.routes', 'organization.id', 'sample.isNew', 'sample.requisition.processingStatus', function() {
    if (!this.sample.isNew) {
      let status = this.get('sample.requisition.processingStatus');
      if (status === "processing" || status === "complete") {
        return !this.currentSession.hasSupportFullAccessEnabledForOrg(this.organization.id);
      }
      return !this.behavior.isAble('requisitions.update', 'route');
    }
    return false;
  }),

  readonly: computed('editable', function() {
    if (this.get('editable')) {
      return undefined;
    } else {
      return true;
    }
  })

});
