/* eslint-disable ember/no-classic-classes, key-spacing, brace-style, ember/no-get, no-undef */
import Model, { hasMany, belongsTo, attr } from '@ember-data/model';
import { equal } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';

export default Model.extend({

  behaviorService: service('sl-behavior'),

  // Associations
  workflow:           belongsTo('workflow', { async: true }),
  activity:           belongsTo('activity', { async: true, polymorphic: true, inverse: 'workflowActivity' }),
  parent:             belongsTo('workflow-activity', { async: true, inverse: null }),
  resources:          hasMany('resource', { async: true }),
  resourceGroups:     hasMany('resource-group', { async: true }),
  queuedJobs:         hasMany('queued-job'),

  // Attributes
  activityName:       attr('string'),
  activityType:       attr('string'),
  label:              attr('string'),
  status:             attr('string'),
  sequence:           attr('string'),
  resultType:         attr('string'),
  rejectableAssays:   attr('boolean'),
  staticResultStatus: attr('string'),
  repeatWorkflowType: attr('string'),
  configuration:      attr(),
  canStart:           attr('boolean'),
  canEditOrRemove:    attr('boolean'),
  permissions:        attr(),

  childIds:  attr({ defaultValue: () => { return []; } }),
  parentIds: attr({ defaultValue: () => { return []; } }),

  isRoot: equal('parentIds.length', 0),

  children: computed('childIds.[]', 'store', function() {
    let childIds = this.get('childIds');

    return childIds.map((id) => {
      if (Ember.testing) {
        return this.store.findRecord('workflow-activity', id);
      }

      return this.store.peekRecord('workflow-activity', id);
    });
  }),

  parents: computed('parentIds.[]', 'store', function() {
    let parentIds = this.get('parentIds');

    return parentIds.map((id) => {
      return this.store.peekRecord('workflow-activity', id);
    });
  }),

  canAccess: computed('activityType', 'routeSuffix', function() {
    let baseRoute = 'workflows.show.workflow-activities';
    let activityType = this.get('activityType');
    let routeSuffix = this.get('routeSuffix');

    return this.get('behaviorService').isAble(`${baseRoute}.${activityType}.${routeSuffix}`, 'route');
  }),

  routeSuffix: computed('status', function() {
    return this.get('status') === 'completed' ? 'show' : 'new';
  })

});
