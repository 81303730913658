/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components */
import Component from '@ember/component';
import layout from './template';

export default Component.extend({
  layout,
  classNames: ['breadcrumb'],
  documentsPath: null,
  documentsText: 'Documents',
  selectedFolder: null,
  selectedFolderPath: null
});
