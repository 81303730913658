/* eslint-disable no-multi-spaces */
import { helper as buildHelper } from '@ember/component/helper';
import { isBlank } from '@ember/utils';

export function withDefault(params) {
  const value    = params[0];
  const fallback = params[1];

  return isBlank(value) ? fallback : value;
}

export default buildHelper(withDefault);
