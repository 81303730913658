/* eslint-disable ember/no-get, indent */
import { typeOf } from '@ember/utils';
import { assert } from '@ember/debug';
import BehaviorService from 'shared/services/behavior';

export default BehaviorService.extend({
  isAble(activity, resource, possible) {
    assert(
      'services/sl-behavior.isAble() expects at least two parameters to be provided',
      activity && resource
    );

    const behaviors = this.get('behaviors');

    if ('instance' === typeOf(resource)) {
      possible = resource.get('behaviors')[activity];
      resource = resource.get('behaviorGroup');
    }

    if ('function' === typeOf(possible)) {
      possible = possible();
    }

    assert(
      'services/sl-behavior.isAble() expects `activity` parameter to be a String',
      'string' === typeOf(activity)
    );

    assert(
      'services/sl-behavior.isAble() expects `resource` parameter to be a String or Object',
      'string' === typeOf(resource) ||
      'object' === typeOf(resource)
    );

    assert(
      'services/sl-behavior.isAble() expects `possible` parameter to be a Boolean or undefined',
      'boolean' === typeOf(possible) ||
      'undefined' === typeOf(possible)
    );

    if ('undefined' === typeOf(possible)) {
      possible = true;
    }

    let isAble = false;

    // Normalize activity
    activity = activity.replace(/.index$/, '')
                       .replace(/.index.new$/, '.new')
                       .replace(/.index.show/, '.show')
                       .replace(/.show.dashboard/, '.show')
                       .replace(/.show.edit/, '.show');

    if (
      behaviors &&
      behaviors[ resource ] &&
      (behaviors[ resource ][ activity ] || behaviors[ resource ][ activity ])
    ) {
      isAble = possible;
    }

    return isAble;
  }

});
