/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components, ember/no-get */
import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  classNames: ['select-wrapper'],
  classNameBindings: ['color'],

  value: null,
  mapping: null,

  color: computed('value', 'mapping', function() {
    let value = this.get('value');
    let mapping = this.get('mapping') || { default: 'white', mini: 'mini-default' };

    return mapping[value] || mapping.default;
  }),

  click() {
    return false;
  }

});
