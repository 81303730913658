import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! template-lint-disable prettier }}\n{{#if this.visible}}\n  <th\n    class=\"text-right {{@class}} {{@theme.theadCell}}\"\n    {{did-insert this.updateName}}\n    data-test-id=\"ui-table-filters-clear\"\n    ...attributes>\n\n    <ClearFilters @onClick={{@action}} />\n\n   </th>\n {{/if}}", {"contents":"{{! template-lint-disable prettier }}\n{{#if this.visible}}\n  <th\n    class=\"text-right {{@class}} {{@theme.theadCell}}\"\n    {{did-insert this.updateName}}\n    data-test-id=\"ui-table-filters-clear\"\n    ...attributes>\n\n    <ClearFilters @onClick={{@action}} />\n\n   </th>\n {{/if}}","moduleName":"shared/components/ui/table/thead/row/filters/clear.hbs","parseOptions":{"srcName":"shared/components/ui/table/thead/row/filters/clear.hbs"}});
/* eslint-disable ember/classic-decorator-hooks */
import UiTableTheadRowColumn from 'shared/components/ui/table/thead/row/column';
import { assert } from '@ember/debug';

export default class UiTableTheadRowFiltersClear extends UiTableTheadRowColumn {
  init() {
    super.init(...arguments);
    assert('Missing required `action` for header.clear', this.action);
  }
}
