/* eslint-disable ember/no-classic-classes, key-spacing, ember/no-get */
import Model, { hasMany, attr } from '@ember-data/model';
import { getProperties, computed } from '@ember/object';
import { isBlank } from '@ember/utils';

export default Model.extend({

  testPanels:     hasMany('test-panel', { async: false }),

  genericName:    attr('string'),
  brandName:      attr('string'),
  name:           attr('string'),
  route:          attr('string'),
  doseQuantity:   attr('number'),
  doseUnit:       attr('string'),
  rxcui:          attr('string'),

  nameWithDose: computed('{name,dose}', function() {
    const { name, dose } = getProperties(this, 'name', 'dose');

    if (isBlank(dose)) {
      return name;
    }

    return `${name} (${dose})`;
  }),

  dose: computed('doseQuanity', 'doseQuantity', 'doseUnit', function() {
    const { doseQuantity, doseUnit } = getProperties(this, 'doseQuantity', 'doseUnit');

    return [doseQuantity, doseUnit].compact().join(' ');
  })

});
