/* eslint-disable ember/no-attrs-in-components */
/* eslint-disable max-len, ember/no-observers */
import { isNone } from '@ember/utils';
import { inject as service } from '@ember/service';
import { alias, not, or, mapBy } from '@ember/object/computed';
import { computed, observer, action } from '@ember/object';
import { next } from '@ember/runloop';
import BaseComponent from '../../requisition-base/component';
import layout from './template';

const MIN_DATE = new Date(1900, 0, 0);

export default BaseComponent.extend({
  layout,
  classNames: ['requisition-sample'],

  store: service(),

  onValidateReceivedDate() {},
  onValidateCollectionDate() {},

  isNew: true,
  readOnly: false,
  portalOrder: false,
  canEditIdentifier: false,
  isVisible: true,

  requisitionTemplate: alias('requisition.requisitionTemplate'),
  sampleState: alias('model.sampleStates.firstObject'),
  editable: not('readOnly'),
  received: alias('model.received'),
  receivedDateTimeValid: alias('model.receivedDateTimeValid'),
  isCustomGlobalIdValid: alias('model.isCustomGlobalIdValid'),
  receivedDateAndColectionDateValid: alias('model.receivedDateAndColectionDateValid'),
  notReceived: not('received'),
  canEditDateReceived: or('notReceived', 'processed'),
  requiredCollectionDate: alias('group.requiredCollectionDatetime'),
  samplesGroupGlobalCustomAttributes: alias('group.requisitionTemplateGlobalCustomAttributes'),
  sampleTypes: mapBy('group.assignedSampleTypeNames', 'name'),

  identifierDisabled: computed('canEditIdentifier', 'group.enforceSingleIdentifier', 'model.isNew', function() {
    return (this.group.enforceSingleIdentifier && this.model.isNew) || (!this.canEditIdentifier && !this.model.isNew);
  }),

  dateOfBirthAndCollectionDateValid: computed('patient.dateOfBirth', 'model.collectionDateTime', function() {
    let dateOfBirth = this.patient.dateOfBirth;
    let collectionDateTime = this.model.collectionDateTime;
    if (dateOfBirth && collectionDateTime && dateOfBirth > collectionDateTime) {
      return null;
    }
    return true;
  }),

  dateOfBirthAndReceivedDateValid: computed('patient.dateOfBirth', 'model.receivedDateTime', function() {
    let dateOfBirth = this.patient.dateOfBirth;
    let receivedDateTime = this.model.receivedDateTime;
    if (dateOfBirth && receivedDateTime && dateOfBirth > receivedDateTime) {
      return null;
    }
    return true;
  }),

  plateConfiguration: computed('group.containerTypesAllowed', function() {
    const containerTypes = this.group.containerTypesAllowed;

    return {
      containerTypes
    };
  }),

  // TODO: Must remove observers
  identifierChanged: observer('model.identifier', function() {
    this.change(); // trigger change for the component when enforceSingleIdentifier is enabled
  }),

  minRequirements: computed(
    'received',
    'receivedDateTimeValid',
    'collectionDateTimeValid',
    'dateOfBirthAndCollectionDateValid',
    'dateOfBirthAndReceivedDateValid',
    'receivedDateAndColectionDateValid',
    'sampleAdded',
    'isCustomGlobalIdValid',
    function() {
      let requiredFields = [];

      requiredFields.push({
        key: 'identifier',
        name: 'Sample identifier'
      });

      requiredFields.push({
        key: 'sampleStates.firstObject.container',
        name: 'Sample container'
      });

      requiredFields.push({
        key: 'sampleStates.firstObject.label',
        name: 'Sample type'
      });

      if (this.received) {
        requiredFields.push({
          key: 'receivedDateTime',
          name: 'Sample Date Received'
        });
      }

      if (!this.receivedDateTimeValid) {
        requiredFields.push({
          key: 'receivedDateTimeValid',
          name: 'Sample Date Received cannot be in the future or before 1900'
        });
      }

      if (!this.receivedDateAndColectionDateValid) {
        requiredFields.push({
          key: 'receivedDateAndColectionDateValid',
          name: 'Sample Date Received must be after Sample Collection Date'
        });
      }

      if (!this.dateOfBirthAndCollectionDateValid) {
        requiredFields.push({
          key: 'dateOfBirthAndCollectionDateValid',
          name: 'Collection Date must be after Date of Birth'
        });
      }

      if (!this.dateOfBirthAndReceivedDateValid) {
        requiredFields.push({
          key: 'dateOfBirthAndReceivedDateValid',
          name: 'Sample Date Received must be after Date of Birth'
        });
      }

      if (!this.isCustomGlobalIdValid) {
        requiredFields.push({
          key: 'customGlobalIdValid',
          name: 'Global ID is not valid'
        });
      }

      return requiredFields;
    }
  ),

  requirements: computed('template.fields.[]', 'templateKey', 'propertyNameSuffix', 'requiredCollectionDate', function() {
    let requirements = this._requirements();

    if (this.requiredCollectionDate) {
      requirements.push({
        key: 'collectionDateTime',
        name: 'Sample Collection Date'
      });
    }

    return requirements;
  }),

  collectionMinDate: computed('patient.dateOfBirth', function() {
    return this.patient.dateOfBirth || MIN_DATE;
  }),

  receivedMinDate: computed('model.collectionDateTime', function() {
    return this.model.collectionDateTime || MIN_DATE;
  }),

  samplesChanged() {
    if (typeof this.attrs.publish === 'function') {
      next(this, () => {
        this.attrs.publish('samplesChanged');
      });
    }
  },

  onFocusReceivedDatetime: action(function() {
    const receivedDateTime = this.model.receivedDateTime;

    // set current datetime if not defined yet
    if (isNone(receivedDateTime)) {
      this.model.set('receivedDateTime', new Date());
    }
  }),

  setPlate: action(function(params) {
    if (params.container) {
      this.sampleState.set('container', params.container);
      this.sampleState.set('position', params.container.positions.shift());
    } else {
      this.sampleState.set('container', null);
    }

    this.change();
    this.samplesChanged();
  }),

  triggerChange: action(function() {
    this.change();
  }),

  sampleStateChanged: action(function() {
    this.change();
    this.samplesChanged();
  })

});
