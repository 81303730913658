/* eslint-disable ember/no-classic-classes, ember/no-get, quotes, ember/no-observers */
import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import { get, observer } from '@ember/object';

export default Helper.extend({

  behavior: service('sl-behavior'),

  compute(_, { route, possible }) {
    const behavior = get(this, 'behavior');

    if (possible === undefined) {
      possible = true;
    }

    return possible && behavior.isAble(route, "route");
  },

  behaviorDidChange: observer('behavior.behaviors', function() {
    this.recompute();
  })

});
