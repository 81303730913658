/* eslint-disable ember/no-classic-components, quotes, ember/no-classic-classes */
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import layout from "./template";

export default Component.extend({
  layout,
  store: service(),
  tagName: '',
  model: null,
  required: false,
  readOnly: false,
  dateFormat: 'YYYY-MM-DD',
  preAuthorizationsRequired: false,
  requiredFields: null,
  hiddenFields: null
});
