/* eslint-disable ember/no-mixins */
import ApplicationSerializer from 'shared/serializers/application';
import ResourceValidation from 'shared/mixins/resource-validation';
import ResourceGroupValidation from 'shared/mixins/resource-group-validation';

export default ApplicationSerializer.extend(ResourceValidation, ResourceGroupValidation, {
  attrs: {
    resources: { deserialize: 'records', serialize: false },
    resourceGroups: { deserialize: 'records', serialize: false },
    samples: { deserialize: 'records', serialize: 'ids' },
    sampleStates: { serialize: 'ids' },
    // adapterContainers: { deserialize: 'ids' },
    adapterContainerWells: { serialize: 'records' },
    workflowSamples: { serialize: 'records' },
    workflowSampleResults: { serialize: 'records' },
    downloadUrl: { serialize: false },
    destinations: { serialize: 'records' },

    pooledWorkflowSampleResult: { embedded: 'always' }
  }
});
