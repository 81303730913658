/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components, ember/no-get, no-prototype-builtins, max-len */
import { notEmpty } from '@ember/object/computed';
import Component from '@ember/component';
import { getProperties, computed } from '@ember/object';
import layout from './template';

export default Component.extend({
  layout,
  tagName: 'td',
  sampleIdColumn: null,
  classNameBindings: ['cssClass', 'sampleFound:sample-found'],
  cellErrors: computed('columnIndex', 'errors', 'rowIndex', function() {
    let { errors, rowIndex, columnIndex } = getProperties(this, ['errors', 'rowIndex', 'columnIndex']);
    let cellErrors = [];

    if (errors && errors.hasOwnProperty(rowIndex) && errors[rowIndex].hasOwnProperty(columnIndex)) {
      cellErrors = errors[rowIndex][columnIndex];
    }

    return cellErrors;
  }),
  hasErrors: notEmpty('cellErrors'),

  cellWarnings: computed('columnIndex', 'rowIndex', 'warnings', function() {
    let { warnings, rowIndex, columnIndex } = getProperties(this, ['warnings', 'rowIndex', 'columnIndex']);
    let cellWarnings = [];

    if (warnings && warnings.hasOwnProperty(rowIndex) && warnings[rowIndex].hasOwnProperty(columnIndex)) {
      cellWarnings = warnings[rowIndex][columnIndex];
    }

    return cellWarnings;
  }),
  hasWarnings: notEmpty('cellWarnings'),

  cellInfos: computed('columnIndex', 'infos', 'rowIndex', function() {
    let { infos, rowIndex, columnIndex } = getProperties(this, ['infos', 'rowIndex', 'columnIndex']);
    let cellInfos = [];

    if (infos && infos.hasOwnProperty(rowIndex) && infos[rowIndex].hasOwnProperty(columnIndex)) {
      cellInfos = infos[rowIndex][columnIndex];
    }

    return cellInfos;
  }),
  hasInfos: notEmpty('cellInfos'),

  tooltipText: computed('cellErrors', 'cellWarnings', 'cellInfos', function() {
    let { cellErrors, cellWarnings, cellInfos } = getProperties(this, ['cellErrors', 'cellWarnings', 'cellInfos']);
    return cellErrors.concat(cellWarnings).concat(cellInfos).join('. ');
  }),

  sampleFound: computed('cellErrors', 'columnIndex', 'hasErrors', 'sampleIdColumn', function() {
    let { hasErrors, sampleIdColumn, columnIndex } = getProperties(this, ['hasErrors', 'sampleIdColumn', 'columnIndex']);

    return !hasErrors && parseInt(sampleIdColumn) === parseInt(columnIndex);
  }),
  cssClass: computed('cellErrors', 'hasErrors', 'hasInfos', 'hasWarnings', function() {
    let { hasErrors, hasWarnings, hasInfos } = getProperties(this, ['hasErrors', 'hasWarnings', 'hasInfos']);

    if (hasErrors) {
      return 'data-error';
    } else if (hasWarnings) {
      return 'data-warning';
    } else if (hasInfos) {
      return 'data-info';
    } else {
      return '';
    }
  }),
  hasMessage: computed('hasErrors', 'hasWarnings', 'hasInfos', function() {
    let { hasErrors, hasWarnings, hasInfos } = getProperties(this, ['hasErrors', 'hasWarnings', 'hasInfos']);
    return hasErrors || hasWarnings || hasInfos;
  }),

  value: null
});
