/* eslint-disable ember/no-classic-classes, key-spacing */
import Model, { belongsTo, attr } from '@ember-data/model';
import { reads } from '@ember/object/computed';

export default Model.extend({
  // Associations
  organization:       belongsTo('organization'),

  // Attributes
  name:               attr('string'),
  workflowType:       attr('string'),
  createdAt:          attr('date'),
  configuration:      attr(),
  graph:              attr(),
  generateReports:    attr('boolean'),

  sampleStartLabel: reads('configuration.sampleStartLabel')

});
