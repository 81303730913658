/* eslint-disable ember/no-classic-classes, max-len, quotes */
import Service, { inject as service } from '@ember/service';
import parseQueryData from 'shared/utils/parse-query-data';
import { task } from 'ember-concurrency';
import { alias, gt } from '@ember/object/computed';

export default Service.extend({
  store: service(),
  currentOrganization: service(),
  settings: service(),

  totalRows: alias('data.meta.total_entries'),
  recordsFound: gt('totalRows', 0),

  reload() {
    this.loadDataTask.perform();
  },

  loadDataTask: task(function *(params) {
    params = params || this.params;
    this.set('params', params);

    let query = parseQueryData(params);

    query.organization_id = this.currentOrganization.id;
    query.sort = query.sort || '-id';
    query.include = 'user,resources';
    query.fields = 'id,from-date,to-date,status,created-at,workflow-names,provider-account-names,provider-names,provider-npis,test-panel-names,test-gene-names,resource.id,resource.name,resource.publicUrl,user.id,user.first-name,user.last-name';

    if (this.settings.portal) {
      query.for_orders_portal = true;
    }

    let response = yield this.store.query('infectious-disease-report', query);

    this.set('data', response);

    return response;
  }).restartable(),

});
