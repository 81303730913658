define("ember-countries/index", ["exports", "ember-countries/utils/countries-lists", "ember-countries/utils/countries-properties", "ember-countries/utils/states-lists", "ember-countries/utils/states-properties"], function (_exports, _countriesLists, _countriesProperties, _statesLists, _statesProperties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "CA_STATES_LIST", {
    enumerable: true,
    get: function () {
      return _statesLists.CA_STATES_LIST;
    }
  });
  Object.defineProperty(_exports, "COUNTRIES_LIST", {
    enumerable: true,
    get: function () {
      return _countriesLists.COUNTRIES_LIST;
    }
  });
  Object.defineProperty(_exports, "COUNTRIES_WITHOUT_ZIP_LIST", {
    enumerable: true,
    get: function () {
      return _countriesLists.COUNTRIES_WITHOUT_ZIP_LIST;
    }
  });
  Object.defineProperty(_exports, "COUNTRIES_WITH_STATES_LIST", {
    enumerable: true,
    get: function () {
      return _countriesLists.COUNTRIES_WITH_STATES_LIST;
    }
  });
  Object.defineProperty(_exports, "STATES_BY_COUNTRIES", {
    enumerable: true,
    get: function () {
      return _statesLists.STATES_BY_COUNTRIES;
    }
  });
  Object.defineProperty(_exports, "US_MILITARY_STATES_LIST", {
    enumerable: true,
    get: function () {
      return _statesLists.US_MILITARY_STATES_LIST;
    }
  });
  Object.defineProperty(_exports, "US_REQUIRING_CUSTOM_DECLARATION_STATES_LIST", {
    enumerable: true,
    get: function () {
      return _statesLists.US_REQUIRING_CUSTOM_DECLARATION_STATES_LIST;
    }
  });
  Object.defineProperty(_exports, "US_STATES_LIST", {
    enumerable: true,
    get: function () {
      return _statesLists.US_STATES_LIST;
    }
  });
  Object.defineProperty(_exports, "countryContainsState", {
    enumerable: true,
    get: function () {
      return _statesProperties.countryContainsState;
    }
  });
  _exports.default = void 0;
  Object.defineProperty(_exports, "getCountry", {
    enumerable: true,
    get: function () {
      return _countriesProperties.getCountry;
    }
  });
  Object.defineProperty(_exports, "getState", {
    enumerable: true,
    get: function () {
      return _statesProperties.getState;
    }
  });
  Object.defineProperty(_exports, "getStatesForCountry", {
    enumerable: true,
    get: function () {
      return _statesProperties.getStatesForCountry;
    }
  });
  Object.defineProperty(_exports, "isCountryWithState", {
    enumerable: true,
    get: function () {
      return _countriesProperties.isCountryWithState;
    }
  });
  Object.defineProperty(_exports, "isCountryWithoutZip", {
    enumerable: true,
    get: function () {
      return _countriesProperties.isCountryWithoutZip;
    }
  });
  Object.defineProperty(_exports, "isCustomsDeclarationRequiredInUS", {
    enumerable: true,
    get: function () {
      return _statesProperties.isCustomsDeclarationRequiredInUS;
    }
  });
  var _default = _exports.default = {
    COUNTRIES_LIST: _countriesLists.COUNTRIES_LIST,
    COUNTRIES_WITHOUT_ZIP_LIST: _countriesLists.COUNTRIES_WITHOUT_ZIP_LIST,
    COUNTRIES_WITH_STATES_LIST: _countriesLists.COUNTRIES_WITH_STATES_LIST,
    US_STATES_LIST: _statesLists.US_STATES_LIST,
    US_MILITARY_STATES_LIST: _statesLists.US_MILITARY_STATES_LIST,
    US_REQUIRING_CUSTOM_DECLARATION_STATES_LIST: _statesLists.US_REQUIRING_CUSTOM_DECLARATION_STATES_LIST,
    CA_STATES_LIST: _statesLists.CA_STATES_LIST,
    STATES_BY_COUNTRIES: _statesLists.STATES_BY_COUNTRIES,
    getCountry: _countriesProperties.getCountry,
    isCountryWithState: _countriesProperties.isCountryWithState,
    isCountryWithoutZip: _countriesProperties.isCountryWithoutZip,
    getStatesForCountry: _statesProperties.getStatesForCountry,
    isCustomsDeclarationRequiredInUS: _statesProperties.isCustomsDeclarationRequiredInUS,
    countryContainsState: _statesProperties.countryContainsState,
    getState: _statesProperties.getState
  };
});