import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! template-lint-disable no-action no-invalid-interactive }}\n<div class=\"dropdown\" ...attributes {{on \"click\" this.handleWindowClick}} id={{this.elementId}}>\n  {{yield this.open (action \"toggle\") this.elementId}}\n</div>", {"contents":"{{! template-lint-disable no-action no-invalid-interactive }}\n<div class=\"dropdown\" ...attributes {{on \"click\" this.handleWindowClick}} id={{this.elementId}}>\n  {{yield this.open (action \"toggle\") this.elementId}}\n</div>","moduleName":"shared/components/wb-dropdown.hbs","parseOptions":{"srcName":"shared/components/wb-dropdown.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';

export default class WbDropdownComponent extends Component {
  @tracked open = false;

  otherSelectors = this.args?.otherSelectors || [];
  elementId = `wb-dropdown-${guidFor(this)}`;

  constructor() {
    super(...arguments);
    this.handler = this.handleWindowClick.bind(this);
    window.addEventListener('click', this.handler);
  }

  willDestroy() {
    super.willDestroy(...arguments);
    window.removeEventListener('click', this.handler);
  }

  @action
  handleWindowClick(e) {
    if (this.open) {
      let didClickThis = Boolean(e.target.closest('.dropdown'));

      let didClickOther = false;
      this
        .otherSelectors
        .forEach((selector) => {
          didClickOther = didClickOther || Boolean(e.target.closest(selector));
        });

      if (!didClickThis && !didClickOther) {
        this.open = false;
      }
    }
  }

  @action
  toggle() {
    this.open = !this.open;
  }
}
