/* eslint-disable ember/no-new-mixins, ember/no-get */
import Mixin from '@ember/object/mixin';
import { computed, get } from '@ember/object';
import _collection from 'lodash/collection';

const { groupBy } = _collection;

export default Mixin.create({
  sampleStatesByContainerAndPosition: computed('sampleStates.[]', function() {
    let sampleStates = get(this, 'sampleStates').filter((sampleState) => {
      return !get(sampleState, 'sample.control');
    }).toArray();

    // Group samples by container and position
    return groupBy(sampleStates, function(ss) {
      return get(ss, 'containerBarcodeAndPosition');
    });
  })
});
