/* eslint-disable ember/no-classic-classes, key-spacing, ember/no-get */
import Model, { belongsTo, attr } from '@ember-data/model';
import { computed } from '@ember/object';

export default Model.extend({
  // Associations
  organization:      belongsTo('organization', { async: true, inverse: null }),

  // Attributes
  name:              attr('string'),
  mappingType:       attr('string'),
  configuration:     attr(),

  configurationJson: computed('configuration', function() {
    let configuration = this.get('configuration');
    return JSON.stringify(configuration, undefined, 2);
  })

});
