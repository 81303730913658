/* eslint-disable max-len, dot-notation, no-multi-spaces, semi */
import { isPresent } from '@ember/utils';
import ApplicationSerializer from 'shared/serializers/application';

export default ApplicationSerializer.extend({
  attrs: {
    identifier: { serialize: false },
    resultActivityLabel: { serialize: false },
    assays: { deserialize: 'records', serialize: false }
  },

  _setTreatmentGroupsAsAssay(workflowSampleResult) {
    let treatments = null;
    if (isPresent(workflowSampleResult.result.medication_results.potential_therapies.detected_organisms)) {
      treatments = workflowSampleResult.result['medication_results']['potential_therapies']['detected_organisms'][0]['treatments'];
    }  else {
      return
    }

    if (isPresent(treatments)) {
      this._setMedicationAssay(workflowSampleResult, treatments, 'first_line', 'First line');
    }

    if (isPresent(treatments)) {
      this._setMedicationAssay(workflowSampleResult, treatments, 'alternative', 'Alternative');
    }
  },

  _setMedicationAssay(wfsr, treatments, treatmentGroup, groupName) {
    const filteredMedications = treatments.filter((t) => t.treatment_group === treatmentGroup);
    const assay = {};
    assay.id = `${wfsr.id}-medication-results-${treatmentGroup}`;
    assay.name = groupName;
    assay.records = filteredMedications;

    wfsr.assays.push(assay);
  },

  normalizeArrayResponse(store, primaryModelClass, payload/*, id, requestType */) {
    if (payload.workflow_sample_results) {
      payload.workflow_sample_results.forEach((wfsr) => {
        if (wfsr.result) {
          wfsr.assays = [];
          let resultKeys = Object.keys(wfsr.result);

          resultKeys.forEach((keyName) => {
            if (['First line', 'Alternative'].includes(keyName)) {
              return;
            }

            if (keyName === 'medication_results') {
              this._setTreatmentGroupsAsAssay(wfsr);
            } else {
              let assay = wfsr.result[keyName];
              assay.id = `${wfsr.id}-${keyName}`;
              assay.name = keyName;

              wfsr.assays.push(assay);
            }
          });
          // delete wfsr.result;
        }
      });
    }

    return this._super(...arguments);
  },

  normalizeSingleResponse(store, primaryModelClass, payload/*, id, requestType */) {
    if (payload.result) {
      payload.assays = [];
      let resultKeys = Object.keys(payload.result);

      resultKeys.forEach(function(keyName) {
        if (['First line', 'Alternative'].includes(keyName)) {
          return;
        }

        if (keyName === 'medication_results') {
          this._setTreatmentGroupsAsAssay(payload);
        } else {
          let assay = payload.result[keyName];
          assay.id = `${payload.id}-${keyName}`;
          assay.name = keyName;

          payload.assays.push(assay);
        }
      });

      // delete payload.result;
    }

    return this._super(...arguments);
  },

  serialize(snapshot/*, options*/) {
    let json = this._super(...arguments);

    let assays = snapshot.hasMany('assays');

    if (assays && assays.length) {
      const medicationResults = json.result['medication_results'];

      json.result = {};

      if (isPresent(medicationResults)) {
        json.result = { medication_results: medicationResults };
      }

      assays.forEach(function(assay) {
        json.result[assay.attr('name')] = {
          status: assay.attr('status'),
          reviewed: assay.attr('reviewed'),
          records: assay.attr('records')
        };
      });
    }

    return json;
  }
});
