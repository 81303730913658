/* eslint-disable key-spacing, object-curly-spacing, quotes, max-len, space-before-function-paren, space-before-blocks, ember/no-get, no-trailing-spaces, ember/no-actions-hash */
import { computed, get, set } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import BaseComponent from '../../requisition-base/component';
import config from 'ember-get-config';
import layout from './template';
import moment from 'moment';

const { SUPPORTED_ETHNICITIES, SUPPORTED_RACES } = config;

const SEX_OPTIONS = [
  { value: 'male', name: 'Male' },
  { value: 'female', name: 'Female' },
  { value: 'undisclosed', name: 'Undisclosed' }
];

const FIELDS = {
  mrn:                { name: 'Medical Record Number' },
  firstName:          { name: 'First Name' },
  lastName:           { name: 'Last Name' },
  middleName:         { name: 'Middle Name'},
  dateOfBirth:        { name: 'Date of Birth' },
  streetAddress:      { name: 'Street Address' },
  streetAddressLine2: { name: 'Street Address Line 2' },
  city:               { name: 'City' },
  state:              { name: 'State' },
  country:            { name: 'Country' },
  zipCode:            { name: 'Zip Code' },
  phoneNumber:        { name: 'Phone Number' },
  gender:             { name: 'Sex' },
  ethnicity:          { name: 'Ethnicity' },
  race:               { name: 'Race' },
  email:              { name: 'Email' },
  billingInformation: { name: 'Billing Information' }
};

const ADDRESS_FIELDS = [
  'streetAddress',
  'streetAddressLine2',
  'city',
  'state',
  'country',
  'zipCode',
];

const MIN_DATE = new Date(1900, 0, 0);
const MAX_DATE = moment().toDate();

export default BaseComponent.extend({
  layout,
  classNames: ['patient-information', 'entry-details-content', 'row'],

  fields: FIELDS,

  store: service(),
  settings: service(),
  changed: service(),

  portalOrder: alias('settings.portalOrder'),

  sexOptions: SEX_OPTIONS,
  ethnicityOptions: SUPPORTED_ETHNICITIES,
  raceOptions: SUPPORTED_RACES,

  onSelectPatient: () => {},

  dateOfBirthInvalid: alias("patient.dateOfBirthInvalid"),

  customAttributes: computed('portalOrder', 'requisitionTemplate.requisitionTemplateGlobalCustomAttributes.@each.{lab,portal}', 'this.requisitionTemplate', function () {
    return this
      .requisitionTemplate
      .get('requisitionTemplateGlobalCustomAttributes')
      .filterBy('globalCustomAttribute.entityType', 'patient')
      .rejectBy(this.portalOrder ? 'portal' : 'lab', 'hidden');
  }),

  requiredFields: computed('portalOrder', 'templateOptions', 'this.templateOptions.fields', function () {
    return this
      .templateOptions
      .fields
      .filterBy(this.portalOrder ? 'portal' : 'lab', 'required')
      .mapBy('key');
  }),

  hiddenFields: computed('portalOrder', 'templateOptions', 'this.templateOptions.fields', function() {
    return this
      .templateOptions
      .fields
      .filterBy(this.portalOrder ? 'portal' : 'lab', 'hidden')
      .mapBy('key');
  }),

  addressSectionHidden: computed('hiddenFields.[]', function() {
    return ADDRESS_FIELDS.every((field) => this.hiddenFields.includes(field));
  }),

  readOnly: false,
  isVisible: true,

  birthDateMinDate: computed(function(){
    return MIN_DATE;
  }),

  birthDateMaxDate: computed(function(){
    return MAX_DATE;
  }),

  _requirements() {
    const requirements = [];
    const requiredFields = get(this, 'requiredFields');

    requiredFields.forEach((fieldKey) => {
      if (fieldKey === 'gender') {
        requirements.push({ key: 'sex', name: FIELDS[fieldKey].name });
      } else {
        requirements.push({ key: fieldKey, name: FIELDS[fieldKey].name });
      }
    });

    if (!this.dateOfBirthInvalid) {
      requiredFields.push({
        key: 'dateOfBirth',
        name: "Date of Birth can't be at future or before 1900 "
      });
    }

    return requirements.uniq();
  },

  _clonePatient(patient) {
    const fields = Object.keys(FIELDS);

    fields.push('sex');
    fields.forEach((fieldName) => {
      this.model.set(fieldName, get(patient, fieldName));
    });

    return this.model;
  },

  _setRequisitionPatient(patient) {
    this.requisition.set('patient', patient);
    this.changed.update();
  },

  _sendBillingInformation(patient) {
    let patientBilling = get(patient, 'billingInformation');

    this.onSelectPatient(patientBilling);
    this.changed.update();
  },

  _updatePatientAttr(key, value) {
    let attr = {};
    let model = get(this, 'model');
    attr[value] = true;

    set(model, key, attr);
  },

  _setDefaults(patient) {
    if (!this.hiddenFields.includes('gender')) {
      patient.set('sex', '');
    }

    if (!this.hiddenFields.includes('ethnicity')) {
      patient.set('ethnicity', '');
    }

    if (!this.hiddenFields.includes('race')) {
      patient.set('race', '');
    }
  },

  init() {
    this._super(...arguments);

    if (this.requisition.isNew) {
      this._setDefaults(this.requisition.patient);
    }
  },

  actions: {

    triggerChange() {
      this.change();
    },

    updateSex(value) {
      set(this, 'model.sex', value);
    },

    updateEthnicity(value) {
      set(this, 'model.ethnicity', value);
    },

    updateRace(value) {
      set(this, 'model.race', value);
    },

    updatePatient(patient) {
      this._setRequisitionPatient(this._clonePatient(patient));
      this._sendBillingInformation(patient);
      this.change();
    },
  }

});
