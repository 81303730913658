/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components */
import Component from '@ember/component';
import layout from './template';

export default Component.extend({
  layout,
  tagName: 'li',
  classNameBindings: ['isActive:active'],
  label: null,
  required: false
});
