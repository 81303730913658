/* eslint-disable ember/no-classic-components, no-unused-vars, ember/no-classic-classes, ember/no-component-lifecycle-hooks */
import Component from '@ember/component';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { task, timeout } from 'ember-concurrency';
import ENV from 'ember-get-config';
import layout from './template';

const TIMEOUT = ENV.environment === 'test' ? 0 : 1000;

export default Component.extend({
  layout,
  tagName: '',

  store: service(),
  flashMessages: service(),

  organization: null,
  forOrdersPortal: false,

  lookupTestPanels: task(function *(search) {
    yield timeout(TIMEOUT);

    if (!this.organization) {
      this.set('testPanels', []);
      return;
    }

    let query = {
      organization_id: this.organization.id,
      fields: 'id,name',
      filter: { search },
      page: 1,
      per_page: 10
    };

    if (this.forOrdersPortal) {
      query.for_orders_portal = true;
    }

    let testPanels = yield this.store.query('test-panel', query).catch(() => {
      this.flashMessages.error('Failed to lookup test panels');
      return [];
    });

    return testPanels;
  }).restartable(),

  didReceiveAttrs() {
    this._super(...arguments);
    this.set('testPanels', this.lookupTestPanels.perform());
  },
});
