/* eslint-disable max-len, ember/no-actions-hash, ember/no-get, ember/require-computed-property-dependencies, ember/require-tagless-components, ember/no-classic-classes, ember/no-classic-components */
import Component from '@ember/component';
import {inject as service} from '@ember/service';
import {alias, and, reads} from '@ember/object/computed';
import {computed, get} from '@ember/object';
import {isNone, isPresent} from '@ember/utils';
import layout from './template';
import { DEFAULT_REQUISITION_SECTIONS_ORDER } from '../../../models/requisition-template';

export default Component.extend({
  layout,
  classNames: ['row', 'requisition-form-v1'],

  store: service(),
  currentSession: service(),
  behavior: service('sl-behavior'),

  portalOrder: false,
  readOnly: false,

  billingInformation: alias('requisition.billingInformation'),
  billingInformationEnabled: alias('billingTemplateOptions.enabled'),
  billingTemplateOptions: alias('templateOptions.billingInformation'),
  heldReasons: alias('model.heldReasons'),
  icd10CodesEnabled: alias('icd10CodesTemplateOptions.enabled'),
  icd10CodesTemplateOptions: alias('templateOptions.icd10Codes'),
  medicalConsentOptions: alias('templateOptions.medicalConsent'),
  notesEnabled: alias('organization.notesEnabled'),
  organization: reads('model.organization'),
  patient: alias('requisition.patient'),
  additionalProviderAccountsEnabled: alias('templateOptions.additionalProviderAccounts.enabled'),
  savedAddressEnabled: alias('templateOptions.savedAddress.enabled'),
  patientConsentOptions: alias('templateOptions.patientConsent'),
  patientInformationEnabled: alias('patientTemplateOptions.enabled'),
  patientTemplateOptions: alias('templateOptions.patient'),
  physicians: alias('model.physicians'),
  portalOrderingOverrides: alias('templateOptions.portalOrderingOverrides'),
  project: alias('model.project'),
  requisition: alias('model.requisition'),
  requisitionTemplateOptions: alias('templateOptions.requisition'),
  salesAccount: alias('model.requisition.salesAccount'),
  providerAccountEnabled: alias('templateOptions.salesAccount.enabled'),
  salesAccountOverrideConsentVersion: alias('model.requisition.salesAccount.overrideConsentVersion'),
  secondaryServiceEnabled: and('secondaryServiceTemplateOptions.enabled', 'notSecondaryServiceAllFieldsHidden'),
  secondaryServiceTemplateOptions: alias('templateOptions.secondaryService'),
  supplementalDocumentsEnabled: alias('requisitionTemplateOptions.supplementalDocuments'),
  ehrDocumentsEnabled: alias('requisitionTemplateOptions.ehrDocuments'),
  toxMedicationsEnabled: alias('templateOptions.medications.reflex'),
  providerAccountPatientConsentOverride: alias('salesAccount.patientConsentOverride'),

  patientConsentEnabled: computed('requisition', 'patientConsentOptions.enabled', 'notPatientConsentFieldsHidden', function() {
    const patientConsentEnabled = this.requisition.isNew ?
      Boolean(this.patientConsentOptions?.enabled) :
      Boolean(this.requisition.patientConsentVersionId);

    return patientConsentEnabled && this.notPatientConsentFieldsHidden;
  }),

  medicalConsentEnabled: computed('requisition', 'medicalConsentOptions.enabled', 'notMedicalConsentFieldsHidden', function() {
    const medicalConsentEnabled = this.requisition.isNew ?
      Boolean(this.medicalConsentOptions?.enabled) :
      Boolean(this.requisition.medicalConsentVersionId);

    return medicalConsentEnabled && this.notMedicalConsentFieldsHidden;
  }),

  canEditIdentifier: computed('behavior.behaviors.routes', 'requisition.processingStatus', 'organization.id', function() {
    if (this.portalOrder) {
      return !this.readOnly;
    }

    let status = this.get('requisition.processingStatus');
    if (status === 'processing' || status === 'complete') {
      return this.currentSession.hasSupportFullAccessEnabledForOrg(this.organization.id);
    }
    return this.behavior.isAble('requisitions.update', 'route');
  }),

  hideSamplesSection: computed('portalOrderingOverrides.hideSamplesSection', 'portalOrder', function() {
    const hideSectionForPortal = this.get('portalOrderingOverrides.hideSamplesSection');
    return this.portalOrder && hideSectionForPortal;
  }),

  medicationSectionsVisible: computed('templateOptions.medications.{enabled,strategy}', function() {
    if (isPresent(this.get('templateOptions.medications'))) {
      const enabled = this.get('templateOptions.medications.enabled');
      return enabled && isPresent(this.get('templateOptions.medications.strategy'));
    }
    return false;
  }),

  notPatientConsentFieldsHidden: computed('patientConsentOptions.fields', 'portalOrder', function() {
    let settings = this.get('patientConsentOptions.fields') || [];
    let hiddenSettings = settings.filterBy(this.portalOrder ? 'portal' : 'lab', 'hidden');
    return hiddenSettings.length < settings.length;
  }),

  notMedicalConsentFieldsHidden: computed('medicalConsentOptions.fields', 'portalOrder', function() {
    let settings = this.get('medicalConsentOptions.fields') || [];
    let hiddenSettings = settings.filterBy(this.portalOrder ? 'portal' : 'lab', 'hidden');
    return hiddenSettings.length < settings.length;
  }),

  notSecondaryServiceAllFieldsHidden: computed('portalOrder', 'secondaryServiceTemplateOptions.fields', function() {
    const fields = this.get('secondaryServiceTemplateOptions.fields') || {};

    if (Object.keys(fields).length === 0) {
      return;
    }

    return fields
      .filterBy(this.portalOrder ? 'portal' : 'lab', 'hidden')
      .length < fields.length;
  }),

  providerAccountConsentVersionId: computed('salesAccount.{consentVersionId,overrideConsentVersion}', function() {
    let consentVersionId = get(this, 'salesAccount.consentVersionId');
    let overrideConsentVersion = get(this, 'salesAccount.overrideConsentVersion');

    return overrideConsentVersion ? consentVersionId : null;
  }),

  rxnormMedicationsEnabled: computed('templateOptions.medications.{reflex,strategy}', function() {
    const reflex = this.get('templateOptions.medications.reflex');
    const strategy = this.get('templateOptions.medications.strategy');

    return !reflex && isPresent(strategy);
  }),

  sectionsOrder: computed('templateOptions.sectionsOrder.[]', function() {
    if (this.templateOptions && !isNone(this.templateOptions.sectionsOrder) && this.templateOptions.sectionsOrder.length > 0) {
      return this.templateOptions.sectionsOrder;
    }

    return DEFAULT_REQUISITION_SECTIONS_ORDER;
  }),

  templateOptions: computed('requisition.requisitionTemplate.template', function() {
    const template = this.get('requisition.requisitionTemplate.template');
    if (isNone(template)) {
      return null;
    }

    return JSON.parse(template);
  }),

  patientConsentTitle: computed('organization', function() {
    return this.organization.hasFeature('Medical Consent workaround') ? 'Patient Notification of Secondary Use and/or Medical Consent' : 'Patient Notification';
  }),

  _getInsuranceByType(insuranceType) {
    let billingInformation = this.billingInformation;

    let insuranceInformations = billingInformation.get('insuranceInformations') || [];
    let insuranceInformation = insuranceInformations.findBy('insuranceType', insuranceType);
    let store = this.get('store');

    if (isNone(insuranceInformation)) {
      insuranceInformation = store.createRecord('insurance-information', { billingInformation, insuranceType });
      insuranceInformations.pushObject(insuranceInformation);
    }

    return insuranceInformation;
  },

  _getPreAuthorizationForInsurance(insuranceInformation) {

    let preAuthorization = get(insuranceInformation, 'preAuthorization');
    if (isNone(preAuthorization) || isNone(preAuthorization.get('id'))) {
      let store = this.get('store');
      preAuthorization = store.createRecord('pre-authorization', { insuranceInformation });
      insuranceInformation.set('preAuthorization', preAuthorization);
    }
    return preAuthorization;
  },

  _isBillToAllowed(billingInformationFound) {
    const billTo = get(billingInformationFound, 'billTo');
    const enabledBillings = get(this, 'billingTemplateOptions.enabledBillings') || [];
    return enabledBillings.includes(billTo);
  },

  actions: {
    setBillingInformation(billingInformationFound) {
      if (this.billingInformationEnabled && this._isBillToAllowed(billingInformationFound)) {
        const fieldsBase = [
          'billTo',
          'city',
          'dateOfInjury',
          'email',
          'memberId',
          'name',
          'phoneNumber',
          'state',
          'street',
          'zip'
        ];

        const insuranceFields = [ //
          'dobOfInsured',
          'groupNumber',
          'idNumber',
          'insuranceProvider',
          'insuranceType',
          'insuredCity',
          'insuredEmail',
          'insuredSex',
          'insuredState',
          'insuredStreetAddress',
          'insuredZipCode',
          'nameOfPersonInsured',
          'otherRelationshipToInsured',
          'reimbursementEligibilityVerified',
          'relationshipToInsured',
          'subscriberNumber',
        ];

        const preAuthorizationFields = [
          'authorizationNumber',
          'contactName',
          'contactPhoneNumber',
          'effectiveEndDate',
          'effectiveStartDate',
          'numAllowedTests'
        ];

        // set values into BillingInformation record
        fieldsBase.forEach((fieldName) => {
          this.billingInformation.set(fieldName, get(billingInformationFound, fieldName));
        });

        const isSecondaryInsuranceProviderEnabled = this.billingTemplateOptions.enabledBillings.includes('Secondary Bill Insurance');

        (billingInformationFound.get('insuranceInformations') || []).forEach(patientInsurance => {
          let skip = patientInsurance.insuranceType === 'Secondary' && !isSecondaryInsuranceProviderEnabled;

          if (!skip) {
            let insuranceInformation = this._getInsuranceByType(patientInsurance.get('insuranceType'));

            // set values into InsuranceInformation record
            insuranceFields.forEach((fieldName) => {
              insuranceInformation.set(fieldName, get(patientInsurance, fieldName));
            });

            let patientPreAuthorization = get(patientInsurance, 'preAuthorization');

            if (patientPreAuthorization) {
              let currentPreAuthorization = this._getPreAuthorizationForInsurance(insuranceInformation);

              // set values into PreAuthorization record
              preAuthorizationFields.forEach((fieldName) => {
                currentPreAuthorization.set(fieldName, get(patientPreAuthorization, fieldName));
              });

              insuranceInformation.set('preAuthorization', currentPreAuthorization);
            }
          }
        });
      }
    }
  }
});
