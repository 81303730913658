/* eslint-disable ember/no-attrs-in-components */
/* eslint-disable ember/no-mixins, comma-spacing, ember/no-get, no-multi-spaces, object-shorthand, indent, ember/no-component-lifecycle-hooks, ember/no-actions-hash */
import { computed, get } from '@ember/object';
import { alias, bool } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import layout from './template';
import BaseComponent from '../../../requisition-base/component';
import EnforceSampleIdentifier from 'shared/mixins/enforce-sample-identifier';

export default BaseComponent.extend(EnforceSampleIdentifier, {
  layout,
  classNames: ['requisition-samples-group','m-bottom'],
  store: service(),

  isVisible: true,
  canEditIdentifier: false,
  readOnly: false,

  isNew: alias('requisition.isNew'),

  groupName: alias('group.groupName'),
  samplesRequired: alias('group.samplesRequired'),

  enforceSingleIdentifier: bool('group.enforceSingleIdentifier'),
  interpolationConfig: alias('group.sampleIdentifierInterpolation'),

  samples: computed('requisition.samples.@each.groupName', 'group.groupName', function() {
    return this.requisition.samples.filterBy('groupName', this.group.groupName);
  }),

  maxSamplesReached: computed('samples.[]', 'group.samplesAllowed', function() {
    return get(this, 'samples.length') === get(this, 'group.samplesAllowed');
  }),

  missingMinRequirements: computed('groupName', 'samples.[]', 'samplesRequired', function() {
    const samplesRequired = get(this, 'samplesRequired');
    const samplesCount    = get(this, 'samples.length');
    const groupName       = get(this, 'groupName');

    return samplesCount < samplesRequired ? [`${samplesRequired} samples required for ${groupName}`] : [];
  }),

  _addSampleToGroup() {
    const store = get(this, 'store');
    const requisition = get(this, 'requisition');
    const newSample = store.createRecord('sample', { requisition: requisition });

    newSample.get('sampleStates').pushObject(store.createRecord('sample-state'));

    get(this, 'group.samples').pushObject(newSample);
    this._setSamplesIdentifiers();
  },

  _setSamplesIdentifiers() {
   this.samples.forEach((sample, idx) => {
      this._setSampleIdentifier(sample, (idx + 1));
   });
  },

  didInsertElement() {
    this._super(...arguments);

    const isNew = get(this, 'isNew');

    // create required number on samples on new requisition
    if (isNew) {
      this.samples.forEach(sample => sample.set('requisitionTemplateSamplesGroup', this.group));

      for (let i = 0; i < this.samplesRequired - this.samples.length; i++) {
        this._addSampleToGroup();
      }
    }
  },

  samplesChanged() {
    if (typeof this.attrs.publish === 'function') {
      this.attrs.publish('samplesChanged');
    }
  },

  actions: {
    addSample() {
      this._addSampleToGroup();
    },

    removeSample(sample) {
      sample.rollbackAttributes();
      this.samples.removeObject(sample);
      this.group.samples.removeObject(sample);
      this.samplesChanged();
    },

    showNewSampleModal() {
      this.set('showNewSampleModal', true);
    },

    cancelNewSample(sample) {
      sample.rollbackAttributes();
      this.samples.removeObject(sample);
      this.group.samples.removeObject(sample);
      this.set('showNewSampleModal', false);
    },

    addCreatedSample() {
      this.set('showNewSampleModal', false);
      this.toggleProperty('sampleAdded');
      this.samplesChanged();
    }
  }
});
