/* eslint-disable no-undef, max-len */
window.deprecationWorkflow = window.deprecationWorkflow || {};
window.deprecationWorkflow.config = {
  workflow: [
    { handler: 'throw', matchMessage: 'WARNING: Binding style attributes may introduce cross-site scripting vulnerabilities; please ensure that values being bound are properly escaped. For more information, including how to disable this warning, see http://emberjs.com/deprecations/v1.x/#toc_binding-style-attributes.' },
    { handler: 'throw', matchMessage: 'DEPRECATION: Invoking the `<LinkTo>` component with positional arguments is deprecated' },
    { handler: 'throw', matchMessage: 'DEPRECATION: `hasBlock` is deprecated. Use `has-block` instead' },
    { handler: 'throw', matchMessage: 'DEPRECATION: The use of the private `{{-in-element}}` is deprecated, please refactor to the public `{{in-element}}`' }
  ]
};
