import moment from 'moment';
import { helper as buildHelper } from '@ember/component/helper';

export function dateDiff(params) {
  const from = moment(params[1]);
  const to = moment(params[0]);
  const diff = from.diff(to, 'days');

  return isNaN(diff) ? '' : diff;
}

export default buildHelper(dateDiff);