/* eslint-disable ember/no-classic-components, ember/no-classic-classes, key-spacing, object-curly-spacing, ember/no-get, object-shorthand, quotes, space-before-blocks, no-multi-spaces, keyword-spacing, curly, ember/no-actions-hash, no-trailing-spaces */
import Component from '@ember/component';
import { get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { isBlank, isEmpty, isPresent } from '@ember/utils';
import { task, timeout } from 'ember-concurrency';
import { alias } from '@ember/object/computed';
import { next } from '@ember/runloop';
import { COUNTRIES_LIST, US_STATES_LIST } from 'ember-countries';
import layout from './template';

export default Component.extend({
  layout,
  tagName: '',

  uuidService: service('uuid'),
  currentOrganization: service(),
  organization: alias('currentOrganization.organization'),
  defaultCountry : alias('organization.defaultCountry'),

  googlePlaceAutocompleteService: service('google-place-autocomplete'),
  changed: service(),

  requiredFields: [],
  fields: null,
  disabled: false,
  onChange: () => {},

  usaSelected: false,
  selectedPlace: null,
  uuid: null,

  countriesList: COUNTRIES_LIST.map((c) => c.name),
  statesList: US_STATES_LIST.map((s) => s.name),

  requestPredictions: task(function *(searchInput) {
    yield timeout(250);

    if (isBlank(searchInput)) {
      this.setProperties({ predictions: [] });
    } else {
      this.setProperties({ predictions: [{description: 'Searching...'}] });
    }

    this.set(`model.${this.fields.streetAddress}`, searchInput);

    const properties = { input: searchInput };
    const predictions = yield get(this, 'googlePlaceAutocompleteService').getPlacePredictions(properties);

    if (isEmpty(predictions)) {
      predictions.pushObject({description: searchInput});
    }

    this.set('predictions', predictions);
  }),

  getPlaceDetails: task(function *(placeId) {
    const googleRequest = {
      placeId: placeId,
      fields: ['address_components']
    };

    const placeDetails = yield get(this, 'googlePlaceAutocompleteService').getDetails(googleRequest);

    this._setPatientAddressFields(placeDetails);
    this._setUsaSelected();
  }),

  init() {
    this._super(...arguments);
    this._setDefaultKeys();
    this._setUsaSelected();

    this.set('selectedPlace', {});
    this.set('uuid', this.uuidService.create());

    this.changed.on('changed', this._updateSelectedPlaceDescription.bind(this));

    this._updateSelectedPlaceDescription();

    next(() => {
      this._setDefaultValues();
    });
  },

  _updateSelectedPlaceDescription() {
    if (!isEmpty(this.get(`model.${this.fields.streetAddress}`))) {
      set(this, 'selectedPlace.description', this.get(`model.${this.fields.streetAddress}`));
    }
  },

  _setDefaultKeys() {
    this.set('fields', this.fields || {
      streetAddress: "streetAddress",
      streetAddressLine2: "streetAddressLine2",
      city: "city",
      state: "state",
      zipCode: "zipCode",
      country: "country",
    });
  },

  _setDefaultValues() {
    if (!this.model.get(this.fields.country)){
      this.model.set(this.fields.country, this.defaultCountry);
      this._setUsaSelected();
    }
  },

  _setUsaSelected() {
    this.set('usaSelected', this.model.get(this.fields.country) === 'United States');
  },

  _setPatientAddressFields(placeDetails) {
    const addressComponents = placeDetails.address_components;
    const model = get(this, 'model');

    // const placeAddressMapping = {
    //   streetAddress: ['street_number', 'route'],
    //   country:       ['country'],
    //   city:          ['locality'],
    //   state:         ['administrative_area_level_1'],
    //   zipCode:       ['postal_code']
    // };

    const placeAddressMapping = {};
    placeAddressMapping[this.fields.streetAddress] = ['street_number', 'route'];
    placeAddressMapping[this.fields.country]       = ['country'];
    placeAddressMapping[this.fields.city]          = ['locality'];
    placeAddressMapping[this.fields.state]         = ['administrative_area_level_1'];
    placeAddressMapping[this.fields.zipCode]       = ['postal_code'];

    for(let key in placeAddressMapping) {
      const placeKeys = placeAddressMapping[key];
      const placeValues = placeKeys.map((key) => {
        const addressComponent = addressComponents.find((ac) => ac.types.includes(key));
        return addressComponent ? addressComponent.long_name : null;
      }).filter((value) => value != null);

      if (placeValues.length) {
        set(model, key, placeValues.join(' '));
      } else {
        if(key != this.fields.streetAddress) set(model, key, '');
      }
    }

    set(this, 'selectedPlace.description', model.get(this.fields.streetAddress));

    this.onChange();
  },

  actions: {
    findPlaceDetails(selectedPlace) {
      if (isBlank(selectedPlace)) {
        this.setProperties({
          selectedPlace: null,
          predictions: []
        });
        return;
      }

      if (isPresent(selectedPlace.place_id)) {
        get(this, 'getPlaceDetails').perform(selectedPlace.place_id);
      } else {
        set(this, `model.${this.fields.streetAddress}`, selectedPlace.description);
      }

      this.setProperties({
        selectedPlace: selectedPlace,
        predictions: []
      });
    },

    updateCountry(country) {
      this.model.set(this.fields.country, country);
      this.model.set(this.fields.state, null);
      this._setUsaSelected();
      this.onChange();
    },

    updateState(state) {
      this.model.set(this.fields.state, state);
      this.onChange();
    }
  }

});
