/* eslint-disable ember/no-mixins, ember/no-classic-classes, key-spacing, brace-style, no-dupe-keys, ember/no-get, quotes, indent, no-multi-spaces */
import { computed, get } from '@ember/object';
import Model, { belongsTo, hasMany, attr } from '@ember-data/model';
import Documents from 'shared/mixins/documents';
import { alias, not, filterBy } from '@ember/object/computed';

export default Model.extend(Documents, {
  // Associations
  team:                       belongsTo('team'),
  boxIntegration:             belongsTo('box-integration'),
  notificationSetting:        belongsTo('notification-setting'),
  memberships:                hasMany('membership', { async: true }),
  organizationMemberships:    hasMany('organization-membership', { async: true }),
  users:                      hasMany('user', { async: true }),
  roles:                      hasMany('role', { async: true }),
  tags:                       hasMany('tag', { async: true }),
  privileges:                 hasMany('privilege', { async: true }),
  projects:                   hasMany('project', { async: true }),
  trainingPacks:              hasMany('training-pack', { async: true }),
  requisitions:               hasMany('requisition', { async: true }),
  incompleteRequisitions:     hasMany('requisition', { async: true }),
  platingConfigurations:      hasMany('plating-configuration', { async: true }),
  requisitionTemplates:       hasMany('requisition-template', { async: true }),
  insuranceProviders:         hasMany('insurance-provider', { async: true }),
  physicians:                 hasMany('physician', { async: true }),
  salesGroups:                hasMany('sales-group', { async: true }),
  salesAccounts:              hasMany('sales-account', { async: true }),
  documentsNeedingSignature:  hasMany('document', { async: true }),
  workflowDefinitions:        hasMany('workflow-definition', { async: true }),
  barcodeTemplates:           hasMany('barcode-templates', { async: true }),
  reportConfigurations:       hasMany('report-configuration', { async: true }),
  billingConfigurations:      hasMany('billing-configuration', { async: true }),
  notifications:              hasMany('notifications', { async: true }),
  controls:                   hasMany('controls', { async: true }),
  salesReps:                  hasMany('sales-rep', { async: true }),
  startingSampleStates:       hasMany('starting-sample-state', { async: true }),
  testPanels:                 hasMany('test-panel', { async: true }),
  printTemplates:             hasMany('print-template', { async: true }),
  // Attributes
  uuid:                     attr('string'),
  name:                     attr('string'),
  identifier:               attr('string'),
  ownerName:                attr('string'),
  trainingReportUrl:        attr('string'),
  customAttributes:         attr({ defaultValue() { return {}; } }),
  features:                 attr('object', { defaultValue() { return {}; } }),
  dateInputFormat:          attr('string', { defaultValue() { return 'MM/DD/YYYY'; } }),
  dateOutputFormat:         attr('string', { defaultValue() { return 'MM/DD/YYYY'; } }),
  timeOutputFormat:         attr('string', { defaultValue() { return 'HH:mm'; } }),
  timeInputFormat:          attr('string', { defaultValue() { return 'HH:mm'; } }),
  timeZone:                 attr('string'),
  metacxKey:                attr('string'),
  containerWellOrder:       attr('string', { defaultValue() { return 'row'; } }),
  automaticDeliveredStatus: attr('boolean'),
  notesEnabled:             attr('boolean'),
  providerAccountsFilterEnabled:  attr('boolean'),
  reportOverallResultEnabled:     attr('boolean', { defaultValue: false }),
  integrations:             attr(),
  clia:                     attr('string'),
  cap:                      attr('string'),
  defaultCountry:           attr('string'),
  displaySupportEmail:      attr('string'),
  includePatientEmployerData: attr('boolean', { defaultValue: false }),
  notifyRequisitionCompleteEnabled: attr('boolean', { defaultValue: false }),
  isOrnMember: attr('boolean', { default: false }),
  isReadOnly: attr('boolean', { default: false }),

  // sftp app settings
  sftpAllowedPorts: attr(),     // allowed ports for sftp
  ovationSftpKeysLimit: attr(), // limit number of keys per Ovation SFTP integration

  supportedIntegrations: attr(),

  sanfordDisclaimerTextFirst: attr('string'),
  sanfordDisclaimerTextSecond: attr('string'),
  showResistanceGenes: attr('boolean', { defaultValue: false }),
  showOnlyTestsOrdered: attr('boolean', { defaultValue: false }),

  // Name from organization is not quite descriptive enough
  showClinicallyRelevantResistanceGenes: alias('showResistanceGenes'),

  // Whether this organization Requires a user to have MFA enabled or not.
  requireMfaLabUser: attr('boolean', { defaultValue: false }),
  requireMfaPortalUser: attr('boolean', { defaultValue: false }),

  hasFeature(feature) {
    return get(get(this, 'features'), feature) === "true";
  },

  dateTimeOutputFormat:     computed('dateOutputFormat', 'timeOutputFormat', function() {
    let dateFormat = this.get('dateOutputFormat');
    let timeFormat = this.get('timeOutputFormat');
    return { "time": timeFormat,
             "date": dateFormat };
  }),

  dateTimeInputFormat:     computed('dateInputFormat', 'timeInputFormat', function() {
    let dateFormat = this.get('dateInputFormat');
    let timeFormat = this.get('timeInputFormat');
    return `${dateFormat} ${timeFormat}`;
  }),

  // Permissions
  isAdmin:                  attr('boolean'),

  logoImage:                attr('string'),
  whitelabelDomain:         attr('string'),
  appHost:                  attr('string'),

  supportedDateFormats:     attr(),
  supportedTimeFormats:     attr(),
  supportedTimeZones:       attr(),

  datePickerFormat:         computed('dateInputFormat', 'supportedDateFormats', function() {
    let dateFormat           = this.get('dateInputFormat');
    let supportedDateFormats = this.get('supportedDateFormats');
    if (supportedDateFormats) {
      return supportedDateFormats[dateFormat].default;
    } else {
      return 'mm/dd/yyyy';
    }
  }),

  // Computed
  hasProjectDashboardWidgets: computed('customAttributes.projectDashboard', function() {
    let projectDashboard = this.get('customAttributes.projectDashboard');
    if (projectDashboard) {
      return get(projectDashboard, 'widgets.length') > 0;
    }
    return false;
  }),

  manualDeliveredStatus: not('automaticDeliveredStatus'),

  rootSalesGroups: computed('salesGroups.[]', function() {
    let salesGroups = get(this, 'salesGroups');

    return salesGroups.filter((group) => {
      const content = group.get('parent.content');
      return content === null || content === undefined;
    });
  }),

  workflowDefinitionsWithGenerateReports: filterBy('workflowDefinitions', 'generateReports'),
  workflowTypesWithGenerateReports: computed('workflowDefinitionsWithGenerateReports.@each.workflowType', function() {
    let workflowTypes = [];
    let workflowDefinitions = this.get('workflowDefinitionsWithGenerateReports');
    workflowDefinitions.forEach((workflowDefinition) => {
      workflowTypes.pushObject(get(workflowDefinition, 'workflowType'));
    });
    return workflowTypes;
  }),

  instrumentImportEnabled: alias('boxIntegration.instrumentImportEnabled'),
  baseSpace: computed('integrations', function() {
    return (this.integrations || []).includes('basespace');
  }),

});
