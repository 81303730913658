/* eslint-disable ember/no-new-mixins, ember/no-get, object-shorthand */
import Mixin from '@ember/object/mixin';
import { computed } from '@ember/object';

export default Mixin.create({
  sortOrder: computed('sortAscending', 'sortProperties.firstObject', function() {
    return [`${this.get('sortProperties.firstObject')}:${this.get('sortAscending') ? 'asc' : 'desc'}`];
  }),

  actions: {
    sortBy: function(key) {
      this.toggleProperty('sortAscending');
      this.set('sortProperties', [key]);
    },
  },
});
