
import Store from '@ember-data/store';
import containerModels from 'shared/const/container-models';

export default class ApplicationStore extends Store {

  createRecord(modelName, inputProperties) {
    if (modelName === 'container') {
      if (!('type' in inputProperties)) {
        throw new Error('Failed to create container. No type specified.');
      }
      modelName = inputProperties.type;
      delete inputProperties.type;
    }
    return super.createRecord(modelName, inputProperties);
  }

  // This has to support the /containers/198628 route, i.e. without a type.
  findRecord(modelName, id, options) {
    if (modelName === 'container') {
      if ((typeof id === 'object') && ('type' in id)) {
        modelName = id.type;
        id = id.id;
      }
    }
    return super.findRecord(modelName, id, options);
  }

  peekRecord(modelName, id) {
    if (modelName === 'container') {
      const record = containerModels.find(model => this.peekRecord(model, id));
      return record || null;
    }
    return super.peekRecord(modelName, id);
  }

  peekAll(modelName) {
    if (modelName === 'container') {
      return containerModels.map(model => super.peekAll(model).toArray()).flatten();
    }
    return super.peekAll(modelName);
  }
}
