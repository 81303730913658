/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/no-actions-hash */
import Component from '@ember/component';
import { set } from '@ember/object';
import layout from './template';

export default Component.extend({
  layout,
  tagName: '',
  page: null,
  archived: false,

  actions: {
    toggleArchived() {
      set(this, 'page', 1);
      this.toggleProperty('archived');

      let switches = document.querySelectorAll('.ios-switch');
      for (var i = 0; i < switches.length; i++) {
        if (this.archived) {
          switches[i].classList.add('active');
        } else {
          switches[i].classList.remove('active');
        }
      }
    }
  }
});
