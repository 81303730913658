/* eslint-disable ember/no-new-mixins, object-shorthand, ember/no-jquery, ember/require-super-in-lifecycle-hooks */
import Ember from 'ember';
import Mixin from '@ember/object/mixin';
import { cancel } from '@ember/runloop';
import $ from 'jquery';

export default Mixin.create({

  onOutsideClick: Ember.K,

  handleOutsideClick: function(event) {
    let $element = $(this.element);
    let $target = $(event.target);

    if (!$target.closest($element).length) {
      this.onOutsideClick();
    }
  },

  didInsertElement() {
    let clickHandler = this.handleOutsideClick.bind(this);
    return $(document).on('click', clickHandler);
  },

  willDestroyElement() {
    let clickHandler = this.handleOutsideClick.bind(this);
    return $(document).off('click', cancel(this, clickHandler));
  }
});
