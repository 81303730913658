/* eslint-disable ember/no-classic-classes, object-shorthand */
import { isEmpty } from '@ember/utils';
import TextField from '@ember/component/text-field';
import layout from './template';

export default TextField.extend({
  layout,
  type: 'file',
  bindAttributes: ['multiple'],

  multiple: true,

  change: function(e) {
    let input = e.target;
    if (!isEmpty(input.files)) {
      this.set('files', input.files);
    }
  }
});
