/* eslint-disable key-spacing */
import ApplicationSerializer from 'shared/serializers/application';

export default ApplicationSerializer.extend({
  attrs: {
    revisions:  { embedded: 'always' },
    tags:       { embedded: 'always' },
    report:     { embedded: 'always' },
    organization: { deserialize: 'records', serialize: 'id' },
    project: { deserialize: 'records', serialize: 'id' },
    requisition: { deserialize: 'records', serialize: 'id' },
  },

  serialize(snapshot) {
    let json = this._super(...arguments);

    if (snapshot.id) {
      return json;
    }

    let resourceId = snapshot.attr('resourceId');

    if (resourceId) {
      json._resource_ids = [resourceId];
    }

    return json;
  }

});
