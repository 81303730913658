/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components, ember/no-get */
import Component from '@ember/component';
import { computed, get } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  gravatar: service(),

  tagName: 'img',
  attributeBindings: ['src', 'alt', 'size:height', 'size:width'],
  classNames: ['gravatar-image', 'avatar'],
  defaultImage: '',

  src: computed('email', 'defaultImage', function() {
    return get(this, 'gravatar')
      .getUrl(get(this, 'email'), get(this, 'defaultImage'));
  })
});
