/* eslint-disable ember/no-classic-classes, key-spacing, ember/no-get, no-multi-spaces, quotes */
import Model, { attr, hasMany, belongsTo } from '@ember-data/model';
import { computed } from '@ember/object';
import { isNone } from '@ember/utils';

export default Model.extend({
  // Associations
  organization: belongsTo('organization', { async: true }),
  salesAccounts: hasMany('sales-account', { inverse: 'physicians' }),
  physicianSalesAccounts: hasMany('physician-sales-account'),
  patients:     hasMany('patient'),

  // Attributes
  name:                    attr('string'),
  email:                   attr('string'),
  officePhone:             attr('string'),
  contact:                 attr('string'),
  contactEmail:            attr('string'),
  salesAccountId:          attr('string'),
  salesAccountName:        attr('string'),
  npi:                     attr('string'),
  licensingRegistry:       attr('string'),
  archived:                attr('boolean'),
  disableNotifyWhenCreate: attr('boolean', { defaultValue: false }),

  // Helper functions
  fqn: computed('contact', 'name', 'npi', 'salesAccountName', function() {
    let name = this.get('name');
    let npi  = this.get('npi');
    let contact = this.get('contact');
    let salesAccountName = this.get('salesAccountName');

    let fqn = [];
    if (!isNone(name)) {
      fqn.push(name);
    } else {
      fqn.push(contact);
    }

    if (!isNone(npi)) {
      fqn.push(`(#${npi})`);
    }

    if (!isNone(salesAccountName)) {
      fqn.push(salesAccountName);
    }

    return fqn.join(' ');
  }),

  isNpi: computed('licensingRegistry', function() {
    return this.get('licensingRegistry').toLowerCase() === "npi";
  }),
});
