/* eslint-disable ember/no-new-mixins, ember/no-get, no-prototype-builtins */
import Mixin from '@ember/object/mixin';
import { computed, get } from '@ember/object';
import _collection from 'lodash/collection';

const { groupBy } = _collection;

export default Mixin.create({
  samplesByContainerAndPosition: computed('samples.[]', function() {
    let samples = get(this, 'samples').toArray();

    // Group samples by container and position
    return groupBy(samples, function(s) {
      return get(s, 'containerBarcodeAndPosition');
    });
  }),

  filteredSamplesByContainerAndPosition: computed('samplesByContainerAndPosition', function() {
    let groupedSamples = get(this, 'samplesByContainerAndPosition');
    let testSamplesFiltered = [];

    // To show just the first sample of each group
    for (let k in groupedSamples) {
      if (groupedSamples.hasOwnProperty(k)) {
        testSamplesFiltered.push(groupedSamples[k][0]);
      }
    }

    return testSamplesFiltered.sortBy('sampleStatePosition');
  })
});
