/* eslint-disable ember/no-mixins */
import ApplicationSerializer from 'shared/serializers/application';
import ResourceValidation from 'shared/mixins/resource-validation';

export default ApplicationSerializer.extend(ResourceValidation, {
  attrs: {
    team: { deserialize: 'records', serialize: 'ids' },
    patient: { embedded: 'always' },
    physician: { embedded: 'always' },
    secondaryPhysician: { embedded: 'always' },
    billingInformation: { embedded: 'always' },
    icdCodes: { deserialize: 'ids', serialize: 'ids' },
    heldReasons: { deserialize: 'ids', serialize: 'ids' },
    resources: { deserialize: 'records', serialize: false },
    reports: { serialize: 'ids' },
    salesAccount: { serialize: 'ids', deserialize: 'records' },
    secondarySalesAccount: { serialize: 'ids', deserialize: 'records' },
    signatures: { embedded: 'always' },
    samples: { deserialize: 'ids', serialize: 'records' },
    reimbursementRequest: { embedded: 'always' },
    requisitionTemplate: { deserialize: 'records', serialize: false },
    projectName: { serialize: false },
    sampleIdentifiers: { serialize: false },
    entityCustomAttributes: { embedded: 'always' },
    requisitionContactInfos: { serialize: 'records', deserialize: 'ids' },
    additionalPhysicianSalesAccounts: { embedded: 'always' },
    printerOptions: { serialize: false },
  },
});
