/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components, ember/no-actions-hash, ember/no-get */
import Component from '@ember/component';
import layout from './template';

export default Component.extend({
  layout,
  classNames: ['floating-timeline-container'],

  toogled: false,

  actions: {
    onToggle() {
      const state = this.get('toogled');
      this.set('toogled', !state);
    }
  }
});
