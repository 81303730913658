/* eslint-disable ember/no-classic-classes, ember/no-classic-components, ember/require-tagless-components, ember/no-side-effects, max-len, ember/no-get, ember/no-actions-hash, no-prototype-builtins, no-console */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { copy } from 'ember-copy';
import {
  notEmpty,
  empty,
  and,
  alias,
  oneWay
} from '@ember/object/computed';
import RSVP from 'rsvp';
import { isNone, isEmpty, isPresent } from '@ember/utils';
import { set, get, computed } from '@ember/object';
import _array from 'lodash/array';
import layout from './template';
import { readFile } from 'shared/utils/file-text-read';

const { flatten, uniq } = _array;

const TUBE_CONTAINER_TYPES = [
  'Tube Box',
  '96-Tube Rack',
];

const CONTAINER_COLUMNS = ['', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
const CONTAINER_ROWS = ['', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L'];

export default Component.extend({
  layout,
  classNames: ['wb-container-lookup'],

  store: service(),
  containerTypes: service(),
  flashMessages: service(),

  allowContainerCreation: true,
  barcodesPresent: false,
  clear: true,
  containerLabel: null,
  currentLocation: undefined,
  defaultContainerType: null,
  destinationConfiguration: null,
  identifier: undefined,
  organization: undefined,
  required: false,
  showMesssage: false,
  showNewContainer: false,
  specifyBarcodes: null,
  container: null,
  validIdentifiers: [],

  allowedContainerTypes: alias('destinationConfiguration.containerTypes'),
  containerType: oneWay('_defaultContainerType'),
  containerTypeEmpty: empty('containerType'),
  containerTypeMapping: alias('containerTypes.containerTypeMapping'),
  createNewContainerDisabled: alias('containerTypeEmpty'),
  hasAllowedContainerTypes: notEmpty('allowedContainerTypes'),
  hasPool: notEmpty('destinationConfiguration.pool'),
  identifierEmpty: empty('identifier'),
  lookupDisabled: alias('identifierEmpty'),
  organizationId: alias('organization.id'),
  showClear: and('clear', 'container'),

  isTubeContainer: computed('containerType', function() {
    return TUBE_CONTAINER_TYPES.includes(this.containerType);
  }),

  _container: computed('containerType', 'filteredContainers.@each.value', 'this.filteredContainers', function() {
    return this
      .filteredContainers
      .findBy('value', this.containerType) || { size: 0, columns: 0 };
  }),

  containerRows: computed('_container.{size,columns,wells}', function() {
    const columns = this._container.columns;
    const rows = this._container.wells / columns;
    const containerRows = [];

    let containerRow = [];
    for (let m = 0; m <= columns; m++) {
      containerRow.push({ label: CONTAINER_COLUMNS[m], header: true });
    }
    containerRows.push(containerRow);

    for (let n = 1; n <= rows; n++) {
      containerRow = [];
      containerRow.push({ label: CONTAINER_ROWS[n], header: true });
      for (let m = 1; m <= columns; m++) {
        containerRow.push({
          barcode: null,
          position: `${CONTAINER_ROWS[n]}${CONTAINER_COLUMNS[m]}`
        });
      }
      containerRows.push(containerRow);
    }

    this.set('barcodesPresent', false);

    return containerRows;
  }),

  containers: computed('containerRows', function() {
    const containers = [];
    this.containerRows.forEach((row) => {
      row.forEach((container) => {
        if (!container.header) {
          containers.push(container);
        }
      });
    });

    return containers;
  }),

  showManualBarcodeInput: computed('specifyBarcodes', 'barcodesPresent', function() {
    return this.specifyBarcodes === 'manually' && !this.barcodesPresent;
  }),

  showUploadBarcodeInput: computed('specifyBarcodes', 'barcodesPresent', function() {
    return this.specifyBarcodes === 'upload' && !this.barcodesPresent;
  }),

  minContainerSize: computed('defaultContainerType', 'destinationConfiguration.minContainerSize', 'hasPool', 'containerTypeMapping', function() {
    let defaultContainerType = this.defaultContainerType;
    let minContainerSize = get(this, 'destinationConfiguration.minContainerSize');
    let hasPool = this.hasPool;

    if (hasPool) {
      return 0;
    }

    if (isNone(minContainerSize)) {
      if (this.containerTypeMapping[defaultContainerType]) {
        return this.containerTypeMapping[defaultContainerType].wells;
      }
      return 0;
    }

    switch (minContainerSize) {
    case 'any':
      return 0;
    case 'same':
      if (this.containerTypeMapping[defaultContainerType]) {
        return this.containerTypeMapping[defaultContainerType].wells;
      }
      return 0;
    default:
      return minContainerSize;
    }
  }),

  filteredContainers: computed('allowedContainerTypes', 'hasAllowedContainerTypes', 'containerTypeMapping', function() {
    let filteredContainers = [];
    for (let key in this.containerTypeMapping) {
      if (Object.prototype.hasOwnProperty.call(this.containerTypeMapping, key)) {
        let containerType = copy(this.containerTypeMapping[key]);
        containerType.defaultType = key;
        containerType.value = key;
        containerType.label = key;
        containerType.columns = containerType.size;
        filteredContainers.pushObject(containerType);
      }
    }

    if (this.hasAllowedContainerTypes) {
      let allowedContainerTypes = this.allowedContainerTypes;
      filteredContainers = filteredContainers.filter(function(item) {
        return allowedContainerTypes.includes(item.value);
      });
    }

    return filteredContainers;
  }),

  label: computed('containerLabel', function() {
    let label = this.containerLabel;

    if (isNone(label)) {
      return undefined;
    }

    return `Container Identifier (label: ${label})`;
  }),

  _defaultContainerType: computed('defaultContainerType', 'containerTypeMapping', function() {
    let defaultContainerType = this.defaultContainerType;

    let containerAttr = this.containerTypeMapping[defaultContainerType];
    if (containerAttr) {
      return containerAttr.defaultType;
    }
    return null;
  }),

  invalidIdentifierMessage: computed('validIdentifiers', function() {
    let validIdentifiers = this.validIdentifiers;
    if (validIdentifiers.length) {
      return `Incorrect container. The expected container barcode is ${validIdentifiers.join(', ')}`;
    }
    return 'Unable to locate container information.';
  }),

  _lookupContainer(identifier) {
    let organizationId = this.organizationId;
    let container;
    let store = this.store;
    let containerLabel = this.containerLabel;
    let containerType = this.containerType;
    let validIdentifiers = this.validIdentifiers;
    let valid = validIdentifiers.length === 0 || validIdentifiers.any((containerIdentifier) => {
      return containerIdentifier === identifier;
    });

    if (!valid) {
      return new RSVP.Promise(function(resolve, reject) {
        resolve([]);
        reject(null);
      });
    }

    container = store
      .peekAll('container')
      .find((record) => {
        return record.get('organization.id') === organizationId &&
          (isNone(containerLabel) || record.get('label') === containerLabel) &&
          (record.get('identifier') === identifier || record.get('barcode') === identifier);
      });

    if (container) {
      return new RSVP.Promise(function(resolve, reject) {
        resolve(container);
        reject(null);
      });
    }

    let queryParams = {
      organization_id: organizationId,
      identifier,
      filter: {},
      include: 'container.sample-states,sample-state.sample'
    };

    if (containerLabel) {
      queryParams.filter.label = containerLabel;
    }

    if (containerType) {
      queryParams.filter.type = containerType;
    }

    if (this.portalOrder) {
      queryParams.for_orders_portal = true;
    }

    return store.queryRecord('container', queryParams);
  },

  _createNewContainer() {
    const type = this.containerTypeMapping[this.containerType].type;
    let container = this.store.createRecord('container', {
      organization: this.organization,
      identifier: this.identifier,
      location: this.currentLocation,
      label: this.containerLabel,
      type
    });

    if (this.specifyBarcodes === 'manually' || this.specifyBarcodes === 'upload') {
      container.set('barcodes', this.containers);
    }

    if (this.portalOrder) {
      container.set('forOrdersPortal', true);
    }

    container.save().then(
      () => {
        this.flashMessages.success('Container created');
        this.set('container', container);
        this.set('identifier', undefined);
        this.set('currentLocation', undefined);
        this.set('showNewContainer', false);
        this.callback({ container });
      },
      () => {
        let msg = [];
        if (container.get('errors.barcode_label')) {
          let errors = container.get('errors.barcode_label').mapBy('message').join(', ');
          msg.push(`Identifier ${errors}`);
        }
        if (container.get('errors.location')) {
          let errors = container.get('errors.location').mapBy('message').join(', ');
          msg.push(`Current location ${errors}`);
        }
        this.flashMessages.error(`Failed to create container. ${msg.join('. ')}`);
        container.rollbackAttributes();
        this.set('container', null);
      }
    );
  },

  init() {
    this._super(...arguments);
    this.set('specifyBarcodes', 'automatically');
    this.containerTypes.loadDataTask.perform();
  },

  actions: {
    toggleNew() {
      this.toggleProperty('showNewContainer');
    },

    cancel() {
      this.set('containerType', null);
      this.toggleProperty('showNewContainer');
    },

    clearSelection() {
      let callback = this.callback;

      get(this, 'container.sampleIdentifiers').forEach((i) => {
        let firstSample = i[0];
        // clear plate for currently assigned samples
        if (firstSample.hasOwnProperty('id') && !firstSample.hasOwnProperty('disabled')) {
          i.clear();
          i.pushObject({ identifier: 'Empty' });
        }
      });

      this.set('container', null);
      this.set('containerType', null);

      if (typeof callback === 'function') {
        callback({ container: null });
      }
    },

    updateContainerType(value) {
      set(this, 'containerType', value);
    },

    lookupContainer() {
      let identifier = this.identifier;

      if (isEmpty(identifier)) {
        return;
      }

      this._lookupContainer(identifier).then(
        (container) => {
          let callback = this.callback;
          let allowContainerCreation = this.allowContainerCreation;

          if (container) {
            this.set('container', container);
            this.set('identifier', undefined);

            // Set disabled locations
            get(this, 'container.sampleIdentifiers').forEach((samples) => {
              samples.forEach((s) => {
                if (s.hasOwnProperty('id')) {
                  s.disabled = true;
                }
              });
            });

            set(this, 'container.disabledWellLocations', uniq(flatten(get(this, 'container.wellAssignments').map((wa) => wa.locations))));

            if (typeof callback === 'function') {
              callback({ container });
            }

            // reload to ensure sample states are included
            this.store.findRecord('container', container.id, {
              reload: true,
              include: 'container.sample-states'
            });
          } else {
            this.set('container', null);
            this.toggleProperty('showMessage');
            if (allowContainerCreation) {
              this.set('showNewContainer', true);
            }
          }
        },
        (xhr) => {
          console.log(xhr);
        }
      );
    },

    createNewContainer() {
      this._createNewContainer();
    },

    uploadBarcodes(file) {
      if (this.isUploadingBarcodes) {
        return;
      }

      this.set('isUploadingBarcodes', true);
      readFile(file, (e) => {
        const errors = [];
        const data = (e.target.result || '').split('\n');

        data.forEach((row) => {
          const rowData = row.trim().replace(/"/g, '').split(',');
          const barcode = rowData[0];
          const position = rowData[1];

          if (isPresent(barcode) && isPresent(position)) {
            const container = this.containers.findBy('position', position);

            if (container) {
              container.barcode = barcode;
            } else {
              errors.push(`Failed to assign barcode '${barcode}' to position '${position}'`);
            }
          }

        });

        if (errors.length) {
          this.flashMessages.error(errors.join('\n'));
        } else {
          this.set('barcodesPresent', true);
        }

        this.set('isUploadingBarcodes', false);
      });
    },

    specifyManualBarcodes() {
      this.set('barcodesPresent', true);
    },

    downloadExampleFile() {
      const content = [
        'P000123,A01',
        'P000124,A02',
        'P000125,A05',
        'P000126,B01',
      ]
        .join('\n');

      const csvContent = `data:text/csv;charset=utf-8,${content}\n`;
      const encodedUri = encodeURI(csvContent);
      const tmpLink = document.createElement('a');
      tmpLink.download = 'barcodes.csv';
      tmpLink.href = encodedUri;
      tmpLink.click();
    },
  }

});
