/* eslint-disable ember/no-classic-components, ember/no-classic-classes, no-trailing-spaces */
import Component from '@ember/component';
import { computed } from '@ember/object';
import ENV from 'ember-get-config';
import layout from './template';

export default Component.extend({
  layout,
  tagName: '',

  destinationElementId: computed(function() {
    if (ENV.environment === 'test') {
      return 'ember-testing';
    }

    return 'ember-wormhole';
  }),
});

