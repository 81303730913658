/* eslint-disable ember/no-classic-classes, key-spacing, ember/no-get */
import Model, { hasMany, attr, belongsTo } from '@ember-data/model';
import { computed } from '@ember/object';

export default Model.extend({
  // Associations

  organization:  belongsTo('organization'),
  salesGroup:    belongsTo('sales-group'),
  salesAccounts: hasMany('sales-accounts'),

  // Attributes
  firstName:    attr('string'),
  lastName:     attr('string'),
  officePhoneNumber: attr('string'),
  email:        attr('string'),
  hasRequisitionAccess: attr('boolean'),
  archived:     attr('boolean'),
  archivable:   attr('boolean'),
  canResetPassword: attr('boolean'),
  isGroupManager: attr('boolean'),

  fullName: computed('firstName', 'lastName', function() {
    return `${this.get('firstName')} ${this.get('lastName')}`;
  })
});
