/* eslint-disable ember/no-mixins, ember/no-classic-classes, key-spacing, brace-style, ember/no-get */
import Model, { hasMany, belongsTo, attr } from '@ember-data/model';
import { computed } from '@ember/object';
import Documents from 'shared/mixins/documents';

export default Model.extend(Documents, {
  // Associations
  organization:                           belongsTo('organization'),
  team:                                   belongsTo('team'),
  sourceProject:                          belongsTo('project', { async: true }),

  memberships:                            hasMany('membership', { async:     true }),
  tags:                                   hasMany('tag', { async:            true, inverse: null }),
  trainingPacks:                          hasMany('training-pack', { async:  true }),
  queuedJobs:                             hasMany('queued-job'),
  projectRequisitionTemplateAssociations: hasMany('project-requisition-template-association', { async: true }),
  associatedReportConfigurations:         hasMany('associated-report-configuration', { async: true }),

  // Attributes
  name:                attr('string'),
  uuid:                attr('string'),
  archived:            attr('boolean'),
  archivable:          attr('boolean'),
  createdAt:           attr('date'),
  customAttributes:    attr({ defaultValue:  () => { return {}; } }),
  researchProjectUrl:  attr('string'),
  exportUrl:           attr('string'),

  // Permissions
  isAdmin:             attr('boolean'),

  // Helper methods
  trainingReportUrl: computed('_data.links.trainingPacks', function() {
    return `${this.get('_data.links.trainingPacks')}.csv`;
  }),

  createdDate: computed('created_timestamp', function() {
    let d = new Date(this.get('created_timestamp') * 1000);
    return `${d.getFullYear()}-${d.getMonth()}-${d.getDate()}`;
  }),

  // Behavior
  behaviorGroup: 'route',
  behaviors: {}

});
