/* eslint-disable ember/no-new-mixins, ember/no-get */
import { computed } from '@ember/object';
import Mixin from '@ember/object/mixin';
import { isPresent } from '@ember/utils';
import { alias } from '@ember/object/computed';

export default Mixin.create({
  isExternal: computed('externalIdentifier', function() {
    return isPresent(this.get('externalIdentifier'));
  }),

  disableBasicFields: alias('isExternal')
});
