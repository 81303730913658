/* eslint-disable keyword-spacing, ember/no-classic-components, ember/no-classic-classes */
import { computed } from'@ember/object';
import Component from '@ember/component';
import layout from './template';

export default Component.extend({
  layout,
  tagName: '',

  loadPercentage: computed('organism.load_percentage', 'loadPercentagesTotal', function() {
    const loadPercentagesTotal = this.loadPercentagesTotal;
    const organismLoad = this.organism.load_percentage;

    return parseFloat(((organismLoad / loadPercentagesTotal) * 100).toFixed(3));
  })
});
