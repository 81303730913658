/* eslint-disable ember/no-new-mixins, object-shorthand */
import Mixin from '@ember/object/mixin';

export default Mixin.create({
  fileContents: function(file) {
    let reader = new FileReader();
    reader.readAsText(file);
    return reader.result;
  },

  readFile: function(file, onLoadCallback) {
    let reader = new FileReader();
    reader.onload = onLoadCallback;
    reader.readAsText(file);
  }
});
