/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components, max-len, ember/no-get, keyword-spacing, no-multi-spaces, key-spacing, object-shorthand, semi, indent */
import Component from '@ember/component';
import { isBlank } from '@ember/utils';
import { get, computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import { groupBy } from 'lodash/collection';
import { inject as service } from '@ember/service';

export default Component.extend({
  settings: service(),
  store: service(),
  customAttributesAssociations: [],
  readOnly: false,

  portalOrder: alias('settings.portalOrder'),
  entityCustomAttributes: alias('model.entityCustomAttributes'),

  groupedCustomAttributes: computed('customAttributesAssociations.[]', 'entityCustomAttributes.@each.id', 'model', 'portalOrder', function() {
    const model = get(this, 'model');
    const customAttributesAssociations = get(this, 'customAttributesAssociations');

    if (!customAttributesAssociations) {
      return;
    }

    const formattedFields = customAttributesAssociations.map((association) => {
      const globalCustomAttribute = association.get('globalCustomAttribute');
      const entityCustomAttributes = model.get('entityCustomAttributes');
      let entityCustomAttribute = entityCustomAttributes.findBy('globalCustomAttribute.id', globalCustomAttribute.get('id'));

      if (!entityCustomAttribute) {
        entityCustomAttribute = get(this, 'store').createRecord('entity-custom-attribute', { globalCustomAttribute });
        entityCustomAttributes.pushObject(entityCustomAttribute);
      }
      const selectProperties = globalCustomAttribute.get('selectProperties');
      let parsedSelectProperties = {};

      if (!isBlank(selectProperties)) {
        try{
          parsedSelectProperties = JSON.parse(selectProperties);
        } catch (e) {
          parsedSelectProperties = {};
          console.error(e);
        }
      }

      const hidden   = association.get(this.portalOrder ? 'portal' : 'lab') === 'hidden';
      const required = association.get(this.portalOrder ? 'portal' : 'lab') === 'required';

      if (hidden) {
        return;
      }

      const [group, header, note] = (association.get('group') || '').split('|');

      return {
        model:      entityCustomAttribute,
        group:      group,
        header:     header,
        note:       note,
        name:       globalCustomAttribute.get('name'),
        key:        'value',
        type:       globalCustomAttribute.get('attributeType'),
        renderType: globalCustomAttribute.get('renderType'),
        required:   required,
        columnWidth: association.get('columnWidth'),
        blank:      parsedSelectProperties.blank,
        prompt:     parsedSelectProperties.prompt,
        options:    parsedSelectProperties.options,
        instruction:  parsedSelectProperties.instruction
      }
    })
    .compact();

    return groupBy(formattedFields, (field) => {
      return field.group;
    });
  })
});
