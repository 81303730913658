/* eslint-disable ember/no-classic-classes */
import Service, { inject as service } from '@ember/service';
import parseQueryData from 'shared/utils/parse-query-data';
import { task } from 'ember-concurrency';
import { alias } from '@ember/object/computed';

export default Service.extend({
  store: service(),
  settings: service(),

  tableApi: null,

  totalRows: alias('data.meta.total_entries'),

  fields: Object.freeze([
    'id',
    'name',
    'start_date',
    'last_run_date',
    'frequency',
    'organization.id',
    'providers.id',
    'providers.name',
    'providers.npi',
    'provider_accounts.id',
    'provider_accounts.name',
    'project.id',
    'project.name',
    'test_panel.id',
    'test_panel.name',
    'user',
    'requisition_template.id',
    'columns'
  ]),

  loadDataTask: task(function *(params) {
    // Save the last run params or use ones passed in from YetiTable API
    if (!params) {
      params = this.params;
    } else {
      this.set('params', params);
    }

    let query = parseQueryData(params);

    query.organization_id = this.organization.get('id');
    query.sort = query.sort || '-requisition_report_schedules.created_at';
    query.fields = this.fields.join(',');
    query.include = 'organization,providers,provider-accounts,project,user,test-panel';
    query.no_tags = true;

    let response = yield this.store.query('requisition-report-schedule', query);

    this.set('data', response);

    return response;
  }).restartable(),

  setOrganization(organization) {
    this.set('organization', organization);
  }
});
