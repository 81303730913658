/* eslint-disable ember/no-classic-components, ember/no-classic-classes */
import Component from '@ember/component';
import { oneWay } from '@ember/object/computed';
import layout from './template';

export default Component.extend({
  layout,
  tagName: '',

  linkClass: oneWay('breadCrumbs.linkClass')
});
