/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/no-actions-hash, ember/no-get */
import { alias } from '@ember/object/computed';
import Component from '@ember/component';
import layout from './template';
import { inject as service } from '@ember/service';

export default Component.extend({
  layout,
  tagName: '',

  flashMessages: service(),

  resource: undefined,
  resources: [],
  onAfterDelete: () => {},

  class: '',

  visible: alias('resource.deletable'),

  actions: {
    delete() {
      let resource = this.get('resource');
      let messages = this.get('flashMessages');
      let resources = this.get('resources');

      resource.destroyRecord().then(
        () => {
          messages.success('Resource deleted');
          resource.delete();
          resources.removeObject(resource);
          this.onAfterDelete();
        },
        () => {
          messages.error('Failed to delete resource');
          resource.rollbackAttributes();
        }
      );
    }
  }
});
