/* eslint-disable max-len, indent */
import { helper as buildHelper } from '@ember/component/helper';

export function attestationText(params) {
  let text = `I, ${params[0]} (or delegate), hereby attest that this medical record entry for ${params[1]} accurately reflects
    signatures/notations that I made in my capacity as an authorized healthcare provider when I treated/diagnosed the
    named beneficiary in this order. I do hereby attest that this information is true, accurate and complete to the
    best of my knowledge and I understand that any justification, omission, or concealment of material fact may
    subject me to administrative, civil, or criminal liability.`;
  return text;
}

export function attestationTextForBulkSignAndSubmit(params) {
  let _text = params[0] > 1
            ? `these ${params[0]} medical records entry for ${params[1]} accurately reflect`
            : `this 1 medical record entry for ${params[1]} accurately reflects`;
  let text = `I, Provider (or delegate), hereby attest that ${_text}
    signatures/notations that I made in my capacity as an authorized healthcare provider when I treated/diagnosed the
    named beneficiary in this order. I do hereby attest that this information is true, accurate and complete to the
    best of my knowledge and I understand that any justification, omission, or concealment of material fact may
    subject me to administrative, civil, or criminal liability.`;
  return text;
}

export default buildHelper(attestationText);
