import EmberPopover from 'ember-tooltips/components/ember-popover';
import ENV from 'ember-get-config';

const CONTAINER = ENV.environment === 'test' ? '#ember-testing' : '#ember-wormhole';

export default class UiPopover extends EmberPopover {
  event = 'hover';
  popperContainer = CONTAINER;
  popperOptions = {
    modifiers: {
      flip: {
        enabled: false,
      },
    }
  };
}
