/* eslint-disable ember/no-mixins, ember/no-classic-classes, key-spacing */
import Model, { attr, hasMany, belongsTo } from '@ember-data/model';
import Tags from 'shared/mixins/tags';

export default Model.extend(Tags, {
  // Associations
  organization:   belongsTo('organization'),
  controlContainers:      hasMany('control-container', { inverse: null, async: false }),
  validControlContainers: hasMany('control-container', { inverse: null }),
  tags:           hasMany('tag', { async: false }),
  tagType:        attr('string'),

  name:           attr('string'),
  archived:       attr('boolean', { defaultValue: false }),
  archivable:     attr('boolean', { defaultValue: false }),
  permissions:    attr(),
  tagIds:         attr(),

  selectedContainer: attr()
});
