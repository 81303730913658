/* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components, ember/no-actions-hash, ember/no-get */
import Component from '@ember/component';
import layout from './template';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { computed } from '@ember/object';

const STATUS = ['processing', 'generated', 'failed'];

export default Component.extend({
  layout,
  classNames: ['table-responsive-auto admin-reports-filter-form'],

  adminReports: service(),
  settings: service(),
  portalPermissions: service(),

  fromLabel: 'from',
  klass: 'date',
  organization: null,
  statusOptions: STATUS,
  toLabel: 'to',

  dateFormat: alias('organization.datePickerFormat'),
  isPrimaryPhysicianContact: alias('portalPermissions.isPrimaryPhysicianContact'),
  isSalesRep: alias('portalPermissions.isSalesRep'),
  portalOrder: alias('settings.portalOrder'),
  totalRows: alias('data.meta.total_entries'),

  requisitionReportDisabled: computed('organization.features', function() {
    return this.organization.hasFeature('Disable Requisition Reports');
  }),

  init() {
    this._super(...arguments);
    this.adminReports.set('reportType', 'requisition');
  },

  actions: {
    resetFilters() {
      this.get('preference').setProperties({
        resourcePath: '',
        searchParams: '',
        requestDateGteq: '',
        requestDateLteq: '',
        userFirstOrLastName: '',
        selectedStatus: ''
      });
    }
  }
});
