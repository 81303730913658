import Model, { attr } from '@ember-data/model';

export default class SampleTagModel extends Model {
  @attr('string') name;
  @attr('boolean') archived;
  @attr('date') createdAt;

  get archivable() {
    return true;
  }
}
