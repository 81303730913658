import Model, { belongsTo, hasMany, attr } from '@ember-data/model';

export default class SampleType extends Model {
  @belongsTo('sample-type', { async: false }) parent;
  @hasMany('sample-type', { async: false, inverse: 'parent' }) sampleTypes;
  @hasMany('sample-type-name', { async: false }) sampleTypeNames;

  @attr('string') category;
  @attr('string') sctid;
  @attr('string') name;
  @attr('string') version;
  @attr('string') snomedClass;

  get displayName() {
    return `${this.name} (${this.sctid} - ${this.category})`;
  }
}
