/* eslint-disable ember/no-classic-classes, key-spacing, brace-style */
import Model, { attr, belongsTo } from '@ember-data/model';

export default Model.extend({

  // Associations
  user:        belongsTo('user', { async: true }),

  // Attributes
  lastAction:                               attr({ defaultValue: () => { return {}; } }),
  sendOrderEmail:                           attr('boolean'),
  orderEmailFrequency:                      attr('string'),
  orderEmailTime:                           attr('datetime'),
  sendExternalOrderEmail:                   attr('boolean'),
  externalOrderEmailFrequency:              attr('string'),
  externalOrderEmailTime:                   attr('datetime'),
  sendFaxFailEmail:                         attr('boolean'),
  sendReportCompletedEmail:                 attr('boolean'),
  reportCompletedEmailFrequency:            attr('string'),
  reportCompletedEmailTime:                 attr('datetime'),
  sendReportSignRequiredEmail:              attr('boolean'),
  reportSignRequiredEmailFrequency:         attr('string'),
  reportSignRequiredEmailTime:              attr('datetime'),
  sendReportFailedEmail:                    attr('boolean'),
  reportFailedEmailFrequency:               attr('string'),
  reportFailedEmailTime:                    attr('datetime'),
  sendProviderAccountCreatedEmail:          attr('boolean'),
  sendSecondaryProviderAccountCreatedEmail: attr('boolean'),
  sendCommentEmail:                         attr('boolean'),
  sendUserCommentEmail:                     attr('boolean'),
  notificationsEnabled:                     attr('boolean'),
  organizationId:                           attr('string')
});
