/* eslint-disable ember/no-classic-classes, key-spacing */
import Model, { belongsTo, attr } from '@ember-data/model';

export default Model.extend({
  // Associations
  organization:   belongsTo('organization', { async: true }),

  // Attributes
  name:           attr('string'),
  route:          attr('string'),
  category:       attr('string'),

});
