/* eslint-disable ember/no-classic-components, quotes, ember/no-classic-classes, ember/require-tagless-components, ember/require-computed-property-dependencies, space-before-function-paren, ember/no-get, max-len, object-shorthand, ember/no-actions-hash */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { alias, and, equal } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import layout from "./template";

export default Component.extend({
  layout,
  classNames: ["requisition-save"],

  currentOrganization: service(),
  currentSession: service('current-session'),
  currentUser: alias('currentSession.currentUser'),
  store: service(),
  flashMessages: service(),
  segment: service(),
  organization: alias('currentOrganization.organization'),

  requisition: null,
  showSaveConfirmation: false,
  showUncompleteConfirmation: false,
  showOnHeldConfirmation: false,
  saveDisabled: false,
  isActive: false,
  redirect: false,

  hideButtons: true,
  hideSaveDraftButton: true,

  isSubmitted: null,
  showSignatureText: false,
  signatureText: '',
  isEditingDigitalSignature: false,

  isSubmitButtonDisabled: computed('currentSignature', 'isEditingDigitalSignature', function () {
    return !this.get('currentSignature.dataUri') || this.isEditingDigitalSignature;
  }),
  currentSignature: alias('currentUser.currentSignature'),

  showChangedDialog: and('showSaveConfirmation', 'isActive'),

  isCancelled: equal('requisition.status', 'canceled'),

  showUncompleteDialog: computed('isActive', 'showUncompleteConfirmation', 'showSaveConfirmation', function() {
    let showUncompleteConfirmation = this.get('showUncompleteConfirmation');
    let isActive = this.get('isActive');
    let showSaveConfirmation = this.get('showSaveConfirmation');
    return isActive && showUncompleteConfirmation && !showSaveConfirmation;
  }),

  showHeldStatusDialog: computed('showOnHeldConfirmation', 'showSaveConfirmation', 'showUncompleteConfirmation', 'isActive', function() {
    let showOnHeldConfirmation = this.get('showOnHeldConfirmation');
    let showSaveConfirmation = this.get('showSaveConfirmation');
    let showUncompleteConfirmation = this.get('showUncompleteConfirmation');
    let isActive = this.get('isActive');
    return showOnHeldConfirmation && isActive && !showSaveConfirmation && !showUncompleteConfirmation;
  }),

  showDialog: computed('showSaveConfirmation', 'showOnHeldConfirmation', 'showUncompleteConfirmation', function() {
    let showSaveConfirmation = this.get('showSaveConfirmation');
    let showUncompleteConfirmation = this.get('showUncompleteConfirmation');
    let showOnHeldConfirmation = this.get('showOnHeldConfirmation');
    return showSaveConfirmation || showUncompleteConfirmation || showOnHeldConfirmation;
  }),

  portalSignatureRequired: alias('requisition.portalSignatureRequired'),

  _submitTextButton: computed('isSubmitted', function () {
    return this.isSubmitted ? 'Resubmit' : 'Submit';
  }),

  _signatureTextButton: computed('isSubmitted', 'portalSignatureRequired', 'canPortalSign', function () {
    if (this.portalSignatureRequired && this.canPortalSign) {
      return this.isSubmitted ? 'Resign and ' : 'Sign and ';
    }
    return '';
  }),
  submitButtonText: computed('_signatureTextButton', '_submitTextButton', function() {
    return `${this._signatureTextButton}${this._submitTextButton}`;
  }),

  canPortalSign: alias('requisition.canPortalSign'),
  submitButtonAction: computed('portalOrder', 'canPortalSign', 'portalSignatureRequired', function () {
    if (this.portalOrder && this.portalSignatureRequired && this.canPortalSign) {
      return 'signAndSubmit';
    }

    return 'submit';
  }),

  _save: function() {
    let redirect = this.get('redirect');
    let sign = this.get('sign');
    this.get('on-save')(redirect, sign);
    this.set('isActive', false);
  },

  _saveDraft: function() {
    let redirect = this.get('redirect');
    this.get('on-save-draft')(redirect);
    this.set('isActive', false);
  },

  actions: {
    updateProcessingStatusAndSave() {
      this.set('showOnHeldConfirmation', false);
      this.set('requisition.onHold', false);
      this._save();
    },

    cancelChange() {
      this.set('isActive', false);
    },

    confirmChange() {
      if (!this.get('showOnHeldConfirmation')) {
        this._save();
      }
    },

    confirmUncomplete() {
      this.set('showUncompleteConfirmation', false);
      if (!this.get('showOnHeldConfirmation')) {
        this._save();
      }
    },

    save() {
      if (this.get('showDialog')) {
        this.set('isActive', true);
      } else {
        this._save();
      }
    },

    saveDraft() {
      this._saveDraft();
      this.set('isActive', false);
    },

    submit(sign) {
      this.set('redirect', true);
      this.set('sign', sign);
      this._save();
      this.segment.trackEvent('Requisition submitted');
    },

    signAndSubmit() {
      this.set('showSignatureText', true);
    },

    activate() {
      this.set('isActive', true);
    },

    activateSubmit() {
      this.set('isActive', true);
      this.set('redirect', true);
    },

    async updateSignature(encodedSignature) {
      let user = this.currentUser;
      let dataUri = encodedSignature;

      let record = await this.store.createRecord('digital-signature', {
        user,
        dataUri
      });

      record.save().catch(() => {
        this.flashMessages.error('An error ocurred creating the new signature. Please try again.');
      });
    }
  }

});
