import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! template-lint-disable no-action no-implicit-this prettier require-input-label }}\n<div class=\"row requisition-template-select w-large\"\n     data-test-id=\"requisition-template-select\"\n     {{did-update this.didUpdateTemplates @templates}}\n     ...attributes>\n  <ControlGroup>\n    <PowerSelect\n      @placeholder={{this.placeholder}}\n      @selected={{@template}}\n      @options={{sort-by \"name\" this.filteredTemplates}}\n      @searchEnabled={{true}}\n      @searchField=\"name\"\n      @onChange={{action this.changeSelectedTemplate}}\n      @dropdownClass=\"light-gray\"\n      @verticalPosition=\"below\"\n      @disabled={{or this.disabled (and (lt this.filteredTemplates.length 2) @template.id)}}\n      as |template|>\n\n      {{template.name}}\n\n    </PowerSelect>\n  </ControlGroup>\n</div>", {"contents":"{{! template-lint-disable no-action no-implicit-this prettier require-input-label }}\n<div class=\"row requisition-template-select w-large\"\n     data-test-id=\"requisition-template-select\"\n     {{did-update this.didUpdateTemplates @templates}}\n     ...attributes>\n  <ControlGroup>\n    <PowerSelect\n      @placeholder={{this.placeholder}}\n      @selected={{@template}}\n      @options={{sort-by \"name\" this.filteredTemplates}}\n      @searchEnabled={{true}}\n      @searchField=\"name\"\n      @onChange={{action this.changeSelectedTemplate}}\n      @dropdownClass=\"light-gray\"\n      @verticalPosition=\"below\"\n      @disabled={{or this.disabled (and (lt this.filteredTemplates.length 2) @template.id)}}\n      as |template|>\n\n      {{template.name}}\n\n    </PowerSelect>\n  </ControlGroup>\n</div>","moduleName":"shared/components/requisition-template-select.hbs","parseOptions":{"srcName":"shared/components/requisition-template-select.hbs"}});
import Component from '@glimmer/component';
import validateArgs from 'shared/utils/validate-args';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class RequisitionTemplateSelect extends Component {

  label = null;
  requiredArguments = [
    'template',
    'templates'
  ];

  @tracked lastUpdated;

  constructor() {
    super(...arguments);
    validateArgs(this.requiredArguments, this.args);
    this.placeholder = this.args.placeholder || 'Select Template';
    this.disabled = this.args.disabled ||
      (this.args.requisition && this.args.requisition.reportingStatus !== 'not-started');

    if (typeof this.args.subscribe === 'function') {
      this.args.subscribe('requestedTestsChanged', this.updateFilteredTemplates.bind(this));
      this.args.subscribe('samplesChanged', this.updateFilteredTemplates.bind(this));
    }
  }

  get templates() {
    return this.args.templates;
  }

  updateFilteredTemplates() {
    this.lastUpdated = (new Date()).getTime();
  }

  get filteredTemplates() {
    if (!this.args.filterTemplates || !this.args.template) {
      return this.args.templates || [];
    }

    if (this.args.template.get('isLegacyTemplate')) {
      return [];
    }

    this.lastUpdated; // eslint-disable-line no-unused-expressions

    // check on sample groups
    const sampleGroupNames = (this.args.template.get('requisitionTemplateSamplesGroups')?.toArray() || [])
      .map(group => group.groupName);
    let testPanelKeys = [];
    if (this.args.requisition) {
      testPanelKeys = Object.keys(this.args.requisition.requestedTests)
        .filter(key => this.args.requisition.requestedTests[key] && key.startsWith('p'));
    }

    return this.args.templates.filter((template) => {
      const newSampleGroups = template.requisitionTemplateSamplesGroups;
      const newSampleGroupNames = newSampleGroups.map(group => group.groupName);
      const hasAllSampleGroups = sampleGroupNames.every(name => newSampleGroupNames.includes(name));

      let hasSampleAndContainerTypes = true;
      if (hasAllSampleGroups && this.args.samples) {
        hasSampleAndContainerTypes = this.args.samples.every((sample) => {
          if (!sample.group) {
            return true;
          }

          const newSampleGroup = newSampleGroups.findBy('groupName', sample.group);
          const newSampleTypes = newSampleGroup.assignedSampleTypeNames.map(record => record.name);
          const newContainerTypes = newSampleGroup.containerTypesAllowed;

          return newSampleTypes.includes(sample._firstSampleState.label) &&
            newContainerTypes.includes(sample._firstSampleState.container.type);
        });
      }

      const newTestPanelKeys = template.requisitionTemplateAssociations.map(record => record.testPanel?.key).compact();
      const hasTestPanels = testPanelKeys.every(key => newTestPanelKeys.includes(key));

      return hasAllSampleGroups && hasSampleAndContainerTypes && hasTestPanels;
    });
  }

  @action
  changeSelectedTemplate(template) {
    if (this.args.onChange) {
      this.args.onChange(template);
    }
  }

  @action
  didUpdateTemplates() {
    let templates = this.filteredTemplates;
    // Set the only available choice as the selected template
    if (templates.length === 1) {
      this.changeSelectedTemplate(templates.toArray()[0]);
    }
  }
}
