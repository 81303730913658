define("ember-preferences/storage/decorator", ["exports", "@ember/object/mixin"], function (_exports, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _mixin.default.create({
    content: null,
    setItem(key, value) {
      return this.get('content').setItem(key, value);
    },
    getItem(key) {
      return this.get('content').getItem(key);
    },
    clear() {
      this.get('content').clear();
    },
    removeItem(key) {
      this.get('content').removeItem(key);
    }
  });
});