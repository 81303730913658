/* eslint-disable ember/no-classic-classes, key-spacing, no-trailing-spaces, ember/no-get, ember/require-return-from-computed, brace-style, block-spacing, quotes */
import Model, { belongsTo, attr } from '@ember-data/model';
import { notEmpty, or } from '@ember/object/computed';
import { computed } from '@ember/object';
import { isNone } from '@ember/utils';

export default Model.extend({
  workflowSampleResult:     belongsTo('workflow-sample-result', { async: false, inverse: 'assays' }),
  name:                     attr('string'),
  status:                   attr('string'),
  computedStatus:           attr('string'),
  reviewed:                 attr('boolean'),
  records:                  attr(),
  hasRecords:               notEmpty('records'),

  currentStatus: or('status', 'computedStatus'),

  rejected: computed('currentStatus', function() {
    let currentStatus = this.get('currentStatus');
    return currentStatus === 'rejected';
  }),

  accepted: computed('currentStatus', function() {
    let currentStatus = this.get('currentStatus');
    return currentStatus === 'accepted';
  }),

  ignored: computed('currentStatus', function() {
    let currentStatus = this.get('currentStatus');
    return currentStatus === 'ignore';
  }),

  currentStatusChanged: computed('computedStatus', 'status', function() {
    let status = this.get('status');
    let computedStatus = this.get('computedStatus');
    let filteredStatus = ['unknown'];
    return computedStatus && status && status !== computedStatus && !filteredStatus.includes(computedStatus);
  }),

  computedStatusLabel: computed('computedStatus', function() {
    let computedStatus = this.get('computedStatus');
    let computedStatusLabels = {
      'accepted': 'Accept',
      'rejected': 'Reject',
      'repeat':   'Repeat',
      'ignore':   'Ignore'
    };

    if (computedStatus) {
      return computedStatusLabels[computedStatus];
    }
  }),

  // all records for specified assay will have the same keys
  // use first assay record to represent header names
  recordHeaders: computed('records.firstObject', function() {
    return Object.keys(this.get('records.firstObject') || {});
  }),

  priority: computed('currentStatus', 'records', function() {
    let lessPriority = Number.MAX_VALUE;
    let statusPriority = { 'rejected': 1, 'repeat': 2, 'ignore': 3, 'accepted': 4 };
    let priority = lessPriority;
    let records = this.get('records');
    let status = this.get('currentStatus');

    if (records) {
      let priorities = records.map((record) => {return record.sort_priority ? record.sort_priority : lessPriority;});
      priority = Math.min(...priorities);
    }
    return [priority, statusPriority[status]].join('');
  }),

  icon: computed('records', function() {
    let records = this.get('records');
    let icon = "";
    if (records) {
      records.forEach((r) => {
        if (r.icon_class) {
          icon = r.icon_class;
          return icon;
        }
      });
    }
    return icon;
  }),

  iconColor: computed('records', function() {
    let records = this.get('records');
    let color = "";
    if (records) {
      records.forEach((r) => {
        if (r.icon_color) {
          color = r.icon_color;
          return color;
        }
      });
    }
    return color;
  }),

  // returns the values of all records in the same order as the specified headerNames
  recordRows:  computed('name', 'recordHeaders', 'records', function() {
    let records = this.get('records');
    let headers = this.get('recordHeaders');
    let name = this.get('name');
    if (isNone(headers) || isNone(records)) {
      return [];
    }

    return records.map((record) => {
      return headers.map((key) => {
        let value = record[key];
        // to support null filename in result
        if (name === 'file' && key === 'filename' && !value) {
          return 'Missing file';
        }
        return value;
      });
    });
  })

});
