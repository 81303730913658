/* eslint-disable ember/no-classic-components, ember/no-classic-classes */
import Component from '@ember/component';
import { task, timeout } from 'ember-concurrency';
import layout from './template';

const DEFAULT_TIMEOUT = 500;

export default Component.extend({
  layout,
  tagName: '',

  type: 'text',

  timeout: DEFAULT_TIMEOUT,

  updateValue: task(function *(value) {
    yield timeout(this.timeout);
    this.set('value', value);

    if (this.onChange) {
      this.onChange(value);
    }
  }).restartable()
});
