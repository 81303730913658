/* eslint-disable semi, ember/no-classic-classes, object-shorthand */
import Transform from '@ember-data/serializer/transform';
import EmberObject from '@ember/object';
import { A } from '@ember/array';

const emberize = function(object) {
  if (!object) {
    return;
  }

  let emberObject = EmberObject.create();
  let keys = Object.keys(object);

  for (let i = 0; i < keys.length; i++) {
    let key = keys[i];

    if (typeof object[key] === 'object') {
      if (Array.isArray(object[key])) {
        let items = object[key].map((item) => emberize(item))
        emberObject[key] = A(items);
      } else {
        emberObject[key] = emberize(object[key]);
      }
    } else {
      emberObject[key] = object[key];
    }
  }

  return emberObject;
}

/**
 * This is an `attr` transforms for objects so that they can be tracked
 * and used in computed properties as expected. Without this transforms
 * a plain object from API will not be useable in CPs
 */
export default Transform.extend({
  deserialize: function(serialized) {
    return emberize(serialized);
  },

  serialize: function(deserialized) {
    return deserialized;
  }
});
