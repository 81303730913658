/* eslint-disable ember/no-classic-classes */
import Model, { hasMany, belongsTo, attr } from '@ember-data/model';

export default Model.extend({
  // Associations
  requisitionTemplate: belongsTo('requisitionTemplate'),
  requisitionTemplateGlobalCustomAttributes: hasMany('requisition-template-global-custom-attribute', { async: false }),
  samples: hasMany('sample'),
  sampleTypes: hasMany('sample-type-name'),
  assignedSampleTypeNames: hasMany('assigned-sample-type-name', { async: false }),

  // Attributes
  groupName: attr('string'),
  groupOrder: attr('number', { defaultValue: 0 }),
  samplesRequired: attr('number'),
  samplesAllowed: attr('number'),
  enforceSingleIdentifier: attr('boolean'),
  hasDependencies: attr('boolean'),
  requiredCollectionDatetime: attr('boolean', { defaultValue: true }),
  sampleIdentifierInterpolation: attr('string'),
  containerTypesAllowed: attr(),
});
